import { atom } from "recoil";

export const requestFormData = atom({
    key: "requestFormData",
    default: {
        moc_date: new Date().toISOString(),
        moc_no: "",
        requisition_by: "",
        department: "",
        change_type: "",
        brief_description: "",
        classification: "",
        particulars: "",
        current_status: "",
        change_required: "",
        reason_change_required: ""
    }
});

export const changeAssessmentData = atom({
    key: "changeAssessmentData",
    default: {
        change_affects: [],
        results: Array(1).fill("#B51849"),
        checkpointsRemarks: Array(1).fill(""),
        environmentResults: Array(1).fill(""),
        healthResults: Array(1).fill(""),
        safetyResults: Array(1).fill(""),
        fireResults: Array(1).fill(""),
        environmentRemarks: Array(1).fill(""),
        healthRemarks: Array(1).fill(""),
        safetyRemarks: Array(1).fill(""),
        fireRemarks: Array(1).fill(""),
    }
});

export const conclusionData = atom({
    key: "conclusionData",
    default: {
        conclusion: "",
        authority_name: "",
        approval_remarks: "",
        // designation: "",
        submission_status: "",
        moc_no: "",
        qualityName: "",
        qualityRemarks: "",
        plantApprovalCheck: "",
        plantApprovalName: "",
        plantApprovalRemarks: "",
        safetyName: "",
        safetyRemarks: "",
    }
});

export const getMocDataID = atom({
    key: "getMocDataID",
    default: {
        request_form_id: "",
        assessment_id: "",
        actionplan_id: "",
        trials_id: "",
        trialsApproval_id: ""
    }
});

export const editMocData = atom({
    key: "editMocData",
    default: false,
});

export const editUserDatastatus = atom({
    key: "editUserDatastatus",
    default: false,
});
export const editActionPlanData = atom({
    key: "editActionPlanData",
    default: false,
});

export const actionPlanData = atom({
    key: "actionPlanData",
    default: Array(8).fill(null).map(() => ({
        // descriptions: "",
        action_required: "",
        targetDate: "",
        completeDate: "",
        responsibility: ""
    }))
});

export const trialsData = atom({
    key: "trialsData",
    default: {
        beforeComments: "",
        afterComments: ""
    }
});

export const documentData = atom({
    key: "documentData",
    default: Array(10).fill(null).map(() => ({
        descriptions: "",
        applicability: "",
        targetDate: "",
        status: ""
    }))
});

export const trialsApprovalData = atom({
    key: "trialsApprovalData",
    default: {
        trialsApprovalRemarks: "",
        trialsQualityRemarks: "",
        trialsSafetyRemarks: ""
    }
});
export const formDataState = atom({
    key: 'formDataState',
    default: {
        0: { selectedValues: {}, images: {}, remarks: {} }, // Step 0
        1: { selectedValues: {}, images: {}, remarks: {} },
        2: { selectedValues: {}, images: {}, remarks: {} },
        3: { selectedValues: {}, images: {}, remarks: {} },
        4: { selectedValues: {}, images: {}, remarks: {} },
        5: { selectedValues: {}, images: {}, remarks: {} },
    }, // Initial state is an empty object
  });
  export const stepState = atom({
    key: 'stepState',
    default: 0, // Initialize with the first step
  });

  export const assessmentCreatedState = atom({
    key: 'assessmentCreatedState',
    default: false,
});

export const editfivesData = atom({
    key: "editfivesData",
    default: '',
});
  
  export const userData = atom({
    key: "userData",
    default: {
        firstName: '',
        lastName: '',
        sgid: '',
        email: '',
        role_id: '',
        plant_id: '',
        department_id: '',
        zone_area_id: '',
        created_by: ''
    }
});


export const RedTagData = atom({
    key: "RedTagData",
    default: {
        zone: '',
        category: '',
        sgid: '',
        itemName: '',
        sapNo: '',
        quantity: '',
        department_id: '',
        unitOfMeasurement: '',
        created_by: ''
    }
});

export const plantData = atom({
    key: "plantData",
    default: {
        plantName: ''
    }
});

export const zoneData = atom({
    key: "zoneData",
    default: {
        zone_area_id: '',
        zone_leader: '',
        group_leader: '',
        wcm_coordinator: '',
        Plant_head:'',
        Store_head:'',
        Auditor:'',
        zone_leader_name:'',
        group_leader_name:'',
        wcm_coordinator_name:'',
        Plant_head_name:'',
        Store_head_name:'',
        Auditor_name:'',
        Team_member_id: '', // Store the ID here
        Team_member_name: '',

    }
});
export const auditStep = atom({
    key: 'auditStep',
    default: 0, // Initialize with the first step
  });
// export const requestFormState = atom({
//     key: 'requestFormState',
//     default: {
//       selectedValues: {
//         question1: '', // Initialize each question with a default value or null
//       question2: '',
//       question3: '',
//       },
//       images: {
//         question1: '',
//       question2: '',
//       question3: '',
//       },
//       remarks: {
//         question1: '',
//         question2: '',
//         question3: '',
//       }
//     },
//   });
  
//   export const straightenState = atom({
//     key: 'straightenState',
//     default: {
//       selectedValues: {},
//       images: {},
//       remarks: {}
//     },
//   });