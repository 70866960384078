import configParam from "../config";

const API = {
  // Forms

  Insert_Assesment: configParam.API_URL + "Insert_Assesment",
  ROLE: configParam.API_URL + "/get_roles",
  DEPARTMENTS: configParam.API_URL + "/get_departments",
  PLANTS: configParam.API_URL + "/get_plants",
  GET_ZONE_AREA: configParam.API_URL + "/get_zone_area",
  INSERT_USER: configParam.API_URL + "/insert_user",
  GET_USERS: configParam.API_URL + '/get_users',
  UPDATE_USER: configParam.API_URL + '/update_user',
  DELETE_USER: configParam.API_URL + '/delete_user',

  INSERT_ZONE_DEFINITIONS: configParam.API_URL + "/insert_zone_definitions",
  GET_ZONE_DEFINITIONS: configParam.API_URL + "/get_zone_definitions",
  UPDATE_ZONE_DEFINITIONS: configParam.API_URL + "/update_zone_definitions",
  DELETE_ZONE_DEFINITIONS: configParam.API_URL + "/delete_zone_definitions",
  GET_USERS_MASS: configParam.API_URL +'/get_users_byplant',
  INSERT_EMAIL_REPORT:configParam.API_URL +'/insert_email_report',
  SEND_MAIL_API:configParam.API_URL +'/send_mail_api'

};

export default API;