import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Header from '../../app/TopAppBar';
import { Card, Popover, IconButton, Tabs, Tab, Typography, Box, Button, MenuItem, Checkbox, Accordion, AccordionSummary, AccordionDetails, FormControlLabel,TextField,Container,Grid,CardContent, Avatar} from '@mui/material';

import axios from 'axios';
import configParam from '../../config';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '../../Asset/Icons/ic_edit.svg';
import { useNavigate } from 'react-router-dom';
import FilterIcon from '../../Asset/Icons/filter_icon.svg';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'; 
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import AddIcon from '@mui/icons-material/Add';
import NewEntry from '../MassFiveS/NewEntry';
const Mass = () => {
  const [massEntries, setMassEntries] = useState([]);
  const [filteredMassEntries, setFilteredMassEntries] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedEntryId, setSelectedEntryId] = useState(null);
  const [aggregatedData, setAggregatedData] = useState({});
  const access_token = JSON.parse(localStorage.getItem("accesstoken"));

  const [filters, setFilters] = useState({
    referenceNumbers: [],
    entryBy: [],
    location:[],
    fromDate: null,
    toDate: null,
});
const [anchorFilterEl, setAnchorFilterEl] = useState(null);
const [snackbarOpen, setSnackbarOpen] = useState(false);
  const navigate = useNavigate();
  const ApiURL = configParam.API_URL;
  const [entryDialogOpen, setEntryDialogOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [userRole, setuser] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getRole();
    fetchMassEntries();
    fetchAggregatedData()
  }, []);
  const entrybyOptions = [...new Set(massEntries.map(entry => entry.firstName + " " + entry.lastName))];
  const locationOptions = [...new Set(massEntries.map(entry => entry.location))];
  const isCoordinator = userRole.some(user => user.wcm_coordinator_firstname !== '' &&  user.wcm_coordinator_firstname !== null);
  console.log("isCoordinatormassfive",isCoordinator)
  const fetchMassEntries = async () => {
    try {
      const selectedPlantId = localStorage.getItem('selectedPlantId');
      // const response = await axios.get(`${ApiURL}/get_mass_entries`, { headers: { Authorization: `Bearer ${access_token}` } });
      const response = await axios.get(`${ApiURL}/get_mass_entries`, {
        params: { plantId: selectedPlantId },
        headers: { Authorization: `Bearer ${access_token}` }
    });
      setMassEntries(response.data);
      setFilteredMassEntries(response.data)
    } catch (error) {
      console.error('Error fetching leaders:', error);
    }
  };
  const fetchAggregatedData = async () => {
    try {
      const selectedPlantId = localStorage.getItem('selectedPlantId');
      const response = await axios.get(`${ApiURL}/get_aggregate_mass`, {
        params: { plantId: selectedPlantId },
        headers: { Authorization: `Bearer ${access_token}` }
    });
      // const response = await axios.get(`${ApiURL}/get_aggregate_mass`, { headers: { Authorization: `Bearer ${access_token}` } });
      setAggregatedData(response.data);
    } catch (error) {
      console.error('Error fetching aggregated data:', error);
    }
  };

  const getRole = () => {
    const userDatas = JSON.parse(localStorage.getItem("userDatas"));
  const userid = userDatas.sgid;
  const plantId = localStorage.getItem('selectedPlantId');
    axios.get(`${ApiURL}/getroleuser/${userid}/${plantId}`,{headers: {
        Authorization: `Bearer ${access_token}`,
       
      }})
        .then(response => {
            setLoading(false);
            if (response.status === 200) {
                if (response.data && response.data.length > 0) {
                    setuser(response.data);
                   
                }
            }
        })
        .catch(error => {
            setLoading(false);
            console.error("Error fetching assessment data:", error);
        });
  };
  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleMenuIconClick = (event, entryId) => {
    setAnchorEl(event.currentTarget);
    setSelectedEntryId(entryId);
  };

  
  const handleEditClick = (massId) => {
    handleClosePopover();
    setDialogOpen(true)
    // navigate('/newEntry', { state: { massId: selectedEntryId } });
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };


  const applyFilters = () => {
    // Apply filters to the original assessment data
    // For simplicity, I'll use filter() method here, you can adjust it according to your data structure
    let filteredData = massEntries.filter(entry => {
      const entryDate = new Date(entry.entry_date);
      console.log("entryDate",entryDate)
     
      const fromDate = filters.fromDate ? new Date(filters.fromDate + 'T00:00:00') : null;
      const toDate = filters.toDate ? new Date(filters.toDate + 'T23:59:59') : null;
      const isDateInRange = (date, from, to) => {
        if (from && to) {
            return date >= from && date <= to;
        } else if (from) {
            return date >= from;
        } else if (to) {
            return date <= to;
        }
        return true;
    };
      
        return (
            (filters.referenceNumbers.length === 0 || filters.referenceNumbers.includes(entry.ref_no)) &&
            (filters.entryBy.length === 0 || filters.entryBy.includes(entry.firstName + " " + entry.lastName)) &&
            (filters.location.length === 0 || filters.location.includes(entry.location))&&
            isDateInRange(entryDate, fromDate, toDate)
            // (!fromDateOnly || entryDateOnly >= fromDateOnly) && // Check if audit date is after or equal to the selected "from" date
            // (!toDateOnly || entryDateOnly <= toDateOnly)
          
        );
    });
    setFilteredMassEntries(filteredData);
    setSnackbarOpen(true);
};

const handleCheckboxChange = (filterName, value) => {
  setFilters({
      ...filters,
      [filterName]: value,
  });
};

const clearFilters = () => {
  setFilters({
    referenceNumbers: [],
    entryBy: [],
    location: [],
   fromDate: null,
    toDate: null,
  });
  
  setFilteredMassEntries(massEntries);
};

const handleDialogClose = () => {
  setDialogOpen(false); // Close the dialog
};
const handleAddClick = () => {
  setDialogOpen(true); 
  setSelectedEntryId(null);
   // Open the dialog
};
const handleSave = () => {
  fetchMassEntries();
  fetchAggregatedData()
  setDialogOpen(false);
};
  return (
    <div>
        <Paper
      component="form"
      sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: "100%",boxShadow:'none',borderBottom:'1px solid #e6e6e6',borderRadius:'0' }}
    >
      <Typography variant='h5' sx={{fontSize:'18px',fontWeight:'600',padding:'0 16px'}}>
                        Mass 5S
                    </Typography>
      {/* <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Search Audit"
        inputProps={{ 'aria-label': 'Filter' }}
        value={searchText}
        onChange={handleSearchInputChange}
      /> */}
      {/* <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
        <SearchIcon />
      </IconButton> */}
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      <IconButton onClick={(event) => setAnchorFilterEl(event.currentTarget)} aria-label="filter">
                        <FilterListIcon/>
                    </IconButton>
     
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      {isCoordinator && (
      <Button
              variant="contained"
              style={{
                background: '#0086DC',
                width: '130px',
                border: 0,
                borderRadius: 3,
                color: 'white',
                height: '34px',
                fontSize:'14px',
                textTransform:'capitalize',
                margin: '0 8px'
              }}
              onClick={handleAddClick}
            >
                        <AddIcon/>
              New Entry
            </Button>
            )}
                      </Paper> 
      {/* <Header pageTitle="Mass 5S" isMassPage={true} />  */}
    <div style={{  marginLeft: "20px", marginRight: "20px", marginBottom: "20px" }}>
   <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", margin: "20px" }}>
   {/* <IconButton onClick={(event) => setAnchorFilterEl(event.currentTarget)} aria-label="filter">
                    <img src={FilterIcon} alt="Filter" />
                </IconButton> */}
   </div>
   <Popover
    open={Boolean(anchorFilterEl)}
    anchorEl={anchorFilterEl}
    onClose={() => setAnchorFilterEl(null)}
    anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
    }}
    transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
    }}

>
    <div style={{ maxHeight: "500px"}}>
        <Grid container style={{ borderBottom: "1px solid #282A2D", display: "flex", alignItems: "center" }}>
            <Grid item xs={11}>
                <Box p={1} style={{ display: "flex", alignItems: "center", marginLeft: "10px" }}>
                    <Button
                        onClick={applyFilters}
                        style={{
                            fontWeight: "bold",
                            backgroundColor: "#00448b",
                            color: "#ffffff",
                            borderRadius: "5px",
                            height: "35px",
                        }}
                    >
                        Apply
                    </Button>
                    <Typography
                        style={{
                            fontWeight: 800,
                            color: "#FAAD14",
                            lineHeight: "16px",
                            marginLeft: "25px",
                            cursor: "pointer",
                            fontSize: "18px",
                        }}
                        onClick={clearFilters}
                    >
                        Reset
                    </Typography>
                </Box>
            </Grid>
           
        </Grid>
        <Card style={{ width: "300px", padding: "10px" }}>
            {/* Accordion for Assessment Numbers */}
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography style={{ fontSize: 16, fontWeight: "bold", color: "#000000" }}>Reference Numbers</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div style={{ display: 'block' }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={filters.referenceNumbers.length === massEntries.map(entry => entry.ref_no).length}
                                    indeterminate={
                                        filters.referenceNumbers.length > 0 && filters.referenceNumbers.length < massEntries.map(entry => entry.ref_no).length
                                    }
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            // If "Select All" is checked, select all options
                                            handleCheckboxChange('referenceNumbers', massEntries.map(entry => entry.ref_no));
                                        } else {
                                            // If "Select All" is unchecked, deselect all options
                                            handleCheckboxChange('referenceNumbers', []);
                                        }
                                    }}
                                />
                            }
                            label="Select All"
                        />
                        {massEntries.map(entry => (
                            <FormControlLabel
                                key={entry.id}
                                control={
                                    <Checkbox
                                        checked={filters.referenceNumbers.includes(entry.ref_no)}
                                        onChange={(e) => handleCheckboxChange('referenceNumbers', e.target.checked ? [...filters.referenceNumbers, entry.ref_no] : filters.referenceNumbers.filter(item => item !==entry.ref_no))}
                                    />
                                }
                                label={entry.ref_no}
                                style={{ display: 'block' }}
                            />
                        ))}
                    </div>
                </AccordionDetails>
            </Accordion>
            {/* Accordion for Auditors */}
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography style={{ fontSize: 16, fontWeight: "bold", color: "#000000" }}>Entry By</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div style={{ display: 'block' }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={filters.entryBy.length === entrybyOptions.length}
                                    indeterminate={
                                        filters.entryBy.length > 0 && filters.entryBy.length < entrybyOptions.length
                                    }
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            // If "Select All" is checked, select all options
                                            handleCheckboxChange('entryBy', entrybyOptions);
                                        } else {
                                            // If "Select All" is unchecked, deselect all options
                                            handleCheckboxChange('entryBy', []);
                                        }
                                    }}
                                />
                            }
                            label="Select All"
                        />
                        {entrybyOptions.map((entryby, index) => (
                            <FormControlLabel
                                key={index}
                                control={
                                    <Checkbox
                                        checked={filters.entryBy.includes(entryby)}
                                        onChange={(e) => handleCheckboxChange('entryBy', e.target.checked ? [...filters.entryBy, entryby] : filters.entryBy.filter(item => item !== entryby))}
                                    />
                                }
                                label={entryby}
                                style={{ display: 'block' }}
                            />
                        ))}
                    </div>
                </AccordionDetails>
            </Accordion>
            {/* Accordion for Zones */}
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography style={{ fontSize: 16, fontWeight: "bold", color: "#000000" }}>Location</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div style={{ display: 'block' }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                // checked={filters.zones.length === (zoneOptions?.length || 0)}
                                    checked={filters.location.length === locationOptions.length}
                                    indeterminate={
                                        filters.location.length > 0 && filters.location.length < locationOptions.length
                                    }
                                 
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            // If "Select All" is checked, select all options
                                            handleCheckboxChange('location', locationOptions);
                                        } else {
                                            // If "Select All" is unchecked, deselect all options
                                            handleCheckboxChange('location', []);
                                        }
                                    }}
                                />
                            }
                            label="Select All"
                        />
                        {locationOptions.map((loc,index) => (
                            <FormControlLabel
                                key={index}
                                control={
                                    <Checkbox
                                        checked={filters.location.includes(loc)}
                                        onChange={(e) => handleCheckboxChange('location', e.target.checked ? [...filters.location, loc] : filters.location.filter(item => item !== loc))}
                                    />
                                }
                                label={loc}
                                style={{ display: 'block' }}
                            />
                        ))}
                    </div>
                </AccordionDetails>
            </Accordion>
            {/* Accordion for Audit Dates */}
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography style={{ fontSize: 16, fontWeight: "bold", color: "#000000" }}>Entry Dates</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div style={{ display: 'block' }}>
                        {/* From Date Picker */}
                        <TextField
                            label="From Date"
                            type="date"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={filters.fromDate}
                            onChange={(e) => setFilters({ ...filters, fromDate: e.target.value })}
                        />
                        {/* To Date Picker */}
                        <TextField
                            label="To Date"
                            type="date"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={filters.toDate}
                            onChange={(e) => setFilters({ ...filters, toDate: e.target.value })}
                        />
                    </div>
                </AccordionDetails>
            </Accordion>
        </Card>
    </div>
</Popover>
    
    <Card
    style={{width:"100%", background: "linear-gradient(to left, rgb(54 174 213), rgb(40 74 177))", padding: "10px", borderRadius: "10px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", marginBottom: "20px", position: 'relative' }}
          
        >
          <CardContent style={{ position: "relative", height: "194px",width:'343px' }}>
          <Typography style={{ fontSize: '14px', fontWeight: 500, color: '#F5F5F5', position: 'absolute', top: '10px', left: '10px' }}>Total Value Generated</Typography>
          <Typography style={{ fontSize: '50px', fontWeight: 700, color: '#F5F5F5', position: 'absolute',top:'28px', left: '10px' }}>{aggregatedData.totalValueGen}</Typography>
    <Typography style={{ fontSize: '14px', fontWeight: 500, color: '#F5F5F5', position: 'absolute', bottom: '40px', left: '500%', transform: 'translateX(-50%)', whiteSpace: 'nowrap' }}>Total Space</Typography>
    <Typography style={{ fontSize: '32px', fontWeight: 700, color: '#F5F5F5', position: 'absolute', bottom: '3px', left: '500%', transform: 'translateX(-50%)', whiteSpace: 'nowrap' }}> {aggregatedData.totalSpaceReclaim}</Typography>
    <Typography style={{ fontSize: '14px', fontWeight: 500, color: '#F5F5F5', position: 'absolute', bottom: '44px', left: '10px' }}>Total Mass 5S</Typography>
    <Typography style={{ fontSize: '32px', fontWeight: 700, color: '#F5F5F5', position: 'absolute', bottom: '8px', left: '10px' }}>{aggregatedData.totalEntries}</Typography>
    <Typography style={{ fontSize: '14px', fontWeight: 500, color: '#F5F5F5', position: 'absolute', bottom: '40px', left: '250%', transform: 'translateX(-50%)', whiteSpace: 'nowrap' }}>Total People</Typography>
   
    <Typography style={{ fontSize: '32px', fontWeight: 700, color: '#F5F5F5', position: 'absolute',bottom: '3px', left: '250%', transform: 'translateX(-50%)' , whiteSpace: 'nowrap'}}>{aggregatedData.totalPeopleAttend}</Typography>
          </CardContent>
        </Card>


        <Grid container spacing={2}>
        {filteredMassEntries.map((entry, index) => (
          <Grid item xs={12} md={6} lg={4}> 
          <Card key={entry.id}
          
    
          style={{ width:"100%",display: 'flex', position: 'relative', marginBottom: '20px', alignItems: 'center', padding: '10px',borderRadius: "8px",
              border: "1px solid #E8E8E8",boxShadow: "0px 2px 12px 0px rgba(0, 0, 0, 0.15)", }}
           
            variant="outlined">
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

              {entry.img_aft.length > 0 ? (
        <img
          src={`${ApiURL}/api/imagesMass/${entry.img_aft[entry.img_aft.length - 1]}`} // Display the latest image
          alt="Entry"
          style={{ width: '170px', height: '170px', objectFit: 'cover', borderRadius: '8px' }}
        />
      ) : (
        <Typography style={{ fontSize: '15px', color: '#616161', marginTop: '15px', fontWeight: 400 }}>
          No image available
        </Typography>
      )}
            {/* <img
              src={`${ApiURL}/api/imagesMass/${entry.img_aft}`} // Assuming the image URL is stored in entry.mass_img
              alt="Entry"
              style={{ width: '170px', height: '170px', objectFit: 'cover', borderRadius: '8px' }}
            /> */}
             {entry.entry_date && (
                <Typography style={{ fontSize: '15px', color: '#616161', marginTop: '15px',fontWeight:400 }}>
                  Date: {formatDate(entry.entry_date)}
                </Typography>
              )}
           </div>
            <CardContent style={{ display: 'flex', flexDirection: 'column', paddingLeft: '20px',marginBottom:"45px" }}>
              <Typography style={{ fontSize: '16px', fontWeight: 400, color: '#616161' }}>Reference No </Typography>
              <Typography style={{ fontSize: '16px', fontWeight: 600, color: '#000000' }}>
                {entry.ref_no}
              </Typography>

              <Typography style={{ fontSize: '16px', fontWeight: 400, color: '#616161',marginTop:'18px' }}>Value Generated </Typography>
              <Typography style={{ fontSize: '16px', fontWeight: 600, color: '#000000' }}>
                Rs.{entry.value_gen}
              </Typography>
              <Typography style={{ fontSize: '16px', fontWeight: 400, color: '#616161',marginTop:'18px' }}>Location </Typography>
              <Typography style={{ fontSize: '16px', fontWeight: 600, color: '#000000' }}>
               {entry.location}
              </Typography>
             
              <Typography
      style={{
        position: 'absolute',
        bottom: '15px',
        right: '15px',
        fontSize: '16px',
        fontWeight: 400,
        color: '#616161'
      }}
    >
       <div style={{ marginBottom: "5px" }}>Mass Entry By:  {entry.firstName + " " + entry.lastName}</div>
    </Typography>
              
             
            </CardContent>
            <Box position="absolute" top={8} right={8}> {/* Button positioned in top-right corner */}
              <IconButton aria-label="options" onClick={(event) => handleMenuIconClick(event, entry)}>
                <MoreVertIcon style={{ color: 'black' }} />
              </IconButton>
            </Box>
          </Card>
          </Grid>
        ))}
        </Grid>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClosePopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {isCoordinator && (
          <div onClick={handleEditClick} style={{ display: "flex", alignItems: 'center', cursor: 'pointer', padding: "10px" }}>
            <img src={EditIcon} alt="Edit Icon" style={{ marginRight: '10px' }} />
            <Typography
              style={{
                color: '#252C32',
                fontFamily: 'Inter',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 800,
                lineHeight: 'normal',
                letterSpacing: '-0.084px',
              }}>Edit</Typography>
          </div>
          )}
        </Popover>
      </div>

      {dialogOpen && (
          <NewEntry
            open={dialogOpen}
            onClose={handleDialogClose}
            entryId={selectedEntryId ? selectedEntryId.id : null}
            onSave={handleSave}
          />
        )}
      {/* <NewEntry  open={dialogOpen}
        onClose={handleDialogClose}
       /> */}
    </div>
  );
};

export default Mass;
