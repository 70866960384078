
import React, { useEffect, useState,useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import Header from '../../app/TopAppBar';
import { Card, Popover, IconButton, Tabs, Tab,Avatar, Typography, Box, Button, MenuItem, Checkbox, Accordion, AccordionSummary, AccordionDetails, FormControlLabel,TextField,Container,Grid, Tooltip} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
// import {ExpandMoreIcon,ExpandLessIcon} from '@mui/icons-material/ExpandMore';
 // Import ExpandMoreIcon
 import {
   
    ExpandMore as ExpandMoreIcon,
    ExpandLess as ExpandLessIcon
} from '@mui/icons-material';
import EditIcon from '../../Asset/Icons/ic_edit.svg';
import DeleteIcon from '../../Asset/Icons/ic_delete.svg';
import axios from 'axios';
import configParam from '../../config';
import { useNavigate } from 'react-router-dom';
import AlertDialog from '../input/AlertDialog';
import MaskGroup from '../../Asset/Icons/MaskGroup.svg';
import NewAssessment from '../RequestForm/NewAssessment';
import { useHistory } from 'react-router-dom';
import HorizontalCombinedStepper from '../RequestForm/HorizontalCombinedStepper';
import FilterIcon from '../../Asset/Icons/filter_icon.svg';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import SgLogo from '../../Asset/Icons/SgLogo.png';
import { useMediaQuery,  createTheme } from '@mui/material';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import AddIcon from '@mui/icons-material/Add';
import { editMocData, requestFormData, changeAssessmentData, conclusionData,formDataState, RedTagData } from "../../recoil/atoms";

import { useRecoilState,useResetRecoilState } from "recoil";
const groupAuditsByMonthAndStatus = (audits) => {
    return audits.reduce((acc, audit) => {
        const date = new Date(audit.audit_date);
        const month = date.toLocaleString('default', { month: 'long', year: 'numeric' });
        
        if (!acc[month]) {
            acc[month] = {
                allAudits: [],
                pendingAudits: [],
                completedAudits: []
            };
        }

        acc[month].allAudits.push(audit);

        if (audit.status_flg === 'P') {
            acc[month].pendingAudits.push(audit);
        } else if (audit.status_flg === 'C') {
            acc[month].completedAudits.push(audit);
        }

        return acc;
    }, {});
};
const Audit = () => {
    const location = useLocation();
    
    const { assessmentData } = location.state || {};
    const [originalUsers, setOriginalUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [userRole, setuser] = useState([]);
    const navigate = useNavigate();
    const ApiURL = configParam.API_URL;
    const [anchorEl, setAnchorEl] = useState(null);
    const [editUser, setEditUser] = useState(null);
    const [tabValue, setTabValue] = useState(0);
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState({
        assessmentNumbers: [],
        auditors: [],
        zones:[],
        fromDate: null,
        toDate: null,
    });
    const access_token = JSON.parse(localStorage.getItem("accesstoken"));

    const resetDealerValue = useResetRecoilState(formDataState);

    const [isFilterOpen, setIsFilterOpen] = useState(false); // Define isFilterOpen state variable
    const [searchText, setSearchText] = useState('');
    const [anchorFilterEl, setAnchorFilterEl] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedValues, setSelectedValues] = useState({});
    const [images, setImages] = useState({});
    const [remarks, setRemarks] = useState({});
    const isMobile = useMediaQuery('(max-width:600px)');
    const [dialogOpen, setDialogOpen] = useState(false);
    const [formData] = useRecoilState(formDataState);
     const [AssessDialogOpen, setAssessDialogOpen] = useState(false);
     const [expanded, setExpanded] = useState({});

     useEffect(() => {
        if (location.state?.openDialog) {
            setAssessDialogOpen(true);
        }
    }, [location.state]);

    useEffect(() => {
        getRole();
    }, []);

    useEffect(() => {
        if (userRole.length > 0) {
            loadAssessmentData();
        }
    }, [userRole]);
    const handleToggle = (month) => {
        setExpanded((prev) => ({ ...prev, [month]: !prev[month] }));
    };
    const auditorOptions = useMemo(() => {
        const uniqueAuditors = [...new Set(originalUsers.map(user => `${user.firstName} ${user.lastName}`))];
        return uniqueAuditors.map(auditor => ({
            display: auditor,
            normalized: auditor.trim().toLowerCase(),
        }));
    }, [originalUsers]);

    const zoneOptions = useMemo(() => {
        const uniqueZones = [...new Set(originalUsers.map(user => user.name))];
        return uniqueZones.map(zone => ({
            display: zone,
            normalized: zone.trim().toLowerCase(),
        }));
    }, [originalUsers]);

   
    // Define options for select dropdowns
    // const auditorOptions = [...new Set(originalUsers.map(user => user.firstName + " " + user.lastName))];
    
    const auditDateOptions = [...new Set(originalUsers.map(user => new Date(user.audit_date).toLocaleDateString()))];


    // const zoneOptions = [...new Set(originalUsers.map(user => user.name))];


const isCoordinator = userRole.some(user => user.wcm_coordinator_firstname);


    const loadAssessmentData = () => {
        if (isCoordinator) {
        
            setLoading(true);
          const selectedPlantId = localStorage.getItem('selectedPlantId');
        axios.get(`${ApiURL}/getassesment/${selectedPlantId}`,{headers: {
            Authorization: `Bearer ${access_token}`
            
          }})
            .then(response => {
                setLoading(false);
                if (response.status === 200) {
                    if (response.data && response.data.length > 0) {
                        setOriginalUsers(response.data);
                        setFilteredUsers(response.data);
                        
                    }
                }
            })
            .catch(error => {
                setLoading(false);
                console.error("Error fetching assessment data:", error);
            });

        }
        else{
console.log("userloadassses",localStorage.getItem('selectedPlantId'))
            setLoading(true);
            const userby = JSON.parse(localStorage.getItem("userDatas"));
            const usersgid = userby.sgid.replace(/"/g, '');
            const selectedPlantId = localStorage.getItem('selectedPlantId');
          axios.get(`${ApiURL}/getassesmentbaseonuser/${selectedPlantId}/${usersgid}`,{headers: {
              Authorization: `Bearer ${access_token}`
              
            }})
              .then(response => {
                  setLoading(false);
                  if (response.status === 200) {
                      if (response.data && response.data.length > 0) {
                      
                          setOriginalUsers(response.data);
                          setFilteredUsers(response.data);
                          
                      }
                  }
              })
              .catch(error => {
                  setLoading(false);
                  console.error("Error fetching assessment data:", error);
              });
        }
        
    };
   
    const getRole = () => {
        const userDatas = JSON.parse(localStorage.getItem("userDatas"));
   const userid = userDatas.sgid;
   const plantId = localStorage.getItem('selectedPlantId');
        axios.get(`${ApiURL}/getroleuser/${userid}/${plantId}`,{headers: {
            Authorization: `Bearer ${access_token}`,
           
          }})
            .then(response => {
                setLoading(false);
                if (response.status === 200) {
                    if (response.data && response.data.length > 0) {
                        setuser(response.data);
                                    
                    }
                }
            })
            .catch(error => {
                setLoading(false);
                console.error("Error fetching assessment data:", error);
            });
    };
 
    
    const applyFilters_old = () => {
        // Apply filters to the original assessment data
        // For simplicity, I'll use filter() method here, you can adjust it according to your data structure
        let filteredData = originalUsers.filter(user => {
          const auditDate = new Date(user.audit_date);
          const fromDate = filters.fromDate ? new Date(filters.fromDate) : null;
          const toDate = filters.toDate ? new Date(filters.toDate) : null;
          const auditDateOnly = auditDate.toLocaleDateString(); // Convert to "M/D/YYYY" format
          const fromDateOnly = fromDate ? fromDate.toLocaleDateString() : null; // Convert to "M/D/YYYY" format
          const toDateOnly = toDate ? toDate.toLocaleDateString() : null;
      
            return (
                (filters.assessmentNumbers.length === 0 || filters.assessmentNumbers.includes(user.ass_no)) &&
                (filters.auditors.length === 0 || filters.auditors.includes(user.firstName + " " + user.lastName)) &&
                (filters.zones.length === 0 || filters.zones.includes(user.name))&&
                (!fromDateOnly || auditDateOnly >= fromDateOnly) && // Check if audit date is after or equal to the selected "from" date
                (!toDateOnly || auditDateOnly <= toDateOnly)
                // (filters.auditDates.length === 0 || filters.auditDates.includes(new Date(user.audit_date).toLocaleDateString()))
            );
        });
        setFilteredUsers(filteredData);
        setSnackbarOpen(true);
    };
    const applyFilters = () => { 
        console.log("Applying filters:", filters);
        let filteredData = originalUsers.filter(user => {
            const auditDate = new Date(user.audit_date);
            const fromDate = filters.fromDate ? new Date(filters.fromDate) : null;
            const toDate = filters.toDate ? new Date(filters.toDate) : null;

            const isWithinDateRange = (!fromDate || auditDate >= fromDate) && (!toDate || auditDate <= toDate);

            const assessmentNumberMatch = filters.assessmentNumbers.length === 0 || filters.assessmentNumbers.includes(user.ass_no);
            const auditorFullName = `${user.firstName} ${user.lastName}`.trim().toLowerCase();
            const auditorMatch = filters.auditors.length === 0 || filters.auditors.includes(auditorFullName);
            const zoneName = user.name.trim().toLowerCase();
            const zoneMatch = filters.zones.length === 0 || filters.zones.includes(zoneName);

            return assessmentNumberMatch && auditorMatch && zoneMatch && isWithinDateRange;
        });

        console.log("Filtered data:", filteredData);
        setFilteredUsers(filteredData);
        setSnackbarOpen(true);
    };
    const handleCheckboxChange = (filterName, value) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            [filterName]: value,
        }));
    };
//old code
    // const applyFilters = () => {
    //     let filteredData = originalUsers.filter(user => {
    //         const auditDate = new Date(user.audit_date);
    //         const fromDate = filters.fromDate ? new Date(filters.fromDate) : null;
    //         const toDate = filters.toDate ? new Date(filters.toDate) : null;
    
    //         // Only compare dates without considering time
    //         const isWithinDateRange = (!fromDate || auditDate >= fromDate) && (!toDate || auditDate <= toDate);
    
    //         return (
    //             (filters.assessmentNumbers.length === 0 || filters.assessmentNumbers.includes(user.ass_no)) &&
    //             (filters.auditors.length === 0 || filters.auditors.includes(user.firstName + " " + user.lastName)) &&
    //             (filters.zones.length === 0 || filters.zones.includes(user.name)) &&
    //             isWithinDateRange
    //         );
    //     });
    //     console.log("Filtered data:", filteredData);
    //     setFilteredUsers(filteredData);
    //     setSnackbarOpen(true);
    // };
    


    // const handleCheckboxChange = (filterName, value) => {
    //     setFilters({
    //         ...filters,
    //         [filterName]: value,
    //     });
    // };

    const clearFilters = () => {
        setFilters({
            assessmentNumbers: [],
            auditors: [],
            
            zones:[],
            fromDate: null,
            toDate: null,
        });
        
        setFilteredUsers(originalUsers);
        setExpanded({});
    };

    const handleEdit = (user) => {
        const assessmentId = user.row_id;
        const  assessmenteditflg =true;
        localStorage.setItem("assessmentId",assessmentId)
        localStorage.setItem("assessmenteditflg",assessmenteditflg);
        // navigate('/horizontalCombinedStepper');
         setAssessDialogOpen(true);
    };

    const handleMenuIconClick = (event, user) => {
        setAnchorEl(event.currentTarget);
        setEditUser(user);
        setSelectedUser(user);
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
        setEditUser(null);
    };

    

    // const handleTabChange = (event, newValue) => {
    //            // console.log("newValue",newValue)
    //             setTabValue(newValue);
    //             if (newValue === '0') {
    //               //  console.log("newValue1",newValue)
    //                 loadAssessmentData();
    //             } if (newValue === '1') {
    //                 //console.log("newValue2",newValue)
    //                 loadPendingAssessments();
    //             } if  (newValue === '2') {
    //               //  console.log("newValue3",newValue)
    //                 loadCompletedAssessments();
    //             }
    //         };



            const handleTabChange = (event, newValue) => {
                setTabValue(newValue);
                if (newValue === 0) {
                  loadAssessmentData();
                } else if (newValue === 1) {
                  loadPendingAssessments();
                } else if (newValue === 2) {
                  loadCompletedAssessments();
                }
              };
    const loadPendingAssessments = () => {
        setOriginalUsers([]);
        setFilteredUsers([]);
        const selectedPlantId = localStorage.getItem('selectedPlantId');
        const userby = JSON.parse(localStorage.getItem("userDatas"));
        const UserID = userby.sgid.replace(/"/g, '');
        if(isCoordinator === true){
            
        axios.get(`${ApiURL}/get_pendingassesment/${selectedPlantId}`,{headers: {
            Authorization: `Bearer ${access_token}`
            
          }})
            .then(response => {
                if (response.status === 200) {
                    if (response.data && response.data.length > 0) {
                        setOriginalUsers(response.data);
                        setFilteredUsers(response.data);
                    }
                }
            })
            .catch(error => {
                console.error("Error fetching pending assessments:", error);
            });
        }
        else{
            axios.get(`${ApiURL}/get_pendingassesmentuser/${selectedPlantId}/${UserID}`,{headers: {
                Authorization: `Bearer ${access_token}`
                
              }})
                .then(response => {
                    if (response.status === 200) {
                        if (response.data && response.data.length > 0) {
                            setOriginalUsers(response.data);
                            setFilteredUsers(response.data);
                        }
                    }
                })
                .catch(error => {
                    console.error("Error fetching pending assessments:", error);
                });

        }
    };
    
    const loadCompletedAssessments = () => {
        setOriginalUsers([]);
        setFilteredUsers([]);
        const userby = JSON.parse(localStorage.getItem("userDatas"));
        const UserID = userby.sgid.replace(/"/g, '');
        const selectedPlantId = localStorage.getItem('selectedPlantId');
        if(isCoordinator=== true){

        
        
        axios.get(`${ApiURL}/get_completedassesment/${selectedPlantId}`,{headers: {
            Authorization: `Bearer ${access_token}`
            
          }})
            .then(response => {
                if (response.status === 200) {
                    if (response.data && response.data.length > 0) {
                        setOriginalUsers(response.data);
                        setFilteredUsers(response.data);
                    }
                }
            })
            .catch(error => {
                setLoading(false);
                console.error("Error fetching completed assessments:", error);
            });
        }
        else{
            axios.get(`${ApiURL}/get_completedassesmentuser/${selectedPlantId}/${UserID}`,{headers: {
                Authorization: `Bearer ${access_token}`
                
              }})
                .then(response => {
                    if (response.status === 200) {
                        if (response.data && response.data.length > 0) {
                            setOriginalUsers(response.data);
                            setFilteredUsers(response.data);
                        }
                    }
                })
                .catch(error => {
                    setLoading(false);
                    console.error("Error fetching completed assessments:", error);
                });

        }
    };
    const handleSearchInputChange = (e) => {
        setSearchText(e.target.value);
        const filteredData = originalUsers.filter(user =>
            user.ass_no.toLowerCase().includes(e.target.value.toLowerCase()) ||
            (user.firstName + " " + user.lastName).toLowerCase().includes(e.target.value.toLowerCase()) ||
            user.name.toLowerCase().includes(e.target.value.toLowerCase())
        );
        setFilteredUsers(filteredData);
    };
   
    const handleDownloadPDFnew = async (selectedUser) => {
        if (!selectedUser) {
            console.error('No user selected for PDF download');
            return;
        }
        try {
            const assessmentId = selectedUser.row_id;
       
            const response = await axios.get(`${ApiURL}/download-pdf/${assessmentId}`, {
                responseType: 'json', // Change the response type to JSON
                headers: {
                    Authorization: `Bearer ${access_token}`,
                   
                }
            });
            if (response.data) {
                const formData = response.data;
                const auditDate = new Date(formData.audit_date);
                const formattedAuditDate = auditDate.toLocaleDateString();
                setSelectedValues(formData.selectedValues || {});
                setRemarks(formData.remarks || {});
                setImages(formData.images || {}); // Update state with images
                const imagePromises = Object.values(formData.images || {}).map(async imageName => {
                    return new Promise((resolve, reject) => {
                        const img = new Image();
                        img.onload = resolve;
                        img.onerror = reject;
                        img.src = `${ApiURL}/api/images/${imageName}`;
                    });
                });
                await Promise.all(imagePromises); 
                // Construct HTML content including images
                const additionalTexts = {
                    'Unusable Items': {
                        title: '1S: SORT(SEIRI)',
                        description: 'Tidiness, Organization. Refers to the practice of sorting through all the tools, materials, etc., in the work area and keeping only essential items.'
                    },
                    'Work Stations': {
                        title: '2S: STRAIGHTEN(SEITON)',
                        description: 'Focuses on the need for an orderly workplace. Tools, equipment, and materials must be systematically arranged for the easiest and most efficient access.'
                    },
                    'Floor cleanliness': {
                        title: '3S: SHINE(SEISO)',
                        description: 'Indicates the need to keep the workplace clean as well as neat. Cleaning in Japanese companies is a daily activity.'
                    },
                    'Clean Standards': {
                        title: '4S: STANDARDISE (SEIKETSU)',
                        description: 'Allows for control and consistency. Basic housekeeping standards apply everywhere in the facility.'
                    },
                    'Employee Involvement': {
                        title: '5S: SUSTAIN(SHITSUKE)',
                        description: 'Refers to maintaining standards and keeping the facility in safe and efficient order day after day, year after year.'
                    },
                    'Emergency Exits': {
                        title: '5S : SAFETY',
                        description: 'Tidiness, Organization. Refers to the practice of sorting through all the tools, materials, etc., in the work area and keeping only essential items.'
                    }
                };
                let htmlContent = `
                <html>
                    <head>
                        <title>Assessment Details</title>
                        <style>
                            .title {
                                text-transform: uppercase;
                                font-size: 16px;
                                font-weight: 600;
                                color: rgb(23, 66, 139);
                                margin-bottom: 10px;
                            }
                            .additional-title {
                                font-size: 18px; /* Adjusted font size */
                                font-weight: bold;
                                color: #17428B; /* Same font color */
                                margin-bottom: 10px;
                            }
                            .additional-description {
                                margin-bottom: 10px;
                                font-size: 11px;
                                font-weight: 500;
                            }
                            .heading {
                                text-transform: uppercase;
                                font-size: 14px;
                                font-weight: 500;
                                color: #17428B;
                                margin-bottom: 10px;
                                margin-top: 20px;
                            }
                            .question {
                                font-weight: bold;
                            }
                            img {
                                
                                max-width: 100%;
                                height: auto;
                                page-break-inside: avoid;
                            }
                            .logo {
                                display: block;
                                margin: 0 auto;
                            }
                            .header-details {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                margin-top: 20px;
                            }
                            .total-score {
                                font-weight: bold;
                                font-size: 25px;
                                color: #4fb0b4; /* Adjusted color to match the example */
                            }
                            .assessment-number {
                                font-weight: bold;
                                font-size: 18px;
                                color: #17428B;
                            }
                            .center-heading {
                                text-align: center; /* Center the heading */
                                font-size: 30px; /* Adjust as needed */
                                font-weight: bold; /* Adjust as needed */
                                
                                margin-top: 20px;
                            }
                            .name-code-container {
                                display: flex;
                                justify-content: space-between;
                            }
                            .name-code-container .code {
                                font-size: 16px;
                                font-weight: bold;
                            }
                            .name-code-container .name {
                                font-size: 16px;
                                font-weight: bold;
                            }
                            .first-last-name-container {
                                display: flex;
                                justify-content: space-between;
                            }
                            .first-last-name-container .firstName,
                            .first-last-name-container .lastName {
                                font-size: 16px;
                                font-weight: bold;
                            }
                            .audit-date {
                                font-size: 16px;
                                font-weight: bold;
                            }
                        </style>
                    </head>
                    <body>
                        <div>
                            <img class="logo" src="${SgLogo}" alt="Company Logo" />
                        </div>
                        <div class="header-details">
        <div class="first-last-name-container">
            <div class="firstName">${formData.firstName}</div>
            <div class="lastName">${formData.lastName}</div>
        </div>
        <div class="audit-date">${formattedAuditDate}</div>
    </div>
    <div class="header-details">
        <div class="assessment-number">${formData.ass_no}</div>
        <div class="total-score">${formData.total_score} / 36</div>
    </div>
    <div class="header-details">
        <div class="name-code-container">
            <div class="code">${formData.code} </div>
            <div class="name"> - ${formData.name}</div>
        </div>
    </div>
                        <h1 class="center-heading">Assessment Details</h1>`;
                

                for (const questionId in formData.selectedValues) {
               
                    if (formData.selectedValues.hasOwnProperty(questionId)) {
                        // Get the question text based on question ID
                    
                        let questionText;
                        let headingText;
                        switch (questionId) {
                            
                            case 'question1':
                                questionText = "1. No faulty, unusable or worn consumables, tools, or materials in the workplace. (On the ground or on racks & cupboards)";
                                headingText = "Unusable Items";
                                break;
                            case 'question2':
                                questionText = "2. No items which are unnecessary or in excess quantity are present in the workplace. Are all items used on a regular basis?";
                                headingText = "Unnecessary Items";
                                break;
                            case 'question3':
                                    questionText = "3. 1- Red Tag procedure in place. 2- Red Tag area defined. 3- Red Tagging materials available. 4- List of items in the red tag area. 5- Monthly review of red tag content. (To score a 1 you need all 5 implemented)";
                                    headingText = "Red Tag Procedure";                                  
                                     break;
                            case 'question4':
                                        questionText = "4. All the work stations (work benches, desks) are in order: tools, materials and consumables are sorted and arranged near the point of use";
                                        headingText = "Work Stations";
                                        break;
                            case 'question5':
                                questionText = "5. Storage areas and locations on racks and in cupboards are identified by readable labels.";
                                headingText = "Storage Labelling";
                                break;
                            case 'question6':
                                    questionText = "6. For any item purposely stored on the ground, the storage location must be indicated by a visual marking.";
                                    headingText = "Floor Marking";
                                    break;
                            case 'question7':
                                questionText = "7. All storage locations are suitable for the materials, tools, dustbins, consumables stored.";
                                headingText = "Ergonomics Of Location";
                                break;
                        case 'question8':
                            questionText = "8. All materials, tools, dustbins, consumables and notice boards are easily accessible. ";
                            headingText = "Accessibility";
                            break;
                        case 'question9':
                            questionText = "9. The stored Work In Progress and Finished Goods are well ordered and locations clearly identified.";
                            headingText = "Wip And Finish Goods";
                        break;
                        case 'question10':
                            questionText = "10. The walkways are delimited and easily identifiable. The delimitation lines are visible, clean and in good condition. Free from obstructions - also applicable for offices.";
                            headingText = "Walkways";
                        break;
                        case 'question11':
                                questionText = "11. Cleaning equipment, shovels, brushes, vacuum cleaner etc. is available, accessible and its storage clearly marked.";
                                headingText = "Cleaning Equipment";
                        break;
                        case 'question12':
                                    questionText = "12. Standardised colour coding for waste segregation. Waste (glass, paper, plastic, metal, wood etc) is sorted into containers in clearly identified areas. The containers are in their dedicated place No cross contamination on waste";
                                    headingText = "Waste Segregation";
                        break;
                        case 'question13':
                            questionText = "13. Display and Information boards (quality, safety, environment, production, 5S, TPM...) are up to date, clean, readable and as per the standard. A date of update or equivalent must appear on the document - no torn or dirty documents, no old papers.";
                            headingText = "Notice Boards";
                        break;
                        case 'question14':
                                questionText = "14. General use documents are identified and organised for ease of use";
                                headingText = "Documants Availability";

                        break;
                        case 'question15':
                            questionText = "15. No rubbish, dirt, oil, water, or broken glass on the ground. The ground is in good condition (grating, staircase, concrete, carpet, painting …) - no potholes.";
                            headingText = "Floor cleanliness";
                            break;
                        case 'question16':
                            questionText = "16. The dustbins are not overflowing, clean externally, and in order.";
                            headingText = "Dustbins";
                            break;
                        case 'question17':
                            questionText = "17. Work surfaces, work benches and desks, are clean: ...no trace of oil, grease, dust, coffee...";
                            headingText = "Clean Work surface";
                            break;
                            case 'question18':
                                questionText = "18. The tools are clean and usable and marked, as appropriate.";
                                headingText = "Clean Tools";
                                break;
                            case 'question19':
                                questionText = "19. The machines and production resources are clean, safe, checked, marked and in working order (PC, printer… for offices)";
                                headingText = "Clean Machines";
                                break;
                            case 'question20':
                                questionText = "20. The buildings are clean. The walls are in good condition and painted. No visible grafitti.";
                                headingText = "Clean Building";
                                break;
                            case 'question21':
                                questionText = "21. Standardised workplace layout visible. Cleaning & inspection planning clearly posted (Who is in charge, what, when, how, where)";
                                headingText = "Clean Standards";
                                break;
                            case 'question22':
                                questionText = "22. There is an organised and up to date 6S noticeboard according to the standard. Containing at least the following information: Team, area layout, action plan, 6S audit results, references photos.";
                                headingText = "5s Noticeboard";
                                break;
                            case 'question23':
                                questionText = "23. Standardised colour coding Standardised labelling Standardised signage.?";
                                headingText = "5s Standards";
                                break;
                            case 'question24':
                                questionText = "24. Zone leader, team responsibilities are defined. Each action to be assigned to a person.";
                                headingText = "Responsibilities";
                                break;
                            case 'question25':
                                questionText = "25. All documents within the workplace comply with the plant management system.";
                                headingText = "Document Standards";
                                break;
                            case 'question26':
                                questionText = "26. All employees involved in maintaining the standards of the workplace. Ask an employee at ramdon regarding their contribution to the area.";
                                headingText = "Employee Involvement";
                                break;
                            case 'question27':
                                questionText = "27. The area audits are carried out in accordance with the planning. All action items have been transferred to the Action Plan.";
                                headingText = "5s Zone Audit";
                                break;
                            case 'question28':
                                questionText = "28. Comments from the previous months audit appear on the action plan and all actions are on time.";
                                headingText = "5s Action Plan";
                                break;
                            case 'question29':
                                questionText = "29. A 6S meeting takes place regularly (weekly/monthly) in the workplace with the works/staff of that area in order to review progress. Proof of this meeting needs to occur with minutes including a list of attendees on the story board / notice board.";
                                headingText = "5s Zone Meeting";
                                break;
                            case 'question30':
                                questionText = "30. The emergency exits are not blocked.The fire-exit doors are not open.Roller Shutter doors which should be closed, are closed.";
                                headingText = "Emergency Exits";
                                break;
                            case 'question31':
                                questionText = "31. The Fire extinguishers and hoses are visible and easily accessible, in working order, and in test (functionnality reviewed regularly).";
                                headingText = "Fire Extinguishers";
                                break;
                            case 'question32':
                                questionText = "32. No observed unsafe acts. Adherence to PPE requirements. Awareness of unsafe conditions.";
                                headingText = "Safety Behaviour";
                                break;
                            case 'question33':
                                questionText = "33. All safety barriers are in working order and are effective.";
                                headingText = "Safety Barrier";
                                break;
                            case 'question34':
                                questionText = "34. There are no noticeable safety deficiencies i.e poor lighting, lack of permit to work system as applicable etc.";
                                headingText = "Safety Deficiencies";
                                break;
                            case 'question35':
                                questionText = "35. All dangerous / hazardous areas are completely marked and have been reviewed to see if this hazard can be removed.";
                                headingText = "Safety Marking";
                                break;
                            case 'question36':
                                questionText = "36. Required Personal Protective Equipment is identified, available and is being worn in the area.";
                                headingText = "PPE";
                                break;
                            // Add more cases for other questions as needed
                            default:
                                questionText = `Question ${questionId}`;
                                headingText = "General";
                        }
                        
                        const additionalText = additionalTexts[headingText];
                        if (additionalText) {
                            htmlContent += `<div class="additional-title">${additionalText.title}</div>`;
                            htmlContent += `<p class="additional-description">${additionalText.description}</p>`;
                        }
                        htmlContent += `<div class="heading">${headingText}</div>`;
                       
                        htmlContent += `<p class="question">${questionText}</p>`;
                        
                        // Get the selected value and remarks
                        const selectedValue = formData.selectedValues[questionId];
                        const remarks = formData.remarks[questionId];
                        const image = formData.images[questionId];
                        
                        // Include selected value and remarks in the HTML content
                        htmlContent += `<p>SCORE: ${selectedValue}</p>`;
                        htmlContent += `<p>Remarks For Improvements: ${remarks}</p>`;
                        if (image) {
                            htmlContent += `<img src="${ApiURL}/api/images/${image}" alt="Image for Question ${questionId}"/>`;
                        }
                    }
                }
             
    
                htmlContent += `</body></html>`;
                
            
                const logoImage = new Image();
            logoImage.src = SgLogo;
            logoImage.onload = () => {
                // Open print window with HTML content
                const printWindow = window.open('', '_blank');
                if (!printWindow) {
                    throw new Error("Unable to open print window. Please check your browser settings.");
                }
                printWindow.document.write(htmlContent);
                printWindow.document.close();
                printWindow.print();
            };
                
            } else {
                console.error('Empty response received from server');
            }
        } catch (error) {
            console.error('Error fetching assessment data:', error);
        }
    };



    const handleDownloadPDF = async (selectedUser) => {
        if (!selectedUser) {
            console.error('No user selected for PDF download');
            return;
        }
    
        try {
            const assessmentId = selectedUser.row_id;
            const response = await axios.get(`${ApiURL}/download-pdf/${assessmentId}`, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            });
    
            if (response.data) {
                const formData = response.data;
                const auditDate = new Date(formData.audit_date);
                const formattedAuditDate = auditDate.toLocaleDateString();
    
            
                let htmlContent = ` <html>
                <head>
                    <title>Assessment Details</title>
                    <style>
                        .title {
                            text-transform: uppercase;
                            font-size: 16px;
                            font-weight: 600;
                            color: rgb(23, 66, 139);
                            margin-bottom: 10px;
                        }
                        .additional-title {
                            font-size: 18px; /* Adjusted font size */
                            font-weight: bold;
                            color: #17428B; /* Same font color */
                            margin-bottom: 10px;
                        }
                        .additional-description {
                            margin-bottom: 10px;
                            font-size: 11px;
                            font-weight: 500;
                        }
                        .heading {
                            text-transform: uppercase;
                            font-size: 14px;
                            font-weight: 500;
                            color: #17428B;
                            margin-bottom: 10px;
                            margin-top: 20px;
                        }
                        .question {
                            font-weight: bold;
                        }
                        img {
                            
                            max-width: 100%;
                            height: auto;
                            page-break-inside: avoid;
                        }
                        .logo {
                            display: block;
                            margin: 0 auto;
                        }
                        .header-details {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            margin-top: 20px;
                        }
                        .total-score {
                            font-weight: bold;
                            font-size: 25px;
                            color: #4fb0b4; /* Adjusted color to match the example */
                        }
                        .assessment-number {
                            font-weight: bold;
                            font-size: 18px;
                            color: #17428B;
                        }
                        .center-heading {
                            text-align: center; /* Center the heading */
                            font-size: 30px; /* Adjust as needed */
                            font-weight: bold; /* Adjust as needed */
                            
                            margin-top: 20px;
                        }
                        .name-code-container {
                            display: flex;
                            justify-content: space-between;
                        }
                        .name-code-container .code {
                            font-size: 16px;
                            font-weight: bold;
                        }
                        .name-code-container .name {
                            font-size: 16px;
                            font-weight: bold;
                        }
                        .first-last-name-container {
                            display: flex;
                            justify-content: space-between;
                        }
                        .first-last-name-container .firstName,
                        .first-last-name-container .lastName {
                            font-size: 16px;
                            font-weight: bold;
                        }
                        .audit-date {
                            font-size: 16px;
                            font-weight: bold;
                        }
                    </style>
                </head>
                <body>
                    <div>
                        <img class="logo" src="${SgLogo}" alt="Company Logo" />
                    </div>
                    <div class="header-details">
    <div class="first-last-name-container">
        <div class="firstName">${formData.firstName}</div>
        <div class="lastName">${formData.lastName}</div>
    </div>
    <div class="audit-date">${formattedAuditDate}</div>
    </div>
    <div class="header-details">
    <div class="assessment-number">${formData.ass_no}</div>
    <div class="total-score">${formData.total_score} / 36</div>
    </div>
    <div class="header-details">
    <div class="name-code-container">
        <div class="code">${formData.code} </div>
        <div class="name"> - ${formData.name}</div>
    </div>
    </div>
                    <h1 class="center-heading">Assessment Details</h1>`
                
                
                ;
    
    
                const questionOrder = [
                    'question1', 'question2', 'question3', 'question4', 'question5', 'question6',
                    'question7', 'question8', 'question9', 'question10', 'question11', 'question12',
                    'question13', 'question14', 'question15', 'question16', 'question17', 'question18',
                    'question19', 'question20', 'question21', 'question22', 'question23', 'question24',
                    'question25', 'question26', 'question27', 'question28', 'question29', 'question30',
                    'question31', 'question32', 'question33', 'question34', 'question35', 'question36'
                ];
    
                for (const questionId of questionOrder) {
                    if (formData.selectedValues.hasOwnProperty(questionId)) {
                        let questionText;
                        let headingText;
    
                        switch (questionId) {
                            
                            case 'question1':
                                questionText = "1. No faulty, unusable or worn consumables, tools, or materials in the workplace. (On the ground or on racks & cupboards)";
                                headingText = "Unusable Items";
                                break;
                            case 'question2':
                                questionText = "2. No items which are unnecessary or in excess quantity are present in the workplace. Are all items used on a regular basis?";
                                headingText = "Unnecessary Items";
                                break;
                            case 'question3':
                                    questionText = "3. 1- Red Tag procedure in place. 2- Red Tag area defined. 3- Red Tagging materials available. 4- List of items in the red tag area. 5- Monthly review of red tag content. (To score a 1 you need all 5 implemented)";
                                    headingText = "Red Tag Procedure";                                  
                                     break;
                            case 'question4':
                                        questionText = "4. All the work stations (work benches, desks) are in order: tools, materials and consumables are sorted and arranged near the point of use";
                                        headingText = "Work Stations";
                                        break;
                            case 'question5':
                                questionText = "5. Storage areas and locations on racks and in cupboards are identified by readable labels.";
                                headingText = "Storage Labelling";
                                break;
                            case 'question6':
                                    questionText = "6. For any item purposely stored on the ground, the storage location must be indicated by a visual marking.";
                                    headingText = "Floor Marking";
                                    break;
                            case 'question7':
                                questionText = "7. All storage locations are suitable for the materials, tools, dustbins, consumables stored.";
                                headingText = "Ergonomics Of Location";
                                break;
                        case 'question8':
                            questionText = "8. All materials, tools, dustbins, consumables and notice boards are easily accessible. ";
                            headingText = "Accessibility";
                            break;
                        case 'question9':
                            questionText = "9. The stored Work In Progress and Finished Goods are well ordered and locations clearly identified.";
                            headingText = "Wip And Finish Goods";
                        break;
                        case 'question10':
                            questionText = "10. The walkways are delimited and easily identifiable. The delimitation lines are visible, clean and in good condition. Free from obstructions - also applicable for offices.";
                            headingText = "Walkways";
                        break;
                        case 'question11':
                                questionText = "11. Cleaning equipment, shovels, brushes, vacuum cleaner etc. is available, accessible and its storage clearly marked.";
                                headingText = "Cleaning Equipment";
                        break;
                        case 'question12':
                                    questionText = "12. Standardised colour coding for waste segregation. Waste (glass, paper, plastic, metal, wood etc) is sorted into containers in clearly identified areas. The containers are in their dedicated place No cross contamination on waste";
                                    headingText = "Waste Segregation";
                        break;
                        case 'question13':
                            questionText = "13. Display and Information boards (quality, safety, environment, production, 5S, TPM...) are up to date, clean, readable and as per the standard. A date of update or equivalent must appear on the document - no torn or dirty documents, no old papers.";
                            headingText = "Notice Boards";
                        break;
                        case 'question14':
                                questionText = "14. General use documents are identified and organised for ease of use";
                                headingText = "Documants Availability";
    
                        break;
                        case 'question15':
                            questionText = "15. No rubbish, dirt, oil, water, or broken glass on the ground. The ground is in good condition (grating, staircase, concrete, carpet, painting …) - no potholes.";
                            headingText = "Floor cleanliness";
                            break;
                        case 'question16':
                            questionText = "16. The dustbins are not overflowing, clean externally, and in order.";
                            headingText = "Dustbins";
                            break;
                        case 'question17':
                            questionText = "17. Work surfaces, work benches and desks, are clean: ...no trace of oil, grease, dust, coffee...";
                            headingText = "Clean Work surface";
                            break;
                            case 'question18':
                                questionText = "18. The tools are clean and usable and marked, as appropriate.";
                                headingText = "Clean Tools";
                                break;
                            case 'question19':
                                questionText = "19. The machines and production resources are clean, safe, checked, marked and in working order (PC, printer… for offices)";
                                headingText = "Clean Machines";
                                break;
                            case 'question20':
                                questionText = "20. The buildings are clean. The walls are in good condition and painted. No visible grafitti.";
                                headingText = "Clean Building";
                                break;
                            case 'question21':
                                questionText = "21. Standardised workplace layout visible. Cleaning & inspection planning clearly posted (Who is in charge, what, when, how, where)";
                                headingText = "Clean Standards";
                                break;
                            case 'question22':
                                questionText = "22. There is an organised and up to date 6S noticeboard according to the standard. Containing at least the following information: Team, area layout, action plan, 6S audit results, references photos.";
                                headingText = "5s Noticeboard";
                                break;
                            case 'question23':
                                questionText = "23. Standardised colour coding Standardised labelling Standardised signage.?";
                                headingText = "5s Standards";
                                break;
                            case 'question24':
                                questionText = "24. Zone leader, team responsibilities are defined. Each action to be assigned to a person.";
                                headingText = "Responsibilities";
                                break;
                            case 'question25':
                                questionText = "25. All documents within the workplace comply with the plant management system.";
                                headingText = "Document Standards";
                                break;
                            case 'question26':
                                questionText = "26. All employees involved in maintaining the standards of the workplace. Ask an employee at ramdon regarding their contribution to the area.";
                                headingText = "Employee Involvement";
                                break;
                            case 'question27':
                                questionText = "27. The area audits are carried out in accordance with the planning. All action items have been transferred to the Action Plan.";
                                headingText = "5s Zone Audit";
                                break;
                            case 'question28':
                                questionText = "28. Comments from the previous months audit appear on the action plan and all actions are on time.";
                                headingText = "5s Action Plan";
                                break;
                            case 'question29':
                                questionText = "29. A 6S meeting takes place regularly (weekly/monthly) in the workplace with the works/staff of that area in order to review progress. Proof of this meeting needs to occur with minutes including a list of attendees on the story board / notice board.";
                                headingText = "5s Zone Meeting";
                                break;
                            case 'question30':
                                questionText = "30. The emergency exits are not blocked.The fire-exit doors are not open.Roller Shutter doors which should be closed, are closed.";
                                headingText = "Emergency Exits";
                                break;
                            case 'question31':
                                questionText = "31. The Fire extinguishers and hoses are visible and easily accessible, in working order, and in test (functionnality reviewed regularly).";
                                headingText = "Fire Extinguishers";
                                break;
                            case 'question32':
                                questionText = "32. No observed unsafe acts. Adherence to PPE requirements. Awareness of unsafe conditions.";
                                headingText = "Safety Behaviour";
                                break;
                            case 'question33':
                                questionText = "33. All safety barriers are in working order and are effective.";
                                headingText = "Safety Barrier";
                                break;
                            case 'question34':
                                questionText = "34. There are no noticeable safety deficiencies i.e poor lighting, lack of permit to work system as applicable etc.";
                                headingText = "Safety Deficiencies";
                                break;
                            case 'question35':
                                questionText = "35. All dangerous / hazardous areas are completely marked and have been reviewed to see if this hazard can be removed.";
                                headingText = "Safety Marking";
                                break;
                            case 'question36':
                                questionText = "36. Required Personal Protective Equipment is identified, available and is being worn in the area.";
                                headingText = "PPE";
                                break;
                            // Add more cases for other questions as needed
                            default:
                                questionText = `Question ${questionId}`;
                                headingText = "General";
                        }
    
                        const selectedValue = formData.selectedValues[questionId];
                        const remarks = formData.remarks[questionId];
                        const image = formData.images[questionId];
    
                        htmlContent += `
                            <div class="heading">${headingText}</div>
                            <p class="question">${questionText}</p>
                            <p>SCORE: ${selectedValue}</p>
                            <p>Remarks For Improvements: ${remarks}</p>
                        `;
    
                        if (image) {
                            htmlContent += `<img src="${ApiURL}/api/images/${image}" alt="Image for Question ${questionId}" />`;
                        }
                    }
                }
    
                htmlContent += `
                    </body>
                    </html>
                `;
    
                // Open a new window to display PDF content
                const printWindow = window.open('', '_blank');
                if (!printWindow) {
                    throw new Error("Unable to open print window. Please check your browser settings.");
                }
    
                // Write HTML content to the print window and trigger print
                printWindow.document.write(htmlContent);
                printWindow.document.close();
                printWindow.print();
            } else {
                console.error('Empty response received from server');
            }
        } catch (error) {
            console.error('Error fetching assessment data:', error);
        }
    };
    
    const handleDialogClose = () => {
        setDialogOpen(false); // Close the dialog
      };
const handleDownloadPDFnewworkd = async (selectedUser) => {
    if (!selectedUser) {
        console.error('No user selected for PDF download');
        return;
    }

    try {
        const assessmentId = selectedUser.row_id;
        const response = await axios.get(`${ApiURL}/download-pdf/${assessmentId}`, {
            headers: {
                Authorization: `Bearer ${access_token}`,
            },
        });

        if (response.data) {
            const formData = response.data;
            const auditDate = new Date(formData.audit_date);
            const formattedAuditDate = auditDate.toLocaleDateString();

            let htmlContent = ` <html>
            <head>
                <title>Assessment Details</title>
                <style>
                    .title {
                        text-transform: uppercase;
                        font-size: 16px;
                        font-weight: 600;
                        color: rgb(23, 66, 139);
                        margin-bottom: 10px;
                    }
                    .additional-title {
                        font-size: 18px; /* Adjusted font size */
                        font-weight: bold;
                        color: #17428B; /* Same font color */
                        margin-bottom: 10px;
                    }
                    .additional-description {
                        margin-bottom: 10px;
                        font-size: 11px;
                        font-weight: 500;
                    }
                    .heading {
                        text-transform: uppercase;
                        font-size: 14px;
                        font-weight: 500;
                        color: #17428B;
                        margin-bottom: 10px;
                        margin-top: 20px;
                    }
                    .question {
                        font-weight: bold;
                    }
                    img {
                        
                        max-width: 100%;
                        height: auto;
                        page-break-inside: avoid;
                    }
                    .logo {
                        display: block;
                        margin: 0 auto;
                    }
                    .header-details {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-top: 20px;
                    }
                    .total-score {
                        font-weight: bold;
                        font-size: 25px;
                        color: #4fb0b4; /* Adjusted color to match the example */
                    }
                    .assessment-number {
                        font-weight: bold;
                        font-size: 18px;
                        color: #17428B;
                    }
                    .center-heading {
                        text-align: center; /* Center the heading */
                        font-size: 30px; /* Adjust as needed */
                        font-weight: bold; /* Adjust as needed */
                        
                        margin-top: 20px;
                    }
                    .name-code-container {
                        display: flex;
                        justify-content: space-between;
                    }
                    .name-code-container .code {
                        font-size: 16px;
                        font-weight: bold;
                    }
                    .name-code-container .name {
                        font-size: 16px;
                        font-weight: bold;
                    }
                    .first-last-name-container {
                        display: flex;
                        justify-content: space-between;
                    }
                    .first-last-name-container .firstName,
                    .first-last-name-container .lastName {
                        font-size: 16px;
                        font-weight: bold;
                    }
                    .audit-date {
                        font-size: 16px;
                        font-weight: bold;
                    }
                </style>
            </head>
            <body>
                <div>
                    <img class="logo" src="${SgLogo}" alt="Company Logo" />
                </div>
                <div class="header-details">
<div class="first-last-name-container">
    <div class="firstName">${formData.firstName}</div>
    <div class="lastName">${formData.lastName}</div>
</div>
<div class="audit-date">${formattedAuditDate}</div>
</div>
<div class="header-details">
<div class="assessment-number">${formData.ass_no}</div>
<div class="total-score">${formData.total_score} / 36</div>
</div>
<div class="header-details">
<div class="name-code-container">
    <div class="code">${formData.code} </div>
    <div class="name"> - ${formData.name}</div>
</div>
</div>
                <h1 class="center-heading">Assessment Details</h1>`
            
            
            ;

            for (const questionId in formData.selectedValues) {
                if (formData.selectedValues.hasOwnProperty(questionId)) {
                    let questionText;
                    let headingText;

                    switch (questionId) {
                            
                        case 'question1':
                            questionText = "1. No faulty, unusable or worn consumables, tools, or materials in the workplace. (On the ground or on racks & cupboards)";
                            headingText = "Unusable Items";
                            break;
                        case 'question2':
                            questionText = "2. No items which are unnecessary or in excess quantity are present in the workplace. Are all items used on a regular basis?";
                            headingText = "Unnecessary Items";
                            break;
                        case 'question3':
                                questionText = "3. 1- Red Tag procedure in place. 2- Red Tag area defined. 3- Red Tagging materials available. 4- List of items in the red tag area. 5- Monthly review of red tag content. (To score a 1 you need all 5 implemented)";
                                headingText = "Red Tag Procedure";                                  
                                 break;
                        case 'question4':
                                    questionText = "4. All the work stations (work benches, desks) are in order: tools, materials and consumables are sorted and arranged near the point of use";
                                    headingText = "Work Stations";
                                    break;
                        case 'question5':
                            questionText = "5. Storage areas and locations on racks and in cupboards are identified by readable labels.";
                            headingText = "Storage Labelling";
                            break;
                        case 'question6':
                                questionText = "6. For any item purposely stored on the ground, the storage location must be indicated by a visual marking.";
                                headingText = "Floor Marking";
                                break;
                        case 'question7':
                            questionText = "7. All storage locations are suitable for the materials, tools, dustbins, consumables stored.";
                            headingText = "Ergonomics Of Location";
                            break;
                    case 'question8':
                        questionText = "8. All materials, tools, dustbins, consumables and notice boards are easily accessible. ";
                        headingText = "Accessibility";
                        break;
                    case 'question9':
                        questionText = "9. The stored Work In Progress and Finished Goods are well ordered and locations clearly identified.";
                        headingText = "Wip And Finish Goods";
                    break;
                    case 'question10':
                        questionText = "10. The walkways are delimited and easily identifiable. The delimitation lines are visible, clean and in good condition. Free from obstructions - also applicable for offices.";
                        headingText = "Walkways";
                    break;
                    case 'question11':
                            questionText = "11. Cleaning equipment, shovels, brushes, vacuum cleaner etc. is available, accessible and its storage clearly marked.";
                            headingText = "Cleaning Equipment";
                    break;
                    case 'question12':
                                questionText = "12. Standardised colour coding for waste segregation. Waste (glass, paper, plastic, metal, wood etc) is sorted into containers in clearly identified areas. The containers are in their dedicated place No cross contamination on waste";
                                headingText = "Waste Segregation";
                    break;
                    case 'question13':
                        questionText = "13. Display and Information boards (quality, safety, environment, production, 5S, TPM...) are up to date, clean, readable and as per the standard. A date of update or equivalent must appear on the document - no torn or dirty documents, no old papers.";
                        headingText = "Notice Boards";
                    break;
                    case 'question14':
                            questionText = "14. General use documents are identified and organised for ease of use";
                            headingText = "Documants Availability";

                    break;
                    case 'question15':
                        questionText = "15. No rubbish, dirt, oil, water, or broken glass on the ground. The ground is in good condition (grating, staircase, concrete, carpet, painting …) - no potholes.";
                        headingText = "Floor cleanliness";
                        break;
                    case 'question16':
                        questionText = "16. The dustbins are not overflowing, clean externally, and in order.";
                        headingText = "Dustbins";
                        break;
                    case 'question17':
                        questionText = "17. Work surfaces, work benches and desks, are clean: ...no trace of oil, grease, dust, coffee...";
                        headingText = "Clean Work surface";
                        break;
                        case 'question18':
                            questionText = "18. The tools are clean and usable and marked, as appropriate.";
                            headingText = "Clean Tools";
                            break;
                        case 'question19':
                            questionText = "19. The machines and production resources are clean, safe, checked, marked and in working order (PC, printer… for offices)";
                            headingText = "Clean Machines";
                            break;
                        case 'question20':
                            questionText = "20. The buildings are clean. The walls are in good condition and painted. No visible grafitti.";
                            headingText = "Clean Building";
                            break;
                        case 'question21':
                            questionText = "21. Standardised workplace layout visible. Cleaning & inspection planning clearly posted (Who is in charge, what, when, how, where)";
                            headingText = "Clean Standards";
                            break;
                        case 'question22':
                            questionText = "22. There is an organised and up to date 6S noticeboard according to the standard. Containing at least the following information: Team, area layout, action plan, 6S audit results, references photos.";
                            headingText = "5s Noticeboard";
                            break;
                        case 'question23':
                            questionText = "23. Standardised colour coding Standardised labelling Standardised signage.?";
                            headingText = "5s Standards";
                            break;
                        case 'question24':
                            questionText = "24. Zone leader, team responsibilities are defined. Each action to be assigned to a person.";
                            headingText = "Responsibilities";
                            break;
                        case 'question25':
                            questionText = "25. All documents within the workplace comply with the plant management system.";
                            headingText = "Document Standards";
                            break;
                        case 'question26':
                            questionText = "26. All employees involved in maintaining the standards of the workplace. Ask an employee at ramdon regarding their contribution to the area.";
                            headingText = "Employee Involvement";
                            break;
                        case 'question27':
                            questionText = "27. The area audits are carried out in accordance with the planning. All action items have been transferred to the Action Plan.";
                            headingText = "5s Zone Audit";
                            break;
                        case 'question28':
                            questionText = "28. Comments from the previous months audit appear on the action plan and all actions are on time.";
                            headingText = "5s Action Plan";
                            break;
                        case 'question29':
                            questionText = "29. A 6S meeting takes place regularly (weekly/monthly) in the workplace with the works/staff of that area in order to review progress. Proof of this meeting needs to occur with minutes including a list of attendees on the story board / notice board.";
                            headingText = "5s Zone Meeting";
                            break;
                        case 'question30':
                            questionText = "30. The emergency exits are not blocked.The fire-exit doors are not open.Roller Shutter doors which should be closed, are closed.";
                            headingText = "Emergency Exits";
                            break;
                        case 'question31':
                            questionText = "31. The Fire extinguishers and hoses are visible and easily accessible, in working order, and in test (functionnality reviewed regularly).";
                            headingText = "Fire Extinguishers";
                            break;
                        case 'question32':
                            questionText = "32. No observed unsafe acts. Adherence to PPE requirements. Awareness of unsafe conditions.";
                            headingText = "Safety Behaviour";
                            break;
                        case 'question33':
                            questionText = "33. All safety barriers are in working order and are effective.";
                            headingText = "Safety Barrier";
                            break;
                        case 'question34':
                            questionText = "34. There are no noticeable safety deficiencies i.e poor lighting, lack of permit to work system as applicable etc.";
                            headingText = "Safety Deficiencies";
                            break;
                        case 'question35':
                            questionText = "35. All dangerous / hazardous areas are completely marked and have been reviewed to see if this hazard can be removed.";
                            headingText = "Safety Marking";
                            break;
                        case 'question36':
                            questionText = "36. Required Personal Protective Equipment is identified, available and is being worn in the area.";
                            headingText = "PPE";
                            break;
                        // Add more cases for other questions as needed
                        default:
                            questionText = `Question ${questionId}`;
                            headingText = "General";
                    }
                    // switch (questionId) {
                    //     case 'question1':
                    //         questionText = "1. No faulty, unusable or worn consumables, tools, or materials in the workplace. (On the ground or on racks & cupboards)";
                    //         headingText = "Unusable Items";
                    //         break;
                    //     case 'question2':
                    //         questionText = "2. No items which are unnecessary or in excess quantity are present in the workplace. Are all items used on a regular basis?";
                    //         headingText = "Unnecessary Items";
                    //         break;
                    //     // Add cases for other questions as needed
                    //     default:
                    //         questionText = `Question ${questionId}`;
                    //         headingText = "General";
                    // }

                    const selectedValue = formData.selectedValues[questionId];
                    const remarks = formData.remarks[questionId];
                    const image = formData.images[questionId];

                    htmlContent += `
                        <div class="heading">${headingText}</div>
                        <p class="question">${questionText}</p>
                        <p>SCORE: ${selectedValue}</p>
                        <p>Remarks For Improvements: ${remarks}</p>
                    `;

                    if (image) {
                        htmlContent += `<img src="${ApiURL}/api/images/${image}" alt="Image for Question ${questionId}"/>`;
                    }
                }
            }

            htmlContent += `
                    </body>
                </html>
            `;

            // Open a new window to display PDF content
            const printWindow = window.open('', '_blank');
            if (!printWindow) {
                throw new Error("Unable to open print window. Please check your browser settings.");
            }

            // Write HTML content to the print window and trigger print
            printWindow.document.write(htmlContent);
            printWindow.document.close();
            printWindow.print();
        } else {
            console.error('Empty response received from server');
        }
    } catch (error) {
        console.error('Error fetching assessment data:', error);
    }
};


  

  const handleAddClick = () => {
      setDialogOpen(true); // Open the dialog
  };

    const handleDownloadExcel = async (selectedUser) => {
        if (!selectedUser) {
            console.error('No user selected for Excel download');
            return;
        }
        
        try {
            const assessmentId = selectedUser.row_id;
            const response = await axios.get(`${ApiURL}/download-excel?id=${assessmentId}`, {
                responseType: 'blob',
                headers: {
                    Authorization: `Bearer ${access_token}`,
                   
                }
            });
    
            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `assessment_${assessmentId}.xlsx`);
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error("Error downloading Excel:", error);
        }
    };
   
    

      const groupedAudits = useMemo(() => groupAuditsByMonthAndStatus(filteredUsers), [filteredUsers]);

    return (
        <div>
            
                    
                    <Paper
      component="form"
      sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: "100%",boxShadow:'none',borderBottom:'1px solid #e6e6e6',borderRadius:'0' }}
    >
      <Typography variant='h5' sx={{fontSize:'18px',fontWeight:'600',padding:'0 16px'}}>
                        Audits
                    </Typography>
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Search Audit"
        inputProps={{ 'aria-label': 'Filter' }}
        value={searchText}
        onChange={handleSearchInputChange}
      />
      <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
        <SearchIcon />
      </IconButton>
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      <IconButton onClick={(event) => setAnchorFilterEl(event.currentTarget)} aria-label="filter">
                        <FilterListIcon/>
                    </IconButton>
      {/* <IconButton onClick={(event) => setAnchorFilterEl(event.currentTarget)} aria-label="filter">
                        <VerticalAlignBottomIcon/>
                    </IconButton> */}
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      <Button
              variant="contained"
              style={{
                background: '#0086DC',
                width: '130px',
                border: 0,
                borderRadius: 3,
                color: 'white',
                height: '34px',
                fontSize:'14px',
                textTransform:'capitalize',
                margin: '0 8px'
              }}
              onClick={handleAddClick}
            >
                        <AddIcon/>
              New Audit
            </Button>
                    </Paper>
                
<Box sx={{display:'flex',flexWrap:'wrap',flexDirection:'row',justifyContent:'space-between',padding:'16px'}}>
           
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
                <MuiAlert elevation={6} variant="filled" severity="success" onClose={() => setSnackbarOpen(false)}>
                    Filter Applied
                </MuiAlert>
            </Snackbar>
            <Popover
    open={Boolean(anchorFilterEl)}
    anchorEl={anchorFilterEl}
    onClose={() => setAnchorFilterEl(null)}
    anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
    }}
    transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
    }}

>
    <div style={{ maxHeight: "500px", overflowY: "auto" }}>
        <Grid container style={{ borderBottom: "1px solid #282A2D", display: "flex", alignItems: "center" }}>
            <Grid item xs={11}>
                <Box p={1} style={{ display: "flex", alignItems: "center", marginLeft: "10px" }}>
                    <Button
                        onClick={applyFilters}
                        style={{
                            fontWeight: "bold",
                            backgroundColor: "#00448b",
                            color: "#ffffff",
                            borderRadius: "5px",
                            height: "35px",
                        }}
                    >
                        Apply
                    </Button>
                    <Typography
                        style={{
                            fontWeight: 800,
                            color: "#FAAD14",
                            lineHeight: "16px",
                            marginLeft: "25px",
                            cursor: "pointer",
                            fontSize: "18px",
                        }}
                        onClick={clearFilters}
                    >
                        Reset
                    </Typography>
                </Box>
            </Grid>
           
        </Grid>
        <Card style={{ width: "300px", padding: "10px" }}>
            {/* Accordion for Assessment Numbers */}
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography style={{ fontSize: 16, fontWeight: "bold", color: "#000000" }}>Assessment Numbers</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={filters.assessmentNumbers.length === originalUsers.map(user => user.ass_no).length}
                                    indeterminate={
                                        filters.assessmentNumbers.length > 0 && filters.assessmentNumbers.length < originalUsers.map(user => user.ass_no).length
                                    }
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            // If "Select All" is checked, select all options
                                            const allAssNos = originalUsers.map(user => user.ass_no);
                                            handleCheckboxChange('assessmentNumbers', allAssNos);
                                            // handleCheckboxChange('assessmentNumbers', originalUsers.map(user => user.ass_no));
                                        } else {
                                            // If "Select All" is unchecked, deselect all options
                                            handleCheckboxChange('assessmentNumbers', []);
                                        }
                                    }}
                                />
                            }
                            label="Select All"
                        />
                        {originalUsers.map(user => (
                            <FormControlLabel
                                key={user.row_id}
                                control={
                                    <Checkbox
                                        checked={filters.assessmentNumbers.includes(user.ass_no)}
                                        onChange={(e) => {
                                            const updatedAssessmentNumbers = e.target.checked
                                                ? [...filters.assessmentNumbers, user.ass_no]
                                                : filters.assessmentNumbers.filter(item => item !== user.ass_no);
                                            handleCheckboxChange('assessmentNumbers', updatedAssessmentNumbers);
                                        }}
                                        // onChange={(e) => handleCheckboxChange('assessmentNumbers', e.target.checked ? [...filters.assessmentNumbers, user.ass_no] : filters.assessmentNumbers.filter(item => item !== user.ass_no))}
                                    />
                                }
                                label={user.ass_no}
                            />
                        ))}
                    </div>
                </AccordionDetails>
            </Accordion>
            {/* Accordion for Auditors */}
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography style={{ fontSize: 16, fontWeight: "bold", color: "#000000" }}>Auditors</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={filters.auditors.length === auditorOptions.length}
                                    indeterminate={
                                        filters.auditors.length > 0 && filters.auditors.length < auditorOptions.length
                                    }
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            // Select all auditors (normalized)
                                            const allAuditors = auditorOptions.map(auditor => auditor.normalized);
                                            handleCheckboxChange('auditors', allAuditors);
                                        } else {
                                            // Deselect all auditors
                                            handleCheckboxChange('auditors', []);
                                        }
                                    }}
                                    // onChange={(e) => {
                                    //     if (e.target.checked) {
                                    //         // If "Select All" is checked, select all options
                                    //         handleCheckboxChange('auditors', auditorOptions);
                                    //     } else {
                                    //         // If "Select All" is unchecked, deselect all options
                                    //         handleCheckboxChange('auditors', []);
                                    //     }
                                    // }}
                                />
                            }
                            label="Select All"
                        />
                        {auditorOptions.map((auditor, index) => (
                            <FormControlLabel
                                key={index}
                                control={
                                    <Checkbox
                                    checked={filters.auditors.includes(auditor.normalized)}
                                    onChange={(e) => {
                                        const updatedAuditors = e.target.checked
                                            ? [...filters.auditors, auditor.normalized]
                                            : filters.auditors.filter(item => item !== auditor.normalized);
                                        handleCheckboxChange('auditors', updatedAuditors);
                                    }}
                                />
                                    // <Checkbox
                                    //     checked={filters.auditors.includes(auditor)}
                                    //     onChange={(e) => handleCheckboxChange('auditors', e.target.checked ? [...filters.auditors, auditor] : filters.auditors.filter(item => item !== auditor))}
                                    // />
                                }
                                label={auditor.display}
                                // label={auditor}
                            />
                        ))}
                    </div>
                </AccordionDetails>
            </Accordion>
            {/* Accordion for Zones */}
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography style={{ fontSize: 16, fontWeight: "bold", color: "#000000" }}>Zones</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div>
                        <FormControlLabel
                            control={
                                <Checkbox
                                // checked={filters.zones.length === (zoneOptions?.length || 0)}
                                    checked={filters.zones.length === zoneOptions.length}
                                    indeterminate={
                                        filters.zones.length > 0 && filters.zones.length < zoneOptions.length
                                    }
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            // Select all zones (normalized)
                                            const allZones = zoneOptions.map(zone => zone.normalized);
                                            handleCheckboxChange('zones', allZones);
                                        } else {
                                            // Deselect all zones
                                            handleCheckboxChange('zones', []);
                                        }
                                    }}
                                    // onChange={(e) => {
                                    //     if (e.target.checked) {
                                    //         // If "Select All" is checked, select all options
                                    //         handleCheckboxChange('zones', zoneOptions);
                                    //     } else {
                                    //         // If "Select All" is unchecked, deselect all options
                                    //         handleCheckboxChange('zones', []);
                                    //     }
                                    // }}
                                />
                            }
                            label="Select All"
                        />
                        {zoneOptions.map((zone,index) => (
                            <FormControlLabel
                                key={index}
                                control={
                                    <Checkbox
                                    checked={filters.zones.includes(zone.normalized)}
                                    onChange={(e) => {
                                        const updatedZones = e.target.checked
                                            ? [...filters.zones, zone.normalized]
                                            : filters.zones.filter(item => item !== zone.normalized);
                                        handleCheckboxChange('zones', updatedZones);
                                    }}
                                />

                                    // <Checkbox
                                    //     checked={filters.zones.includes(zone)}
                                    //     onChange={(e) => handleCheckboxChange('zones', e.target.checked ? [...filters.zones, zone] : filters.zones.filter(item => item !== zone))}
                                    // />
                                }
                                label={zone.display}
                                // label={zone}
                            />
                        ))}
                    </div>
                </AccordionDetails>
            </Accordion>
            {/* Accordion for Audit Dates */}
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography style={{ fontSize: 16, fontWeight: "bold", color: "#000000" }}>Audit Dates</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div>
                        {/* From Date Picker */}
                        <TextField
                            label="From Date"
                            type="date"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            style={{margin:"10px"}}
                            value={filters.fromDate}
                            // onChange={(e) => setFilters({ ...filters, fromDate: e.target.value })}
                            onChange={(e) => setFilters(prev => ({ ...prev, fromDate: e.target.value }))}
                                
                        />
                        {/* To Date Picker */}
                        <TextField
                            label="To Date"
                            type="date"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            style={{margin:"10px"}}
                            value={filters.toDate}
                            onChange={(e) => setFilters(prev => ({ ...prev, toDate: e.target.value }))}
                            // onChange={(e) => setFilters({ ...filters, toDate: e.target.value })}
                        />
                    </div>
                </AccordionDetails>
            </Accordion>
        </Card>
    </div>
</Popover>


<Grid container direction="column" spacing={2} style={{ marginTop: '5px', width: '100%' }}>
                {filteredUsers.length > 0 ? (
                    groupedAudits &&
                    Object.entries(groupedAudits).map(([month, auditsByStatus]) => (
                        <Grid item key={month}>
                            <Card variant="outlined" style={{ padding: '16px' }}>
                                {/* Month Accordion Header */}
                                <Box
                                    onClick={() => handleToggle(month)}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        cursor: 'pointer',
                                    }}
                                >
                                    <Typography variant="h6">{month}</Typography>
                                    <IconButton>
                                        {expanded[month] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                    </IconButton>
                                </Box>

                                {/* Expanded Content */}
                                {expanded[month] && (
                                    <Box mt={2}>
                                        <Grid container spacing={2}>
                                            {/* All Audits Section */}
                                            <Grid item md={4} xs={12}>
                                                <Typography variant="subtitle1" style={{ fontWeight: 600, marginBottom: '8px' }}>
                                                    All Audits
                                                </Typography>
                                                {auditsByStatus.allAudits.length > 0 ? (
                                                    auditsByStatus.allAudits.map(user => (
                                                        <Card
                                                            key={user.row_id}
                                                            style={{
                                                                width: '100%',
                                                                padding: 10,
                                                                borderRadius: '8px',
                                                                border: '1px solid #E8E8E8',
                                                                background: '#FFF',
                                                                marginBottom: '30px',
                                                                marginTop: '20px',
                                                                boxShadow: '0px 2px 12px 0px rgba(0, 0, 0, 0.15)',
                                                                position: 'relative',
                                                            }}
                                                            variant="outlined"
                                                        >
                                                            <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                                                                {/* Assessment Number */}
                                                                <div style={{ marginBottom: '20px' }}>
                                                                    <Typography
                                                                        style={{
                                                                            fontWeight: 550,
                                                                            fontSize: '16px',
                                                                            color: '#1D1B20',
                                                                        }}
                                                                    >
                                                                        Assessment Number
                                                                    </Typography>
                                                                    <Typography
                                                                        style={{
                                                                            fontWeight: 500,
                                                                            fontSize: '12px',
                                                                            color: '#616161',
                                                                        }}
                                                                    >
                                                                        {user.ass_no}
                                                                    </Typography>
                                                                </div>

                                                                {/* Zone */}
                                                                <div style={{ marginBottom: '20px' }}>
                                                                    <Typography
                                                                        style={{
                                                                            fontWeight: 550,
                                                                            fontSize: '16px',
                                                                            color: '#1D1B20',
                                                                        }}
                                                                    >
                                                                        Zone
                                                                    </Typography>
                                                                    <Typography
                                                                        style={{
                                                                            fontWeight: 500,
                                                                            fontSize: '12px',
                                                                            color: '#616161',
                                                                        }}
                                                                    >
                                                                        {user.code} - {user.name}
                                                                    </Typography>
                                                                </div>

                                                                {/* Divider */}
                                                                <div
                                                                    style={{
                                                                        borderBottom: '1px solid #E8E8E8',
                                                                        marginBottom: '10px',
                                                                    }}
                                                                ></div>

                                                                {/* Last Updated and Avatar */}
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        justifyContent: 'space-between',
                                                                        marginBottom: '5px',
                                                                    }}
                                                                >
                                                                    <Typography
                                                                        style={{
                                                                            fontWeight: 500,
                                                                            fontSize: '13px',
                                                                            color: '#616161',
                                                                            display: 'flex',
                                                                            flexDirection: 'column',
                                                                            alignItems: 'flex-start',
                                                                        }}
                                                                    >
                                                                        <div>
                                                                            Last Updated:{' '}
                                                                            {new Date(user.audit_date).toLocaleDateString()}
                                                                        </div>
                                                                    </Typography>
                                                                    <Typography
                                                                        style={{
                                                                            fontWeight: 500,
                                                                            paddingRight: '52px',
                                                                            fontSize: '13px',
                                                                            color: '#616161',
                                                                            display: 'flex',
                                                                            flexDirection: 'column',
                                                                            alignItems: 'flex-end',
                                                                        }}
                                                                    >
                                                                        <Tooltip title={user ? `${user.firstName} ${user.lastName}` : ''}>
                                                                            <Avatar
                                                                                style={{
                                                                                    fontSize: '13px',
                                                                                    width: '30px',
                                                                                    height: '30px',
                                                                                    cursor: 'pointer',
                                                                                }}
                                                                            >
                                                                                {user
                                                                                    ? `${user.firstName.charAt(0).toUpperCase()}${user.lastName.charAt(0).toUpperCase()}`
                                                                                    : ' '}
                                                                            </Avatar>
                                                                        </Tooltip>
                                                                    </Typography>
                                                                </div>

                                                                {/* Options Icon */}
                                                                <Box position="absolute" top={8} right={8}>
                                                                    <IconButton
                                                                        aria-label="options"
                                                                        onClick={event => handleMenuIconClick(event, user)}
                                                                    >
                                                                        <MoreVertIcon style={{ color: 'black' }} />
                                                                    </IconButton>
                                                                </Box>
                                                            </div>
                                                        </Card>
                                                    ))
                                                ) : (
                                                    <Typography variant="body2" color="textSecondary">
                                                        No audits available.
                                                    </Typography>
                                                )}
                                            </Grid>

                                            {/* Pending Audits Section */}
                                            <Grid item md={4} xs={12}>
                                                <Typography variant="subtitle1" style={{ fontWeight: 600, marginBottom: '8px' }}>
                                                    Pending
                                                </Typography>
                                                {auditsByStatus.pendingAudits.length > 0 ? (
                                                    auditsByStatus.pendingAudits.map(user => (
                                                        <Card
                                                            key={user.row_id}
                                                            style={{
                                                                width: '100%',
                                                                padding: 10,
                                                                borderRadius: '8px',
                                                                border: '1px solid #E8E8E8',
                                                                background: '#FFF',
                                                                marginBottom: '30px',
                                                                marginTop: '20px',
                                                                boxShadow: '0px 2px 12px 0px rgba(0, 0, 0, 0.15)',
                                                                position: 'relative',
                                                            }}
                                                            variant="outlined"
                                                        >
                                                            <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                                                                {/* Assessment Number */}
                                                                <div style={{ marginBottom: '20px' }}>
                                                                    <Typography
                                                                        style={{
                                                                            fontWeight: 550,
                                                                            fontSize: '16px',
                                                                            color: '#1D1B20',
                                                                        }}
                                                                    >
                                                                        Assessment Number
                                                                    </Typography>
                                                                    <Typography
                                                                        style={{
                                                                            fontWeight: 500,
                                                                            fontSize: '12px',
                                                                            color: '#616161',
                                                                        }}
                                                                    >
                                                                        {user.ass_no}
                                                                    </Typography>
                                                                </div>

                                                                {/* Zone */}
                                                                <div style={{ marginBottom: '20px' }}>
                                                                    <Typography
                                                                        style={{
                                                                            fontWeight: 550,
                                                                            fontSize: '16px',
                                                                            color: '#1D1B20',
                                                                        }}
                                                                    >
                                                                        Zone
                                                                    </Typography>
                                                                    <Typography
                                                                        style={{
                                                                            fontWeight: 500,
                                                                            fontSize: '12px',
                                                                            color: '#616161',
                                                                        }}
                                                                    >
                                                                        {user.code} - {user.name}
                                                                    </Typography>
                                                                </div>

                                                                {/* Divider */}
                                                                <div
                                                                    style={{
                                                                        borderBottom: '1px solid #E8E8E8',
                                                                        marginBottom: '10px',
                                                                    }}
                                                                ></div>

                                                                {/* Last Updated and Avatar */}
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        justifyContent: 'space-between',
                                                                        marginBottom: '5px',
                                                                    }}
                                                                >
                                                                    <Typography
                                                                        style={{
                                                                            fontWeight: 500,
                                                                            fontSize: '13px',
                                                                            color: '#616161',
                                                                            display: 'flex',
                                                                            flexDirection: 'column',
                                                                            alignItems: 'flex-start',
                                                                        }}
                                                                    >
                                                                        <div>
                                                                            Last Updated:{' '}
                                                                            {new Date(user.audit_date).toLocaleDateString()}
                                                                        </div>
                                                                    </Typography>
                                                                    <Typography
                                                                        style={{
                                                                            fontWeight: 500,
                                                                            paddingRight: '52px',
                                                                            fontSize: '13px',
                                                                            color: '#616161',
                                                                            display: 'flex',
                                                                            flexDirection: 'column',
                                                                            alignItems: 'flex-end',
                                                                        }}
                                                                    >
                                                                        <Tooltip title={user ? `${user.firstName} ${user.lastName}` : ''}>
                                                                            <Avatar
                                                                                style={{
                                                                                    fontSize: '13px',
                                                                                    width: '30px',
                                                                                    height: '30px',
                                                                                    cursor: 'pointer',
                                                                                }}
                                                                            >
                                                                                {user
                                                                                    ? `${user.firstName.charAt(0).toUpperCase()}${user.lastName.charAt(0).toUpperCase()}`
                                                                                    : ' '}
                                                                            </Avatar>
                                                                        </Tooltip>
                                                                    </Typography>
                                                                </div>

                                                                {/* Options Icon */}
                                                                <Box position="absolute" top={8} right={8}>
                                                                    <IconButton
                                                                        aria-label="options"
                                                                        onClick={event => handleMenuIconClick(event, user)}
                                                                    >
                                                                        <MoreVertIcon style={{ color: 'black' }} />
                                                                    </IconButton>
                                                                </Box>
                                                            </div>
                                                        </Card>
                                                    ))
                                                ) : (
                                                    <Typography variant="body2" color="textSecondary">
                                                        No pending audits.
                                                    </Typography>
                                                )}
                                            </Grid>

                                            {/* Completed Audits Section */}
                                            <Grid item md={4} xs={12}>
                                                <Typography variant="subtitle1" style={{ fontWeight: 600, marginBottom: '8px' }}>
                                                    Completed
                                                </Typography>
                                                {auditsByStatus.completedAudits.length > 0 ? (
                                                    auditsByStatus.completedAudits.map(user => (
                                                        <Card
                                                            key={user.row_id}
                                                            style={{
                                                                width: '100%',
                                                                padding: 10,
                                                                borderRadius: '8px',
                                                                border: '1px solid #E8E8E8',
                                                                background: '#FFF',
                                                                marginBottom: '30px',
                                                                marginTop: '20px',
                                                                boxShadow: '0px 2px 12px 0px rgba(0, 0, 0, 0.15)',
                                                                position: 'relative',
                                                            }}
                                                            variant="outlined"
                                                        >
                                                            <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                                                                {/* Assessment Number */}
                                                                <div style={{ marginBottom: '20px' }}>
                                                                    <Typography
                                                                        style={{
                                                                            fontWeight: 550,
                                                                            fontSize: '16px',
                                                                            color: '#1D1B20',
                                                                        }}
                                                                    >
                                                                        Assessment Number
                                                                    </Typography>
                                                                    <Typography
                                                                        style={{
                                                                            fontWeight: 500,
                                                                            fontSize: '12px',
                                                                            color: '#616161',
                                                                        }}
                                                                    >
                                                                        {user.ass_no}
                                                                    </Typography>
                                                                </div>

                                                                {/* Zone */}
                                                                <div style={{ marginBottom: '20px' }}>
                                                                    <Typography
                                                                        style={{
                                                                            fontWeight: 550,
                                                                            fontSize: '16px',
                                                                            color: '#1D1B20',
                                                                        }}
                                                                    >
                                                                        Zone
                                                                    </Typography>
                                                                    <Typography
                                                                        style={{
                                                                            fontWeight: 500,
                                                                            fontSize: '12px',
                                                                            color: '#616161',
                                                                        }}
                                                                    >
                                                                        {user.code} - {user.name}
                                                                    </Typography>
                                                                </div>

                                                                {/* Divider */}
                                                                <div
                                                                    style={{
                                                                        borderBottom: '1px solid #E8E8E8',
                                                                        marginBottom: '10px',
                                                                    }}
                                                                ></div>

                                                                {/* Last Updated and Avatar */}
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        justifyContent: 'space-between',
                                                                        marginBottom: '5px',
                                                                    }}
                                                                >
                                                                    <Typography
                                                                        style={{
                                                                            fontWeight: 500,
                                                                            fontSize: '13px',
                                                                            color: '#616161',
                                                                            display: 'flex',
                                                                            flexDirection: 'column',
                                                                            alignItems: 'flex-start',
                                                                        }}
                                                                    >
                                                                        <div>
                                                                            Last Updated:{' '}
                                                                            {new Date(user.audit_date).toLocaleDateString()}
                                                                        </div>
                                                                    </Typography>
                                                                    <Typography
                                                                        style={{
                                                                            fontWeight: 500,
                                                                            paddingRight: '52px',
                                                                            fontSize: '13px',
                                                                            color: '#616161',
                                                                            display: 'flex',
                                                                            flexDirection: 'column',
                                                                            alignItems: 'flex-end',
                                                                        }}
                                                                    >
                                                                        <Tooltip title={user ? `${user.firstName} ${user.lastName}` : ''}>
                                                                            <Avatar
                                                                                style={{
                                                                                    fontSize: '13px',
                                                                                    width: '30px',
                                                                                    height: '30px',
                                                                                    cursor: 'pointer',
                                                                                }}
                                                                            >
                                                                                {user
                                                                                    ? `${user.firstName.charAt(0).toUpperCase()}${user.lastName.charAt(0).toUpperCase()}`
                                                                                    : ' '}
                                                                            </Avatar>
                                                                        </Tooltip>
                                                                    </Typography>
                                                                </div>

                                                                {/* Options Icon */}
                                                                <Box position="absolute" top={8} right={8}>
                                                                    <IconButton
                                                                        aria-label="options"
                                                                        onClick={event => handleMenuIconClick(event, user)}
                                                                    >
                                                                        <MoreVertIcon style={{ color: 'black' }} />
                                                                    </IconButton>
                                                                </Box>
                                                            </div>
                                                        </Card>
                                                    ))
                                                ) : (
                                                    <Typography variant="body2" color="textSecondary">
                                                        No completed audits.
                                                    </Typography>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Box>
                                )}
                            </Card>
                        </Grid>
                    ))
                ) : (
                    <Typography variant="body2" color="textSecondary">
                        No audits match the selected filters.
                    </Typography>
                )}


                  <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                 
          


                {selectedUser && (
                     
         <Box>
            {/* userRole === 'auditor' &&  */}
             {  selectedUser.status_flg === 'P' && (
                   <div onClick={() => handleEdit(editUser)} style={{ display: "flex", alignItems: 'center', cursor: 'pointer', padding: "10px" }}>
                   <img src={EditIcon} alt="Edit Icon" style={{ marginRight: '10px' }} />
                   <Typography
                       style={{
                           color: '#252C32',
                           fontFamily: 'Inter',
                           fontSize: '16px',
                           fontStyle: 'normal',
                           fontWeight: 600,
                           lineHeight: 'normal',
                           letterSpacing: '-0.084px',
                       }}>Edit</Typography>
               </div>
            )} 
            {/* userRole === 'coordinator' &&  */}
              {isCoordinator  && selectedUser.status_flg === 'C' && (
             <div onClick={() => handleEdit(editUser)} style={{ display: "flex", alignItems: 'center', cursor: 'pointer', padding: "10px" }}>
             <img src={EditIcon} alt="Edit Icon" style={{ marginRight: '10px' }} />
             <Typography
                 style={{
                     color: '#252C32',
                     fontFamily: 'Inter',
                     fontSize: '16px',
                     fontStyle: 'normal',
                     fontWeight: 600,
                     lineHeight: 'normal',
                     letterSpacing: '-0.084px',
                 }}>Edit</Typography>
         </div>
            )} 
            
            </Box>
               )} 

                {selectedUser && selectedUser.status_flg === 'C' && (
                     <>
                <div 
                // onClick={handleDownloadPDF}
                onClick={() => handleDownloadPDF(selectedUser)}
                
                style={{ display: "flex", alignItems: 'center', cursor: 'pointer', padding: "10px" }}>
                    
                    <Typography
                        style={{
                            color: '#252C32',
                            fontFamily: 'Inter',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            lineHeight: 'normal',
                            letterSpacing: '-0.084px',
                        }}>Download as PDF</Typography>
                </div>

                <div onClick={() => handleDownloadExcel(selectedUser)}style={{ display: "flex", alignItems: 'center', cursor: 'pointer', padding: "10px" }}>
                    
                    <Typography
                        style={{
                            color: '#252C32',
                            fontFamily: 'Inter',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            lineHeight: 'normal',
                            letterSpacing: '-0.084px',
                        }}>Download as Excel</Typography>
                </div>
                </>  )} 
                 </Popover> 
            </Grid>

{/* 
<Grid container direction="column" spacing={2}style={{marginTop:'5px'}}>
                    {Object.entries(groupedAudits).map(([month, auditsByStatus]) => (
                        <Grid item key={month}>
                            <Card variant="outlined" style={{ padding: '16px' }}>
                                <div>
                                    {/* Month Accordion */}
                                    {/* <Box
                                        onClick={() => handleToggle(month)}
                                        style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer' }}
                                    >
                                        <Typography variant="h6">{month}</Typography>
                                        <IconButton>
                                            {expanded[month] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                        </IconButton>
                                    </Box> */}
                                    {/* Expanded Content */}
                                    {/* {expanded[month] && (
                                        <Box mt={2}>
                                            {/* All Audits Subheading */}
                                            {/* <Grid container spacing={2}>
                                            <Grid item md={4} xs={12}>
                                            <Typography variant="subtitle1" style={{ fontWeight: 600, marginBottom: '8px' }}>
                                                All Audits
                                            </Typography>
                                            {auditsByStatus.allAudits.length > 0 ? (
                                                auditsByStatus.allAudits.map(user => (
                                                    <Card
                                                        key={user.row_id}
                                                        style={{
                                                            width: "100%",
                                                            padding: 10,
                                                            borderRadius: "8px",
                                                            border: "1px solid #E8E8E8",
                                                            background: "#FFF",
                                                            marginBottom: "30px",
                                                            marginTop:'20px',
                                                            boxShadow: "0px 2px 12px 0px rgba(0, 0, 0, 0.15)",
                                                            position: "relative",
                                                        }}
                                                        variant="outlined"
                                                    >
                                                        <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                                        <div style={{ marginBottom: "20px" }}>
                                <Typography style={{ fontWeight: 550, fontSize: "16px", color: '#1D1B20' }}>Assessment Number</Typography>
                                <Typography style={{ fontWeight: 500, fontSize: "12px", color: '#616161' }}>{user.ass_no}</Typography>
                            </div>
                            <div style={{ marginBottom: "20px" }}>
                                <Typography style={{ fontWeight: 550, fontSize: "16px", color: '#1D1B20' }}>Zone</Typography>
                                <Typography style={{ fontWeight: 500, fontSize: "12px", color: '#616161' }}>{user.code} - {user.name}</Typography>
                            </div>
                            <div style={{ borderBottom: "1px solid #E8E8E8", marginBottom: "10px" }}></div>
                            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "5px" }}>
                                <Typography style={{ fontWeight: 500, fontSize: "13px", color: '#616161', display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                                    <div>Last Updated: {new Date(user.audit_date).toLocaleDateString()}</div>
                                </Typography>
                                <Typography style={{ fontWeight: 500, paddingRight: "52px", fontSize: "13px", color: '#616161', display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                                    <div style={{ marginBottom: "5px" }}>
                                    <Tooltip title={user.firstName + user.lastName}><span>
                                        <Avatar style={{ fontSize: "13px", width: "30px", height: "30px" }}>
                                            
                                                {user ? user.lastName.charAt(0).toUpperCase() + user.firstName.charAt(0).toUpperCase() : " "}
                                        
                                        </Avatar>
                                        </span>    </Tooltip>
                                    </div>
                                </Typography>
                            </div>
                                                            <Box position="absolute" top={8} right={8}>
                                                                <IconButton aria-label="options" onClick={(event) => handleMenuIconClick(event, user)}>
                                                                    <MoreVertIcon style={{ color: 'black' }} />
                                                                </IconButton>
                                                            </Box>
                                                        </div>
                                                    </Card>
                                                ))
                                            ) : (
                                                <Typography variant="body2" color="textSecondary">No audits available.</Typography>
                                            )}
</Grid>
                                            {/* Pending Subheading */}
                                            {/* <Grid item md={4} xs={12}>
                                            <Typography variant="subtitle1" style={{ fontWeight: 600, marginBottom: '8px' }}>
                                                Pending
                                            </Typography>
                                            {auditsByStatus.pendingAudits.length > 0 ? (
                                                auditsByStatus.pendingAudits.map(user => (
                                                    <Card
                                                        key={user.row_id}
                                                        style={{
                                                            width: "100%",
                                                            padding: 10,
                                                            borderRadius: "8px",
                                                            border: "1px solid #E8E8E8",
                                                            background: "#FFF",
                                                            marginBottom: "30px",
                                                            marginTop:'20px',
                                                            boxShadow: "0px 2px 12px 0px rgba(0, 0, 0, 0.15)",
                                                            position: "relative",
                                                        }}
                                                        variant="outlined"
                                                    >
                                                        <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                                        <div style={{ marginBottom: "20px" }}>
                                <Typography style={{ fontWeight: 550, fontSize: "16px", color: '#1D1B20' }}>Assessment Number</Typography>
                                <Typography style={{ fontWeight: 500, fontSize: "12px", color: '#616161' }}>{user.ass_no}</Typography>
                            </div>
                            <div style={{ marginBottom: "20px" }}>
                                <Typography style={{ fontWeight: 550, fontSize: "16px", color: '#1D1B20' }}>Zone</Typography>
                                <Typography style={{ fontWeight: 500, fontSize: "12px", color: '#616161' }}>{user.code} - {user.name}</Typography>
                            </div>
                            <div style={{ borderBottom: "1px solid #E8E8E8", marginBottom: "10px" }}></div>
                            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "5px" }}>
                                <Typography style={{ fontWeight: 500, fontSize: "13px", color: '#616161', display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                                    <div>Last Updated: {new Date(user.audit_date).toLocaleDateString()}</div>
                                </Typography>
                                <Typography style={{ fontWeight: 500, paddingRight: "52px", fontSize: "13px", color: '#616161', display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                                    <div style={{ marginBottom: "5px" }}>
                                        <Avatar style={{ fontSize: "13px", width: "30px", height: "30px" }}>
                                            <Tooltip title={user.firstName + user.lastName}><span>
                                                {user ? user.lastName.charAt(0).toUpperCase() + user.firstName.charAt(0).toUpperCase() : " "}
                                        </span>    </Tooltip>
                                        </Avatar>
                                    </div>
                                </Typography>
                            </div>
                                                            <Box position="absolute" top={8} right={8}>
                                                                <IconButton aria-label="options" onClick={(event) => handleMenuIconClick(event, user)}>
                                                                    <MoreVertIcon style={{ color: 'black' }} />
                                                                </IconButton>
                                                            </Box>
                                                        </div>
                                                    </Card>
                                                ))
                                            ) : (
                                                <Typography variant="body2" color="textSecondary">No pending audits.</Typography>
                                            )}
</Grid> */}
                                            {/* Completed Subheading */}
                                            {/* <Grid item md={4} xs={12}>
                                            <Typography variant="subtitle1" style={{ fontWeight: 600, marginBottom: '8px' }}>
                                                Completed
                                            </Typography>
                                            {auditsByStatus.completedAudits.length > 0 ? (
                                                auditsByStatus.completedAudits.map(user => (
                                                    <Card
                                                        key={user.row_id}
                                                        style={{
                                                            width: "100%",
                                                            padding: 10,
                                                            borderRadius: "8px",
                                                            border: "1px solid #E8E8E8",
                                                            background: "#FFF",
                                                            marginBottom: "30px",
                                                            marginTop:'20px',
                                                            boxShadow: "0px 2px 12px 0px rgba(0, 0, 0, 0.15)",
                                                            position: "relative",
                                                        }}
                                                        variant="outlined"
                                                    >
                                                        <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                                        <div style={{ marginBottom: "20px" }}>
                                <Typography style={{ fontWeight: 550, fontSize: "16px", color: '#1D1B20' }}>Assessment Number</Typography>
                                <Typography style={{ fontWeight: 500, fontSize: "12px", color: '#616161' }}>{user.ass_no}</Typography>
                            </div>
                            <div style={{ marginBottom: "20px" }}>
                                <Typography style={{ fontWeight: 550, fontSize: "16px", color: '#1D1B20' }}>Zone</Typography>
                                <Typography style={{ fontWeight: 500, fontSize: "12px", color: '#616161' }}>{user.code} - {user.name}</Typography>
                            </div>
                            <div style={{ borderBottom: "1px solid #E8E8E8", marginBottom: "10px" }}></div>
                            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "5px" }}>
                                <Typography style={{ fontWeight: 500, fontSize: "13px", color: '#616161', display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                                    <div>Last Updated: {new Date(user.audit_date).toLocaleDateString()}</div>
                                </Typography>
                                <Typography style={{ fontWeight: 500, paddingRight: "52px", fontSize: "13px", color: '#616161', display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                                    <div style={{ marginBottom: "5px" }}>
                                        <Avatar style={{ fontSize: "13px", width: "30px", height: "30px" }}>
                                            <Tooltip title={user.firstName + user.lastName}><span>
                                                {user ? user.lastName.charAt(0).toUpperCase() + user.firstName.charAt(0).toUpperCase() : " "}
                                        </span>    </Tooltip>
                                        </Avatar>
                                    </div>
                                </Typography>
                            </div>
                                                            <Box position="absolute" top={8} right={8}>
                                                                <IconButton aria-label="options" onClick={(event) => handleMenuIconClick(event, user)}>
                                                                    <MoreVertIcon style={{ color: 'black' }} />
                                                                </IconButton>
                                                            </Box>
                                                        </div>
                                                    </Card>
                                                ))
                                            ) : (
                                                <Typography variant="body2" color="textSecondary">No completed audits.</Typography>
                                            )}
                                            </Grid> */}
                                            {/* </Grid>  */}
                                        {/* </Box>
                                    )} 
                                </div>
                                </Card>
                           
                        </Grid>
                    ))} */}
                    {/* <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            > */}
                 
          
{/* 

                {selectedUser && (
                     
         <Box>
            {/* userRole === 'auditor' &&  */}
            {/* {  selectedUser.status_flg === 'P' && (
                   <div onClick={() => handleEdit(editUser)} style={{ display: "flex", alignItems: 'center', cursor: 'pointer', padding: "10px" }}>
                   <img src={EditIcon} alt="Edit Icon" style={{ marginRight: '10px' }} />
                   <Typography
                       style={{
                           color: '#252C32',
                           fontFamily: 'Inter',
                           fontSize: '16px',
                           fontStyle: 'normal',
                           fontWeight: 600,
                           lineHeight: 'normal',
                           letterSpacing: '-0.084px',
                       }}>Edit</Typography>
               </div>
            )} */}
            {/* userRole === 'coordinator' &&  */}
             {/* {isCoordinator  && selectedUser.status_flg === 'C' && (
             <div onClick={() => handleEdit(editUser)} style={{ display: "flex", alignItems: 'center', cursor: 'pointer', padding: "10px" }}>
             <img src={EditIcon} alt="Edit Icon" style={{ marginRight: '10px' }} />
             <Typography
                 style={{
                     color: '#252C32',
                     fontFamily: 'Inter',
                     fontSize: '16px',
                     fontStyle: 'normal',
                     fontWeight: 600,
                     lineHeight: 'normal',
                     letterSpacing: '-0.084px',
                 }}>Edit</Typography>
         </div>
            )} */}
{/*             
            </Box>
               )} */}
{/* 
                {selectedUser && selectedUser.status_flg === 'C' && (
                     <>
                <div 
                // onClick={handleDownloadPDF}
                onClick={() => handleDownloadPDF(selectedUser)}
                
                style={{ display: "flex", alignItems: 'center', cursor: 'pointer', padding: "10px" }}>
                    
                    <Typography
                        style={{
                            color: '#252C32',
                            fontFamily: 'Inter',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            lineHeight: 'normal',
                            letterSpacing: '-0.084px',
                        }}>Download as PDF</Typography>
                </div>

                <div onClick={() => handleDownloadExcel(selectedUser)}style={{ display: "flex", alignItems: 'center', cursor: 'pointer', padding: "10px" }}>
                    
                    <Typography
                        style={{
                            color: '#252C32',
                            fontFamily: 'Inter',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            lineHeight: 'normal',
                            letterSpacing: '-0.084px',
                        }}>Download as Excel</Typography>
                </div>
                </>  )} */}
                {/* </Popover>
                    </Grid>   */}

          
 
</Box>
<NewAssessment  open={dialogOpen}
        onClose={handleDialogClose}
       />
        
   <HorizontalCombinedStepper open={AssessDialogOpen}   state={formData}
                assesmentid={localStorage.getItem("assessmentIdcreate")} />
        </div>
        
    );
    
   
};


export default Audit;



