import React, { useState,useEffect,useRef} from "react";
import { Typography, Button, Snackbar,Alert,Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import imageCompression from 'browser-image-compression';
import Header from '../../app/TopAppBar';
import CloudUpload from "../../Asset/Icons/file_upload_icon.svg";
import { useRecoilState } from 'recoil';
import { stepState, formDataState,assessmentCreatedState} from '../../recoil/atoms';
import axios from "axios";
import configParam from "../../config";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import MuiAlert from '@mui/material/Alert';
import LogoutIcon from '@mui/icons-material/Logout';
const Sustain = ( { onNext, activeStep,onSave,loading } ) => {
    const [formData, setFormData] = useRecoilState(formDataState);
    const [selectedValues, setSelectedValues] = useState({});
    const fileInputRef = useRef(null);
    const [images, setImages] = useState({});
    const [remarks, setRemarks] = useState({});
    const [errors, setErrors] = useState({});
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const[set,setStream] = useState({});
    const [isCameraOpen, setIsCameraOpen] = useState(false);
    const [assessmentCreated, setAssessmentCreated] = useRecoilState(assessmentCreatedState);
    const [assessmentId, setAssessmentId] = useState(null);
    const assessmenteditflg = localStorage.getItem("assessmenteditflg") === "true";
    const ApiURL = configParam.API_URL;
    const [saveflg, setSaveflg] = useState(false);
    const  access_token =  JSON.parse(localStorage.getItem("accesstoken"));
    useEffect(() => {
        return () => {
            // Store form data in Recoil state when unmounting
            setFormData(prevFormData => ({
                ...prevFormData,
                [activeStep]: {
                    selectedValues,
                    images,
                    remarks
                }
            }));
        };
    }, [activeStep, selectedValues, images, remarks, setFormData])

    useEffect(() => {
        if (assessmenteditflg) {
            localStorage.setItem("assessmentIdcreate","");
            const assessmentId = localStorage.getItem("assessmentId");
            geteditdetails(assessmentId);
        }
    }, []);


    async function geteditdetails(assessmentId) {
        try {
            const response = await axios.get(`${ApiURL}/get_AssessmentByIds/${assessmentId}`,{headers: {
                Authorization: `Bearer ${access_token}`
              }});
            if (response.data) {
                const formData = JSON.parse(response.data.data.details);
                setSelectedValues(formData.selectedValues || {});
                setRemarks(formData.remarks || {});
    
                // Construct image paths for each image
                const updatedImages = {};
                for (const questionId in formData.images) {
                    if (formData.images.hasOwnProperty(questionId)) {
                        const imageName = formData.images[questionId];
                        const imagePath = `${ApiURL}/api/images/${imageName}`;// Replace this with your actual image directory path
                        updatedImages[questionId] = imagePath;
                    }
                }
                setImages(updatedImages);
    
                console.log("Updated Images", updatedImages);
            } else {
                console.log("No data found in the response");
            }
        } catch (error) {
            console.log("Error:", error);
            return null;
        }
    }
    
    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setSnackbarOpen(false);
      };

    const handleNextClick = () => {
        // Validate form fields
        const newErrors = {};
        if (selectedValues['question26'] === "0") {
            // If radio button value is "0", both image and remarks fields are mandatory
            if (!images['question26']) {
                newErrors["question26"] = "Please capture an image.";
            }
            if (!remarks['question26']) {
                newErrors["question26"] = "Please provide remarks.";
            }
        }
          if (selectedValues['question26'] === "1") {
            // If radio button value is "1", only remarks field is mandatory
            if (!remarks['question26']) {
                newErrors["question26"] = "Please provide remarks.";
            }
        }
    
        if (selectedValues['question27'] === "0") {
            // If radio button value is "0", both image and remarks fields are mandatory
            if (!images['question27']) {
                newErrors["question27"] = "Please capture an image.";
            }
            if (!remarks['question27']) {
                newErrors["question27"] = "Please provide remarks.";
            }
        }
          if (selectedValues['question27'] === "1") {
            // If radio button value is "1", only remarks field is mandatory
            if (!remarks['question27']) {
                newErrors["question27"] = "Please provide remarks.";
            }
        }

            if (selectedValues['question28'] === "0") {
                // If radio button value is "0", both image and remarks fields are mandatory
                if (!images['question28']) {
                    newErrors["question28"] = "Please capture an image.";
                }
                if (!remarks['question28']) {
                    newErrors["question28"] = "Please provide remarks.";
                }
            }
              if (selectedValues['question28'] === "1") {
                // If radio button value is "1", only remarks field is mandatory
                if (!remarks['question28']) {
                    newErrors["question28"] = "Please provide remarks.";
                }
            }
                if (selectedValues['question29'] === "0") {
                    // If radio button value is "0", both image and remarks fields are mandatory
                    if (!images['question29']) {
                        newErrors["question29"] = "Please capture an image.";
                    }
                    if (!remarks['question29']) {
                        newErrors["question29"] = "Please provide remarks.";
                    }
                }
                  if (selectedValues['question29'] === "1") {
                    // If radio button value is "1", only remarks field is mandatory
                    if (!remarks['question29']) {
                        newErrors["question29"] = "Please provide remarks.";
                    }
                }
                   
        
        setErrors(newErrors);

        // If there are errors, display Snackbar and prevent moving to the next step
        if (Object.keys(newErrors).length > 0) {
            setSnackbarMessage("Please fill the questions.");
            setSnackbarOpen(true);
        } 
        
        else {
            if(selectedValues['question26']  ||selectedValues['question27'] ||selectedValues['question28'] || selectedValues['question29'] ){
              
                if( saveflg === true ){
                    onNext();
                    setSaveflg(false)
                }
                else{
                    setSnackbarMessage("Please save your changes before moving to the next step.");
                    setSnackbarOpen(true);
                }
            }
            else{
                onNext();
            }
        }
    };
    

    
const handleCapture = async (questionId) => {
    // Open the gallery for image selection
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Trigger the input element to open the gallery
      fileInputRef.current.onchange = (e) => {
        const selectedFile = e.target.files[0];
        console.log("selectedfile",selectedFile)
        if (selectedFile) {
          // Process the selected image using the setUploadedFile function
          if (!selectedFile.type.startsWith("image/")) {
        
          }
          else{
          
            setUploadedFile({ target: { files: [selectedFile] } },questionId);
          }
         
        }
      };
   
    }
  };
  
  
  const setUploadedFile = async (event,questionId) => {
      const file = event.target.files[0];
      console.log("file",file)
      if (file) {
        // If an image is uploaded, set the imagePath state to the URL of the uploaded image
       
        await generateImagesArray(file,questionId);
       // await uploadBlobImagesAPI(imageData,file);
      } else {
        // If no image is uploaded, reset the imagePath state
       // setImagePath('');
      }
    };
  
  
  const generateImagesArray = async (file,questionId) => {
    const imagesData = [];
    console.log("fileimage",file)
    console.log("fileimage",file.type)
    const reader = new FileReader();
    reader.onloadend = async () => {
      const imageType = file.type.split('/')[1];
      console.log("imageType",imageType)
      const imageBase64 = reader.result.split(',')[1];
      const imageName = `image_${Date.now()}.${imageType}`;
  
      console.log("imageBase6411",imageBase64)
      imagesData.push({
        imageName,
        imageBase64,
        imageType,
      });
      setImages(prevImages => ({ ...prevImages, [questionId]: `data:image/${imageType};base64,`+ imageBase64 }));
    
      // Call the upload function after processing the images
      //await uploadBlobImagesAPI(imagesData,file);
    };
   
  reader.readAsDataURL(file);
    console.log("imagesData",imagesData)
  }



    // const handleCapture = async (questionId) => {
    //     if (!isCameraOpen) {
    //         try {
    //             const newStream = await navigator.mediaDevices.getUserMedia({ video: true });
    //             setStream(newStream);
    //             const video = document.createElement('video');
    //             document.getElementById(`camera-container-${questionId}`).appendChild(video);
    //             video.srcObject = newStream;
    //             video.play();
    //             const captureImage = () => {
    //                 const canvas = document.createElement('canvas');
    //                 canvas.width = video.videoWidth;
    //                 canvas.height = video.videoHeight;
    //                 canvas.getContext('2d').drawImage(video, 0, 0);
    //                 const imageUrl = canvas.toDataURL('image/png');
    //                 setImages(prevImages => ({ ...prevImages, [questionId]: imageUrl }));
    //                 newStream.getTracks().forEach(track => track.stop());
    //                 document.getElementById(`camera-container-${questionId}`).removeChild(video);
    //                 setStream(null);
    //                 setIsCameraOpen(false);
    //             };
    //             video.addEventListener('click', captureImage);
    //             setIsCameraOpen(true);
    //         } catch (error) {
    //             console.error('Error accessing camera:', error);
    //         }
    //     }
    // };





    const handleRetake = (questionId) => {
        // Clear the previously captured image and allow the user to capture a new one
        setImages(prevImages => ({ ...prevImages, [questionId]: null }));
        handleCapture(questionId);
    };

    const handleRemarksChange = (questionId, value) => {
        // Update selectedValues state
        setRemarks({ ...remarks, [questionId]: value });

        // Update formData state by merging selectedValues into the current step's data
        setFormData(prevFormData => ({
            ...prevFormData,
            [activeStep]: {
                ...prevFormData[activeStep],
                remarks: { ...prevFormData[activeStep].remarks, [questionId]: value }
            }
        }));
    };


    // const handleImageUpload = (event, question) => {
    //     const file = event.target.files[0];
    //     if (file) {
    //         const reader = new FileReader();
    //         reader.onload = () => {
    //             setImages(prevImages => ({ ...prevImages, [question]: reader.result }));
    //         };
    //         reader.readAsDataURL(file);
    //     }
    // };
    useEffect(() => {
        populateFormFields();
    }, [activeStep]);
    const populateFormFields = () => {
        const stepFormData = formData[activeStep];
        setSelectedValues(stepFormData.selectedValues || {});
        setImages(stepFormData.images || {});
        setRemarks(stepFormData.remarks || {});
    };
    // const handleNextClick = () => {
       
    
    //     onNext();
    // };
    
    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };
    const handleRadioChange = (questionId, value) => {
        // Update selectedValues state
        setSelectedValues({ ...selectedValues, [questionId]: value });

        // Update formData state by merging selectedValues into the current step's data
        setFormData(prevFormData => ({
            ...prevFormData,
            [activeStep]: {
                ...prevFormData[activeStep],
                selectedValues: { ...prevFormData[activeStep].selectedValues, [questionId]: value }
            }
        }));
    };
    // const handleSave = async () => {
    //     try {
    //         // Prepare data to send to the backend
    //         const postData = {
    //             assessmentData: {
    //                 selectedValues,
    //                 images,
    //                 remarks
    //             }
    //         };
    
    //         // Check if the assessment has been created
    //         if (!assessmentCreated) {
    //             // If assessment has not been created, call create_assessment API
    //             const response = await axios.post(ApiURL + '/create_assessment', postData);
    //             if (response.data.success) {
    //                 // Set the assessmentCreated state to true
    //                 setAssessmentCreated(true);
    //             } else {
    //                 // Handle error response
    //                 setSnackbarMessage("Failed to create assessment.");
    //                 setSnackbarOpen(true);
    //                 return;
    //             }
    //         } else {
    //             // If assessment has been created, call update_assessment API
    //             const response = await axios.post(ApiURL + '/update_assessment', postData);
    //             if (!response.data.success) {
    //                 // Handle error response
    //                 setSnackbarMessage("Failed to update assessment.");
    //                 setSnackbarOpen(true);
    //                 return;
    //             }
    //         }
    
    //         // Handle success response
    //         setSnackbarMessage("Assessment updated successfully.");
    //         setSnackbarOpen(true);
    //     } catch (error) {
    //         // Handle request error
    //         console.error('Error updating assessment:', error);
    //         setSnackbarMessage("Failed to update assessment. Please try again later.");
    //         setSnackbarOpen(true);
    //     }
    // };
    // const handleSaveClick = () => {
    //     // Call the onSave function passed from the parent component
    //     onSave();
    //   }; 

      const handleSaveClick = () => {
        if (!loading) {
        // Call the onSave function passed from the parent component
        const newErrors = {};
        let functionEntered = false; 
        if (selectedValues['question26'] === "0") {
            // If radio button value is "0", both image and remarks fields are mandatory
            if (!images['question26']) {
                newErrors["question26"] = "Please capture an image.";
            }
            if (!remarks['question26']) {
                newErrors["question26"] = "Please provide remarks.";
            }
            functionEntered = true; 
        }
          if (selectedValues['question26'] === "1") {
            // If radio button value is "1", only remarks field is mandatory
            if (!remarks['question26']) {
                newErrors["question26"] = "Please provide remarks.";
            }
            functionEntered = true; 
        }
    
        if (selectedValues['question27'] === "0") {
            // If radio button value is "0", both image and remarks fields are mandatory
            if (!images['question27']) {
                newErrors["question27"] = "Please capture an image.";
            }
            if (!remarks['question27']) {
                newErrors["question27"] = "Please provide remarks.";
            }
            functionEntered = true; 
        }
          if (selectedValues['question27'] === "1") {
            // If radio button value is "1", only remarks field is mandatory
            if (!remarks['question27']) {
                newErrors["question27"] = "Please provide remarks.";
            }
            functionEntered = true; 
        }

            if (selectedValues['question28'] === "0") {
                // If radio button value is "0", both image and remarks fields are mandatory
                if (!images['question28']) {
                    newErrors["question28"] = "Please capture an image.";
                }
                if (!remarks['question28']) {
                    newErrors["question28"] = "Please provide remarks.";
                }
                functionEntered = true; 
            }
              if (selectedValues['question28'] === "1") {
                // If radio button value is "1", only remarks field is mandatory
                if (!remarks['question28']) {
                    newErrors["question28"] = "Please provide remarks.";
                }
                functionEntered = true; 
            }
                if (selectedValues['question29'] === "0") {
                    // If radio button value is "0", both image and remarks fields are mandatory
                    if (!images['question29']) {
                        newErrors["question29"] = "Please capture an image.";
                    }
                    if (!remarks['question29']) {
                        newErrors["question29"] = "Please provide remarks.";
                    }
                    functionEntered = true; 
                }
                  if (selectedValues['question29'] === "1") {
                    // If radio button value is "1", only remarks field is mandatory
                    if (!remarks['question29']) {
                        newErrors["question29"] = "Please provide remarks.";
                    }
                    functionEntered = true; 
                }
                   
        
        setErrors(newErrors);

        // If there are errors, display Snackbar and prevent moving to the next step
        if (Object.keys(newErrors).length > 0) {
            setSnackbarMessage("Please fill the questions.");
            setSnackbarOpen(true);
        } 

        if (!functionEntered) {
            setSnackbarMessage("Please fill at least one field.");              
            setSnackbarOpen(true);
           // newErrors["general"] = "Please fill at least one field.";
        }
     
        else {
            if (selectedValues['question26'] === "0") {
                // If radio button value is "0", both image and remarks fields are mandatory
                if (!images['question26']) {
                    newErrors["question26"] = "Please capture an image.";
                }
                if (!remarks['question26']) {
                    newErrors["question26"] = "Please provide remarks.";
                }
            }
              if (selectedValues['question26'] === "1") {
                // If radio button value is "1", only remarks field is mandatory
                if (!remarks['question26']) {
                    newErrors["question26"] = "Please provide remarks.";
                }
            }
        
            if (selectedValues['question27'] === "0") {
                // If radio button value is "0", both image and remarks fields are mandatory
                if (!images['question27']) {
                    newErrors["question27"] = "Please capture an image.";
                }
                if (!remarks['question27']) {
                    newErrors["question27"] = "Please provide remarks.";
                }
            }
              if (selectedValues['question27'] === "1") {
                // If radio button value is "1", only remarks field is mandatory
                if (!remarks['question27']) {
                    newErrors["question27"] = "Please provide remarks.";
                }
            }
    
                if (selectedValues['question28'] === "0") {
                    // If radio button value is "0", both image and remarks fields are mandatory
                    if (!images['question28']) {
                        newErrors["question28"] = "Please capture an image.";
                    }
                    if (!remarks['question28']) {
                        newErrors["question28"] = "Please provide remarks.";
                    }
                }
                  if (selectedValues['question28'] === "1") {
                    // If radio button value is "1", only remarks field is mandatory
                    if (!remarks['question28']) {
                        newErrors["question28"] = "Please provide remarks.";
                    }
                }
                    if (selectedValues['question29'] === "0") {
                        // If radio button value is "0", both image and remarks fields are mandatory
                        if (!images['question29']) {
                            newErrors["question29"] = "Please capture an image.";
                        }
                        if (!remarks['question29']) {
                            newErrors["question29"] = "Please provide remarks.";
                        }
                    }
                      if (selectedValues['question29'] === "1") {
                        // If radio button value is "1", only remarks field is mandatory
                        if (!remarks['question29']) {
                            newErrors["question29"] = "Please provide remarks.";
                        }
                    }
                       
            
            setErrors(newErrors);
    
            // If there are errors, display Snackbar and prevent moving to the next step
            if (Object.keys(newErrors).length > 0) {
                setSnackbarMessage("Please fill the questions.");
                setSnackbarOpen(true);
            } 
            else{
                setSaveflg(true);
                onSave(selectedValues, images, remarks);
            }
           
        }
    }
        // onSave(selectedValues, images, remarks);
      };
    return (
        <Box>

        {/* <Header pageTitle={assessmenteditflg ? "Edit Assessment" : "New Assessment"} isAuditPage={true} />
         */}
        {/* <Header pageTitle="New Assessment" isAuditPage={true} /> */}
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            {/* Question 1 */}
            <Card variant="outlined" style={{ marginBottom: '20px' }}>
        <CardContent>
        <Typography variant="h5" style={{ textTransform: 'uppercase', fontSize: "14px", fontWeight: '500', color: '#17428B', marginBottom: '10px' }}>
        Employee involment
        </Typography>
        <div style={{ marginBottom: '20px' }}>
        <p style={{ marginBottom: '10px', fontSize: "14px", fontWeight: '500' }}>
        26. All employees involved in maintaining the standards of the workplace. Ask an employee at ramdon regarding their contribution to the area.
        </p>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <p style={{ marginRight: '10px', fontSize: "14px", fontWeight: '500' }}>SCORE:</p>
          <label style={{ marginRight: '20px' }}>
            <input
              type="radio"
              name="radioButton26"
              value="0"
              checked={selectedValues['question26'] === "0"}
              onChange={(e) => handleRadioChange('question26', e.target.value)}
            /> 0
          </label>
          <label>
            <input
              type="radio"
              name="radioButton26"
              value="1"
              checked={selectedValues['question26'] === "1"}
              onChange={(e) => handleRadioChange('question26', e.target.value)}
            /> 1
          </label>
        </div>
        </div>
        
        {selectedValues.question26 === "0" && (
        <div>
          <div id="camera-container-question26" style={{ border: "1px dashed #e6e6e6", height: '160px', textAlign: 'center', marginBottom: '20px', position: 'relative', borderRadius: '10px' }}>
            {!images['question26'] ? (
              <>
                <input
                  id="upload-image_question26"
                  multiple
                  type="file"
                  style={{ display: "none" }}
                  ref={fileInputRef}
                />
                <button onClick={() => handleCapture('question26')} onChange={(e) => setFormData({ ...selectedValues, image26: e.target.value })} style={{ position: 'absolute', cursor: 'pointer', background: 'none', border: 'none', width: '100%', height: '100%', left: '0', top: '0' }}>
                  <LogoutIcon sx={{ transform: 'rotate(-90deg)', color: '#C9C9CC', margin: '30px 0 10px' }} />
                  <Typography style={{ textAlign: 'center', color: 'black', fontWeight: 500, fontSize: '16px' }}>Drag & drop images or <span style={{ color: '#17428B' }}>browse images</span></Typography>
                  <Typography style={{ textAlign: 'center', color: '#4F4D55', fontSize: '12px', marginTop: '10px' }}>.xlsx - max file size 2MB</Typography>
                </button>
              </>
            ) : (
              <>
                <img src={images['question26']} onChange={(e) => setFormData({ ...selectedValues, image26: e.target.value })} alt="Captured" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                <div style={{ position: 'absolute', bottom: '10px', left: '50%', transform: 'translateX(-50%)', zIndex: 1 }}>
                  <Button variant="contained" onClick={() => handleRetake('question26')}>Retake</Button>
                </div>
              </>
            )}
          </div>
        </div>
        )}
        
        <div>
        <p style={{ marginBottom: '10px', fontSize: "14px", fontWeight: '500' }}>Remarks for Improvement</p>
        <textarea
          value={remarks["question26"] || ''}
          onChange={(e) => handleRemarksChange('question26', e.target.value)}
          placeholder="Type here"
          style={{ width: '100%', padding: '8px', outline: 'none', border: '1px solid #E6E6E6', borderRadius: '4px', resize: "none" }}
        />
        </div>
        
        {errors["question26"] && <Typography style={{ color: 'red' }}>{errors["question26"]}</Typography>}
        </CardContent>
        </Card>
        
        <Card variant="outlined" style={{ marginBottom: '20px' }}>
        <CardContent>
        <Typography variant="h5" style={{ textTransform: 'uppercase', fontSize: "14px", fontWeight: '500', color: '#17428B', marginBottom: '10px' }}>
        5S zone audit
        </Typography>
        <div style={{ marginBottom: '20px' }}>
        <p style={{ marginBottom: '10px', fontSize: "14px", fontWeight: '500' }}>
        27. The area audits are carried out in accordance with the planning. All action items have been transferred to the Action Plan.
        </p>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <p style={{ marginRight: '10px', fontSize: "14px", fontWeight: '500' }}>SCORE:</p>
          <label style={{ marginRight: '20px' }}>
            <input
              type="radio"
              name="radioButton27"
              value="0"
              checked={selectedValues['question27'] === "0"}
              onChange={(e) => handleRadioChange('question27', e.target.value)}
            /> 0
          </label>
          <label>
            <input
              type="radio"
              name="radioButton27"
              value="1"
              checked={selectedValues['question27'] === "1"}
              onChange={(e) => handleRadioChange('question27', e.target.value)}
            /> 1
          </label>
        </div>
        </div>
        
        {selectedValues.question27 === "0" && (
        <div>
          <div id="camera-container-question27" style={{ border: "1px dashed #e6e6e6", height: '160px', textAlign: 'center', marginBottom: '20px', position: 'relative', borderRadius: '10px' }}>
            {!images['question27'] ? (
              <>
                <input
                  id="upload-image_question27"
                  multiple
                  type="file"
                  style={{ display: "none" }}
                  ref={fileInputRef}
                />
                <button onClick={() => handleCapture('question27')} onChange={(e) => setFormData({ ...selectedValues, image27: e.target.value })} style={{ position: 'absolute', cursor: 'pointer', background: 'none', border: 'none', width: '100%', height: '100%', left: '0', top: '0' }}>
                  <LogoutIcon sx={{ transform: 'rotate(-90deg)', color: '#C9C9CC', margin: '30px 0 10px' }} />
                  <Typography style={{ textAlign: 'center', color: 'black', fontWeight: 500, fontSize: '16px' }}>Drag & drop images or <span style={{ color: '#17428B' }}>browse images</span></Typography>
                  <Typography style={{ textAlign: 'center', color: '#4F4D55', fontSize: '12px', marginTop: '10px' }}>.xlsx - max file size 2MB</Typography>
                </button>
              </>
            ) : (
              <>
                <img src={images['question27']} onChange={(e) => setFormData({ ...selectedValues, image27: e.target.value })} alt="Captured" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                <div style={{ position: 'absolute', bottom: '10px', left: '50%', transform: 'translateX(-50%)', zIndex: 1 }}>
                  <Button variant="contained" onClick={() => handleRetake('question27')}>Retake</Button>
                </div>
              </>
            )}
          </div>
        </div>
        )}
        
        <div>
        <p style={{ marginBottom: '10px', fontSize: "14px", fontWeight: '500' }}>Remarks for Improvement</p>
        <textarea
          value={remarks["question27"] || ''}
          onChange={(e) => handleRemarksChange('question27', e.target.value)}
          placeholder="Type here"
          style={{ width: '100%', padding: '8px', outline: 'none', border: '1px solid #E6E6E6', borderRadius: '4px', resize: "none" }}
        />
        </div>
        
        {errors["question27"] && <Typography style={{ color: 'red' }}>{errors["question27"]}</Typography>}
        </CardContent>
        </Card>
        
        
        
       
        
        
        
        <Card variant="outlined" style={{ marginBottom: '20px' }}>
        <CardContent>
        <Typography variant="h5" style={{ textTransform: 'uppercase', fontSize: "14px", fontWeight: '500', color: '#17428B', marginBottom: '10px' }}>
        5S Action plan
        </Typography>
        <div style={{ marginBottom: '20px' }}>
        <p style={{ marginBottom: '10px', fontSize: "14px", fontWeight: '500' }}>
        28. Comments from the previous months audit appear on the action plan and all actions are on time.
        </p>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <p style={{ marginRight: '10px', fontSize: "14px", fontWeight: '500' }}>SCORE:</p>
          <label style={{ marginRight: '20px' }}>
            <input
              type="radio"
              name="radioButton28"
              value="0"
              checked={selectedValues['question28'] === "0"}
              onChange={(e) => handleRadioChange('question28', e.target.value)}
            /> 0
          </label>
          <label>
            <input
              type="radio"
              name="radioButton28"
              value="1"
              checked={selectedValues['question28'] === "1"}
              onChange={(e) => handleRadioChange('question28', e.target.value)}
            /> 1
          </label>
        </div>
        </div>
        
        {selectedValues.question28 === "0" && (
        <div>
          <div id="camera-container-question28" style={{ border: "1px dashed #e6e6e6", height: '160px', textAlign: 'center', marginBottom: '20px', position: 'relative', borderRadius: '10px' }}>
            {!images['question28'] ? (
              <>
                <input
                  id="upload-image_question28"
                  multiple
                  type="file"
                  style={{ display: "none" }}
                  ref={fileInputRef}
                />
                <button onClick={() => handleCapture('question28')} onChange={(e) => setFormData({ ...selectedValues, image28: e.target.value })} style={{ position: 'absolute', cursor: 'pointer', background: 'none', border: 'none', width: '100%', height: '100%', left: '0', top: '0' }}>
                  <LogoutIcon sx={{ transform: 'rotate(-90deg)', color: '#C9C9CC', margin: '30px 0 10px' }} />
                  <Typography style={{ textAlign: 'center', color: 'black', fontWeight: 500, fontSize: '16px' }}>Drag & drop images or <span style={{ color: '#17428B' }}>browse images</span></Typography>
                  <Typography style={{ textAlign: 'center', color: '#4F4D55', fontSize: '12px', marginTop: '10px' }}>.xlsx - max file size 2MB</Typography>
                </button>
              </>
            ) : (
              <>
                <img src={images['question28']} onChange={(e) => setFormData({ ...selectedValues, image28: e.target.value })} alt="Captured" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                <div style={{ position: 'absolute', bottom: '10px', left: '50%', transform: 'translateX(-50%)', zIndex: 1 }}>
                  <Button variant="contained" onClick={() => handleRetake('question28')}>Retake</Button>
                </div>
              </>
            )}
          </div>
        </div>
        )}
        
        <div>
        <p style={{ marginBottom: '10px', fontSize: "14px", fontWeight: '500' }}>Remarks for Improvement</p>
        <textarea
          value={remarks["question28"] || ''}
          onChange={(e) => handleRemarksChange('question28', e.target.value)}
          placeholder="Type here"
          style={{ width: '100%', padding: '8px', outline: 'none', border: '1px solid #E6E6E6', borderRadius: '4px', resize: "none" }}
        />
        </div>
        
        {errors["question28"] && <Typography style={{ color: 'red' }}>{errors["question28"]}</Typography>}
        </CardContent>
        </Card>
        <Card variant="outlined" style={{ marginBottom: '20px' }}>
        <CardContent>
        <Typography variant="h5" style={{ textTransform: 'uppercase', fontSize: "14px", fontWeight: '500', color: '#17428B', marginBottom: '10px' }}>
        5S zone meeting
        </Typography>
        <div style={{ marginBottom: '20px' }}>
        <p style={{ marginBottom: '10px', fontSize: "14px", fontWeight: '500' }}>
        29. A 6S meeting takes place regularly (weekly/monthly) in the workplace with the works/staff of that area in order to review progress. Proof of this meeting needs to occur with minutes including a list of attendees on the story board / notice board.
        </p>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <p style={{ marginRight: '10px', fontSize: "14px", fontWeight: '500' }}>SCORE:</p>
          <label style={{ marginRight: '20px' }}>
            <input
              type="radio"
              name="radioButton29"
              value="0"
              checked={selectedValues['question29'] === "0"}
              onChange={(e) => handleRadioChange('question29', e.target.value)}
            /> 0
          </label>
          <label>
            <input
              type="radio"
              name="radioButton29"
              value="1"
              checked={selectedValues['question29'] === "1"}
              onChange={(e) => handleRadioChange('question29', e.target.value)}
            /> 1
          </label>
        </div>
        </div>
        
        {selectedValues.question29 === "0" && (
        <div>
          <div id="camera-container-question29" style={{ border: "1px dashed #e6e6e6", height: '160px', textAlign: 'center', marginBottom: '20px', position: 'relative', borderRadius: '10px' }}>
            {!images['question29'] ? (
              <>
                <input
                  id="upload-image_question29"
                  multiple
                  type="file"
                  style={{ display: "none" }}
                  ref={fileInputRef}
                />
                <button onClick={() => handleCapture('question29')} onChange={(e) => setFormData({ ...selectedValues, image29: e.target.value })} style={{ position: 'absolute', cursor: 'pointer', background: 'none', border: 'none', width: '100%', height: '100%', left: '0', top: '0' }}>
                  <LogoutIcon sx={{ transform: 'rotate(-90deg)', color: '#C9C9CC', margin: '30px 0 10px' }} />
                  <Typography style={{ textAlign: 'center', color: 'black', fontWeight: 500, fontSize: '16px' }}>Drag & drop images or <span style={{ color: '#17428B' }}>browse images</span></Typography>
                  <Typography style={{ textAlign: 'center', color: '#4F4D55', fontSize: '12px', marginTop: '10px' }}>.xlsx - max file size 2MB</Typography>
                </button>
              </>
            ) : (
              <>
                <img src={images['question29']} onChange={(e) => setFormData({ ...selectedValues, image29: e.target.value })} alt="Captured" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                <div style={{ position: 'absolute', bottom: '10px', left: '50%', transform: 'translateX(-50%)', zIndex: 1 }}>
                  <Button variant="contained" onClick={() => handleRetake('question29')}>Retake</Button>
                </div>
              </>
            )}
          </div>
        </div>
        )}
        
        <div>
        <p style={{ marginBottom: '10px', fontSize: "14px", fontWeight: '500' }}>Remarks for Improvement</p>
        <textarea
          value={remarks["question29"] || ''}
          onChange={(e) => handleRemarksChange('question29', e.target.value)}
          placeholder="Type here"
          style={{ width: '100%', padding: '8px', outline: 'none', border: '1px solid #E6E6E6', borderRadius: '4px', resize: "none" }}
        />
        </div>
        
        {errors["question29"] && <Typography style={{ color: 'red' }}>{errors["question29"]}</Typography>}
        </CardContent>
        </Card>
        
        
         
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                message={snackbarMessage}
            />
            <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', marginTop: '20px' }}>
                <Button variant="contained" color="primary" onClick={handleSaveClick} style={{
                    width: '100%',
                    background: 'white', // Set background color to white
                    borderRadius: '5px', // Add border radius for rounded corners
                    border: '1px solid #17428B', // Set initial border to 1px solid
                    color: '#15408A', // Set font color to blue
                    boxShadow: 'none',
                    position: 'relative',
                    // Remove default box shadow
                }} disabled={loading}
        
                >Save
        
                </Button>
                <Button variant="contained" style={{
                    width: '100%',
                    background: '#17428B', // Adjust the gradient colors as needed
                    borderRadius: 3,
                    border: 0,
                    color: 'white',
                    height: 48,
                    padding: '0 30px',
                    boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
                }} color="primary" onClick={handleNextClick} disabled={loading} >
                    Next
                </Button>
            </div>
        
        </div>
        {loading && (
            <div
                style={{
                    position: 'fixed',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 9999,
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    padding: '20px',
                    borderRadius: '8px',
                    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                }}
            >
                {/* Replace this with your preferred loading indicator */}
                <Typography variant="h6" style={{ textAlign: 'center', marginBottom: '10px' }}>
                    Saving Assessment...
                </Typography>
                {/* Example loading spinner */}
                <div style={{ textAlign: 'center' }}>
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
        )}
        </Box>
    );
}

export default Sustain;
