import React, { useEffect, useState } from 'react';
import { styled } from '@mui/system';
import TextBox from '../input/TextBox';
import SelectBox from '../input/SelectBox';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Modal, Box, Typography, IconButton, TextField, InputLabel, FormControl, Autocomplete, Tooltip, CircularProgress } from '@mui/material';
import EditIcon from "../../Asset/Icons/ic_edit.svg";
import DeleteIcon from "../../Asset/Icons/ic_delete.svg";
import SearchIcon from "../../Asset/Icons/ic_search.svg";
import CloseIcon from "../../Asset/Icons/ic_close.svg";
import AlertDialog from '../input/AlertDialog';
import { useRecoilState } from "recoil";
import { userData } from '../../recoil/atoms';
import StyledButton from '../../Inputs/StyledButton';
import API from '../../Services/API';
import configParam from "../../config";
const ModalContent = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    height: 800,
    backgroundColor: "#fff",
    padding: theme.spacing(4)
}));

const firstRowStyles = {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '-0.006em',
    textAlign: 'left',
    color: "#0A090B",
}

const NewUser = () => {
    const [open, setOpen] = useState(false);
    const [roles, setRoles] = useState([]);
    const [userRole, setuser] = useState([]);
    const [formData, setFormData] = useRecoilState(userData);
    const [users, setUsers] = useState([]);
    const [selectedUserIndex, setSelectedUserIndex] = useState(null);
    const [isEditMode, setIsEditMode] = useState(false); // New state for edit mode
    const [isInputVisible, setInputVisible] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [formErrors, setFormErrors] = useState({}); // Initialize formErrors state
    const [loading, setLoading] = useState(false); // Loader state
    const [error, setError] = useState(false); // Error state
    const [department_id, setDepartment_Id] = useState([]);
    const [plants, setPlants] = useState([]);
    const [zoneareas, setZoneAreas] = useState([]);
    const [noRecords, setNoRecords] = useState(false);
    const [firstName, setFirstName] = useState(false);
    const [lastName, setLastName] = useState(false);
    const ApiURL = configParam.API_URL;
    const [email, setEmail] = useState(false);
    const  access_token =  JSON.parse(localStorage.getItem("accesstoken"));
    const  secret_key =  JSON.parse(localStorage.getItem("secret"));
    useEffect(() => {
        getRole();
        fetchData();
        fetchRoles();
        fetchPlants();
        fetchDepartments();
        fetchZoneArea();
    }, []);

    const fetchRoles = async () => {
        try {
           
            const response = await axios.get(API.ROLE,{headers: {
                Authorization: `Bearer ${access_token}`,
              
              }});
            console.log("Roles response", response.data);
            setRoles(response.data);
        } catch (error) {
            console.error('Error fetching roles:', error);
        }
    };

    const fetchPlants = async () => {
        try {
            const response = await axios.get(API.PLANTS,{headers: {
                Authorization: `Bearer ${access_token}`,
              
              }});
            console.log("Roles response", response);
            setPlants(response.data);
        } catch (error) {
            console.error('Error fetching roles:', error);
        }
    };

    const fetchDepartments = async () => {
        try {
            const response = await axios.get(API.DEPARTMENTS,{headers: {
                Authorization: `Bearer ${access_token}`,
               
              }});
            console.log("Roles response", response);
            setDepartment_Id(response.data);
        } catch (error) {
            console.error('Error fetching roles:', error);
        }
    };

    const fetchZoneArea = async (plant_id) => {
        try {
            console.log(plant_id, "plant97")
            const response = await axios.get(`${API.GET_ZONE_AREA}/${plant_id}`,{headers: {
                Authorization: `Bearer ${access_token}`,
             
              }});
            const sortedZones = response.data.sort((a, b) => a.code - b.code); // Sort zones by code
            setZoneAreas(sortedZones);
            console.log(response.data, "response342")
        } catch (error) {
            console.error('Error fetching roles:', error);
        }
    };

    const fetchData = async () => {
        setError(null);
        try {
            setLoading(true);
            const response = await axios.get(API.GET_USERS,{headers: {
                Authorization: `Bearer ${access_token}`,
              
              }});
            setUsers(response.data);
            setFilteredUsers(response.data)
        } catch (error) {
            setError("Error fetching data");
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false); // Close the loader regardless of success or failure
        }
    };

    const handleChange = (event) => {
        console.log("role363", event.target.value)
        const { name, value } = event.target || event;
        if (name === 'firstName' && /\d/.test(value)) {
            setFirstName("Only alphabets are allowed.");
            return;
        } else if (name === 'lastName' && /\d/.test(value)) {
            setLastName("Only alphabets are allowed.");
            return;
        }
        // Update the form data
        const updatedValue = name === 'role_id' ? value : value;
        setFormData(prevState => ({
            ...prevState,
            [name]: updatedValue
        }));
        // If the changed field is 'plant_id', fetch zone area
        if (name === 'plant_id') {
            fetchZoneArea(value); // Pass the plant_id to fetchZoneArea function
        }
        console.log(value, "valueddd")
        setFirstName(false);
        setLastName(false);
        setEmail(false);
    };

    const handleZoneChange = (event, newValue) => {
        const { name, value } = event.target || event;
        setFormData(prevState => ({
            ...prevState,
            zone_area_id: newValue ? newValue.id : '' // Set zone_area_id directly to the form data
        }));
        if (name === 'plant_id') {
            fetchZoneArea(value); // Pass the plant_id to fetchZoneArea function
        }
    };

    const handleOpenModal = () => {
        setOpen(true);
        setIsEditMode(false);
        setFormData([]);
    };

    const handleSearchIconClick = () => {
        setInputVisible(true);
    };

    const handleInputChange = (event) => {
        const query = event.target.value.toLowerCase(); // Convert query to lowercase
        setSearchQuery(query);
        let filtered;
        if (query.trim() === "") {
            // filtered = users; // Reset to original list if query is empty
            setFilteredUsers(users); 
        } else {
            filtered = users.filter((user) => {
                const fullName = `${user.firstName} ${user.lastName}`; // Combine first name and last name
                const fullNameIncludesQuery = fullName.toLowerCase().includes(query.toLowerCase()); // Case-insensitive comparison
                const sgidIncludesQuery = user.sgid.toLowerCase().includes(query);
                const roleIncludesQuery = getRoleName(user.role_id).toLowerCase().includes(query);
                const plantIncludesQuery = getPlantName(user.plant_id).toLowerCase().includes(query);
                return fullNameIncludesQuery || sgidIncludesQuery || roleIncludesQuery || plantIncludesQuery;
            });
            setFilteredUsers(filtered);
        }
        
        setNoRecords(filteredUsers.length === 0);
    };

    const handleCloseIconClick = () => {
        setInputVisible(false);
        setSearchQuery('');
        setFilteredUsers([]);
        setNoRecords(false);
    };

    const handleCloseModal = () => {
        setOpen(false);
    };

    const storedUserDataString = localStorage.getItem("userDatas");
    const storedUserData = JSON.parse(storedUserDataString);
    console.log(storedUserData, "storedUserData")
    const array = storedUserData.firstName;
    console.log(array, "array207")
    const handleSave = async () => {
        const storedUserData = localStorage.getItem("userDatas");
        
        console.log(storedUserData, "storedUserData206")
        console.log(access_token, "access_token");
        try {
            const isValid = validateFormData();
            console.log(isValid, "isvalid181")
            if (!isValid) return;
            setLoading(true); // Start loader
            const jsonData = {
                firstName: formData.firstName,
                lastName: formData.lastName,
                sgid: formData.sgid,
                email: formData.email,
              //  role_id: formData.role_id,
                plant_id: formData.plant_id,
                department_id: formData.department_id,
               // zone_area_id: formData.zone_area_id,
                created_by: array
            };
            console.log("jsonData",jsonData);
            let response;
            let successMessage;
            if (isEditMode && selectedUserIndex !== null) {
                // const userId = users[selectedUserIndex].id;
               const userId= localStorage.getItem("USERID")
                console.log("userIdsettings",userId)
                response = await axios.put(`${API.UPDATE_USER}/${userId}`, jsonData, {
                    headers: {
                        Authorization: `Bearer ${access_token}`
                    }
                });
               
                // response = await axios.put(`${API.UPDATE_USER}/${userId}`,{headers: {
                //     Authorization: `Bearer ${access_token}`,
                   
                //   }}, jsonData);
                successMessage = 'User Updated Successfully';
            } else {
                response = await axios.post(API.INSERT_USER, jsonData,{headers: {
                    Authorization: `Bearer ${access_token}`,
                  
                  }});
                successMessage = 'User Inserted Successfully';
            }
            console.log("UPDATEResponse:", response);
            if (response.status === 200) {
                AlertDialog({
                    type: 'success',
                    title: 'Success',
                    text: successMessage,
                    confirmButtonText: 'Ok',
                    onConfirm: async () => {
                        await fetchData();
                    },
                });
            } else {
                console.error('Error saving data:', response.data);
                AlertDialog({
                    type: 'warning',
                    title: 'Error',
                    text: 'Failed to save data',
                });
            }

            setOpen(false); // Close the modal after saving
        } catch (error) {
            console.error('Error saving data:', error);
            AlertDialog({
                type: 'warning',
                title: 'Error',
                text: error.message || 'An error occurred',
            });
        } finally {
            setLoading(false); // Stop loader regardless of success or failure
        }
    };

    const isValidEmail = (email) => {
        // Email validation regex
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validateFormData = () => {
        const errors = {};
        console.log(formData, "fordata229")
        if (!formData.firstName) {
            errors.firstName = 'First name is required';
        }
        if (!formData.lastName) {
            errors.lastName = 'Last name is required';
        }
        if (!formData.sgid) {
            errors.sgid = 'SGID is required';
        }
        // if (!formData.email) {
        //     errors.email = 'Email ID is required';
        // } 
        // else if (!isValidEmail(formData.email)) {
        //     errors.email = 'Invalid email format';
        // }
        if (formData.email && !isValidEmail(formData.email)) {
            errors.email = 'Invalid email format';
        }
        // if (!formData.role_id) {
        //     errors.role_id = 'Role is required';
        // }
        if (!formData.plant_id) {
            errors.plant_id = 'Plant is required';
        }
        // if (!formData.department_id) {
        //     errors.department_id = 'Department is required';
        // }
        // if (!formData.zone_area_id) {
        //     errors.zone_area_id = 'Zone is required';
        // }
        // Set the form errors state
        setFormErrors(errors);
        // Return true if there are no errors, otherwise return false
        return Object.keys(errors).length === 0;
    };

    const handleEdit = async (index) => {
        setSelectedUserIndex(index);
        setIsEditMode(true);
        // const selectedUser = users[index];
        const selectedUser = filteredUsers.length > 0 ? filteredUsers[index] : users[index];
        console.log(selectedUser, "selectedUser313")
        const userd = selectedUser.id;
  const   userid = localStorage.setItem("USERID",userd);
        setFormData({
            firstName: selectedUser.firstName,
            lastName: selectedUser.lastName,
            sgid: selectedUser.sgid,
            email: selectedUser.email,
            // role_id: selectedUser.role_id,
            plant_id: selectedUser.plant_id,
            department_id: selectedUser.department_id,
            // zone_area_id: selectedUser.zone_area_id,
        });
        setOpen(true);
        setIsEditMode(true);
    }

    const handleDelete = async (index) => {
        setSelectedUserIndex(index);
        const selectedUser = users[index];
        const id = selectedUser.id;
        AlertDialog({
            type: 'delete',
            title: 'Delete?',
            text: 'Are you sure you want to delete?',
            confirmButtonText: 'Yes',
            onConfirm: async () => {
                const response = await axios.delete(`${API.DELETE_USER}/${id}`,{headers: {
                    Authorization: `Bearer ${access_token}`,
                   
                  }});
                if (response) {
                    fetchData();
                }
            },
        });
    }

    const getRoleName = (id) => {
        const roleName = roles.find(role_id => role_id.id === id);
        return roleName ? roleName.name : '';
    };

    const getPlantName = (id) => {
        const plant_id = plants.find(plant_id => plant_id.id === id);
        return plant_id ? plant_id.name : '';
    };

    const getDepartmentName = (departments) => {
        const department = department_id.find(plant_id => plant_id.id === parseInt(departments));
        return department ? department.name : '';
    };


    const getRole = () => {
        const userDatas = JSON.parse(localStorage.getItem("userDatas"));
      const userid = userDatas.sgid;
      const plantId = localStorage.getItem('selectedPlantId');
        axios.get(`${ApiURL}/getroleuser/${userid}/${plantId}`,{headers: {
            Authorization: `Bearer ${access_token}`,
           
          }})
            .then(response => {
                setLoading(false);
                if (response.status === 200) {
                    if (response.data && response.data.length > 0) {
                        setuser(response.data);
                          console.log("userRole",response.data)                 
                    }
                }
            })
            .catch(error => {
                setLoading(false);
                console.error("Error fetching assessment data:", error);
            });
      };
      
      const isCoordinator = userRole.some(user => user.wcm_coordinator_firstname !== '' &&  user.wcm_coordinator_firstname !== null);
      console.log("isCoordinator",isCoordinator)
      const iszoneleader = userRole.some(user => user.zone_first_name !== '' &&  user.zone_first_name !== null);
      console.log("iszoneleader",iszoneleader)
      const isgroupleader = userRole.some(user => user.group_firstname !== '' &&  user.group_firstname !== null);
      console.log("isgroupleader",isgroupleader)
    return (
        <div>
            {error && <div>{error}</div>}
            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '20vh' }}>
                    <CircularProgress />
                </div>
            ) : (
                <div>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", borderBottom: '1px solid #E5E9EB', paddingBottom: "8px", marginBottom: "10px", padding: 5 }}>
                        <Typography
                            variant="h5"
                            component="div"
                            sx={{
                                fontSize: '16px',
                                fontWeight: 500,
                                lineHeight: '14px',
                                letterSpacing: '-0.006em',
                                textAlign: 'left',
                                color: "#0A090B",
                                marginLeft: 1.3
                            }}
                        >
                            User Details
                        </Typography>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Tooltip title="Search">
                                <img src={SearchIcon} alt="search icon" style={{ marginRight: '15px', cursor: 'pointer' }} onClick={handleSearchIconClick} />
                            </Tooltip>
                            {isInputVisible && (
                                <div style={{ position: 'relative' }}>
                                    <input
                                        type="text"
                                        value={searchQuery}
                                        onChange={handleInputChange}
                                        placeholder="Search..."
                                        style={{ marginRight: '15px', height: "35px" }}
                                    />
                                    <img
                                        src={CloseIcon}
                                        alt="close icon"
                                        style={{ position: 'absolute', top: '50%', right: '20px', transform: 'translateY(-50%)', cursor: 'pointer' }}
                                        onClick={handleCloseIconClick}
                                    />
                                </div>
                            )}
                            {isCoordinator ?(<>
                            <Button variant="contained" color="primary"
                                sx={{
                                    textTransform: 'none', // Remove all-caps transformation
                                }}
                                onClick={handleOpenModal}
                            >
                                Add User
                            </Button></>):(<div></div>) }
                        </div>
                    </div>
                    
                    {/* Table displaying users */}
                    {/* {isCoordinator ?(<> */}
                    <UserTable users={users} handleEdit={handleEdit} handleDelete={handleDelete} getRoleName={getRoleName} getPlantName={getPlantName} getDepartmentName={getDepartmentName} noRecords={noRecords} searchQuery={searchQuery} filteredUsers={filteredUsers} />

                    {/* Modal for adding/editing users */}
                    <UserModal open={open} handleClose={handleCloseModal} formData={formData} handleChange={handleChange} handleSave={handleSave} roles={roles} isEditMode={isEditMode} formErrors={formErrors} plants={plants} department_id={department_id} zoneareas={zoneareas} handleZoneChange={handleZoneChange} firstName={firstName} lastName={lastName} email={email} />
{/* </>):(
    <div></div>
)} */}
                </div>
            )}
        </div>
    );
};

const UserTable = ({ users, handleEdit, handleDelete, getRoleName, getPlantName, getDepartmentName, searchQuery, filteredUsers, noRecords }) => {
    const [userRole, setuser] = useState([]);
    const ApiURL = configParam.API_URL; 
    const [loading, setLoading] = useState(false); // Loader state
    const [error, setError] = useState(false);   
    const  access_token =  JSON.parse(localStorage.getItem("accesstoken"));
    
    useEffect(() => {
        getRole();
      
    }, []);
    
    const getRole = () => {
        const userDatas = JSON.parse(localStorage.getItem("userDatas"));
      const userid = userDatas.sgid;
      const plantId = localStorage.getItem('selectedPlantId');
        axios.get(`${ApiURL}/getroleuser/${userid}/${plantId}`,{headers: {
            Authorization: `Bearer ${access_token}`,
           
          }})
            .then(response => {
                setLoading(false);
                if (response.status === 200) {
                    if (response.data && response.data.length > 0) {
                        setuser(response.data);
                          console.log("userRole",response.data)                 
                    }
                }
            })
            .catch(error => {
                setLoading(false);
                console.error("Error fetching assessment data:", error);
            });
      };
      
    const isCoordinator = userRole.some(user => user.wcm_coordinator_firstname !== '' &&  user.wcm_coordinator_firstname !== null);
    console.log("isCoordinator",isCoordinator)
    const iszoneleader = userRole.some(user => user.zone_first_name !== '' &&  user.zone_first_name !== null);
    console.log("iszoneleader",iszoneleader)
    const isgroupleader = userRole.some(user => user.group_firstname !== '' &&  user.group_firstname !== null);
    console.log("isgroupleader",isgroupleader)
    return (
        <div>
            <TableContainer component={Paper} style={{ boxShadow: "none" }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={firstRowStyles}>S.No</TableCell>
                            <TableCell style={firstRowStyles}>First Name</TableCell>
                            <TableCell style={firstRowStyles}>Last Name</TableCell>
                            <TableCell style={firstRowStyles}>Sgid</TableCell>
                            <TableCell style={firstRowStyles}>Email</TableCell>
                            {/* <TableCell style={firstRowStyles}>Role</TableCell> */}
                            <TableCell style={firstRowStyles}>Plant</TableCell>
                            {/* <TableCell style={firstRowStyles}>Department</TableCell> */}
                            {/* <TableCell style={firstRowStyles}>Zone Area</TableCell> */}
                            {isCoordinator ?(<>
                            <TableCell style={firstRowStyles}>Action</TableCell>
                           </> ):(<div></div>)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(searchQuery ? filteredUsers : users).map((user, index) => (
                            
                            <TableRow key={user.id}>
                                {console.log(user, "user671")}
                                <TableCell style={{ color: "#7F7D83" }}>{index + 1}</TableCell>
                                <TableCell style={{ color: "#7F7D83" }}>{user.firstName}</TableCell>
                                <TableCell style={{ color: "#7F7D83" }}>{user.lastName}</TableCell>
                                <TableCell style={{ color: "#7F7D83" }}>{user.sgid}</TableCell>
                                <TableCell style={{ color: "#7F7D83" }}>{user.email}</TableCell>
                                {/* <TableCell style={{ color: "#7F7D83" }}>{getRoleName(user.role_id)}</TableCell> */}
                                <TableCell style={{ color: "#7F7D83" }}>{getPlantName(user.plant_id)}</TableCell>
                                {/* <TableCell>{getDepartmentName(user.department_id)}</TableCell> */}
                                {/* <TableCell>{user.zone_area_id}</TableCell> */}
                                <TableCell>
                                {isCoordinator ?(<>
                                    <Tooltip title="Edit">
                                        <IconButton aria-label="edit" onClick={() => handleEdit(index)}>
                                            <img src={EditIcon} alt="Edit Icon" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Delete">
                                        <IconButton aria-label="delete" onClick={() => handleDelete(index)}>
                                            <img src={DeleteIcon} alt="Delete Icon" />
                                        </IconButton>
                                    </Tooltip>
                                    </>):(
    <div></div>
)}
                                </TableCell>
                            </TableRow>
                        ))}
                        {noRecords && <p>No records found</p>}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

const UserModal = ({ open, handleClose, formData, handleChange, handleSave, roles, isEditMode, formErrors, plants, department_id, zoneareas, handleZoneChange, firstName, lastName, email }) => {
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <ModalContent style={{ maxHeight: '80vh', overflowY: 'auto' }}>
                <div style={{ display: 'flex', flexDirection: "column" }}>
                    <Typography
                        variant="body1"
                        sx={{
                            fontSize: 18,
                            fontWeight: 600,
                            lineHeight: '24px',
                            letterSpacing: '-0.006em',
                            textAlign: 'left',
                            color: "#252C32"
                        }}
                    >
                        {isEditMode ? "Update User" : "Add User"}
                    </Typography>
                </div>

                <div style={{ marginTop: "5px" }}>
                    <TextBox
                        name="firstName"
                        label="First Name*"
                        value={formData.firstName}
                        onChange={handleChange}
                        error={formErrors.firstName}
                        helperText={
                            <span style={{ color: "#FF0000" }}>{firstName || " "}</span>
                        }
                    />
                </div>

                <div style={{ marginTop: "5px" }}>
                    <TextBox
                        name="lastName"
                        label="Last Name*"
                        value={formData.lastName}
                        onChange={handleChange}
                        isString={true}
                        error={formErrors.lastName}
                        helperText={
                            <span style={{ color: "#FF0000" }}>{lastName || " "}</span>
                        }
                    />
                </div>

                <div style={{ marginTop: "5px" }}>
                    <TextBox
                        name="sgid"
                        label="Sgid*"
                        value={formData.sgid}
                        onChange={handleChange}
                        isString={true}
                        error={formErrors.sgid}
                        disabled={isEditMode ? true : false}
                    />
                </div>

                <div style={{ marginTop: "5px" }}>
                    <TextBox
                        name="email"
                        label="Email"
                        value={formData.email}
                        onChange={handleChange}
                        isString={true}
                        error={formErrors.email}
                        // disabled={isEditMode ? true : false}
                        helperText={
                            <span style={{ color: "#FF0000" }}>{formErrors.email || " "}</span>
                        }
                    />
                </div>

                {/* <div style={{ marginTop: "5px" }}>
                    <SelectBox
                        name="role_id"
                        labelValue="Role*"
                        value={formData.role_id} // Assuming role_id is one of the fields in your form
                        onChange={handleChange} // Assuming you have a handleChange function
                        options={roles}
                        labelCondition="name" // Assuming you want to display role_id names
                        isLabel={true}
                        error={formErrors.role_id}
                    />
                </div> */}

                <div style={{ marginTop: "5px" }}>
                    <SelectBox
                        name="plant_id"
                        labelValue="Plant*"
                        value={formData.plant_id} // Assuming role_id is one of the fields in your form
                        onChange={handleChange} // Assuming you have a handleChange function
                        options={plants}
                        labelCondition="name" // Assuming you want to display role_id names
                        isLabel={true}
                        error={formErrors.plant_id}
                    />
                    {console.log(formData, "formData.plant_id")}
                    {console.log(plants, "plants571")}
                </div>

                {/* <div style={{ marginTop: "5px" }}>
                    <SelectBox
                        name="department_id"
                        labelValue="Department*"
                        value={formData.department_id} // Assuming role_id is one of the fields in your form
                        onChange={handleChange} // Assuming you have a handleChange function
                        options={department_id}
                        labelCondition="name" // Assuming you want to display role_id names
                        isLabel={true}
                        error={formErrors.department_id}
                    />
                </div> */}

                {/* <InputLabel style={{ fontSize: 14, fontStyle: 'normal' }}>Zone Area*</InputLabel>
                <FormControl fullWidth sx={{ marginBottom: 2 }}>
                    <Autocomplete
                        options={zoneareas}
                        // options={formData.plant_id === "f224f31c-75bf-43f8-a1f2-0c60d55b1a33" ? zoneareas : []}
                        name="zone_area_id"
                        value={formData.zone_area_id ? zoneareas.find(area => area.id === formData.zone_area_id) : null}
                        getOptionLabel={(option) => `Zone ${option.code} - ${option.name}`}
                        onChange={handleZoneChange}
                        renderInput={(params) => <TextField {...params} fullWidth
                            error={formErrors.zone_area_id} />}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        // Use custom ListboxComponent
                        style={{ marginBottom: 10 }}
                    />
                </FormControl> */}
                <div style={{ flexDirection: "row", flex: 1, display: "flex" }}>
                    <StyledButton
                        variant="outlined"
                        text="Cancel" onClick={handleClose}>Cancel</StyledButton>
                    <StyledButton
                        variant="contained"
                        text={isEditMode ? "Update" : "Save"}
                        onClick={handleSave}>Save</StyledButton>
                </div>
            </ModalContent>
        </Modal>
    );
};

export default NewUser;