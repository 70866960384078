import React, { useState, useEffect } from 'react';
import Header from '../../app/TopAppBar';
import axios from 'axios';
import configParam from "../../config";
import AlertDialog from '../input/AlertDialog';
import { useNavigate } from 'react-router-dom';
import { CardActions, CardMedia, Card, CardContent,Box, Dialog, DialogTitle, DialogContent, DialogActions, Button, Select, MenuItem, FormControl, InputLabel, TextField, Autocomplete, Typography } from "@mui/material";
import { Navigate } from 'react-router-dom';
const moment = require('moment');
const currentday = new Date().toISOString().split('T')[0];

const modalInputLabelStyle = {
  fontSize: 16,
  fontStyle: 'normal',
  fontWeight: 500,
  color: '#000',
};

const RedTagRegister = ({open, onClose}) => {
  // const [redTagNo, setRedTagNo] = useState('');
  // const [category, setCategory] = useState('');
  // const [itemName, setItemName] = useState('');
  // const [sapNo, setSapNo] = useState('');
  // const [quantity, setQuantity] = useState('');
  // const [unitOfMeasurement, setUnitOfMeasurement] = useState('');
  // const [approxValue, setApproxValue] = useState('');
  const [reason, setReason] = useState('');
  const [disposalMethod, setDisposalMethod] = useState('');
  // const [groupBy, setGroupBy] = useState('');
  const ApiURL = configParam.API_URL;
  const access_token = JSON.parse(localStorage.getItem("accesstoken"));
 
  const [zoneAreas, setZoneAreas] = useState([]);
  const [zone, setZone] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);
  // const [auditor, setAuditor] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  // const [auditorOptions, setAuditorOptions] = useState([]);
  // const [auditorSgid, setAuditorSgid] = useState('');
  // const [userdet, setUserdet] = useState('');
  const today = new Date().toISOString().split('T')[0];
  // const plantId = localStorage.getItem('selectedPlantId');
  // const [errorMessageDate, setErrorMessageDate] = useState(null);
  const navigate = useNavigate();
  // const RedTagIdeditflg = localStorage.getItem("redtageditflg") === "true";
  const [selectedOption, setSelectedOption] = useState('');
  const [filterType, setFilterType] = useState('');

  const handleFilterTypeChange = (event) => {
    setFilterType(event.target.value);
  };
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleZoneChange = (event, newValue) => {
    setZone(newValue ? newValue.id : null);
  };

  useEffect(() => {
   // setSelectedDate(currentday);
    fetchZoneAreas();
  }, []);

  const fetchZoneAreas = async () => {
    try {
      const plant_id = localStorage.getItem('selectedPlantId');
      const response = await axios.get(`${ApiURL}/get_ZoneAreasByPlantId/${plant_id}`, {
        headers: {
          Authorization: `Bearer ${access_token}`
        }
      });
      const sortedZones = response.data.sort((a, b) => a.code - b.code);
      setZoneAreas(sortedZones);
    } catch (error) {
      console.error('Error fetching ZoneAreas:', error);
      setLoading(false);
      setError('Failed to fetch zone areas. Please try again later.');
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    // Assuming the endpoint for submission is '/submit_redtag'
    const formData = {
      reason: reason,
           disposal_method: disposalMethod,
      zone_no: zone,
      filterType: filterType,
    // entry_date: selectedDate,
      //status: selectedOption,
    }

    try {
   
        const queryString = new URLSearchParams(formData).toString();
        console.log(queryString,"queryString");
        navigate(`/RedTagDetails?${queryString}`);
      
    } catch (error) {
      console.error('Error submitting Red Tag data:', error);
      setError('Submission failed. Please try again.');
    }
  };

  // const handleDateChange = (event) => {
  //   const dateTimeString = event.target.value;
  //   const formattedDate = moment.utc(dateTimeString).format("YYYY-MM-DD");
  //   const today = new Date().toISOString().split('T')[0];
    
  //   if (formattedDate > today) {
  //     setErrorMessageDate("You cannot select a future date.");
  //   } else {
  //     setErrorMessageDate('');
  //     setSelectedDate(formattedDate);
  //   }
  // };
  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  return (
    <div>
      {/* <Header pageTitle="Red Tag Register" isRedTagPage={true} /> */}
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
          <DialogTitle style={{ textAlign: 'center', color: 'black', fontWeight: 600, fontSize: '16px' }}>
          Red Tag Register
          </DialogTitle>
      <Box style={{ maxWidth: '600px', padding: '16px', backgroundColor: '#f9f9f9', borderRadius: '8px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
        <form onSubmit={handleSubmit}>
          <InputLabel style={{ marginBottom: 10 }}>Entry Date</InputLabel>
          <TextField
            size="small"
            className="input-nis"
            fullWidth
            id="date"
            type="date"
            value={selectedDate}
            onChange={handleDateChange}
            inputProps={{ max: today }}
            style={{ width: '100%',  borderRadius: '5px', marginBottom: 10 }}
          />
          <InputLabel style={{ marginBottom: 10 }}>Zone</InputLabel>
          <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <Autocomplete
              options={zoneAreas}
              getOptionLabel={(option) => `Zone ${option.code} - ${option.name}`}
              onChange={handleZoneChange}
              renderInput={(params) => <TextField {...params} fullWidth />}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              style={{ marginBottom: 10 }}
            />
          </FormControl>
          <InputLabel style={{ marginBottom: 10 }}>Reason For Tagging :</InputLabel>
          <Select
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            style={{ width: '100%',  borderRadius: '5px', marginBottom: 10 }}
            MenuProps={{ PaperProps: { style: { maxHeight: 300 } } }}
          >
            <MenuItem value="">Select</MenuItem>
            <MenuItem value="Not Needed">Not Needed</MenuItem>
            <MenuItem value="Defective">Defective</MenuItem>
            <MenuItem value="Not Needed Now">Not Needed Now</MenuItem>
            <MenuItem value="Scrap Material">Scrap Material</MenuItem>
            <MenuItem value="Unknown">Unknown</MenuItem>
            <MenuItem value="Others">Others</MenuItem>
          </Select>
          <InputLabel style={{ marginBottom: 10 }}>Disposal Method Suggested:</InputLabel>
          <Select
            value={disposalMethod}
            onChange={(e) => setDisposalMethod(e.target.value)}
            style={{ width: '100%',  borderRadius: '5px', marginBottom: 10 }}
          >
            <MenuItem value="">Select</MenuItem>
            <MenuItem value="To Be Scrapped">To Be Scrapped</MenuItem>
            <MenuItem value="To Be Reused /Redeployed">To Be Reused /Redeployed</MenuItem>
            <MenuItem value="To Be Rectified/Reused">To Be Rectified/Reused</MenuItem>
            <MenuItem value="To Be Disposed">To Be Disposed</MenuItem>
          </Select>
          <InputLabel style={{ marginBottom: 10 }}>Status:</InputLabel>
          <div style={{ marginBottom: 10 }}>
            <label style={{ marginRight: 20 }}>
              <input
                type="radio"
                name="status"
                value="option1"
                checked={selectedOption === 'option1'}
                onChange={handleOptionChange}
              />
              Created
            </label>
            <label style={{ marginRight: 20 }}>
              <input
                type="radio"
                name="status"
                value="option2"
                checked={selectedOption === 'option2'}
                onChange={handleOptionChange}
              />
              Confirmed
            </label>
            <label style={{ marginRight: 20 }}>
              <input
                type="radio"
                name="status"
                value="option3"
                checked={selectedOption === 'option3'}
                onChange={handleOptionChange}
              />
              Resolved
            </label>
            <label style={{ marginRight: 20 }}>
              <input
                type="radio"
                name="status"
                value="option4"
                checked={selectedOption === 'option4'}
                onChange={handleOptionChange}
              />
              Closed
            </label>


            
          </div>


          <InputLabel style={{ marginBottom: 10 }}>Filter By:</InputLabel>
          <div style={{ marginBottom: 10 }}>
            <label style={{ marginRight: 20 }}>
              <input
                type="radio"
                name="filterType"
                value="zone"
                checked={filterType === 'zone'}
                onChange={handleFilterTypeChange}
              />
              Zone
            </label>
            <label style={{ marginRight: 20 }}>
              <input
                type="radio"
                name="filterType"
                value="category"
                checked={filterType === 'category'}
                onChange={handleFilterTypeChange}
              />
              Category
            </label>
            <label style={{ marginRight: 20 }}>
              <input
                type="radio"
                name="filterType"
                value="status"
                checked={filterType === 'status'}
                onChange={handleFilterTypeChange}
              />
              Status
            </label>
          </div>
          <CardActions>
            <Button type="submit" variant="contained" color="primary">Submit</Button>
          </CardActions>
        </form>
       
      </Box>
      </Dialog>
    </div>
  );
};

export default RedTagRegister;
