
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../../app/TopAppBar';
import configParam from "../../config";
import { useLocation } from 'react-router-dom';
// import { CardActions, CardMedia, Card, CardContent, Dialog, DialogTitle, DialogContent, DialogActions, Button, Select, MenuItem, FormControl, InputLabel, TextField, Autocomplete, Typography } from "@mui/material";
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Modal, Box, Typography, IconButton, Tooltip, CircularProgress, FormHelperText, FormControl, InputLabel, Dialog, DialogTitle, DialogContent, Select, MenuItem, DialogActions, TextField
} from '@mui/material';





//new working code
const WasteWealthDetails = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const ApiURL = configParam.API_URL;
  const access_token = JSON.parse(localStorage.getItem("accesstoken"));
  const location = useLocation();

  useEffect(() => {
    fetchData();
  }, [location.search]);

  const fetchData = async () => {
    const query = new URLSearchParams(location.search);
    const filters = Object.fromEntries(query.entries());

    try {
      const response = await axios.get(`${ApiURL}/get_wastewealth_details?${query.toString()}`, {
        headers: {
          Authorization: `Bearer ${access_token}`
        },
      });
      const responseData = response.data.data;
      console.log("Fetched Data: ", responseData);
      let filteredData = responseData.filter(item => {
        return Object.entries(filters).every(([key, value]) => {
          if (key === 'filterType' || key === 'status') return true; // Skip filterType and status
          if (value === '') return true;
          return item[key] && item[key].toString() === value.toString();
        });
      });

      setData(filteredData);
    } catch (error) {
      setError(error);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0]; // Returns date in YYYY-MM-DD format
  };
  

  const renderTable = () => {
    const query = new URLSearchParams(location.search);
    const filterType = query.get('filterType');
    const status = query.get('status');
    const groupKey = filterType === 'zone' ? 'zone_name' : filterType === 'category' ? 'category_no' : filterType;

    const renderTableHeader = (columns) => (
      <TableHead>
        <TableRow bgcolor="#abd6ff" height="22px">
          <TableCell align="center" style={{ width: '5%' }}>S.No</TableCell>
          {columns.map(col => (
            <TableCell key={col} align="center" style={{ width: '20%' }}>
              {col}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );

    const renderTableRows = (rows, columns) => (
      <TableBody>
        {rows.map((item, index) => (
          <TableRow 
            key={item.red_tagno} 
            bgcolor={index % 2 === 0 ? '#ffffff' : '#f9f9f9'} 
            onClick={() => handleRowClick(item.red_tagno)}
            style={{ cursor: 'pointer' }} 
          >
            <TableCell align="right" style={{ width: '5%' }}>{index + 1}</TableCell>
            {columns.map(col => (
              <TableCell key={col} style={{ width: '20%' }}>
                {/* {col === 'disposal_value' || col === 'scrap_value' || col === 'approximate' ? item['approximate'] : item[col]} */}
                {col === 'disposal_value' || col === 'scrap_value' || col === 'approximate' ? item['approximate'] :
                 col === 'modified_date' ? formatDate(item[col]) : item[col]}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    );

    const renderStatusTable = (status, columns, title) => {
      const statusData = data.filter(item => item.status === status);

      if (statusData.length === 0) return null;

      const groupedStatusData = statusData.reduce((acc, item) => {
        const key = filterType === 'zone' ? item['zone_name'] : item[groupKey];
        if (!acc[key]) acc[key] = [];
        acc[key].push(item);
        return acc;
      }, {});

      return (
        <div key={status}>
          <h3>{title}</h3>
          {Object.keys(groupedStatusData).map((group, index) => (
            <div key={group}>
              <Table className="example" border="1" cellSpacing="0" cellPadding="0" width="780" style={{ borderCollapse: 'collapse' }}>
                <TableRow>
                  <TableCell colSpan={columns.length + 1} align="left" bgcolor="#d9f1ff" style={{ fontWeight: 'bold' }}>
                    {group}
                  </TableCell>
                </TableRow>
                {renderTableHeader(columns)}
                {renderTableRows(groupedStatusData[group], columns)}
              </Table>
            </div>
          ))}
        </div>
      );
    };

    return (
      <div>
        {(!status || status === 'Redeployed') && renderStatusTable('Redeployed', ['red_tagno', 'item_name', 'rec_zone_name', 'approximate', 'modified_date'], 'Redeployment Table')}
        {(!status || status === 'Disposed') && renderStatusTable('Disposed', ['red_tagno', 'item_name', 'disposal_value', 'modified_date'], 'Disposal Table')}
        {(!status || status === 'Move to scrap') && renderStatusTable('Move to scrap', ['red_tagno', 'item_name', 'scrap_value', 'modified_date'], 'Scrap Table')}
      </div>
    );
  };

 
 

  const handleRowClick = (redTagNo) => {
    const redTagWindow = window.open('', '_blank', 'width=800,height=600');
    redTagWindow.document.write(`
      <html>
      <head>
        <title>Red Tag Details</title>
        <style>
          body { font-family: Arial, sans-serif; margin: 20px; }
          h2 { color: #333; }
          table { width: 100%; border-collapse: collapse; margin-bottom: 20px; }
          th, td { border: 1px solid #ddd; padding: 8px; }
          th { background-color: #f2f2f2; text-align: left; }
          .section-title { background-color: #ff7f50; color: white; padding: 10px; }
        </style>
      </head>
      <body>
        <h2>Loading...</h2>
      </body>
      </html>
    `);

    axios.get(`${ApiURL}/get_redtag_number_details/${redTagNo}`, {
      headers: {
        Authorization: `Bearer ${access_token}`
      }
    })
    .then(response => {
      const redTagData = response.data.data;
      redTagWindow.document.body.innerHTML = `
        <h2>Red Tag Details</h2>
        <table>
          <tr class="section-title"><td colspan="2">Tag Details</td></tr>
          <tr><th>Red Tag No</th><td>${redTagData.red_tagno}</td></tr>
          <tr><th>Category</th><td>${redTagData.category_no}</td></tr>
          <tr><th>Item Name</th><td>${redTagData.item_name}</td></tr>
          <tr><th>Quantity</th><td>${redTagData.qty}</td></tr>
          <tr><th>Approximate value of Each(in Rs)</th><td>${redTagData.approximate}</td></tr>
          <tr><th>Reason For tagging</td><td>${redTagData.reason}</td></tr>
          <tr><th>Disposal Action Suggested</th><td>${redTagData.disposal_method}</td></tr>
        </table>
        <table>
          <tr class="section-title"><td colspan="2">Tag Review</td></tr>
          <tr><th>Disposal Action Recommended By Group Leader</th><td> </td></tr>
          <tr><th>Comment By Group Leader</th><td></td></tr>
          <tr><th>Reviewed</th><td></td></tr>
        </table>
        <table>
          <tr class="section-title"><td colspan="2">Common Red Tag Area Details</td></tr>
          <tr><th>Action By Common Redtag Area</th><td> </td></tr>
          <tr><th>Common Redtag Area Comment</th><td> </td></tr>
          <tr><th>Redeployed Zone</th><td> </td></tr>
          <tr><th>Scrap Value</th><td> </td></tr>
          <tr><th>Disposal Value</th><td> </td></tr>
        </table>
      `;
    })
    .catch(error => {
      redTagWindow.document.body.innerHTML = `<h2>Error loading details</h2>`;
    });
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (data.length === 0) {
    return <div>No list to populate for the given criteria</div>;
  }

  return (
    <div>
      <Header pageTitle="Red Tag Register" isRedTagPage={true} />
      <div style={{ margin: '0 auto', backgroundColor: '#f9f9f9', borderRadius: '8px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', marginTop: "65px" }}>
        <div className="spot-award">
          {renderTable()}
        </div>
      </div>
    </div>
  );
};

export default WasteWealthDetails;


 // const renderTable = () => {
  //   const query = new URLSearchParams(location.search);
  //   const filterType = query.get('filterType');
  //   const status = query.get('status');
  //   // Use 'zone_name' for grouping if filterType is 'zone', otherwise use filterType value
  //   const groupKey = filterType === 'zone' ? 'zone_name' : filterType === 'category' ? 'category_no' : filterType;
  
  //   // Function to render table headers
  //   const renderTableHeader = (columns) => (
  //     <TableHead>
  //       <TableRow bgcolor="#abd6ff" height="22px">
  //         <TableCell align="center" style={{ width: '5%' }}>S.No</TableCell>
  //         {columns.map(col => (
  //           <TableCell key={col} align="center" style={{ width: '20%' }}>
  //             {col}
  //           </TableCell>
  //         ))}
  //       </TableRow>
  //     </TableHead>
  //   );
  
  //   // Function to render table rows
  //   const renderTableRows = (rows, columns) => (
  //     <TableBody>
  //       {rows.map((item, index) => (
  //         <TableRow key={item.red_tagno} bgcolor={index % 2 === 0 ? '#ffffff' : '#f9f9f9'}>
  //           <TableCell align="right" style={{ width: '5%' }}>{index + 1}</TableCell>
  //           {columns.map(col => (
  //             <TableCell key={col} style={{ width: '20%' }}>
  //               {col === 'disposal_value' || col === 'scrap_value' || col === 'approximate' ? item['approximate'] : item[col]}
  //             </TableCell>
  //           ))}
  //         </TableRow>
  //       ))}
  //     </TableBody>
  //   );
  
  //   const renderStatusTable = (status, columns, title) => {
  //     const statusData = data.filter(item => item.status === status);
  
  //     if (statusData.length === 0) return null;
  
  //     // Group data by the selected key
  //     const groupedStatusData = statusData.reduce((acc, item) => {
  //       const key = filterType === 'zone' ? item['zone_name'] : item[groupKey];
  //       if (!acc[key]) acc[key] = [];
  //       acc[key].push(item);
  //       return acc;
  //     }, {});
  
  //     return (
  //       <div key={status}>
  //         <h3>{title}</h3>
  //         {Object.keys(groupedStatusData).map((group, index) => (
  //           <div key={group}>
  //             {/* Render group title row */}
  //             <Table className="example" border="1" cellSpacing="0" cellPadding="0" width="780" style={{ borderCollapse: 'collapse' }}>
  //               <TableRow>
  //                 <TableCell colSpan={columns.length + 1} align="left" bgcolor="#d9f1ff" style={{ fontWeight: 'bold' }}>
  //                   {group}
  //                 </TableCell>
  //               </TableRow>
  //               {/* Render column headers */}
  //               {renderTableHeader(columns)}
  //               {/* Render table rows */}
  //               {renderTableRows(groupedStatusData[group], columns)}
  //             </Table>
  //           </div>
  //         ))}
  //       </div>
  //     );
  //   };
  
  //   return (
  //     <div>
  //       {(!status || status === 'Redeployed') && renderStatusTable('Redeployed', ['red_tagno', 'item_name', 'rec_zone_name', 'approximate', 'modified_date'], 'Redeployment Table')}
  //       {(!status || status === 'Disposed') && renderStatusTable('Disposed', ['red_tagno', 'item_name', 'disposal_value', 'modified_date'], 'Disposal Table')}
  //       {(!status || status === 'Move to scrap') && renderStatusTable('Move to scrap', ['red_tagno', 'item_name', 'scrap_value', 'modified_date'], 'Scrap Table')}
  //     </div>
  //   );
  // };
  
 

  



//old code
// const WasteWealthDetails = () => {
//   const [data, setData] = useState([]);
//   const [error, setError] = useState(null);
//   const ApiURL = configParam.API_URL;
//   const access_token = JSON.parse(localStorage.getItem("accesstoken"));
//   const location = useLocation();

  
  

//   useEffect(() => {
//     fetchData();
//   }, [location.search]);



 


//    const fetchData = async () => {
//     const query = new URLSearchParams(location.search);
//     const filters = Object.fromEntries(query.entries());

//     try {
//       const response = await axios.get(`${ApiURL}/get_wastewealth_details?${query.toString()}`, {
//         headers: {
//           Authorization: `Bearer ${access_token}`
//         },
//       });
//       const responseData = response.data.data;
// console.log("responseDataresponseData",responseData)
//       let filteredData = responseData.filter(item => {
//         return Object.entries(filters).every(([key, value]) => {
//           if (key === 'filterType') return true; // Skip filterType
//           if (value === '') return true;
//           return item[key] && item[key].toString() === value.toString();
//         });
//       });

//       setData(filteredData);
//     } catch (error) {
//       setError(error);
//     }
//   };


  

//   const renderTable = () => {
//     const query = new URLSearchParams(location.search);
//     const filterType = query.get('filterType');
    
//     const groupKey = filterType === 'zone' ? 'zone_no' : filterType === 'category' ? 'category_no' : filterType;
    
//     if (!filterType) {
//       return (
//         <Table className="example" border="1" cellSpacing="0" cellPadding="0" width="780" style={{ borderCollapse: 'collapse' }}>
//           <TableHead>
//             <TableRow bgcolor="#abd6ff" height="22px">
//               <TableCell align="center">S.No</TableCell>
//               <TableCell align="center">Red Tag No</TableCell>
//               <TableCell align="center">Item Name</TableCell>
//               <TableCell align="center">Redeployed Zone</TableCell>
              
//               <TableCell align="center">Redeployed date</TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {data.map((item, index) => (
//               <TableRow key={item.red_tagno} bgcolor={index % 2 === 0 ? '#ffffff' : '#f9f9f9'}>
//                 <TableCell align="right">{index + 1}</TableCell>
//                 <TableCell align="right" style={{ cursor: 'pointer' }} onClick={() => handleRowClick(item.red_tagno)}>
//                   <u>{item.red_tagno}</u>
//                 </TableCell>
//                 <TableCell>{item.item_name}</TableCell>
//                 <TableCell align="center">{item.rec_zone_name}</TableCell>
               
//                 <TableCell align="left">{item.modified_date}</TableCell>
//               </TableRow>
//             ))}
//           </TableBody>
//         </Table>
//       );
//     }
//     const groupedData = data.reduce((acc, item) => {
//       const key = item[groupKey];
//       if (!acc[key]) acc[key] = [];
//       acc[key].push(item);
//       return acc;
//     }, {});
//     console.log("groupedData", groupedData);

//     return Object.entries(groupedData).map(([group, items]) => (
//       <div key={group}>
//         <h3>
//           {
//            filterType === 'zone' ? `Zone: ${items[0].zone_name}` :
//            filterType === 'category' ? `Category: ${group}` :
//            ''}
//         </h3>
//         <Table className="example" border="1" cellSpacing="0" cellPadding="0" width="780" style={{ borderCollapse: 'collapse' }}>
//           <TableHead>
//             <TableRow bgcolor="#abd6ff" height="22px">
//               <TableCell align="center">S.No</TableCell>
//               <TableCell align="center">Red Tag No</TableCell>
//               <TableCell align="center">Item Name</TableCell>
              
//                <TableCell align="center">Redeployedzone</TableCell>
//               <TableCell align="center">RedployedDate</TableCell>
             
//               <TableCell align="center">Created Date</TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {items.map((item, index) => (
//               <TableRow key={item.red_tagno} bgcolor={index % 2 === 0 ? '#ffffff' : '#f9f9f9'}>
//                 <TableCell align="right">{index + 1}</TableCell>
//                 <TableCell align="right" style={{ cursor: 'pointer' }} onClick={() => handleRowClick(item.red_tagno)}>
//                   <u>{item.red_tagno}</u>
//                 </TableCell>
//                 <TableCell>{item.item_name}</TableCell>
//                 {filterType !== 'status' && <TableCell>{item.status}</TableCell>}
//                 {filterType === 'zone' && <TableCell align="center">{item.category_no}</TableCell>}
//                 {filterType === 'category' && <TableCell align="center">{item.zone_name}</TableCell>}
//                 {filterType === 'status' && <>
//                   <TableCell align="center">{item.zone_name}</TableCell>
//                   <TableCell align="center">{item.category_no}</TableCell>
//                 </>}
//                 <TableCell align="left">{item.entry_date}</TableCell>
//               </TableRow>
//             ))}
//           </TableBody>
//         </Table>
//       </div>
//     ));
//   };


//   const handleRowClick = (redTagNo) => {
//     const redTagWindow = window.open('', '_blank', 'width=800,height=600');
//     redTagWindow.document.write(`
//       <html>
//       <head>
//         <title>Red Tag Details</title>
//         <style>
//           body { font-family: Arial, sans-serif; margin: 20px; }
//           h2 { color: #333; }
//           table { width: 100%; border-collapse: collapse; margin-bottom: 20px; }
//           th, td { border: 1px solid #ddd; padding: 8px; }
//           th { background-color: #f2f2f2; text-align: left; }
//           .section-title { background-color: #ff7f50; color: white; padding: 10px; }
//         </style>
//       </head>
//       <body>
//         <h2>Loading...</h2>
//       </body>
//       </html>
//     `);

//     axios.get(`${ApiURL}/get_redtag_number_details/${redTagNo}`, {
//       headers: {
//         Authorization: `Bearer ${access_token}`
//       }
//     })
//     .then(response => {
//       const redTagData = response.data.data;
//       redTagWindow.document.body.innerHTML = `
//         <h2>Red Tag Details</h2>
//         <table>
//           <tr class="section-title"><td colspan="2">Tag Details</td></tr>
//           <tr><th>Red Tag No</th><td>${redTagData.red_tagno}</td></tr>
//           <tr><th>Category</th><td>${redTagData.category_no}</td></tr>
//           <tr><th>Item Name</th><td>${redTagData.item_name}</td></tr>
//           <tr><th>Quantity</th><td>${redTagData.qty}</td></tr>
//           <tr><th>Approximate value of Each(in Rs)</th><td>${redTagData.approximate}</td></tr>
//           <tr><th>Reason For tagging</th><td>${redTagData.reason}</td></tr>
//           <tr><th>Disposal Action Suggested</th><td>${redTagData.disposal_method}</td></tr>
//         </table>
//         <table>
//           <tr class="section-title"><td colspan="2">Tag Review</td></tr>
//           <tr><th>Disposal Action Recommended By Group Leader</th><td> </td></tr>
//           <tr><th>Comment By Group Leader</th><td></td></tr>
//           <tr><th>Reviewed</th><td></td></tr>
//         </table>
//         <table>
//           <tr class="section-title"><td colspan="2">Common Red Tag Area Details</td></tr>
//           <tr><th>Action By Common Redtag Area</th><td> </td></tr>
//           <tr><th>Common Redtag Area Comment</th><td> </td></tr>
//           <tr><th>Redeployed Zone</th><td> </td></tr>
//           <tr><th>Scrap Value</th><td> </td></tr>
//           <tr><th>Disposal Value</th><td> </td></tr>
//         </table>
//       `;
//     })
//     .catch(error => {
//       redTagWindow.document.body.innerHTML = `<h2>Error loading details</h2>`;
//     });
//   };

//   if (error) {
//     return <div>Error: {error.message}</div>;
//   }

//   if (data.length === 0) {
//     return <div>No list to populate for the given criteria</div>;
//   }
   
//     return (
//       <div>
//         <Header pageTitle="Red Tag Register" isRedTagPage={true} />
//         <div style={{ margin: '0 auto', backgroundColor: '#f9f9f9', borderRadius: '8px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', marginTop: "65px" }}>
//           <div className="spot-award">
//             {renderTable()}
//           </div>
//         </div>
//       </div>
   
//    )
//   };
  
//   export default WasteWealthDetails;