import React, { useState, useEffect } from "react";
import { AppBar, IconButton, Toolbar, Typography, Tooltip } from "@mui/material";
import LeftDrawer from "../../src/Pages/LeftDrawer";
import MenuIcon from "../Asset/Icons/drawer_icon.svg";
import BackIcon from "../Asset/Icons/back_icon.svg";
import PlusIcon from "../Asset/Icons/addnew_icon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import NewAssessment from "../Pages/RequestForm/NewAssessment";
import NewRedTag from "../Pages/RedTagging/NewRedTag";
import RedTagFrom from "../Pages/RedTagging/RedTagForm";
import RedTagRegister from "../Pages/RedTagging/RedTagRegister";

// import RedTagActivities from "../Pages/RedTagging/RedTagActivities";
import { useRecoilState,useResetRecoilState } from "recoil";
import { editMocData, requestFormData, changeAssessmentData, conclusionData,formDataState, RedTagData } from "../recoil/atoms";
// import HorizontalCombinedStepper from "../Pages/RequestForm/HorizontalCombinedStepper";

export default function Header({
  pageTitle,
  isDashboard,
  isNewUser,
  isAuditPage,
  isRedTagPage,
  isMassPage,
  // isSettingsPage,
  isHelpAndSupport,
  goBack
}) {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [, setRequestForm] = useRecoilState(requestFormData);
  const resetDealerValue = useResetRecoilState(formDataState);
  const reseredtagtDealerValue = useResetRecoilState(RedTagData);
  const [, setChangeAssessment] = useRecoilState(changeAssessmentData);
  const [, setConclu] = useRecoilState(conclusionData);
  const [, setIsEditMoc] = useRecoilState(editMocData);
  const [userData, setUserData] = useState([]);
  const [initialLoad, setInitialLoad] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [iconOpen, setIconopen] = useState(false);
  const [RedTagiconOpen, setRedTagIconopen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [redTagdialogOpen, setredTagDialogOpen] = useState(false);
  const [formData, setFormData] = useRecoilState(formDataState);
  const navigate = useNavigate();
  const location = useLocation();


  const isAudit = location.pathname === "/Audit";
  const isRedTag = location.pathname === "/RedTagActivities";
  const isRedTagActivites = location.pathname === "/RedTagActivities";
  const isMassFive = location.pathname === "/massfives";

  const handleOpenLeftDrawerClick = () => {
    setDrawerOpen(true);
  };

  const handleCloseLeftDrawer = () => {
    setDrawerOpen(false);
  };

  const handleGoBack = () => {
    window.history.back();
  };

  // const handleSettingsClick = () => {
  //   localStorage.setItem("selectedUser", "");
  //   localStorage.setItem("isEditMode", false);
  //   navigate("/newuser");
  // }
  const handleDialogClose = () => {
    setDialogOpen(false); // Close the dialog
  };

  const handleRedTagDialogClose = () => {
    setredTagDialogOpen(false); // Close the dialog
  };

  // const handleDialogNext = () => {
  //   // Navigate to '/horizontalcombinedstepper' page
  //   resetDealerValue();
  //     localStorage.setItem("assessmentId", "");
  //     localStorage.setItem("assessmentIdcreate", "");
      
  //     localStorage.setItem("assessmenteditflg", false);
  //     setIconopen(true);
  //     navigate("/horizontalCombinedStepper");
  //   // navigate("/horizontalcombinedstepper");
    
  // };

  const handleRedTagDialogNext = () => {
    // Navigate to '/horizontalcombinedstepper' page
    reseredtagtDealerValue();
    localStorage.setItem("redtagId", "");
    localStorage.setItem("redtageditflg", false);
    setRedTagIconopen(true);
      navigate("/RedTagForm");
    // navigate("/horizontalcombinedstepper");
    
  };

  const handleAddClick = () => {
    if (isAuditPage) {
      setDialogOpen(true); // Open the dialog
    } else {
      // Handle other pages
    }
  };

  const handleRedAddClick = () => {
    if (isRedTagPage) {
      setredTagDialogOpen(true); // Open the dialog
    }
     else {
      // Handle other pages
    }
 
  };
  const handleMassAddClick = () => {
    if (isMassPage) {
      navigate('/newEntry')
      // setDialogOpen(true); // Open the dialog
    } else {
      // Handle other pages
    }
  };
  const storedUserData = JSON.parse(localStorage.getItem("userDatas"));
  console.log("storedUserData", storedUserData);
  const storedAllUserData = JSON.parse(localStorage.getItem("allAdminData"));
  //const adminSGID = storedUserData[0].SGID;
  const adminSGID = storedUserData.sgid;
  useEffect(() => {
    if (storedAllUserData) {
      const loggedInUserData = storedAllUserData.find(user => user.sgid === adminSGID);
      if (loggedInUserData && loggedInUserData.role === "Admin") {
        setIsAdmin(true);
      }
    }
  }, [adminSGID, storedAllUserData]);


  return (
    <>
      <AppBar
        elevation={0}
        position="fixed"
        sx={{
          backgroundColor: "white",
          boxShadow: "0px 2px 5px #00000026",
          minHeight: "60px",
        }}
      >
        <Toolbar>
          {isDashboard || isHelpAndSupport ||isAudit || isRedTag ||isMassFive ? (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleOpenLeftDrawerClick}
            >
              <img
                src={MenuIcon}
                alt="menu icon"
                style={{ width: "28px", height: "24px" }}
              />
            </IconButton>
          ) : (goBack ? null :
            (
              <IconButton
                edge="start"
                color="inherit"
                aria-label="back"
                onClick={handleGoBack}
                goBack
              >
                <img
                  src={BackIcon}
                  alt="back icon"
                  style={{ width: "28px", height: "24px" }}
                />
              </IconButton>
            )
          )}


          <Typography
            variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
              textAlign: "center",
              color: "#000",
              fontSize: 16,
              fontWeight: 600,
              fontStyle: "normal",
              fontFamily: "Inter, sans-serif",
            }}
          >
            {pageTitle}
          </Typography>



          {/* {(isAuditPage) && !iconOpen ? (
            <Tooltip title="New User" arrow>
              <IconButton
                edge="end"
                color="inherit"
                aria-label="add"
                onClick={handleAddClick}
              >
                <img
                  src={PlusIcon}
                  alt="add icon"
                  style={{ width: "28px", height: "24px" }}
                />
              </IconButton>
            </Tooltip>
          ) : <></>} */}

{/* {isAudit && ( // Render '+' icon only if on Audit page
  <Tooltip title="Add">
    <IconButton
      edge="end"
      color="inherit"
      
      aria-label="add"
      onClick={handleAddClick}
    >
      <img
        src={PlusIcon}
        alt="add icon"
        style={{ width: "28px", height: "24px" }}
      />
    </IconButton>
  </Tooltip>
)} */}


{isRedTag && ( // Render '+' icon only if on Audit page
  <Tooltip title="Add">
    <IconButton
      edge="end"
      color="inherit"
      
      aria-label="add"
      onClick={handleRedAddClick}
    >
      <img
        src={PlusIcon}
        alt="add icon"
        style={{ width: "28px", height: "24px" }}
      />
    </IconButton>
  </Tooltip>
)}
{isMassFive && ( // Render '+' icon only if on Audit page
  <Tooltip title="Add">
    <IconButton
      edge="end"
      color="inherit"
      
      aria-label="add"
      onClick={handleMassAddClick}
    >
      <img
        src={PlusIcon}
        alt="add icon"
        style={{ width: "28px", height: "24px" }}
      />
    </IconButton>
  </Tooltip>
)}



          {/* 
    {isSettingsPage &&( // Render '+' icon only if on Settings page
      <Tooltip title="Add">
        <IconButton
          edge="end"
          color="inherit"
          aria-label="add"
          onClick={handleSettingsClick}
        >
          <img
            src={PlusIcon}
            alt="add icon"
            style={{ width: "28px", height: "24px" }}
          />
        </IconButton>
      </Tooltip>
    )} */}

          {(isNewUser) && isAdmin ? (
            <Tooltip title="New User" arrow>
              <IconButton
                edge="end"
                color="inherit"
                aria-label="add"
                onClick={handleAddClick}
              >
                <img
                  src={PlusIcon}
                  alt="add icon"
                  style={{ width: "28px", height: "24px" }}
                />
              </IconButton>
            </Tooltip>
          ) : <></>}
        </Toolbar>
      </AppBar>
      <LeftDrawer open={drawerOpen} onClose={handleCloseLeftDrawer} />
      
      
      
      {/* <NewAssessment  open={dialogOpen}
        onClose={handleDialogClose}
        onNext={handleDialogNext}/> */}
          <NewRedTag  open={redTagdialogOpen}
        onClose={handleRedTagDialogClose}
        onNext={handleRedTagDialogNext}/>
                  {/* <RedTagActivities  open={redTagdialogOpen}
        onClose={handleRedTagDialogClose}
        onNext={handleRedTagDialogNext}/> */}
    </>
  );
}