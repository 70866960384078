import React, { useState,useEffect,useRef } from "react";
import { Typography, Button, Snackbar,Alert,Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Header from '../../app/TopAppBar';
import imageCompression from 'browser-image-compression';
import CloudUpload from "../../Asset/Icons/file_upload_icon.svg";
import { useRecoilState } from 'recoil';
import { stepState, formDataState,assessmentCreatedState} from '../../recoil/atoms';
import configParam from "../../config";
import axios from "axios";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import LogoutIcon from '@mui/icons-material/Logout';

import MuiAlert from '@mui/material/Alert';
const Standardise = ({ onNext,activeStep,onSave,loading }) => {
    const [formData, setFormData] = useRecoilState(formDataState);
    const [selectedValues, setSelectedValues] = useState({});
    const fileInputRef = useRef(null);
    const [images, setImages] = useState({});
    const [remarks, setRemarks] = useState({});
    const [errors, setErrors] = useState({});
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
        const[set,setStream] = useState({});
    const [isCameraOpen, setIsCameraOpen] = useState(false);
    const [assessmentCreated, setAssessmentCreated] = useRecoilState(assessmentCreatedState);
    const [assessmentId, setAssessmentId] = useState(null);
    const [saveflg, setSaveflg] = useState(false);
    const assessmenteditflg = localStorage.getItem("assessmenteditflg") === "true";
    const ApiURL = configParam.API_URL;
    const  access_token =  JSON.parse(localStorage.getItem("accesstoken"));
    
    useEffect(() => {
        return () => {
            // Store form data in Recoil state when unmounting
            setFormData(prevFormData => ({
                ...prevFormData,
                [activeStep]: {
                    selectedValues,
                    images,
                    remarks
                }
            }));
        };
    }, [activeStep, selectedValues, images, remarks, setFormData])

    useEffect(() => {
        if (assessmenteditflg) {
            const assessmentId = localStorage.getItem("assessmentId");
            geteditdetails(assessmentId);
        }
    }, []);

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setSnackbarOpen(false);
      };

    async function geteditdetails(assessmentId) {
        try {
            const response = await axios.get(`${ApiURL}/get_AssessmentByIds/${assessmentId}`,{headers: {
                Authorization: `Bearer ${access_token}`
              }});
            if (response.data) {
                const formData = JSON.parse(response.data.data.details);
                setSelectedValues(formData.selectedValues || {});
                setRemarks(formData.remarks || {});
    
                // Construct image paths for each image
                const updatedImages = {};
                for (const questionId in formData.images) {
                    if (formData.images.hasOwnProperty(questionId)) {
                        const imageName = formData.images[questionId];
                        const imagePath = `${ApiURL}/api/images/${imageName}`; // Replace this with your actual image directory path
                        updatedImages[questionId] = imagePath;
                    }
                }
                setImages(updatedImages);
    
                console.log("Updated Images", updatedImages);
            } else {
                console.log("No data found in the response");
            }
        } catch (error) {
            console.log("Error:", error);
            return null;
        }
    }
    

    const handleRemarksChange = (questionId, value) => {
        // Update selectedValues state
        setRemarks({ ...remarks, [questionId]: value });

        // Update formData state by merging selectedValues into the current step's data
        setFormData(prevFormData => ({
            ...prevFormData,
            [activeStep]: {
                ...prevFormData[activeStep],
                remarks: { ...prevFormData[activeStep].remarks, [questionId]: value }
            }
        }));
    };

    const handleNextClick = () => {
        // Validate form fields
        const newErrors = {};
        if (selectedValues['question21'] === "0") {
            // If radio button value is "0", both image and remarks fields are mandatory
            if (!images['question21']) {
                newErrors["question21"] = "Please capture an image.";
            }
            if (!remarks['question21']) {
                newErrors["question21"] = "Please provide remarks.";
            }
        }
          if (selectedValues['question21'] === "1") {
            // If radio button value is "1", only remarks field is mandatory
            if (!remarks['question21']) {
                newErrors["question15"] = "Please provide remarks.";
            }
        }
        if (selectedValues['question22'] === "0") {
            // If radio button value is "0", both image and remarks fields are mandatory
            if (!images['question22']) {
                newErrors["question22"] = "Please capture an image.";
            }
            if (!remarks['question22']) {
                newErrors["question22"] = "Please provide remarks.";
            }
        }
          if (selectedValues['question22'] === "1") {
            // If radio button value is "1", only remarks field is mandatory
            if (!remarks['question22']) {
                newErrors["question22"] = "Please provide remarks.";
            }
        }
        if (selectedValues['question23'] === "0") {
            // If radio button value is "0", both image and remarks fields are mandatory
            if (!images['question23']) {
                newErrors["question23"] = "Please capture an image.";
            }
            if (!remarks['question23']) {
                newErrors["question23"] = "Please provide remarks.";
            }
        }
          if (selectedValues['question23'] === "1") {
            // If radio button value is "1", only remarks field is mandatory
            if (!remarks['question23']) {
                newErrors["question23"] = "Please provide remarks.";
            }
        }

            if (selectedValues['question24'] === "0") {
                // If radio button value is "0", both image and remarks fields are mandatory
                if (!images['question24']) {
                    newErrors["question24"] = "Please capture an image.";
                }
                if (!remarks['question24']) {
                    newErrors["question24"] = "Please provide remarks.";
                }
            }
              if (selectedValues['question24'] === "1") {
                // If radio button value is "1", only remarks field is mandatory
                if (!remarks['question24']) {
                    newErrors["question24"] = "Please provide remarks.";
                }
            }
                if (selectedValues['question25'] === "0") {
                    // If radio button value is "0", both image and remarks fields are mandatory
                    if (!images['question25']) {
                        newErrors["question25"] = "Please capture an image.";
                    }
                    if (!remarks['question25']) {
                        newErrors["question25"] = "Please provide remarks.";
                    }
                }
                  if (selectedValues['question25'] === "1") {
                    // If radio button value is "1", only remarks field is mandatory
                    if (!remarks['question25']) {
                        newErrors["question25"] = "Please provide remarks.";
                    }
                }
                   
                // if (formData.question20 === "0") {
                //         // If radio button value is "0", both image and remarks fields are mandatory
                //         if (!images['question20']) {
                //             newErrors["question20"] = "Please capture an image.";
                //         }
                //         if (!formData.remarks20) {
                //             newErrors["question20"] = "Please provide remarks.";
                //         }
                //     }
                //       if (formData.question20 === "1") {
                //         // If radio button value is "1", only remarks field is mandatory
                //         if (!formData.remarks20) {
                //             newErrors["question20"] = "Please provide remarks.";
                //         }
                //     }

        

        
        setErrors(newErrors);

        // If there are errors, display Snackbar and prevent moving to the next step
        if (Object.keys(newErrors).length > 0) {
            setSnackbarMessage("Please fill the questions.");
            setSnackbarOpen(true);
        } 
        
        else {
            if(selectedValues['question21']  ||selectedValues['question22'] ||selectedValues['question23'] || selectedValues['question24'] || selectedValues['question25'] ){
              
                if( saveflg === true ){
                    onNext();
                    setSaveflg(false)
                }
                else{
                    setSnackbarMessage("Please save your changes before moving to the next step.");
                    setSnackbarOpen(true);
                }
            }
            else{
                onNext();
            }
        }
    };
    



    const handleCapture = async (questionId) => {
        // Open the gallery for image selection
        if (fileInputRef.current) {
          fileInputRef.current.click(); // Trigger the input element to open the gallery
          fileInputRef.current.onchange = (e) => {
            const selectedFile = e.target.files[0];
            console.log("selectedfile",selectedFile)
            if (selectedFile) {
              // Process the selected image using the setUploadedFile function
              if (!selectedFile.type.startsWith("image/")) {
            
              }
              else{
              
                setUploadedFile({ target: { files: [selectedFile] } },questionId);
              }
             
            }
          };
       
        }
      };
      
      
      const setUploadedFile = async (event,questionId) => {
          const file = event.target.files[0];
          console.log("file",file)

          if (file) {
            const options = {
              maxSizeMB: 0.5,  // Reduce max size further to 0.5MB
              maxWidthOrHeight: 800,  // Reduce dimensions to 800px
              useWebWorker: true
            };
            try {
              const compressedFile = await imageCompression(file, options);
              if (compressedFile.size < 1000000) { // Ensure the file is less than 1MB
                await generateImagesArray(compressedFile, questionId);
              } else {
                console.error('Compressed image size is still too large.');
              }
            } catch (error) {
              console.error('Error compressing image:', error);
            }
          } else {
            // If no image is uploaded, reset the imagePath state
           // setImagePath('');
          }
        //   if (file) {
        //     // If an image is uploaded, set the imagePath state to the URL of the uploaded image
           
        //     await generateImagesArray(file,questionId);
        //    // await uploadBlobImagesAPI(imageData,file);
        //   } 
          
        
        };
      
      
      const generateImagesArray = async (file,questionId) => {
        const imagesData = [];
        console.log("fileimage",file)
        console.log("fileimage",file.type)
        const reader = new FileReader();
        reader.onloadend = async () => {
          const imageType = file.type.split('/')[1];
          console.log("imageType",imageType)
          const imageBase64 = reader.result.split(',')[1];
          const imageName = `image_${Date.now()}.${imageType}`;
      
          console.log("imageBase6411",imageBase64)
          imagesData.push({
            imageName,
            imageBase64,
            imageType,
          });
          setImages(prevImages => ({ ...prevImages, [questionId]: `data:image/${imageType};base64,`+ imageBase64 }));
        
          // Call the upload function after processing the images
          //await uploadBlobImagesAPI(imagesData,file);
        };
       
      reader.readAsDataURL(file);
        console.log("imagesData",imagesData)
      }
    
    
    // const handleCapture = async (questionId) => {
    //     if (!isCameraOpen) {
    //         try {
    //             const newStream = await navigator.mediaDevices.getUserMedia({ video: true });
    //             setStream(newStream);
    //             const video = document.createElement('video');
    //             document.getElementById(`camera-container-${questionId}`).appendChild(video);
    //             video.srcObject = newStream;
    //             video.play();
    //             const captureImage = () => {
    //                 const canvas = document.createElement('canvas');
    //                 canvas.width = video.videoWidth;
    //                 canvas.height = video.videoHeight;
    //                 canvas.getContext('2d').drawImage(video, 0, 0);
    //                 const imageUrl = canvas.toDataURL('image/png');
    //                 setImages(prevImages => ({ ...prevImages, [questionId]: imageUrl }));
    //                 newStream.getTracks().forEach(track => track.stop());
    //                 document.getElementById(`camera-container-${questionId}`).removeChild(video);
    //                 setStream(null);
    //                 setIsCameraOpen(false);
    //             };
    //             video.addEventListener('click', captureImage);
    //             setIsCameraOpen(true);
    //         } catch (error) {
    //             console.error('Error accessing camera:', error);
    //         }
    //     }
    // };

    const handleRetake = (questionId) => {
        // Clear the previously captured image and allow the user to capture a new one
        setImages(prevImages => ({ ...prevImages, [questionId]: null }));
        handleCapture(questionId);
    };


    // const handleImageUpload = (event, question) => {
    //     const file = event.target.files[0];
    //     if (file) {
    //         const reader = new FileReader();
    //         reader.onload = () => {
    //             setImages(prevImages => ({ ...prevImages, [question]: reader.result }));
    //         };
    //         reader.readAsDataURL(file);
    //     }
    // };
    useEffect(() => {
        populateFormFields();
    }, [activeStep]);
    const populateFormFields = () => {
        const stepFormData = formData[activeStep];
        setSelectedValues(stepFormData.selectedValues || {});
        setImages(stepFormData.images || {});
        setRemarks(stepFormData.remarks || {});
    };
    // const handleNextClick = () => {
       
    
    //     onNext();
    // };
    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };
    const handleRadioChange = (questionId, value) => {
        // Update selectedValues state
        setSelectedValues({ ...selectedValues, [questionId]: value });

        // Update formData state by merging selectedValues into the current step's data
        setFormData(prevFormData => ({
            ...prevFormData,
            [activeStep]: {
                ...prevFormData[activeStep],
                selectedValues: { ...prevFormData[activeStep].selectedValues, [questionId]: value }
            }
        }));
    };
    // const handleSaveClick = () => {
    //     // Call the onSave function passed from the parent component
    //     onSave();
    //   };
      const handleSaveClick = () => {
        if (!loading) {
        const newErrors = {};
        let functionEntered = false; 
        if (selectedValues['question21'] === "0") {
            // If radio button value is "0", both image and remarks fields are mandatory
            if (!images['question21']) {
                newErrors["question21"] = "Please capture an image.";
            }
            if (!remarks['question21']) {
                newErrors["question21"] = "Please provide remarks.";
            }
            functionEntered = true; 
        }
          if (selectedValues['question21'] === "1") {
            // If radio button value is "1", only remarks field is mandatory
            if (!remarks['question21']) {
                newErrors["question15"] = "Please provide remarks.";
            }
            functionEntered = true; 
        }
        if (selectedValues['question22'] === "0") {
            // If radio button value is "0", both image and remarks fields are mandatory
            if (!images['question22']) {
                newErrors["question22"] = "Please capture an image.";
            }
            if (!remarks['question22']) {
                newErrors["question22"] = "Please provide remarks.";
            }
            functionEntered = true; 
        }
          if (selectedValues['question22'] === "1") {
            // If radio button value is "1", only remarks field is mandatory
            if (!remarks['question22']) {
                newErrors["question22"] = "Please provide remarks.";
            }
            functionEntered = true; 
        }
        if (selectedValues['question23'] === "0") {
            // If radio button value is "0", both image and remarks fields are mandatory
            if (!images['question23']) {
                newErrors["question23"] = "Please capture an image.";
            }
            if (!remarks['question23']) {
                newErrors["question23"] = "Please provide remarks.";
            }
            functionEntered = true; 
        }
          if (selectedValues['question23'] === "1") {
            // If radio button value is "1", only remarks field is mandatory
            if (!remarks['question23']) {
                newErrors["question23"] = "Please provide remarks.";
            }
            functionEntered = true; 
        }

            if (selectedValues['question24'] === "0") {
                // If radio button value is "0", both image and remarks fields are mandatory
                if (!images['question24']) {
                    newErrors["question24"] = "Please capture an image.";
                }
                if (!remarks['question24']) {
                    newErrors["question24"] = "Please provide remarks.";
                }
                functionEntered = true; 
            }
              if (selectedValues['question24'] === "1") {
                // If radio button value is "1", only remarks field is mandatory
                if (!remarks['question24']) {
                    newErrors["question24"] = "Please provide remarks.";
                }
                functionEntered = true; 
            }
                if (selectedValues['question25'] === "0") {
                    // If radio button value is "0", both image and remarks fields are mandatory
                    if (!images['question25']) {
                        newErrors["question25"] = "Please capture an image.";
                    }
                    if (!remarks['question25']) {
                        newErrors["question25"] = "Please provide remarks.";
                    }
                    functionEntered = true; 
                }
                  if (selectedValues['question25'] === "1") {
                    // If radio button value is "1", only remarks field is mandatory
                    if (!remarks['question25']) {
                        newErrors["question25"] = "Please provide remarks.";
                    }
                    functionEntered = true; 
                }
                   
           
        setErrors(newErrors);

        // If there are errors, display Snackbar and prevent moving to the next step
        if (Object.keys(newErrors).length > 0) {
            setSnackbarMessage("Please fill the questions.");
            setSnackbarOpen(true);
        } 
        if (!functionEntered) {
            setSnackbarMessage("Please fill at least one field.");              
            setSnackbarOpen(true);
           // newErrors["general"] = "Please fill at least one field.";
        }
     
        else {
            if (selectedValues['question21'] === "0") {
                // If radio button value is "0", both image and remarks fields are mandatory
                if (!images['question21']) {
                    newErrors["question21"] = "Please capture an image.";
                }
                if (!remarks['question21']) {
                    newErrors["question21"] = "Please provide remarks.";
                }
            }
              if (selectedValues['question21'] === "1") {
                // If radio button value is "1", only remarks field is mandatory
                if (!remarks['question21']) {
                    newErrors["question15"] = "Please provide remarks.";
                }
            }
            if (selectedValues['question22'] === "0") {
                // If radio button value is "0", both image and remarks fields are mandatory
                if (!images['question22']) {
                    newErrors["question22"] = "Please capture an image.";
                }
                if (!remarks['question22']) {
                    newErrors["question22"] = "Please provide remarks.";
                }
            }
              if (selectedValues['question22'] === "1") {
                // If radio button value is "1", only remarks field is mandatory
                if (!remarks['question22']) {
                    newErrors["question22"] = "Please provide remarks.";
                }
            }
            if (selectedValues['question23'] === "0") {
                // If radio button value is "0", both image and remarks fields are mandatory
                if (!images['question23']) {
                    newErrors["question23"] = "Please capture an image.";
                }
                if (!remarks['question23']) {
                    newErrors["question23"] = "Please provide remarks.";
                }
            }
              if (selectedValues['question23'] === "1") {
                // If radio button value is "1", only remarks field is mandatory
                if (!remarks['question23']) {
                    newErrors["question23"] = "Please provide remarks.";
                }
            }
    
                if (selectedValues['question24'] === "0") {
                    // If radio button value is "0", both image and remarks fields are mandatory
                    if (!images['question24']) {
                        newErrors["question24"] = "Please capture an image.";
                    }
                    if (!remarks['question24']) {
                        newErrors["question24"] = "Please provide remarks.";
                    }
                }
                  if (selectedValues['question24'] === "1") {
                    // If radio button value is "1", only remarks field is mandatory
                    if (!remarks['question24']) {
                        newErrors["question24"] = "Please provide remarks.";
                    }
                }
                    if (selectedValues['question25'] === "0") {
                        // If radio button value is "0", both image and remarks fields are mandatory
                        if (!images['question25']) {
                            newErrors["question25"] = "Please capture an image.";
                        }
                        if (!remarks['question25']) {
                            newErrors["question25"] = "Please provide remarks.";
                        }
                    }
                      if (selectedValues['question25'] === "1") {
                        // If radio button value is "1", only remarks field is mandatory
                        if (!remarks['question25']) {
                            newErrors["question25"] = "Please provide remarks.";
                        }
                    }
                       
                    // if (formData.question20 === "0") {
                    //         // If radio button value is "0", both image and remarks fields are mandatory
                    //         if (!images['question20']) {
                    //             newErrors["question20"] = "Please capture an image.";
                    //         }
                    //         if (!formData.remarks20) {
                    //             newErrors["question20"] = "Please provide remarks.";
                    //         }
                    //     }
                    //       if (formData.question20 === "1") {
                    //         // If radio button value is "1", only remarks field is mandatory
                    //         if (!formData.remarks20) {
                    //             newErrors["question20"] = "Please provide remarks.";
                    //         }
                    //     }
    
            
    
            
            setErrors(newErrors);
    
            // If there are errors, display Snackbar and prevent moving to the next step
            if (Object.keys(newErrors).length > 0) {
                setSnackbarMessage("Please fill the questions.");
                setSnackbarOpen(true);
            } 
            else{
                setSaveflg(true);
                onSave(selectedValues, images, remarks);
            }
          
        }
    }
       // onSave(selectedValues, images, remarks);
      };
    // const handleSave = async () => {
    //     try {
    //         // Prepare data to send to the backend
    //         const postData = {
    //             assessmentData: {
    //                 selectedValues,
    //                 images,
    //                 remarks
    //             }
    //         };
    
    //         // Check if the assessment has been created
    //         if (!assessmentCreated) {
    //             // If assessment has not been created, call create_assessment API
    //             const response = await axios.post(ApiURL + '/create_assessment', postData);
    //             if (response.data.success) {
    //                 // Set the assessmentCreated state to true
    //                 setAssessmentCreated(true);
    //             } else {
    //                 // Handle error response
    //                 setSnackbarMessage("Failed to create assessment.");
    //                 setSnackbarOpen(true);
    //                 return;
    //             }
    //         } else {
    //             // If assessment has been created, call update_assessment API
    //             const response = await axios.post(ApiURL + '/update_assessment', postData);
    //             if (!response.data.success) {
    //                 // Handle error response
    //                 setSnackbarMessage("Failed to update assessment.");
    //                 setSnackbarOpen(true);
    //                 return;
    //             }
    //         }
    
    //         // Handle success response
    //         setSnackbarMessage("Assessment updated successfully.");
    //         setSnackbarOpen(true);
    //     } catch (error) {
    //         // Handle request error
    //         console.error('Error updating assessment:', error);
    //         setSnackbarMessage("Failed to update assessment. Please try again later.");
    //         setSnackbarOpen(true);
    //     }
    // };
    
    return (

        <Box>

        {/* <Header pageTitle={assessmenteditflg ? "Edit Assessment" : "New Assessment"} isAuditPage={true} />
         */}
        {/* <Header pageTitle="New Assessment" isAuditPage={true} /> */}
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            {/* Question 1 */}
            <Card variant="outlined" style={{ marginBottom: '20px' }}>
  <CardContent>
    <Typography variant="h5" style={{ textTransform: 'uppercase', fontSize: "14px", fontWeight: '500', color: '#17428B', marginBottom: '10px' }}>
      Cleaning standards
    </Typography>
    <div style={{ marginBottom: '20px' }}>
      <p style={{ marginBottom: '10px', fontSize: "14px", fontWeight: '500' }}>
        21. Standardised workplace layout visible. Cleaning & inspection planning clearly posted (Who is in charge, what, when, how, where)
      </p>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
        <p style={{ marginRight: '10px', fontSize: "14px", fontWeight: '500' }}>SCORE:</p>
        <label style={{ marginRight: '20px' }}>
          <input
            type="radio"
            name="radioButton21"
            value="0"
            checked={selectedValues['question21'] === "0"}
            onChange={(e) => handleRadioChange('question21', e.target.value)}
          /> 0
        </label>
        <label>
          <input
            type="radio"
            name="radioButton21"
            value="1"
            checked={selectedValues['question21'] === "1"}
            onChange={(e) => handleRadioChange('question21', e.target.value)}
          /> 1
        </label>
      </div>
    </div>

    {selectedValues.question21 === "0" && (
      <div>
        <div id="camera-container-question21" style={{ border: "1px dashed #e6e6e6", height: '160px', textAlign: 'center', marginBottom: '20px', position: 'relative', borderRadius: '10px' }}>
          {!images['question21'] ? (
            <>
              <input
                id="upload-image_question21"
                multiple
                type="file"
                style={{ display: "none" }}
                ref={fileInputRef}
              />
              <button onClick={() => handleCapture('question21')} onChange={(e) => setFormData({ ...selectedValues, image21: e.target.value })} style={{ position: 'absolute', cursor: 'pointer', background: 'none', border: 'none', width: '100%', height: '100%', left: '0', top: '0' }}>
                <LogoutIcon sx={{ transform: 'rotate(-90deg)', color: '#C9C9CC', margin: '30px 0 10px' }} />
                <Typography style={{ textAlign: 'center', color: 'black', fontWeight: 500, fontSize: '16px' }}>Drag & drop images or <span style={{ color: '#17428B' }}>browse images</span></Typography>
                <Typography style={{ textAlign: 'center', color: '#4F4D55', fontSize: '12px', marginTop: '10px' }}>.xlsx - max file size 2MB</Typography>
              </button>
            </>
          ) : (
            <>
              <img src={images['question21']} onChange={(e) => setFormData({ ...selectedValues, image21: e.target.value })} alt="Captured" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
              <div style={{ position: 'absolute', bottom: '10px', left: '50%', transform: 'translateX(-50%)', zIndex: 1 }}>
                <Button variant="contained" onClick={() => handleRetake('question21')}>Retake</Button>
              </div>
            </>
          )}
        </div>
      </div>
    )}

    <div>
      <p style={{ marginBottom: '10px', fontSize: "14px", fontWeight: '500' }}>Remarks for Improvement</p>
      <textarea
        value={remarks["question21"] || ''}
        onChange={(e) => handleRemarksChange('question21', e.target.value)}
        placeholder="Type here"
        style={{ width: '100%', padding: '8px', outline: 'none', border: '1px solid #E6E6E6', borderRadius: '4px', resize: "none" }}
      />
    </div>

    {errors["question21"] && <Typography style={{ color: 'red' }}>{errors["question21"]}</Typography>}
  </CardContent>
</Card>

<Card variant="outlined" style={{ marginBottom: '20px' }}>
  <CardContent>
    <Typography variant="h5" style={{ textTransform: 'uppercase', fontSize: "14px", fontWeight: '500', color: '#17428B', marginBottom: '10px' }}>
      5S Noticeboard
    </Typography>
    <div style={{ marginBottom: '20px' }}>
      <p style={{ marginBottom: '10px', fontSize: "14px", fontWeight: '500' }}>
        22. There is an organised and up to date 6S noticeboard according to the standard. Containing at least the following information: Team, area layout, action plan, 6S audit results, references photos.
      </p>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
        <p style={{ marginRight: '10px', fontSize: "14px", fontWeight: '500' }}>SCORE:</p>
        <label style={{ marginRight: '20px' }}>
          <input
            type="radio"
            name="radioButton22"
            value="0"
            checked={selectedValues['question22'] === "0"}
            onChange={(e) => handleRadioChange('question22', e.target.value)}
          /> 0
        </label>
        <label>
          <input
            type="radio"
            name="radioButton22"
            value="1"
            checked={selectedValues['question22'] === "1"}
            onChange={(e) => handleRadioChange('question22', e.target.value)}
          /> 1
        </label>
      </div>
    </div>

    {selectedValues.question22 === "0" && (
      <div>
        <div id="camera-container-question22" style={{ border: "1px dashed #e6e6e6", height: '160px', textAlign: 'center', marginBottom: '20px', position: 'relative', borderRadius: '10px' }}>
          {!images['question22'] ? (
            <>
              <input
                id="upload-image_question22"
                multiple
                type="file"
                style={{ display: "none" }}
                ref={fileInputRef}
              />
              <button onClick={() => handleCapture('question22')} onChange={(e) => setFormData({ ...selectedValues, image22: e.target.value })} style={{ position: 'absolute', cursor: 'pointer', background: 'none', border: 'none', width: '100%', height: '100%', left: '0', top: '0' }}>
                <LogoutIcon sx={{ transform: 'rotate(-90deg)', color: '#C9C9CC', margin: '30px 0 10px' }} />
                <Typography style={{ textAlign: 'center', color: 'black', fontWeight: 500, fontSize: '16px' }}>Drag & drop images or <span style={{ color: '#17428B' }}>browse images</span></Typography>
                <Typography style={{ textAlign: 'center', color: '#4F4D55', fontSize: '12px', marginTop: '10px' }}>.xlsx - max file size 2MB</Typography>
              </button>
            </>
          ) : (
            <>
              <img src={images['question22']} onChange={(e) => setFormData({ ...selectedValues, image22: e.target.value })} alt="Captured" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
              <div style={{ position: 'absolute', bottom: '10px', left: '50%', transform: 'translateX(-50%)', zIndex: 1 }}>
                <Button variant="contained" onClick={() => handleRetake('question22')}>Retake</Button>
              </div>
            </>
          )}
        </div>
      </div>
    )}

    <div>
      <p style={{ marginBottom: '10px', fontSize: "14px", fontWeight: '500' }}>Remarks for Improvement</p>
      <textarea
        value={remarks["question22"] || ''}
        onChange={(e) => handleRemarksChange('question22', e.target.value)}
        placeholder="Type here"
        style={{ width: '100%', padding: '8px', outline: 'none', border: '1px solid #E6E6E6', borderRadius: '4px', resize: "none" }}
      />
    </div>

    {errors["question22"] && <Typography style={{ color: 'red' }}>{errors["question22"]}</Typography>}
  </CardContent>
</Card>



<Card variant="outlined" style={{ marginBottom: '20px' }}>
  <CardContent>
    <Typography variant="h5" style={{ textTransform: 'uppercase', fontSize: "14px", fontWeight: '500', color: '#17428B', marginBottom: '10px' }}>
    5S standards
    </Typography>
    <div style={{ marginBottom: '20px' }}>
      <p style={{ marginBottom: '10px', fontSize: "14px", fontWeight: '500' }}>
      23. Standardised colour coding Standardised labelling Standardised signage.
      </p>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
        <p style={{ marginRight: '10px', fontSize: "14px", fontWeight: '500' }}>SCORE:</p>
        <label style={{ marginRight: '20px' }}>
          <input
            type="radio"
            name="radioButton23"
            value="0"
            checked={selectedValues['question23'] === "0"}
            onChange={(e) => handleRadioChange('question23', e.target.value)}
          /> 0
        </label>
        <label>
          <input
            type="radio"
            name="radioButton23"
            value="1"
            checked={selectedValues['question23'] === "1"}
            onChange={(e) => handleRadioChange('question23', e.target.value)}
          /> 1
        </label>
      </div>
    </div>

    {selectedValues.question23 === "0" && (
      <div>
        <div id="camera-container-question23" style={{ border: "1px dashed #e6e6e6", height: '160px', textAlign: 'center', marginBottom: '20px', position: 'relative', borderRadius: '10px' }}>
          {!images['question23'] ? (
            <>
              <input
                id="upload-image_question23"
                multiple
                type="file"
                style={{ display: "none" }}
                ref={fileInputRef}
              />
              <button onClick={() => handleCapture('question23')} onChange={(e) => setFormData({ ...selectedValues, image23: e.target.value })} style={{ position: 'absolute', cursor: 'pointer', background: 'none', border: 'none', width: '100%', height: '100%', left: '0', top: '0' }}>
                <LogoutIcon sx={{ transform: 'rotate(-90deg)', color: '#C9C9CC', margin: '30px 0 10px' }} />
                <Typography style={{ textAlign: 'center', color: 'black', fontWeight: 500, fontSize: '16px' }}>Drag & drop images or <span style={{ color: '#17428B' }}>browse images</span></Typography>
                <Typography style={{ textAlign: 'center', color: '#4F4D55', fontSize: '12px', marginTop: '10px' }}>.xlsx - max file size 2MB</Typography>
              </button>
            </>
          ) : (
            <>
              <img src={images['question23']} onChange={(e) => setFormData({ ...selectedValues, image23: e.target.value })} alt="Captured" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
              <div style={{ position: 'absolute', bottom: '10px', left: '50%', transform: 'translateX(-50%)', zIndex: 1 }}>
                <Button variant="contained" onClick={() => handleRetake('question23')}>Retake</Button>
              </div>
            </>
          )}
        </div>
      </div>
    )}

    <div>
      <p style={{ marginBottom: '10px', fontSize: "14px", fontWeight: '500' }}>Remarks for Improvement</p>
      <textarea
        value={remarks["question23"] || ''}
        onChange={(e) => handleRemarksChange('question23', e.target.value)}
        placeholder="Type here"
        style={{ width: '100%', padding: '8px', outline: 'none', border: '1px solid #E6E6E6', borderRadius: '4px', resize: "none" }}
      />
    </div>

    {errors["question23"] && <Typography style={{ color: 'red' }}>{errors["question23"]}</Typography>}
  </CardContent>
</Card>



<Card variant="outlined" style={{ marginBottom: '20px' }}>
  <CardContent>
    <Typography variant="h5" style={{ textTransform: 'uppercase', fontSize: "14px", fontWeight: '500', color: '#17428B', marginBottom: '10px' }}>
    Responsibilities
    </Typography>
    <div style={{ marginBottom: '20px' }}>
      <p style={{ marginBottom: '10px', fontSize: "14px", fontWeight: '500' }}>
      24. Zone leader, team responsibilities are defined. Each action to be assigned to a person.
      </p>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
        <p style={{ marginRight: '10px', fontSize: "14px", fontWeight: '500' }}>SCORE:</p>
        <label style={{ marginRight: '20px' }}>
          <input
            type="radio"
            name="radioButton24"
            value="0"
            checked={selectedValues['question24'] === "0"}
            onChange={(e) => handleRadioChange('question24', e.target.value)}
          /> 0
        </label>
        <label>
          <input
            type="radio"
            name="radioButton24"
            value="1"
            checked={selectedValues['question24'] === "1"}
            onChange={(e) => handleRadioChange('question24', e.target.value)}
          /> 1
        </label>
      </div>
    </div>

    {selectedValues.question24 === "0" && (
      <div>
        <div id="camera-container-question24" style={{ border: "1px dashed #e6e6e6", height: '160px', textAlign: 'center', marginBottom: '20px', position: 'relative', borderRadius: '10px' }}>
          {!images['question24'] ? (
            <>
              <input
                id="upload-image_question24"
                multiple
                type="file"
                style={{ display: "none" }}
                ref={fileInputRef}
              />
              <button onClick={() => handleCapture('question24')} onChange={(e) => setFormData({ ...selectedValues, image24: e.target.value })} style={{ position: 'absolute', cursor: 'pointer', background: 'none', border: 'none', width: '100%', height: '100%', left: '0', top: '0' }}>
                <LogoutIcon sx={{ transform: 'rotate(-90deg)', color: '#C9C9CC', margin: '30px 0 10px' }} />
                <Typography style={{ textAlign: 'center', color: 'black', fontWeight: 500, fontSize: '16px' }}>Drag & drop images or <span style={{ color: '#17428B' }}>browse images</span></Typography>
                <Typography style={{ textAlign: 'center', color: '#4F4D55', fontSize: '12px', marginTop: '10px' }}>.xlsx - max file size 2MB</Typography>
              </button>
            </>
          ) : (
            <>
              <img src={images['question24']} onChange={(e) => setFormData({ ...selectedValues, image24: e.target.value })} alt="Captured" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
              <div style={{ position: 'absolute', bottom: '10px', left: '50%', transform: 'translateX(-50%)', zIndex: 1 }}>
                <Button variant="contained" onClick={() => handleRetake('question24')}>Retake</Button>
              </div>
            </>
          )}
        </div>
      </div>
    )}

    <div>
      <p style={{ marginBottom: '10px', fontSize: "14px", fontWeight: '500' }}>Remarks for Improvement</p>
      <textarea
        value={remarks["question24"] || ''}
        onChange={(e) => handleRemarksChange('question24', e.target.value)}
        placeholder="Type here"
        style={{ width: '100%', padding: '8px', outline: 'none', border: '1px solid #E6E6E6', borderRadius: '4px', resize: "none" }}
      />
    </div>

    {errors["question24"] && <Typography style={{ color: 'red' }}>{errors["question24"]}</Typography>}
  </CardContent>
</Card>
<Card variant="outlined" style={{ marginBottom: '20px' }}>
  <CardContent>
    <Typography variant="h5" style={{ textTransform: 'uppercase', fontSize: "14px", fontWeight: '500', color: '#17428B', marginBottom: '10px' }}>
    Document standards
    </Typography>
    <div style={{ marginBottom: '20px' }}>
      <p style={{ marginBottom: '10px', fontSize: "14px", fontWeight: '500' }}>
      25. All documents within the workplace comply with the plant management system.
      </p>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
        <p style={{ marginRight: '10px', fontSize: "14px", fontWeight: '500' }}>SCORE:</p>
        <label style={{ marginRight: '20px' }}>
          <input
            type="radio"
            name="radioButton25"
            value="0"
            checked={selectedValues['question25'] === "0"}
            onChange={(e) => handleRadioChange('question25', e.target.value)}
          /> 0
        </label>
        <label>
          <input
            type="radio"
            name="radioButton25"
            value="1"
            checked={selectedValues['question25'] === "1"}
            onChange={(e) => handleRadioChange('question25', e.target.value)}
          /> 1
        </label>
      </div>
    </div>

    {selectedValues.question25 === "0" && (
      <div>
        <div id="camera-container-question25" style={{ border: "1px dashed #e6e6e6", height: '160px', textAlign: 'center', marginBottom: '20px', position: 'relative', borderRadius: '10px' }}>
          {!images['question25'] ? (
            <>
              <input
                id="upload-image_question25"
                multiple
                type="file"
                style={{ display: "none" }}
                ref={fileInputRef}
              />
              <button onClick={() => handleCapture('question25')} onChange={(e) => setFormData({ ...selectedValues, image25: e.target.value })} style={{ position: 'absolute', cursor: 'pointer', background: 'none', border: 'none', width: '100%', height: '100%', left: '0', top: '0' }}>
                <LogoutIcon sx={{ transform: 'rotate(-90deg)', color: '#C9C9CC', margin: '30px 0 10px' }} />
                <Typography style={{ textAlign: 'center', color: 'black', fontWeight: 500, fontSize: '16px' }}>Drag & drop images or <span style={{ color: '#17428B' }}>browse images</span></Typography>
                <Typography style={{ textAlign: 'center', color: '#4F4D55', fontSize: '12px', marginTop: '10px' }}>.xlsx - max file size 2MB</Typography>
              </button>
            </>
          ) : (
            <>
              <img src={images['question25']} onChange={(e) => setFormData({ ...selectedValues, image25: e.target.value })} alt="Captured" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
              <div style={{ position: 'absolute', bottom: '10px', left: '50%', transform: 'translateX(-50%)', zIndex: 1 }}>
                <Button variant="contained" onClick={() => handleRetake('question25')}>Retake</Button>
              </div>
            </>
          )}
        </div>
      </div>
    )}

    <div>
      <p style={{ marginBottom: '10px', fontSize: "14px", fontWeight: '500' }}>Remarks for Improvement</p>
      <textarea
        value={remarks["question25"] || ''}
        onChange={(e) => handleRemarksChange('question25', e.target.value)}
        placeholder="Type here"
        style={{ width: '100%', padding: '8px', outline: 'none', border: '1px solid #E6E6E6', borderRadius: '4px', resize: "none" }}
      />
    </div>

    {errors["question25"] && <Typography style={{ color: 'red' }}>{errors["question25"]}</Typography>}
  </CardContent>
</Card>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                message={snackbarMessage}
            />
            <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', marginTop: '20px' }}>
                <Button variant="contained" color="primary" onClick={handleSaveClick} style={{
                    width: '100%',
                    background: 'white', // Set background color to white
                    borderRadius: '5px', // Add border radius for rounded corners
                    border: '1px solid #17428B', // Set initial border to 1px solid
                    color: '#15408A', // Set font color to blue
                    boxShadow: 'none',
                    position: 'relative',
                    // Remove default box shadow
                }} disabled={loading}

                >Save

                </Button>
                <Button variant="contained" style={{
                    width: '100%',
                    background: '#17428B', // Adjust the gradient colors as needed
                    borderRadius: 3,
                    border: 0,
                    color: 'white',
                    height: 48,
                    padding: '0 30px',
                    boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
                }} color="primary" onClick={handleNextClick} disabled={loading} >
                    Next
                </Button>
            </div>

        </div>
        {loading && (
            <div
                style={{
                    position: 'fixed',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 9999,
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    padding: '20px',
                    borderRadius: '8px',
                    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                }}
            >
                {/* Replace this with your preferred loading indicator */}
                <Typography variant="h6" style={{ textAlign: 'center', marginBottom: '10px' }}>
                    Saving Assessment...
                </Typography>
                {/* Example loading spinner */}
                <div style={{ textAlign: 'center' }}>
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
        )}
    </Box>


        
    );
}

export default Standardise;
