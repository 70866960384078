import React from "react";
import { Button } from "@mui/material";

const StyledButton = ({ variant, text, onClick }) => {
    const buttonStyles = {
        outlined: {
            width: '252px',
            height: '40px',
            padding: '8px 12px',
            borderRadius: '6px',
            color: "#252C32",
            gap: '8px',
            border: '1px solid #E5E9EB',
            fontSize: "14px",
            marginRight: '10px',
            textTransform: 'none',
        },
        contained: {
            width: '252px',
            height: '40px',
            padding: '8px 12px',
            borderRadius: '6px',
            color: "#FFFFFF",
            gap: '8px',
            background: "#0086DC",
            fontSize: "14px",
            textTransform: 'none',
        }
    };

    return (
        <Button
            variant={variant}
            sx={buttonStyles[variant]}
            onClick={onClick}
        >
            {text}
        </Button>
    );
};

export default StyledButton;