import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Select, MenuItem, FormControl, InputLabel, TextField, Autocomplete, Typography } from "@mui/material";
import axios from 'axios';
import configParam from "../../config";
import { formDataState } from '../../recoil/atoms';
import HorizontalCombinedStepper from "../RequestForm/HorizontalCombinedStepper";
import { useResetRecoilState } from "recoil";
const moment = require('moment');

const modalInputLabelStyle = {
  fontSize: 16,
  fontStyle: 'normal',
  fontWeight: 500,
  color: '#000',
  marginBottom: '4px'
};

const NewAssessment = ({ open, onClose, onNext }) => {
  const [zoneAreas, setZoneAreas] = useState([]);
  const [zone, setZone] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);
  const [auditor, setAuditor] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [errorMessageDate, setErrorMessageDate] = useState(null);
  const [auditorOptions, setAuditorOptions] = useState([]);
  const [userdet, setUserdet] = useState('');
  const [userRoles, setUserRoles] = useState([]);
  const ApiURL = configParam.API_URL;
  const today = new Date().toISOString().split('T')[0];
  const plant_id = localStorage.getItem('selectedPlantId');
  const access_token = JSON.parse(localStorage.getItem("accesstoken"));
  const [AssessDialogOpen, setAssessDialogOpen] = useState(false);

  const resetDealerValue = useResetRecoilState(formDataState);

  useEffect(() => {
    fetchUserDetailsAndRoles();
  }, []);

  useEffect(() => {
    setSelectedDate(today);
  }, [open]);

  const fetchUserDetailsAndRoles = async () => {
    try {
      const userDatas = JSON.parse(localStorage.getItem("userDatas"));
      if (userDatas) {
        const sgid = userDatas.sgid;
        setUserdet(sgid);
        const userResponse = await axios.get(`${ApiURL}/checkUserBySGID/${sgid}`, {
          headers: {
            Authorization: `Bearer ${access_token}`
          }
        });
        if (userResponse.data.exists) {
          const user = userResponse.data.user;
          setAuditorOptions([`${user.firstName} ${user.lastName}`]);
          setAuditor(sgid);
        }
        
        const roleResponse = await axios.get(`${ApiURL}/getroleuser/${sgid}/${plant_id}`, {
          headers: {
            Authorization: `Bearer ${access_token}`
          }
        });
        if (roleResponse.status === 200 && roleResponse.data && roleResponse.data.length > 0) {
          setUserRoles(roleResponse.data);
    
        }
        
        fetchZoneAreas(roleResponse.data);
      }
    } catch (error) {
      console.error('Error fetching user details and roles:', error);
      setLoading(false);
    }
  };

  

  const fetchZoneAreas = async (roles) => {
    try {
      const userDatas = JSON.parse(localStorage.getItem("userDatas"));
      const sgid =userDatas.sgid
      const response = await axios.get(`${ApiURL}/get_ZoneAreasByPlantId/${plant_id}`, {
        headers: {
          Authorization: `Bearer ${access_token}`
        }
      });
  
      let sortedZones = response.data.sort((a, b) => a.code - b.code);
  
      // Determine user roles
      const isCoordinator = roles.some(role => role.wcm_coordinator_firstname !== null);
      const isAuditor = roles.some(role => role.audit_first_name !== null);
      const isZoneOrGroupLeader = !isCoordinator && !isAuditor;
  
      if (isCoordinator) {
        // Show all zones if user is a coordinator
        sortedZones = sortedZones;
      } else if (isAuditor) {
        // Show only zones assigned to the auditor
        const auditorZones = roles
          .filter(role => role.auditname === sgid)
          .map(role => role.code);
  
      
        sortedZones = sortedZones.filter(zone => {
          // Debug: Check each zone code
          return auditorZones.includes(zone.code);
        });
      } else if (isZoneOrGroupLeader) {
        // Show no zones if user is a zone leader or group leader
        sortedZones = [];
      }
  
      setZoneAreas(sortedZones);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching ZoneAreas:', error);
      setLoading(false);
      setError('Failed to fetch zone areas. Please try again later.');
    }
  };
  

  const handleNext = async () => {
    try {
      const dateTimeString = selectedDate;
      const formattedDate = moment.utc(dateTimeString).format("YYYY-MM-DD");

      if (!zone) {
        setError('Please select a zone.');
        return;
      }

      const response = await axios.get(`${ApiURL}/getAuditByZoneAndMonth/${zone}/${moment(selectedDate).format('YYYY-MM')}`, {
        headers: {
          Authorization: `Bearer ${access_token}`
        }
      });

      if (response.data.exists1) {
        setError('Selected zone is not mapped.');
        return;
      }

      if (parseInt(moment(formattedDate).format('DD')) > 25) {
        setErrorMessageDate("You cannot create an audit after the 25th of the month.");
        return;
      }

      if (response.data.exists) {
        setError('This audit is already completed for the respective zone.');
      } else {
        onClose();
        localStorage.setItem("auditor", userdet);
        localStorage.setItem("zone", zone);
        localStorage.setItem("selectedDate", selectedDate);
        resetDealerValue();
        localStorage.setItem("assessmentId", "");
        localStorage.setItem("assessmentIdcreate", "");
        localStorage.setItem("assessmenteditflg", false);
        setAssessDialogOpen(true);
      }
    } catch (error) {
      console.error('Error checking audit:', error);
      setError('Failed to check audit. Please try again later.');
    }
  };

  const handleZoneChange = (event, newValue) => {
    setZone(newValue ? newValue.id : null);
  };

  const handleDateChange = (event) => {
    const dateTimeString = event.target.value;
    const formattedDate = moment.utc(dateTimeString).format("YYYY-MM-DD");

    if (parseInt(moment(formattedDate).format('DD')) > 25) {
      setErrorMessageDate("You cannot create an audit after the 25th of the month.");
    } else {
      setErrorMessageDate('');
      setSelectedDate(formattedDate);
    }
  };

  const handleDialogClose = () => {
    setAssessDialogOpen(false); // Close the dialog
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle color={'#17428B'}>New Assessment</DialogTitle>
        <DialogContent>
          <InputLabel style={modalInputLabelStyle}>Auditor</InputLabel>
          <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <Select size='small' value={auditor} onChange={(event) => setAuditor(event.target.value)} fullWidth>
              {loading ? (
                <MenuItem disabled>Loading...</MenuItem>
              ) : (
                auditorOptions.map((auditorOption) => (
                  <MenuItem key={auditorOption} value={userdet}>
                    {auditorOption}
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>
          <InputLabel style={modalInputLabelStyle}>Zone</InputLabel>
          <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <Autocomplete
              options={zoneAreas}
              getOptionLabel={(option) => `Zone ${option.code} - ${option.name}`}
              onChange={handleZoneChange}
              renderInput={(params) => <TextField {...params} fullWidth />}
              selectOnFocus
              clearOnBlur
              size='small'
              handleHomeEndKeys
              style={{ marginBottom: 10 }}
            />
          </FormControl>
          <InputLabel style={modalInputLabelStyle}>Audit Date</InputLabel>
          <TextField
            size="small"
            className="input-nis"
            fullWidth
            id="date"
            type="date"
            value={selectedDate}
            onChange={handleDateChange}
            inputProps={{ max: today }}
            style={{ marginBottom: 10 }}
          />
          {errorMessageDate && <Typography color="error">{errorMessageDate}</Typography>}
          {error && <Typography color="error">{error}</Typography>}
        </DialogContent>
        <DialogActions sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '0 32px 16px' }}>
          <Button onClick={onClose} style={{ width: 'calc(100% - 16px)', textTransform: 'capitalize', background: 'white', height: '38px', borderRadius: 5, border: '1px solid', color: '#15408A', boxShadow: 'none' }}>Cancel</Button>
          <Button onClick={handleNext} style={{ width: 'calc(100% - 16px)', textTransform: 'capitalize', background: '#17428B', borderRadius: 5, border: 0, color: 'white', height: 38, padding: '0 30px', boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)' }}>Next</Button>
        </DialogActions>
      </Dialog>
      <HorizontalCombinedStepper open={AssessDialogOpen} />
    </>
  );
};

export default NewAssessment;
