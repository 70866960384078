import React, { useState, useEffect } from 'react';
import Header from '../app/TopAppBar';
import { Typography, Button, Grid, Radio, FormControl, FormControlLabel, CircularProgress, Tooltip, Box } from '@mui/material';
import { useRecoilState } from "recoil";
import { plantData } from '../recoil/atoms';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import MaskGroup from "../Asset/Icons/MaskGroup.svg";
import API from '../Services/API';
import configParam from '../config';

const Plant = () => {
    const ApiURL = configParam.API_URL;
    const [, setPlantSelection] = useRecoilState(plantData);
    const [plantOptions, setPlantOptions] = useState([]);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false); // Loader state
    const navigate = useNavigate();

    useEffect(() => {
        getPlantOptions();
    }, []);

    const getPlantOptions = async () => {
        setError(null);
        try {
            const access_token = JSON.parse(localStorage.getItem("accesstoken"));


            setLoading(true);
            const response = await axios.get(API.PLANTS, {
                headers: {
                    Authorization: `Bearer ${access_token}`,

                }
            });
            setPlantOptions(response.data);
        } catch (error) {
            setError("Error fetching data");
        } finally {
            setLoading(false);
        }
    };



    const handleAPIClick = async () => {
        try {
            const userDetails = {
                userid: "SG1HR",
                password: "nK+<@P`s7B4R=:Pz",
                secretkey: "<%&Q4km++X:vdM2q",
            }
            const response = await axios.post(`${ApiURL}/CHECK_API`, { userDetails: userDetails });

            console.log(userDetails, "userDetails688")
            if (response.status === 200) {
                console.log("response", response);
            }
        } catch (error) {
            console.error("An error occurred in update trials:", error);
        }
    };

    const handleSelectionChange = (plantId) => {
        localStorage.setItem('selectedPlantId', plantId); // Store selected plant ID in local storage
        const selectedPlant = plantOptions.find(plant => plant.id === plantId);
        setPlantSelection({ plantName: selectedPlant.name }); // Update the selected plant name in state
    };

    const handleGetStart = () => {
        const selectedPlantId = localStorage.getItem('selectedPlantId');
        if (selectedPlantId) {
            navigate('/Dashboard');
        } else {
            setError('Please select a plant before proceeding.');
        }
    };

    const storedUserData = JSON.parse(localStorage.getItem("userDatas"));
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            {/* <Header pageTitle="Plant" goBack={true} /> */}
            {loading ? (
                <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '40vh' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <Box sx={{
                    background: '#fff',
                    padding: '20px',
                    width: '550px',
                    borderRadius: '10px',
                    border: '1px solid #e6e6e6'
                }}>
                    <Box style={{
                        display: "flex",
                        justifyContent: "center",
                    }}>
                        <img
                            src={MaskGroup}
                            alt="MaskGroup"
                            style={{
                                width: "150px",
                                height: "150px",
                                cursor: "pointer",
                                marginLeft: "auto",
                                marginRight: "auto",
                            }}
                        />
                    </Box>
                    <Typography variant='h6' style={{ fontWeight: 'bold', textAlign: "center", marginTop: '16px' }}>Hi {storedUserData?.firstName}, Please select devision <br />to get started!</Typography>
                    <Grid container style={{ marginTop: '40px' }}>
                        {plantOptions.map((plant, index) => (
                            <Grid item xs={index === plantOptions.length - 1 ? 12 : 6} key={plant.id}>
                                <FormControl sx={{ width: '100%', marginBottom: '16px', padding: '0 8px' }}>
                                    <FormControlLabel
                                        style={{ padding: "10px 14px", border: "1px solid #e6e6e6", width: '100%', borderRadius: "8px", height: 'auto', display: "flex", margin: '0' }}
                                        value={plant.id}
                                        control={<Radio />}
                                        label={
                                            <Typography style={{ fontSize: '14px', color: ' #0E4E95', fontWeight: '500' }}>
                                                {plant.name}
                                            </Typography>
                                        }
                                        checked={localStorage.getItem('selectedPlantId') === plant.id}
                                        onChange={() => handleSelectionChange(plant.id)}
                                    />
                                </FormControl>
                            </Grid>
                        ))}
                    </Grid>
                    {error && <Typography variant='body1' style={{ color: "red", marginTop: "10px", textAlign: "center" }}>
                        {error}
                    </Typography>
                    }
                    <Box sx={{ padding: '0 8px' }}>
                        <Button
                            variant="contained"
                            style={{
                                fontSize: 15,
                                backgroundImage: 'linear-gradient(to right, #4DB1B3, #0195D6, #0F5299, #C5284C, #E83430, #E66407)',
                                textTransform: "none",
                                padding: '0 30px',
                                height: 48,
                                border: 0,
                                borderRadius: "8px",
                                width: "100%"
                            }}
                            onClick={handleGetStart}>Get Started</Button>

                         {/* <Button
                            variant="contained"
                            style={{
                                fontSize: 15,
                                backgroundImage: 'linear-gradient(to right, #4DB1B3, #0195D6, #0F5299, #C5284C, #E83430, #E66407)',
                                textTransform: "none",
                                marginTop: 20,
                                padding: '0 30px',
                                height: 48,
                                border: 0,
                                borderRadius: "8px",
                                width: "100%"
                            }}
                            onClick={handleAPIClick}>Get API</Button> */}


                    </Box>
                </Box>
            )}
        </Box >
    );
}

export default Plant;