import React, { useState,useEffect,useRef } from "react";
import { Typography, Button,Snackbar,Alert,Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import imageCompression from 'browser-image-compression';
import Header from '../../app/TopAppBar';
import CloudUpload from "../../Asset/Icons/file_upload_icon.svg";
import { useRecoilState } from 'recoil';
import { stepState, formDataState,assessmentCreatedState} from '../../recoil/atoms';
import axios from "axios";
import configParam from "../../config";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import MuiAlert from '@mui/material/Alert';
import LogoutIcon from '@mui/icons-material/Logout';
const Straighten = ( { onNext, activeStep,onSave,loading  } ) => {
    const [formData, setFormData] = useRecoilState(formDataState);
    const [selectedValues, setSelectedValues] = useState({});
    const fileInputRef = useRef(null);
    const [images, setImages] = useState({});
    const [remarks, setRemarks] = useState({});
    const [errors, setErrors] = useState({});
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const[set,setStream] = useState({});
    const [isCameraOpen, setIsCameraOpen] = useState(false);
    const [assessmentCreated, setAssessmentCreated] = useRecoilState(assessmentCreatedState);
    const [assessmentId, setAssessmentId] = useState(null);
    const ApiURL = configParam.API_URL;
    const [saveflg, setSaveflg] = useState(false);
    const assessmenteditflg = localStorage.getItem("assessmenteditflg") === "true";
    const  access_token =  JSON.parse(localStorage.getItem("accesstoken"));
    useEffect(() => {
        return () => {
            // Store form data in Recoil state when unmounting
            setFormData(prevFormData => ({
                ...prevFormData,
                [activeStep]: {
                    selectedValues,
                    images,
                    remarks
                }
            }));
        };
    }, [activeStep, selectedValues, images, remarks, setFormData])
    
    useEffect(() => {
        if (assessmenteditflg) {
            const assessmentId = localStorage.getItem("assessmentId");
            geteditdetails(assessmentId);
        }
    }, []);
  
    async function geteditdetails(assessmentId) {
        try {
            const response = await axios.get(`${ApiURL}/get_AssessmentByIds/${assessmentId}`,{headers: {
                Authorization: `Bearer ${access_token}`
              }});
            if (response.data) {
                const formData = JSON.parse(response.data.data.details);
                setSelectedValues(formData.selectedValues || {});
                setRemarks(formData.remarks || {});
    
                // Construct image paths for each image
                const updatedImages = {};
                for (const questionId in formData.images) {
                    if (formData.images.hasOwnProperty(questionId)) {
                        const imageName = formData.images[questionId];
                        const imagePath = `${ApiURL}/api/images/${imageName}`; // Replace this with your actual image directory path
                        updatedImages[questionId] = imagePath;
                    }
                }
                setImages(updatedImages);
    
                console.log("Updated Images", updatedImages);
            } else {
                console.log("No data found in the response");
            }
        } catch (error) {
            console.log("Error:", error);
            return null;
        }
    }

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setSnackbarOpen(false);
      };


    const handleRemarksChange = (questionId, value) => {
        // Update selectedValues state
        setRemarks({ ...remarks, [questionId]: value });

        // Update formData state by merging selectedValues into the current step's data
        setFormData(prevFormData => ({
            ...prevFormData,
            [activeStep]: {
                ...prevFormData[activeStep],
                remarks: { ...prevFormData[activeStep].remarks, [questionId]: value }
            }
        }));
    };

    const handleNextClick = () => {
        // Validate form fields
        const newErrors = {};
        if (selectedValues['question4'] === "0") {
            // If radio button value is "0", both image and remarks fields are mandatory
            if (!images['question4']) {
                newErrors["question4"] = "Please capture an image.";
            }
            if (!remarks['question4']) {
                newErrors["question4"] = "Please provide remarks.";
            }
        }
          if (selectedValues['question4'] === "1") {
            // If radio button value is "1", only remarks field is mandatory
            if (!remarks['question4']) {
                newErrors["question4"] = "Please provide remarks.";
            }
        }
        if (selectedValues['question5'] === "0") {
            // If radio button value is "0", both image and remarks fields are mandatory
            if (!images['question5']) {
                newErrors["question5"] = "Please capture an image.";
            }
            if (!remarks['question5']) {
                newErrors["question5"] = "Please provide remarks.";
            }
        }
          if (selectedValues['question5'] === "1") {
            // If radio button value is "1", only remarks field is mandatory
            if (!remarks['question5']) {
                newErrors["question5"] = "Please provide remarks.";
            }
        }
        if (selectedValues['question6'] === "0") {
            // If radio button value is "0", both image and remarks fields are mandatory
            if (!images['question6']) {
                newErrors["question6"] = "Please capture an image.";
            }
            if (!remarks['question6']) {
                newErrors["question6"] = "Please provide remarks.";
            }
        }
          if (selectedValues['question6'] === "1") {
            // If radio button value is "1", only remarks field is mandatory
            if (!remarks['question6']) {
                newErrors["question6"] = "Please provide remarks.";
            }
        }


        if (selectedValues['question7'] === "0") {
            // If radio button value is "0", both image and remarks fields are mandatory
            if (!images['question7']) {
                newErrors["question7"] = "Please capture an image.";
            }
            if (!remarks['question7']) {
                newErrors["question7"] = "Please provide remarks.";
            }
        }
          if (selectedValues['question7'] === "1") {
            // If radio button value is "1", only remarks field is mandatory
            if (!remarks['question7']) {
                newErrors["question7"] = "Please provide remarks.";
            }
        }

            if (selectedValues['question8'] === "0") {
                // If radio button value is "0", both image and remarks fields are mandatory
                if (!images['question8']) {
                    newErrors["question8"] = "Please capture an image.";
                }
                if (!remarks['question8']) {
                    newErrors["question8"] = "Please provide remarks.";
                }
            }
              if (selectedValues['question8'] === "1") {
                // If radio button value is "1", only remarks field is mandatory
                if (!remarks['question8']) {
                    newErrors["question8"] = "Please provide remarks.";
                }
            }
                if (selectedValues['question9'] === "0") {
                    // If radio button value is "0", both image and remarks fields are mandatory
                    if (!images['question9']) {
                        newErrors["question9"] = "Please capture an image.";
                    }
                    if (!remarks['question9']) {
                        newErrors["question9"] = "Please provide remarks.";
                    }
                }
                  if (selectedValues['question9'] === "1") {
                    // If radio button value is "1", only remarks field is mandatory
                    if (!remarks['question9']) {
                        newErrors["question9"] = "Please provide remarks.";
                    }
                }
                    if (selectedValues['question10'] === "0") {
                        // If radio button value is "0", both image and remarks fields are mandatory
                        if (!images['question10']) {
                            newErrors["question10"] = "Please capture an image.";
                        }
                        if (!remarks['question10']) {
                            newErrors["question10"] = "Please provide remarks.";
                        }
                    }
                      if (selectedValues['question10'] === "1") {
                        // If radio button value is "1", only remarks field is mandatory
                        if (!remarks['question10']) {
                            newErrors["question10"] = "Please provide remarks.";
                        }
                    }

                    if (selectedValues['question11'] === "0") {
                        // If radio button value is "0", both image and remarks fields are mandatory
                        if (!images['question11']) {
                            newErrors["question11"] = "Please capture an image.";
                        }
                        if (!remarks['question11']) {
                            newErrors["question11"] = "Please provide remarks.";
                        }
                    }
                      if (selectedValues['question11'] === "1") {
                        // If radio button value is "1", only remarks field is mandatory
                        if (!remarks['question11']) {
                            newErrors["question11"] = "Please provide remarks.";
                        }
                    }

                    if (selectedValues['question12'] === "0") {
                        // If radio button value is "0", both image and remarks fields are mandatory
                        if (!images['question12']) {
                            newErrors["question12"] = "Please capture an image.";
                        }
                        if (!remarks['question12']) {
                            newErrors["question12"] = "Please provide remarks.";
                        }
                    }
                      if (selectedValues['question12'] === "1") {
                        // If radio button value is "1", only remarks field is mandatory
                        if (!remarks['question12']) {
                            newErrors["question12"] = "Please provide remarks.";
                        }
                    }
                    if (selectedValues['question13'] === "0") {
                        // If radio button value is "0", both image and remarks fields are mandatory
                        if (!images['question13']) {
                            newErrors["question13"] = "Please capture an image.";
                        }
                        if (!remarks['question13']) {
                            newErrors["question13"] = "Please provide remarks.";
                        }
                    }
                      if (selectedValues['question13'] === "1") {
                        // If radio button value is "1", only remarks field is mandatory
                        if (!remarks['question13']) {
                            newErrors["question13"] = "Please provide remarks.";
                        }
                    }
                    if (selectedValues['question14'] === "0") {
                        // If radio button value is "0", both image and remarks fields are mandatory
                        if (!images['question14']) {
                            newErrors["question14"] = "Please capture an image.";
                        }
                        if (!remarks['question14']) {
                            newErrors["question14"] = "Please provide remarks.";
                        }
                    }
                      if (selectedValues['question14'] === "1") {
                        // If radio button value is "1", only remarks field is mandatory
                        if (!remarks['question14']) {
                            newErrors["question14"] = "Please provide remarks.";
                        }
                    }

        

        
        setErrors(newErrors);

        // If there are errors, display Snackbar and prevent moving to the next step
        if (Object.keys(newErrors).length > 0) {
            setSnackbarMessage("Please fill the questions.");
            setSnackbarOpen(true);
        } 
        
        else {
            if(selectedValues['question4']  ||selectedValues['question5'] ||selectedValues['question6'] || selectedValues['question7'] || selectedValues['question8'] || selectedValues['question9'] || selectedValues['question10']|| selectedValues['question11'] || selectedValues['question12']|| selectedValues['question13']|| selectedValues['question14']){
              
                if( saveflg === true ){
                    onNext();
                    setSaveflg(false)
                }
                else{
                    setSnackbarMessage("Please save your changes before moving to the next step.");
                    setSnackbarOpen(true);
                }
            }
            else{
                onNext();
            }
        }
    };
    


    
const handleCapture = async (questionId) => {
    // Open the gallery for image selection
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Trigger the input element to open the gallery
      fileInputRef.current.onchange = (e) => {
        const selectedFile = e.target.files[0];
        console.log("selectedfile",selectedFile)
        if (selectedFile) {
          // Process the selected image using the setUploadedFile function
          if (!selectedFile.type.startsWith("image/")) {
        
          }
          else{
          
            setUploadedFile({ target: { files: [selectedFile] } },questionId);
          }
         
        }
      };
   
    }
  };
  
  
  const setUploadedFile = async (event,questionId) => {
      const file = event.target.files[0];
      console.log("file",file)
      if (file) {
        const compressedFile = await imageCompression(file, {
            maxSizeMB: 1,
            maxWidthOrHeight: 1024,
            useWebWorker: true
          });
      await generateImagesArray(compressedFile, questionId);
    } else {
        // If no image is uploaded, reset the imagePath state
       // setImagePath('');
      }
    };
  
  
  const generateImagesArray = async (file,questionId) => {
    const imagesData = [];
    console.log("fileimage",file)
    console.log("fileimage",file.type)
    const reader = new FileReader();
    reader.onloadend = async () => {
      const imageType = file.type.split('/')[1];
      console.log("imageType",imageType)
      const imageBase64 = reader.result.split(',')[1];
      const imageName = `image_${Date.now()}.${imageType}`;
  
      console.log("imageBase6411",imageBase64)
      imagesData.push({
        imageName,
        imageBase64,
        imageType,
      });
      setImages(prevImages => ({ ...prevImages, [questionId]: `data:image/${imageType};base64,`+ imageBase64 }));
    
      // Call the upload function after processing the images
      //await uploadBlobImagesAPI(imagesData,file);
    };
   
  reader.readAsDataURL(file);
    console.log("imagesData",imagesData)
  }
    
    // const handleCapture = async (questionId) => {
    //     if (!isCameraOpen) {
    //         try {
    //             const newStream = await navigator.mediaDevices.getUserMedia({ video: true });
    //             setStream(newStream);
    //             const video = document.createElement('video');
    //             document.getElementById(`camera-container-${questionId}`).appendChild(video);
    //             video.srcObject = newStream;
    //             video.play();
    //             const captureImage = () => {
    //                 const canvas = document.createElement('canvas');
    //                 canvas.width = video.videoWidth;
    //                 canvas.height = video.videoHeight;
    //                 canvas.getContext('2d').drawImage(video, 0, 0);
    //                 const imageUrl = canvas.toDataURL('image/png');
    //                 setImages(prevImages => ({ ...prevImages, [questionId]: imageUrl }));
    //                 newStream.getTracks().forEach(track => track.stop());
    //                 document.getElementById(`camera-container-${questionId}`).removeChild(video);
    //                 setStream(null);
    //                 setIsCameraOpen(false);
    //             };
    //             video.addEventListener('click', captureImage);
    //             setIsCameraOpen(true);
    //         } catch (error) {
    //             console.error('Error accessing camera:', error);
    //         }
    //     }
    // };

    const handleRetake = (questionId) => {
        // Clear the previously captured image and allow the user to capture a new one
        setImages(prevImages => ({ ...prevImages, [questionId]: null }));
        handleCapture(questionId);
    };




    // const handleImageUpload = (event, question) => {
    //     const file = event.target.files[0];
    //     if (file) {
    //         const reader = new FileReader();
    //         reader.onload = () => {
    //             setImages(prevImages => ({ ...prevImages, [question]: reader.result }));
    //         };
    //         reader.readAsDataURL(file);
    //     }
    // };
    useEffect(() => {
        populateFormFields();
    }, [activeStep]);
    const populateFormFields = () => {
        const stepFormData = formData[activeStep];
        setSelectedValues(stepFormData.selectedValues || {});
        setImages(stepFormData.images || {});
        setRemarks(stepFormData.remarks || {});
    };
    // const handleNextClick = () => {
       
    
    //     onNext();
    // };
    
    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };
   
    const handleRadioChange = (questionId, value) => {
        // Update selectedValues state
        setSelectedValues({ ...selectedValues, [questionId]: value });

        // Update formData state by merging selectedValues into the current step's data
        setFormData(prevFormData => ({
            ...prevFormData,
            [activeStep]: {
                ...prevFormData[activeStep],
                selectedValues: { ...prevFormData[activeStep].selectedValues, [questionId]: value }
            }
        }));
    };
    // const handleSave = async () => {
    //     try {
    //         // Prepare data to send to the backend
    //         let postData = {
    //             assessmentId: assessmentId,
    //             assessmentData: {
    //                 ...(formDataState[activeStep] || {}), // Existing assessment data for the current step
    //                 selectedValues,
    //                 images,
    //                 remarks
    //             }
    //         };
    
    //         // Check if the assessment has been created
    //         if (!assessmentCreated) {
    //             // If assessment has not been created, call create_assessment API
    //             const response = await axios.post(ApiURL + '/create_assessment', postData);
    //             if (response.data.success) {
    //                 // Set the assessmentCreated state to true
    //                 setAssessmentCreated(true);
    //             } else {
    //                 // Handle error response
    //                 setSnackbarMessage("Failed to create assessment.");
    //                 setSnackbarOpen(true);
    //                 return;
    //             }
    //         } else {
    //             // If assessment has been created, call update_assessment API
    //             const response = await axios.post(ApiURL + '/update_assessment', postData);
    //             if (!response.data.success) {
    //                 // Handle error response
    //                 setSnackbarMessage("Failed to update assessment.");
    //                 setSnackbarOpen(true);
    //                 return;
    //             }
    //         }
    
    //         // Handle success response
    //         setSnackbarMessage("Assessment updated successfully.");
    //         setSnackbarOpen(true);
    //     } catch (error) {
    //         // Handle request error
    //         console.error('Error updating assessment:', error);
    //         setSnackbarMessage("Failed to update assessment. Please try again later.");
    //         setSnackbarOpen(true);
    //     }
    // };
    const handleSaveClick = () => {
        if (!loading) {
        const newErrors = {};
        let functionEntered = false; 
        if (selectedValues['question4'] === "0") {
            // If radio button value is "0", both image and remarks fields are mandatory
            if (!images['question4']) {
                newErrors["question4"] = "Please capture an image.";
            }
            if (!remarks['question4']) {
                newErrors["question4"] = "Please provide remarks.";
            }
            functionEntered = true; 
        }
          if (selectedValues['question4'] === "1") {
            // If radio button value is "1", only remarks field is mandatory
            if (!remarks['question4']) {
                newErrors["question4"] = "Please provide remarks.";
            }
            functionEntered = true; 
        }
        if (selectedValues['question5'] === "0") {
            // If radio button value is "0", both image and remarks fields are mandatory
            if (!images['question5']) {
                newErrors["question5"] = "Please capture an image.";
            }
            if (!remarks['question5']) {
                newErrors["question5"] = "Please provide remarks.";
            }
            functionEntered = true; 
        }
          if (selectedValues['question5'] === "1") {
            // If radio button value is "1", only remarks field is mandatory
            if (!remarks['question5']) {
                newErrors["question5"] = "Please provide remarks.";
            }
            functionEntered = true; 
        }
        if (selectedValues['question6'] === "0") {
            // If radio button value is "0", both image and remarks fields are mandatory
            if (!images['question6']) {
                newErrors["question6"] = "Please capture an image.";
            }
            if (!remarks['question6']) {
                newErrors["question6"] = "Please provide remarks.";
            }
            functionEntered = true; 
        }
          if (selectedValues['question6'] === "1") {
            // If radio button value is "1", only remarks field is mandatory
            if (!remarks['question6']) {
                newErrors["question6"] = "Please provide remarks.";
            }
            functionEntered = true; 
        }


        if (selectedValues['question7'] === "0") {
            // If radio button value is "0", both image and remarks fields are mandatory
            if (!images['question7']) {
                newErrors["question7"] = "Please capture an image.";
            }
            if (!remarks['question7']) {
                newErrors["question7"] = "Please provide remarks.";
            }
            functionEntered = true; 
        }
          if (selectedValues['question7'] === "1") {
            // If radio button value is "1", only remarks field is mandatory
            if (!remarks['question7']) {
                newErrors["question7"] = "Please provide remarks.";
            }
            functionEntered = true; 
        }

            if (selectedValues['question8'] === "0") {
                // If radio button value is "0", both image and remarks fields are mandatory
                if (!images['question8']) {
                    newErrors["question8"] = "Please capture an image.";
                }
                if (!remarks['question8']) {
                    newErrors["question8"] = "Please provide remarks.";
                }
                functionEntered = true; 
            }
              if (selectedValues['question8'] === "1") {
                // If radio button value is "1", only remarks field is mandatory
                if (!remarks['question8']) {
                    newErrors["question8"] = "Please provide remarks.";
                }
                functionEntered = true; 
            }
                if (selectedValues['question9'] === "0") {
                    // If radio button value is "0", both image and remarks fields are mandatory
                    if (!images['question9']) {
                        newErrors["question9"] = "Please capture an image.";
                    }
                    if (!remarks['question9']) {
                        newErrors["question9"] = "Please provide remarks.";
                    }
                    functionEntered = true; 
                }
                  if (selectedValues['question9'] === "1") {
                    // If radio button value is "1", only remarks field is mandatory
                    if (!remarks['question9']) {
                        newErrors["question9"] = "Please provide remarks.";
                    }
                    functionEntered = true; 
                }
                    if (selectedValues['question10'] === "0") {
                        // If radio button value is "0", both image and remarks fields are mandatory
                        if (!images['question10']) {
                            newErrors["question10"] = "Please capture an image.";
                        }
                        if (!remarks['question10']) {
                            newErrors["question10"] = "Please provide remarks.";
                        }
                        functionEntered = true; 
                    }
                      if (selectedValues['question10'] === "1") {
                        // If radio button value is "1", only remarks field is mandatory
                        if (!remarks['question10']) {
                            newErrors["question10"] = "Please provide remarks.";
                        }
                        functionEntered = true; 
                    }

                    if (selectedValues['question11'] === "0") {
                        // If radio button value is "0", both image and remarks fields are mandatory
                        if (!images['question11']) {
                            newErrors["question11"] = "Please capture an image.";
                        }
                        if (!remarks['question11']) {
                            newErrors["question11"] = "Please provide remarks.";
                        }
                        functionEntered = true; 
                    }
                      if (selectedValues['question11'] === "1") {
                        // If radio button value is "1", only remarks field is mandatory
                        if (!remarks['question11']) {
                            newErrors["question11"] = "Please provide remarks.";
                        }
                        functionEntered = true; 
                    }

                    if (selectedValues['question12'] === "0") {
                        // If radio button value is "0", both image and remarks fields are mandatory
                        if (!images['question12']) {
                            newErrors["question12"] = "Please capture an image.";
                        }
                        if (!remarks['question12']) {
                            newErrors["question12"] = "Please provide remarks.";
                        }
                        functionEntered = true; 
                    }
                      if (selectedValues['question12'] === "1") {
                        // If radio button value is "1", only remarks field is mandatory
                        if (!remarks['question12']) {
                            newErrors["question12"] = "Please provide remarks.";
                        }
                        functionEntered = true; 
                    }
                    if (selectedValues['question13'] === "0") {
                        // If radio button value is "0", both image and remarks fields are mandatory
                        if (!images['question13']) {
                            newErrors["question13"] = "Please capture an image.";
                        }
                        if (!remarks['question13']) {
                            newErrors["question13"] = "Please provide remarks.";
                        }
                        functionEntered = true; 
                    }
                      if (selectedValues['question13'] === "1") {
                        // If radio button value is "1", only remarks field is mandatory
                        if (!remarks['question13']) {
                            newErrors["question13"] = "Please provide remarks.";
                        }
                        functionEntered = true; 
                    }
                    if (selectedValues['question14'] === "0") {
                        // If radio button value is "0", both image and remarks fields are mandatory
                        if (!images['question14']) {
                            newErrors["question14"] = "Please capture an image.";
                        }
                        if (!remarks['question14']) {
                            newErrors["question14"] = "Please provide remarks.";
                        }
                        functionEntered = true; 
                    }
                      if (selectedValues['question14'] === "1") {
                        // If radio button value is "1", only remarks field is mandatory
                        if (!remarks['question14']) {
                            newErrors["question14"] = "Please provide remarks.";
                        }
                        functionEntered = true; 
                    }

        

        
        setErrors(newErrors);

        // If there are errors, display Snackbar and prevent moving to the next step
        if (Object.keys(newErrors).length > 0) {
            setSnackbarMessage("Please fill the questions.");
            setSnackbarOpen(true);
        } 
        if (!functionEntered) {
            setSnackbarMessage("Please fill at least one field.");              
            setSnackbarOpen(true);
           // newErrors["general"] = "Please fill at least one field.";
        }
     
        else {
            if (selectedValues['question4'] === "0") {
                // If radio button value is "0", both image and remarks fields are mandatory
                if (!images['question4']) {
                    newErrors["question4"] = "Please capture an image.";
                }
                if (!remarks['question4']) {
                    newErrors["question4"] = "Please provide remarks.";
                }
            }
              if (selectedValues['question4'] === "1") {
                // If radio button value is "1", only remarks field is mandatory
                if (!remarks['question4']) {
                    newErrors["question4"] = "Please provide remarks.";
                }
            }
            if (selectedValues['question5'] === "0") {
                // If radio button value is "0", both image and remarks fields are mandatory
                if (!images['question5']) {
                    newErrors["question5"] = "Please capture an image.";
                }
                if (!remarks['question5']) {
                    newErrors["question5"] = "Please provide remarks.";
                }
            }
              if (selectedValues['question5'] === "1") {
                // If radio button value is "1", only remarks field is mandatory
                if (!remarks['question5']) {
                    newErrors["question5"] = "Please provide remarks.";
                }
            }
            if (selectedValues['question6'] === "0") {
                // If radio button value is "0", both image and remarks fields are mandatory
                if (!images['question6']) {
                    newErrors["question6"] = "Please capture an image.";
                }
                if (!remarks['question6']) {
                    newErrors["question6"] = "Please provide remarks.";
                }
            }
              if (selectedValues['question6'] === "1") {
                // If radio button value is "1", only remarks field is mandatory
                if (!remarks['question6']) {
                    newErrors["question6"] = "Please provide remarks.";
                }
            }
    
    
            if (selectedValues['question7'] === "0") {
                // If radio button value is "0", both image and remarks fields are mandatory
                if (!images['question7']) {
                    newErrors["question7"] = "Please capture an image.";
                }
                if (!remarks['question7']) {
                    newErrors["question7"] = "Please provide remarks.";
                }
            }
              if (selectedValues['question7'] === "1") {
                // If radio button value is "1", only remarks field is mandatory
                if (!remarks['question7']) {
                    newErrors["question7"] = "Please provide remarks.";
                }
            }
    
                if (selectedValues['question8'] === "0") {
                    // If radio button value is "0", both image and remarks fields are mandatory
                    if (!images['question8']) {
                        newErrors["question8"] = "Please capture an image.";
                    }
                    if (!remarks['question8']) {
                        newErrors["question8"] = "Please provide remarks.";
                    }
                }
                  if (selectedValues['question8'] === "1") {
                    // If radio button value is "1", only remarks field is mandatory
                    if (!remarks['question8']) {
                        newErrors["question8"] = "Please provide remarks.";
                    }
                }
                    if (selectedValues['question9'] === "0") {
                        // If radio button value is "0", both image and remarks fields are mandatory
                        if (!images['question9']) {
                            newErrors["question9"] = "Please capture an image.";
                        }
                        if (!remarks['question9']) {
                            newErrors["question9"] = "Please provide remarks.";
                        }
                    }
                      if (selectedValues['question9'] === "1") {
                        // If radio button value is "1", only remarks field is mandatory
                        if (!remarks['question9']) {
                            newErrors["question9"] = "Please provide remarks.";
                        }
                    }
                        if (selectedValues['question10'] === "0") {
                            // If radio button value is "0", both image and remarks fields are mandatory
                            if (!images['question10']) {
                                newErrors["question10"] = "Please capture an image.";
                            }
                            if (!remarks['question10']) {
                                newErrors["question10"] = "Please provide remarks.";
                            }
                        }
                          if (selectedValues['question10'] === "1") {
                            // If radio button value is "1", only remarks field is mandatory
                            if (!remarks['question10']) {
                                newErrors["question10"] = "Please provide remarks.";
                            }
                        }
    
                        if (selectedValues['question11'] === "0") {
                            // If radio button value is "0", both image and remarks fields are mandatory
                            if (!images['question11']) {
                                newErrors["question11"] = "Please capture an image.";
                            }
                            if (!remarks['question11']) {
                                newErrors["question11"] = "Please provide remarks.";
                            }
                        }
                          if (selectedValues['question11'] === "1") {
                            // If radio button value is "1", only remarks field is mandatory
                            if (!remarks['question11']) {
                                newErrors["question11"] = "Please provide remarks.";
                            }
                        }
    
                        if (selectedValues['question12'] === "0") {
                            // If radio button value is "0", both image and remarks fields are mandatory
                            if (!images['question12']) {
                                newErrors["question12"] = "Please capture an image.";
                            }
                            if (!remarks['question12']) {
                                newErrors["question12"] = "Please provide remarks.";
                            }
                        }
                          if (selectedValues['question12'] === "1") {
                            // If radio button value is "1", only remarks field is mandatory
                            if (!remarks['question12']) {
                                newErrors["question12"] = "Please provide remarks.";
                            }
                        }
                        if (selectedValues['question13'] === "0") {
                            // If radio button value is "0", both image and remarks fields are mandatory
                            if (!images['question13']) {
                                newErrors["question13"] = "Please capture an image.";
                            }
                            if (!remarks['question13']) {
                                newErrors["question13"] = "Please provide remarks.";
                            }
                        }
                          if (selectedValues['question13'] === "1") {
                            // If radio button value is "1", only remarks field is mandatory
                            if (!remarks['question13']) {
                                newErrors["question13"] = "Please provide remarks.";
                            }
                        }
                        if (selectedValues['question14'] === "0") {
                            // If radio button value is "0", both image and remarks fields are mandatory
                            if (!images['question14']) {
                                newErrors["question14"] = "Please capture an image.";
                            }
                            if (!remarks['question14']) {
                                newErrors["question14"] = "Please provide remarks.";
                            }
                        }
                          if (selectedValues['question14'] === "1") {
                            // If radio button value is "1", only remarks field is mandatory
                            if (!remarks['question14']) {
                                newErrors["question14"] = "Please provide remarks.";
                            }
                        }
    
            
            setErrors(newErrors);
    
            // If there are errors, display Snackbar and prevent moving to the next step
            if (Object.keys(newErrors).length > 0) {
                setSnackbarMessage("Please fill the questions.");
                setSnackbarOpen(true);
            } 
        else{
            setSaveflg(true);
            onSave(selectedValues, images, remarks);
        }    
         
        }
    }
      //  onSave(selectedValues, images, remarks);
      };
    return (


        <Box>

            {/* <Header pageTitle={assessmenteditflg ? "Edit Assessment" : "New Assessment"} isAuditPage={true} />
             */}
            {/* <Header pageTitle="New Assessment" isAuditPage={true} /> */}
            <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                {/* Question 4 */}
                <Card variant="outlined" style={{ marginBottom: '20px' }}>
                    <CardContent>
                        {/* <Typography variant="h6" style={{ textTransform: 'uppercase',fontSize: "16px",  fontWeight: '600', color: '#17428B', marginBottom: '10px' }}>1S: Sort(seiri)</Typography>
                <p style={{ marginBottom: '10px',fontSize: "10px",fontWeight: '500' }}>Tidiness, Organization. Refers To The Practice Of Sorting Through All The Tools, Materials, Etc., In The Work Area And Keeping Only Essential Items.</p> */}
                        <Typography variant="h5" style={{ textTransform: 'uppercase', fontSize: "14px", fontWeight: '500', color: '#17428B', marginBottom: '10px' }}>Work stations</Typography>
                        <div>
                            <p style={{ marginBottom: '10px', fontSize: "14px", fontWeight: '500' }}>4. All the work stations (work benches, desks) are in order: tools, materials and consumables are sorted and arranged near the point of use.</p>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                <p style={{ marginRight: '10px', fontSize: "14px", fontWeight: '500' }}>SCORE:</p>
                                <label>
                                    <input
                                        type="radio"
                                        name="radioButton4"
                                        value="0"
                                        checked={selectedValues['question4'] === "0"}
                                        // checked={formData.question1 === "0"}
                                        // onChange={(e) => setFormData({ ...formData, question1: e.target.value })}
                                        onChange={(e) => handleRadioChange('question4', e.target.value)}

                                    /> 0
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        name="radioButton4"
                                        value="1"
                                        checked={selectedValues['question4'] === "1"}
                                        // checked={formData.question1 === "1"}
                                        // onChange={(e) => setFormData({ ...formData, question1: e.target.value })}
                                        onChange={(e) => handleRadioChange('question4', e.target.value)}

                                    /> 1
                                </label>
                            </div>
                        </div>

                        {selectedValues.question4 === "0" && (
                            <div>

                                <div id="camera-container-question4" style={{ border: "1px dashed #e6e6e6", height: '160px',textAlign:'center', marginBottom: '20px', position: 'relative', borderRadius:'10px' }}>
                                    {!images['question4'] ? (
                                        <>
                                            {/* Camera button */}
                                            <input
                                                id="upload-image_question4"
                                                multiple
                                                type="file"
                                                style={{ display: "none" }}
                                                // onChange={setUploadedFile}
                                                ref={fileInputRef}
                                            />
                                            <button onClick={() => handleCapture('question4')} onChange={(e) => setFormData({ ...selectedValues, image4: e.target.value })} style={{ position: 'absolute', cursor: 'pointer', background: 'none', border: 'none', width: '100%', height: '100%',left:'0',top:'0' }}>
                                            </button>
                                            <LogoutIcon sx={{
                                                transform: 'rotate(-90deg)',
                                                color: '#C9C9CC',
                                                margin:'30px 0 10px'
                                            }} />
                                            <Typography style={{ textAlign: 'center', color: 'black', fontWeight: 500, fontSize: '16px' }}>Drag & drop images or <span style={{ color: '#17428B' }}>browse images</span></Typography>
                                            <Typography style={{ textAlign: 'center', color: '#4F4D55', fontSize: '12px', marginTop: '10px' }}>.xlsx - max file size 2MB</Typography>
                                        </>
                                    ) : (
                                        <>
                                            {/* Display captured image */}
                                            <img src={images['question4']} onChange={(e) => setFormData({ ...selectedValues, image4: e.target.value })} alt="Captured" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                                            <div style={{ position: 'absolute', bottom: '10px', left: '50%', transform: 'translateX(-50%)', zIndex: 1 }}>
                                                {/* Retake button */}
                                                <Button variant="contained" onClick={() => handleRetake('question4')}>Retake</Button>
                                            </div>
                                        </>
                                    )}</div>
                            </div>
                        )}



                        <div>
                            <p style={{ marginBottom: '10px', fontSize: "14px", fontWeight: '500' }}>Remarks for Improvement</p>
                            {/* <textarea value={remarks["question4"] || ''} onChange={(e) => handleChange('remarks', 'question4', e.target.value)} placeholder="Type here" style={{ width: '100%', padding: '8px', border: '1px solid #E6E6E6', borderRadius: '4px' }} /> */}
                            <textarea value={remarks["question4"] || ''} onChange={(e) => handleRemarksChange('question4', e.target.value)} placeholder="Type here" style={{ width: '100%', padding: '8px', outline: 'none', border: '1px solid #E6E6E6', borderRadius: '4px', resize: "none" }} />
                        </div>
                        {errors["question4"] && <Typography style={{ color: 'red' }}>{errors["question4"]}</Typography>}
                    </CardContent>
                </Card>
                {/* Question 5 */}
      <Card variant="outlined" style={{ marginBottom: '20px' }}>
                    <CardContent>
                        {/* <Typography variant="h6" style={{ textTransform: 'uppercase',fontSize: "16px",  fontWeight: '600', color: '#17428B', marginBottom: '10px' }}>1S: Sort(seiri)</Typography>
                <p style={{ marginBottom: '10px',fontSize: "10px",fontWeight: '500' }}>Tidiness, Organization. Refers To The Practice Of Sorting Through All The Tools, Materials, Etc., In The Work Area And Keeping Only Essential Items.</p> */}
                        <Typography variant="h5" style={{ textTransform: 'uppercase', fontSize: "14px", fontWeight: '500', color: '#17428B', marginBottom: '10px' }}>Storage Labelling</Typography>
                        <div>
                            <p style={{ marginBottom: '10px', fontSize: "14px", fontWeight: '500' }}>5. Storage areas and locations on racks and in cupboards are identified by readable labels.</p>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                <p style={{ marginRight: '10px', fontSize: "14px", fontWeight: '500' }}>SCORE:</p>
                                <label>
                                    <input
                                        type="radio"
                                        name="radioButton5"
                                        value="0"
                                        checked={selectedValues['question5'] === "0"}
                                        // checked={formData.question1 === "0"}
                                        // onChange={(e) => setFormData({ ...formData, question1: e.target.value })}
                                        onChange={(e) => handleRadioChange('question5', e.target.value)}

                                    /> 0
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        name="radioButton5"
                                        value="1"
                                        checked={selectedValues['question5'] === "1"}
                                        // checked={formData.question1 === "1"}
                                        // onChange={(e) => setFormData({ ...formData, question1: e.target.value })}
                                        onChange={(e) => handleRadioChange('question5', e.target.value)}

                                    /> 1
                                </label>
                            </div>
                        </div>

                        {selectedValues.question5 === "0" && (
                            <div>

                                <div id="camera-container-question5" style={{ border: "1px dashed #e6e6e6", height: '160px',textAlign:'center', marginBottom: '20px', position: 'relative', borderRadius:'10px' }}>
                                    {!images['question5'] ? (
                                        <>
                                            {/* Camera button */}
                                            <input
                                                id="upload-image_question5"
                                                multiple
                                                type="file"
                                                style={{ display: "none" }}
                                                // onChange={setUploadedFile}
                                                ref={fileInputRef}
                                            />
                                            <button onClick={() => handleCapture('question5')} onChange={(e) => setFormData({ ...selectedValues, image5: e.target.value })} style={{ position: 'absolute', cursor: 'pointer', background: 'none', border: 'none', width: '100%', height: '100%',left:'0',top:'0' }}>
                                            </button>
                                            <LogoutIcon sx={{
                                                transform: 'rotate(-90deg)',
                                                color: '#C9C9CC',
                                                margin:'30px 0 10px'
                                            }} />
                                            <Typography style={{ textAlign: 'center', color: 'black', fontWeight: 500, fontSize: '16px' }}>Drag & drop images or <span style={{ color: '#17428B' }}>browse images</span></Typography>
                                            <Typography style={{ textAlign: 'center', color: '#4F4D55', fontSize: '12px', marginTop: '10px' }}>.xlsx - max file size 2MB</Typography>
                                        </>
                                    ) : (
                                        <>
                                            {/* Display captured image */}
                                            <img src={images['question5']} onChange={(e) => setFormData({ ...selectedValues, image5: e.target.value })} alt="Captured" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                                            <div style={{ position: 'absolute', bottom: '10px', left: '50%', transform: 'translateX(-50%)', zIndex: 1 }}>
                                                {/* Retake button */}
                                                <Button variant="contained" onClick={() => handleRetake('question5')}>Retake</Button>
                                            </div>
                                        </>
                                    )}</div>
                            </div>
                        )}



                        <div>
                            <p style={{ marginBottom: '10px', fontSize: "14px", fontWeight: '500' }}>Remarks for Improvement</p>
                            {/* <textarea value={remarks["question4"] || ''} onChange={(e) => handleChange('remarks', 'question4', e.target.value)} placeholder="Type here" style={{ width: '100%', padding: '8px', border: '1px solid #E6E6E6', borderRadius: '4px' }} /> */}
                            <textarea value={remarks["question5"] || ''} onChange={(e) => handleRemarksChange('question5', e.target.value)} placeholder="Type here" style={{ width: '100%', padding: '8px', outline: 'none', border: '1px solid #E6E6E6', borderRadius: '4px', resize: "none" }} />
                        </div>
                        {errors["question5"] && <Typography style={{ color: 'red' }}>{errors["question5"]}</Typography>}
                    </CardContent>
                </Card>

                {/* Question 6 */}

                <Card variant="outlined" style={{ marginBottom: '20px' }}>
                    <CardContent>
                        {/* <Typography variant="h6" style={{ textTransform: 'uppercase',fontSize: "16px",  fontWeight: '600', color: '#17428B', marginBottom: '10px' }}>1S: Sort(seiri)</Typography>
                <p style={{ marginBottom: '10px',fontSize: "10px",fontWeight: '500' }}>Tidiness, Organization. Refers To The Practice Of Sorting Through All The Tools, Materials, Etc., In The Work Area And Keeping Only Essential Items.</p> */}
                        <Typography variant="h5" style={{ textTransform: 'uppercase', fontSize: "14px", fontWeight: '500', color: '#17428B', marginBottom: '10px' }}>Floor Marking</Typography>
                        <div>
                            <p style={{ marginBottom: '10px', fontSize: "14px", fontWeight: '500' }}>6. For any item purposely stored on the ground, the storage location must be indicated by a visual marking.</p>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                <p style={{ marginRight: '10px', fontSize: "14px", fontWeight: '500' }}>SCORE:</p>
                                <label>
                                    <input
                                        type="radio"
                                        name="radioButton6"
                                        value="0"
                                        checked={selectedValues['question6'] === "0"}
                                        // checked={formData.question1 === "0"}
                                        // onChange={(e) => setFormData({ ...formData, question1: e.target.value })}
                                        onChange={(e) => handleRadioChange('question6', e.target.value)}

                                    /> 0
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        name="radioButton6"
                                        value="1"
                                        checked={selectedValues['question6'] === "1"}
                                        // checked={formData.question1 === "1"}
                                        // onChange={(e) => setFormData({ ...formData, question1: e.target.value })}
                                        onChange={(e) => handleRadioChange('question6', e.target.value)}

                                    /> 1
                                </label>
                            </div>
                        </div>

                        {selectedValues.question6 === "0" && (
                            <div>

                                <div id="camera-container-question6" style={{ border: "1px dashed #e6e6e6", height: '160px',textAlign:'center', marginBottom: '20px', position: 'relative', borderRadius:'10px' }}>
                                    {!images['question6'] ? (
                                        <>
                                            {/* Camera button */}
                                            <input
                                                id="upload-image_question6"
                                                multiple
                                                type="file"
                                                style={{ display: "none" }}
                                                // onChange={setUploadedFile}
                                                ref={fileInputRef}
                                            />
                                            <button onClick={() => handleCapture('question6')} onChange={(e) => setFormData({ ...selectedValues, image6: e.target.value })} style={{ position: 'absolute', cursor: 'pointer', background: 'none', border: 'none', width: '100%', height: '100%',left:'0',top:'0' }}>
                                            </button>
                                            <LogoutIcon sx={{
                                                transform: 'rotate(-90deg)',
                                                color: '#C9C9CC',
                                                margin:'30px 0 10px'
                                            }} />
                                            <Typography style={{ textAlign: 'center', color: 'black', fontWeight: 500, fontSize: '16px' }}>Drag & drop images or <span style={{ color: '#17428B' }}>browse images</span></Typography>
                                            <Typography style={{ textAlign: 'center', color: '#4F4D55', fontSize: '12px', marginTop: '10px' }}>.xlsx - max file size 2MB</Typography>
                                        </>
                                    ) : (
                                        <>
                                            {/* Display captured image */}
                                            <img src={images['question6']} onChange={(e) => setFormData({ ...selectedValues, image6: e.target.value })} alt="Captured" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                                            <div style={{ position: 'absolute', bottom: '10px', left: '50%', transform: 'translateX(-50%)', zIndex: 1 }}>
                                                {/* Retake button */}
                                                <Button variant="contained" onClick={() => handleRetake('question6')}>Retake</Button>
                                            </div>
                                        </>
                                    )}</div>
                            </div>
                        )}



                        <div>
                            <p style={{ marginBottom: '10px', fontSize: "14px", fontWeight: '500' }}>Remarks for Improvement</p>
                            {/* <textarea value={remarks["question4"] || ''} onChange={(e) => handleChange('remarks', 'question4', e.target.value)} placeholder="Type here" style={{ width: '100%', padding: '8px', border: '1px solid #E6E6E6', borderRadius: '4px' }} /> */}
                            <textarea value={remarks["question6"] || ''} onChange={(e) => handleRemarksChange('question6', e.target.value)} placeholder="Type here" style={{ width: '100%', padding: '8px', outline: 'none', border: '1px solid #E6E6E6', borderRadius: '4px', resize: "none" }} />
                        </div>
                        {errors["question6"] && <Typography style={{ color: 'red' }}>{errors["question6"]}</Typography>}
                    </CardContent>
                </Card>


{/* {} */}
<Card variant="outlined" style={{ marginBottom: '20px' }}>
  <CardContent>
    {/* Title */}
    <Typography variant="h5" style={{ textTransform: 'uppercase', fontSize: "14px", fontWeight: '500', color: '#17428B', marginBottom: '10px' }}>
      Ergonomics of Location
    </Typography>

    {/* Question */}
    <div style={{ marginBottom: '20px' }}>
      <p style={{ marginBottom: '10px', fontSize: "14px", fontWeight: '500' }}>
        7. All storage locations are suitable for the materials, tools, dustbins, consumables stored.
      </p>

      {/* Radio buttons */}
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
        <p style={{ marginRight: '10px', fontSize: "14px", fontWeight: '500' }}>SCORE:</p>
        <label>
          <input
            type="radio"
            name="radioButton7"
            value="0"
            checked={selectedValues['question7'] === "0"}
            onChange={(e) => handleRadioChange('question7', e.target.value)}
          /> 0
        </label>
        <label>
          <input
            type="radio"
            name="radioButton7"
            value="1"
            checked={selectedValues['question7'] === "1"}
            onChange={(e) => handleRadioChange('question7', e.target.value)}
          /> 1
        </label>
      </div>
    </div>

    {/* Upload image */}
    {selectedValues.question7 === "0" && (
      <div>
        <div id="camera-container-question7" style={{ border: "1px dashed #e6e6e6", height: '160px', textAlign: 'center', marginBottom: '20px', position: 'relative', borderRadius: '10px' }}>
          {!images['question7'] ? (
            <>
              {/* Camera button */}
              <input
                id="upload-image_question7"
                multiple
                type="file"
                style={{ display: "none" }}
                ref={fileInputRef}
              />
              <button onClick={() => handleCapture('question7')} onChange={(e) => setFormData({ ...selectedValues, image7: e.target.value })} style={{ position: 'absolute', cursor: 'pointer', background: 'none', border: 'none', width: '100%', height: '100%', left: '0', top: '0' }}>
                <LogoutIcon sx={{ transform: 'rotate(-90deg)', color: '#C9C9CC', margin: '30px 0 10px' }} />
                <Typography style={{ textAlign: 'center', color: 'black', fontWeight: 500, fontSize: '16px' }}>Drag & drop images or <span style={{ color: '#17428B' }}>browse images</span></Typography>
                <Typography style={{ textAlign: 'center', color: '#4F4D55', fontSize: '12px', marginTop: '10px' }}>.xlsx - max file size 2MB</Typography>
              </button>
            </>
          ) : (
            <>
              {/* Display captured image */}
              <img src={images['question7']} onChange={(e) => setFormData({ ...selectedValues, image7: e.target.value })} alt="Captured" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
              <div style={{ position: 'absolute', bottom: '10px', left: '50%', transform: 'translateX(-50%)', zIndex: 1 }}>
                {/* Retake button */}
                <Button variant="contained" onClick={() => handleRetake('question7')}>Retake</Button>
              </div>
            </>
          )}
        </div>
      </div>
    )}

    {/* Remarks for Improvement */}
    <div>
      <p style={{ marginBottom: '10px', fontSize: "14px", fontWeight: '500' }}>Remarks for Improvement</p>
      <textarea 
        value={remarks["question7"] || ''} 
        onChange={(e) => handleRemarksChange('question7', e.target.value)} 
        placeholder="Type here" 
        style={{ width: '100%', padding: '8px', outline: 'none', border: '1px solid #E6E6E6', borderRadius: '4px', resize: "none" }} 
      />
    </div>

    {/* Error message */}
    {errors["question7"] && <Typography style={{ color: 'red' }}>{errors["question7"]}</Typography>}
  </CardContent>
</Card>


<Card variant="outlined" style={{ marginBottom: '20px' }}>
  <CardContent>
    {/* Title */}
    <Typography variant="h5" style={{ textTransform: 'uppercase', fontSize: "14px", fontWeight: '500', color: '#17428B', marginBottom: '10px' }}>
      Accessibility
    </Typography>

    {/* Question */}
    <div style={{ marginBottom: '20px' }}>
      <p style={{ marginBottom: '10px', fontSize: "14px", fontWeight: '500' }}>
        8. All materials, tools, dustbins, consumables and notice boards are easily accessible.
      </p>

      {/* Radio buttons */}
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
        <p style={{ marginRight: '10px', fontSize: "14px", fontWeight: '500' }}>SCORE:</p>
        <label>
          <input
            type="radio"
            name="radioButton8"
            value="0"
            checked={selectedValues['question8'] === "0"}
            onChange={(e) => handleRadioChange('question8', e.target.value)}
          /> 0
        </label>
        <label>
          <input
            type="radio"
            name="radioButton8"
            value="1"
            checked={selectedValues['question8'] === "1"}
            onChange={(e) => handleRadioChange('question8', e.target.value)}
          /> 1
        </label>
      </div>
    </div>

    {/* Upload image */}
    {selectedValues.question8 === "0" && (
      <div>
        <div id="camera-container-question8" style={{ border: "1px dashed #e6e6e6", height: '160px', textAlign: 'center', marginBottom: '20px', position: 'relative', borderRadius: '10px' }}>
          {!images['question8'] ? (
            <>
              {/* Camera button */}
              <input
                id="upload-image_question8"
                multiple
                type="file"
                style={{ display: "none" }}
                ref={fileInputRef}
              />
              <button onClick={() => handleCapture('question8')} onChange={(e) => setFormData({ ...selectedValues, image8: e.target.value })} style={{ position: 'absolute', cursor: 'pointer', background: 'none', border: 'none', width: '100%', height: '100%', left: '0', top: '0' }}>
                <LogoutIcon sx={{ transform: 'rotate(-90deg)', color: '#C9C9CC', margin: '30px 0 10px' }} />
                <Typography style={{ textAlign: 'center', color: 'black', fontWeight: 500, fontSize: '16px' }}>Drag & drop images or <span style={{ color: '#17428B' }}>browse images</span></Typography>
                <Typography style={{ textAlign: 'center', color: '#4F4D55', fontSize: '12px', marginTop: '10px' }}>.xlsx - max file size 2MB</Typography>
              </button>
            </>
          ) : (
            <>
              {/* Display captured image */}
              <img src={images['question8']} onChange={(e) => setFormData({ ...selectedValues, image8: e.target.value })} alt="Captured" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
              <div style={{ position: 'absolute', bottom: '10px', left: '50%', transform: 'translateX(-50%)', zIndex: 1 }}>
                {/* Retake button */}
                <Button variant="contained" onClick={() => handleRetake('question8')}>Retake</Button>
              </div>
            </>
          )}
        </div>
      </div>
    )}

    {/* Remarks for Improvement */}
    <div>
      <p style={{ marginBottom: '10px', fontSize: "14px", fontWeight: '500' }}>Remarks for Improvement</p>
      <textarea 
        value={remarks["question8"] || ''} 
        onChange={(e) => handleRemarksChange('question8', e.target.value)} 
        placeholder="Type here" 
        style={{ width: '100%', padding: '8px', outline: 'none', border: '1px solid #E6E6E6', borderRadius: '4px', resize: "none" }} 
      />
    </div>

    {/* Error message */}
    {errors["question8"] && <Typography style={{ color: 'red' }}>{errors["question8"]}</Typography>}
  </CardContent>
</Card>

<Card variant="outlined" style={{ marginBottom: '20px' }}>
  <CardContent>
    {/* Title */}
    <Typography variant="h5" style={{ textTransform: 'uppercase', fontSize: "14px", fontWeight: '500', color: '#17428B', marginBottom: '10px' }}>
      Wip and Finish Goods
    </Typography>

    {/* Question */}
    <div style={{ marginBottom: '20px' }}>
      <p style={{ marginBottom: '10px', fontSize: "14px", fontWeight: '500' }}>
        9. The stored Work In Progress and Finished Goods are well ordered and locations clearly identified.
      </p>

      {/* Radio buttons */}
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
        <p style={{ marginRight: '10px', fontSize: "14px", fontWeight: '500' }}>SCORE:</p>
        <label>
          <input
            type="radio"
            name="radioButton9"
            value="0"
            checked={selectedValues['question9'] === "0"}
            onChange={(e) => handleRadioChange('question9', e.target.value)}
          /> 0
        </label>
        <label>
          <input
            type="radio"
            name="radioButton9"
            value="1"
            checked={selectedValues['question9'] === "1"}
            onChange={(e) => handleRadioChange('question9', e.target.value)}
          /> 1
        </label>
      </div>
    </div>

    {/* Upload image */}
    {selectedValues.question9 === "0" && (
      <div>
        <div id="camera-container-question9" style={{ border: "1px dashed #e6e6e6", height: '160px', textAlign: 'center', marginBottom: '20px', position: 'relative', borderRadius: '10px' }}>
          {!images['question9'] ? (
            <>
              {/* Camera button */}
              <input
                id="upload-image_question9"
                multiple
                type="file"
                style={{ display: "none" }}
                ref={fileInputRef}
              />
              <button onClick={() => handleCapture('question9')} onChange={(e) => setFormData({ ...selectedValues, image9: e.target.value })} style={{ position: 'absolute', cursor: 'pointer', background: 'none', border: 'none', width: '100%', height: '100%', left: '0', top: '0' }}>
                <LogoutIcon sx={{ transform: 'rotate(-90deg)', color: '#C9C9CC', margin: '30px 0 10px' }} />
                <Typography style={{ textAlign: 'center', color: 'black', fontWeight: 500, fontSize: '16px' }}>Drag & drop images or <span style={{ color: '#17428B' }}>browse images</span></Typography>
                <Typography style={{ textAlign: 'center', color: '#4F4D55', fontSize: '12px', marginTop: '10px' }}>.xlsx - max file size 2MB</Typography>
              </button>
            </>
          ) : (
            <>
              {/* Display captured image */}
              <img src={images['question9']} onChange={(e) => setFormData({ ...selectedValues, image9: e.target.value })} alt="Captured" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
              <div style={{ position: 'absolute', bottom: '10px', left: '50%', transform: 'translateX(-50%)', zIndex: 1 }}>
                {/* Retake button */}
                <Button variant="contained" onClick={() => handleRetake('question9')}>Retake</Button>
              </div>
            </>
          )}
        </div>
      </div>
    )}

    {/* Remarks for Improvement */}
    <div>
      <p style={{ marginBottom: '10px', fontSize: "14px", fontWeight: '500' }}>Remarks for Improvement</p>
      <textarea 
        value={remarks["question9"] || ''} 
        onChange={(e) => handleRemarksChange('question9', e.target.value)} 
        placeholder="Type here" 
        style={{ width: '100%', padding: '8px', outline: 'none', border: '1px solid #E6E6E6', borderRadius: '4px', resize: "none" }} 
      />
    </div>

    {/* Error message */}
    {errors["question9"] && <Typography style={{ color: 'red' }}>{errors["question9"]}</Typography>}
  </CardContent>
</Card>


<Card variant="outlined" style={{ marginBottom: '20px' }}>
  <CardContent>
    {/* Title */}
    <Typography variant="h5" style={{ textTransform: 'uppercase', fontSize: "14px", fontWeight: '500', color: '#17428B', marginBottom: '10px' }}>
      WalkWays
    </Typography>

    {/* Question */}
    <div style={{ marginBottom: '20px' }}>
      <p style={{ marginBottom: '10px', fontSize: "14px", fontWeight: '500' }}>
        10. The walkways are delimited and easily identifiable. The delimitation lines are visible, clean and in good condition. Free from obstructions - also applicable for offices.
      </p>

      {/* Radio buttons */}
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
        <p style={{ marginRight: '10px', fontSize: "14px", fontWeight: '500' }}>SCORE:</p>
        <label>
          <input
            type="radio"
            name="radioButton10"
            value="0"
            checked={selectedValues['question10'] === "0"}
            onChange={(e) => handleRadioChange('question10', e.target.value)}
          /> 0
        </label>
        <label>
          <input
            type="radio"
            name="radioButton10"
            value="1"
            checked={selectedValues['question10'] === "1"}
            onChange={(e) => handleRadioChange('question10', e.target.value)}
          /> 1
        </label>
      </div>
    </div>

    {/* Upload image */}
    {selectedValues.question10 === "0" && (
      <div>
        <div id="camera-container-question10" style={{ border: "1px dashed #e6e6e6", height: '160px', textAlign: 'center', marginBottom: '20px', position: 'relative', borderRadius: '10px' }}>
          {!images['question10'] ? (
            <>
              {/* Camera button */}
              <input
                id="upload-image_question10"
                multiple
                type="file"
                style={{ display: "none" }}
                ref={fileInputRef}
              />
              <button onClick={() => handleCapture('question10')} onChange={(e) => setFormData({ ...selectedValues, image10: e.target.value })} style={{ position: 'absolute', cursor: 'pointer', background: 'none', border: 'none', width: '100%', height: '100%', left: '0', top: '0' }}>
                <LogoutIcon sx={{ transform: 'rotate(-90deg)', color: '#C9C9CC', margin: '30px 0 10px' }} />
                <Typography style={{ textAlign: 'center', color: 'black', fontWeight: 500, fontSize: '16px' }}>Drag & drop images or <span style={{ color: '#17428B' }}>browse images</span></Typography>
                <Typography style={{ textAlign: 'center', color: '#4F4D55', fontSize: '12px', marginTop: '10px' }}>.xlsx - max file size 2MB</Typography>
              </button>
            </>
          ) : (
            <>
              {/* Display captured image */}
              <img src={images['question10']} onChange={(e) => setFormData({ ...selectedValues, image10: e.target.value })} alt="Captured" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
              <div style={{ position: 'absolute', bottom: '10px', left: '50%', transform: 'translateX(-50%)', zIndex: 1 }}>
                {/* Retake button */}
                <Button variant="contained" onClick={() => handleRetake('question10')}>Retake</Button>
              </div>
            </>
          )}
        </div>
      </div>
    )}

    {/* Remarks for Improvement */}
    <div>
      <p style={{ marginBottom: '10px', fontSize: "14px", fontWeight: '500' }}>Remarks for Improvement</p>
      <textarea 
        value={remarks["question10"] || ''} 
        onChange={(e) => handleRemarksChange('question10', e.target.value)} 
        placeholder="Type here" 
        style={{ width: '100%', padding: '8px', outline: 'none', border: '1px solid #E6E6E6', borderRadius: '4px', resize: "none" }} 
      />
    </div>

    {/* Error message */}
    {errors["question10"] && <Typography style={{ color: 'red' }}>{errors["question10"]}</Typography>}
  </CardContent>
</Card>

<Card variant="outlined" style={{ marginBottom: '20px' }}>
  <CardContent>
    {/* Title */}
    <Typography variant="h5" style={{ textTransform: 'uppercase', fontSize: "14px", fontWeight: '500', color: '#17428B', marginBottom: '10px' }}>
      Cleaning Equipment
    </Typography>

    {/* Question */}
    <div style={{ marginBottom: '20px' }}>
      <p style={{ marginBottom: '10px', fontSize: "14px", fontWeight: '500' }}>
        11. Cleaning equipment, shovels, brushes, vacuum cleaner etc. is available, accessible and its storage clearly marked.
      </p>

      {/* Radio buttons */}
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
        <p style={{ marginRight: '10px', fontSize: "14px", fontWeight: '500' }}>SCORE:</p>
        <label>
          <input
            type="radio"
            name="radioButton11"
            value="0"
            checked={selectedValues['question11'] === "0"}
            onChange={(e) => handleRadioChange('question11', e.target.value)}
          /> 0
        </label>
        <label>
          <input
            type="radio"
            name="radioButton11"
            value="1"
            checked={selectedValues['question11'] === "1"}
            onChange={(e) => handleRadioChange('question11', e.target.value)}
          /> 1
        </label>
      </div>
    </div>

    {/* Upload image */}
    {selectedValues.question11 === "0" && (
      <div>
        <div id="camera-container-question11" style={{ border: "1px dashed #e6e6e6", height: '160px', textAlign: 'center', marginBottom: '20px', position: 'relative', borderRadius: '10px' }}>
          {!images['question11'] ? (
            <>
              {/* Camera button */}
              <input
                id="upload-image_question11"
                multiple
                type="file"
                style={{ display: "none" }}
                ref={fileInputRef}
              />
              <button onClick={() => handleCapture('question11')} onChange={(e) => setFormData({ ...selectedValues, image11: e.target.value })} style={{ position: 'absolute', cursor: 'pointer', background: 'none', border: 'none', width: '100%', height: '100%', left: '0', top: '0' }}>
                <LogoutIcon sx={{ transform: 'rotate(-90deg)', color: '#C9C9CC', margin: '30px 0 10px' }} />
                <Typography style={{ textAlign: 'center', color: 'black', fontWeight: 500, fontSize: '16px' }}>Drag & drop images or <span style={{ color: '#17428B' }}>browse images</span></Typography>
                <Typography style={{ textAlign: 'center', color: '#4F4D55', fontSize: '12px', marginTop: '10px' }}>.xlsx - max file size 2MB</Typography>
              </button>
            </>
          ) : (
            <>
              {/* Display captured image */}
              <img src={images['question11']} onChange={(e) => setFormData({ ...selectedValues, image11: e.target.value })} alt="Captured" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
              <div style={{ position: 'absolute', bottom: '10px', left: '50%', transform: 'translateX(-50%)', zIndex: 1 }}>
                {/* Retake button */}
                <Button variant="contained" onClick={() => handleRetake('question11')}>Retake</Button>
              </div>
            </>
          )}
        </div>
      </div>
    )}

    {/* Remarks for Improvement */}
    <div>
      <p style={{ marginBottom: '10px', fontSize: "14px", fontWeight: '500' }}>Remarks for Improvement</p>
      <textarea 
        value={remarks["question11"] || ''} 
        onChange={(e) => handleRemarksChange('question11', e.target.value)} 
        placeholder="Type here" 
        style={{ width: '100%', padding: '8px', outline: 'none', border: '1px solid #E6E6E6', borderRadius: '4px', resize: "none" }} 
      />
    </div>

    {/* Error message */}
    {errors["question11"] && <Typography style={{ color: 'red' }}>{errors["question11"]}</Typography>}
  </CardContent>
</Card>


<Card variant="outlined" style={{ marginBottom: '20px' }}>
  <CardContent>
    {/* Title */}
    <Typography variant="h5" style={{ textTransform: 'uppercase', fontSize: "14px", fontWeight: '500', color: '#17428B', marginBottom: '10px' }}>
      Waste Segregation
    </Typography>

    {/* Question */}
    <div style={{ marginBottom: '20px' }}>
      <p style={{ marginBottom: '10px', fontSize: "14px", fontWeight: '500' }}>
        12. Standardised colour coding for waste segregation. Waste (glass, paper, plastic, metal, wood etc) is sorted into containers in clearly identified areas. The containers are in their dedicated place No cross contamination on waste.
      </p>
      {/* Radio buttons */}
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
        <p style={{ marginRight: '10px', fontSize: "14px", fontWeight: '500' }}>SCORE:</p>
        <label>
          <input
            type="radio"
            name="radioButton12"
            value="0"
            checked={selectedValues['question12'] === "0"}
            onChange={(e) => handleRadioChange('question12', e.target.value)}
          /> 0
        </label>
        <label>
          <input
            type="radio"
            name="radioButton12"
            value="1"
            checked={selectedValues['question12'] === "1"}
            onChange={(e) => handleRadioChange('question12', e.target.value)}
          /> 1
        </label>
      </div>
    </div>

    {/* Upload image */}
    {selectedValues.question12 === "0" && (
      <div>
        <div id="camera-container-question12" style={{ border: "1px dashed #e6e6e6", height: '160px', textAlign: 'center', marginBottom: '20px', position: 'relative', borderRadius: '10px' }}>
          {!images['question12'] ? (
            <>
              {/* Camera button */}
              <input
                id="upload-image_question12"
                multiple
                type="file"
                style={{ display: "none" }}
                ref={fileInputRef}
              />
              <button onClick={() => handleCapture('question12')} onChange={(e) => setFormData({ ...selectedValues, image12: e.target.value })} style={{ position: 'absolute', cursor: 'pointer', background: 'none', border: 'none', width: '100%', height: '100%', left: '0', top: '0' }}>
                <LogoutIcon sx={{ transform: 'rotate(-90deg)', color: '#C9C9CC', margin: '30px 0 10px' }} />
                <Typography style={{ textAlign: 'center', color: 'black', fontWeight: 500, fontSize: '16px' }}>Drag & drop images or <span style={{ color: '#17428B' }}>browse images</span></Typography>
                <Typography style={{ textAlign: 'center', color: '#4F4D55', fontSize: '12px', marginTop: '10px' }}>.xlsx - max file size 2MB</Typography>
              </button>
            </>
          ) : (
            <>
              {/* Display captured image */}
              <img src={images['question12']} onChange={(e) => setFormData({ ...selectedValues, image12: e.target.value })} alt="Captured" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
              <div style={{ position: 'absolute', bottom: '10px', left: '50%', transform: 'translateX(-50%)', zIndex: 1 }}>
                {/* Retake button */}
                <Button variant="contained" onClick={() => handleRetake('question12')}>Retake</Button>
              </div>
            </>
          )}
        </div>
      </div>
    )}

    {/* Remarks for Improvement */}
    <div>
      <p style={{ marginBottom: '10px', fontSize: "14px", fontWeight: '500' }}>Remarks for Improvement</p>
      <textarea 
        value={remarks["question12"] || ''} 
        onChange={(e) => handleRemarksChange('question12', e.target.value)} 
        placeholder="Type here" 
        style={{ width: '100%', padding: '8px', outline: 'none', border: '1px solid #E6E6E6', borderRadius: '4px', resize: "none" }} 
      />
    </div>

    {/* Error message */}
    {errors["question12"] && <Typography style={{ color: 'red' }}>{errors["question12"]}</Typography>}
  </CardContent>
</Card>

<Card variant="outlined" style={{ marginBottom: '20px' }}>
  <CardContent>
    {/* Title */}
    <Typography variant="h5" style={{ textTransform: 'uppercase', fontSize: "14px", fontWeight: '500', color: '#17428B', marginBottom: '10px' }}>
      Notice Boards
    </Typography>

    {/* Question */}
    <div style={{ marginBottom: '20px' }}>
      <p style={{ marginBottom: '10px', fontSize: "14px", fontWeight: '500' }}>
        13. Display and Information boards (quality, safety, environment, production, 5S, TPM...) are up to date, clean, readable and as per the standard. A date of update or equivalent must appear on the document - no torn or dirty documents, no old papers.
      </p>
      {/* Radio buttons */}
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
        <p style={{ marginRight: '10px', fontSize: "14px", fontWeight: '500' }}>SCORE:</p>
        <label>
          <input
            type="radio"
            name="radioButton13"
            value="0"
            checked={selectedValues['question13'] === "0"}
            onChange={(e) => handleRadioChange('question13', e.target.value)}
          /> 0
        </label>
        <label>
          <input
            type="radio"
            name="radioButton13"
            value="1"
            checked={selectedValues['question13'] === "1"}
            onChange={(e) => handleRadioChange('question13', e.target.value)}
          /> 1
        </label>
      </div>
    </div>

    {/* Upload image */}
    {selectedValues.question13 === "0" && (
      <div>
        <div id="camera-container-question13" style={{ border: "1px dashed #e6e6e6", height: '160px', textAlign: 'center', marginBottom: '20px', position: 'relative', borderRadius: '10px' }}>
          {!images['question13'] ? (
            <>
              {/* Camera button */}
              <input
                id="upload-image_question13"
                multiple
                type="file"
                style={{ display: "none" }}
                ref={fileInputRef}
              />
              <button onClick={() => handleCapture('question13')} onChange={(e) => setFormData({ ...selectedValues, image13: e.target.value })} style={{ position: 'absolute', cursor: 'pointer', background: 'none', border: 'none', width: '100%', height: '100%', left: '0', top: '0' }}>
                <LogoutIcon sx={{ transform: 'rotate(-90deg)', color: '#C9C9CC', margin: '30px 0 10px' }} />
                <Typography style={{ textAlign: 'center', color: 'black', fontWeight: 500, fontSize: '16px' }}>Drag & drop images or <span style={{ color: '#17428B' }}>browse images</span></Typography>
                <Typography style={{ textAlign: 'center', color: '#4F4D55', fontSize: '12px', marginTop: '10px' }}>.xlsx - max file size 2MB</Typography>
              </button>
            </>
          ) : (
            <>
              {/* Display captured image */}
              <img src={images['question13']} onChange={(e) => setFormData({ ...selectedValues, image13: e.target.value })} alt="Captured" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
              <div style={{ position: 'absolute', bottom: '10px', left: '50%', transform: 'translateX(-50%)', zIndex: 1 }}>
                {/* Retake button */}
                <Button variant="contained" onClick={() => handleRetake('question13')}>Retake</Button>
              </div>
            </>
          )}
        </div>
      </div>
    )}

    {/* Remarks for Improvement */}
    <div>
      <p style={{ marginBottom: '10px', fontSize: "14px", fontWeight: '500' }}>Remarks for Improvement</p>
      <textarea 
        value={remarks["question13"] || ''} 
        onChange={(e) => handleRemarksChange('question13', e.target.value)} 
        placeholder="Type here" 
        style={{ width: '100%', padding: '8px', outline: 'none', border: '1px solid #E6E6E6', borderRadius: '4px', resize: "none" }} 
      />
    </div>

    {/* Error message */}
    {errors["question13"] && <Typography style={{ color: 'red' }}>{errors["question13"]}</Typography>}
  </CardContent>
</Card>

<Card variant="outlined" style={{ marginBottom: '20px' }}>
  <CardContent>
    {/* Title */}
    <Typography variant="h5" style={{ textTransform: 'uppercase', fontSize: "14px", fontWeight: '500', color: '#17428B', marginBottom: '10px' }}>
      Documents availability
    </Typography>

    {/* Question */}
    <div style={{ marginBottom: '20px' }}>
      <p style={{ marginBottom: '10px', fontSize: "14px", fontWeight: '500' }}>
        14. General use documents are identified and organised for ease of use.
      </p>

      {/* Radio buttons */}
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
        <p style={{ marginRight: '10px', fontSize: "14px", fontWeight: '500' }}>SCORE:</p>
        <label>
          <input
            type="radio"
            name="radioButton14"
            value="0"
            checked={selectedValues['question14'] === "0"}
            onChange={(e) => handleRadioChange('question14', e.target.value)}
          /> 0
        </label>
        <label>
          <input
            type="radio"
            name="radioButton14"
            value="1"
            checked={selectedValues['question14'] === "1"}
            onChange={(e) => handleRadioChange('question14', e.target.value)}
          /> 1
        </label>
      </div>
    </div>

    {/* Upload image */}
    {selectedValues.question14 === "0" && (
      <div>
        <div id="camera-container-question14" style={{ border: "1px dashed #e6e6e6", height: '160px', textAlign: 'center', marginBottom: '20px', position: 'relative', borderRadius: '10px' }}>
          {!images['question14'] ? (
            <>
              {/* Camera button */}
              <input
                id="upload-image_question14"
                multiple
                type="file"
                style={{ display: "none" }}
                ref={fileInputRef}
              />
              <button onClick={() => handleCapture('question14')} onChange={(e) => setFormData({ ...selectedValues, image14: e.target.value })} style={{ position: 'absolute', cursor: 'pointer', background: 'none', border: 'none', width: '100%', height: '100%', left: '0', top: '0' }}>
                <LogoutIcon sx={{ transform: 'rotate(-90deg)', color: '#C9C9CC', margin: '30px 0 10px' }} />
                <Typography style={{ textAlign: 'center', color: 'black', fontWeight: 500, fontSize: '16px' }}>Drag & drop images or <span style={{ color: '#17428B' }}>browse images</span></Typography>
                <Typography style={{ textAlign: 'center', color: '#4F4D55', fontSize: '12px', marginTop: '10px' }}>.xlsx - max file size 2MB</Typography>
              </button>
            </>
          ) : (
            <>
              {/* Display captured image */}
              <img src={images['question14']} onChange={(e) => setFormData({ ...selectedValues, image14: e.target.value })} alt="Captured" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
              <div style={{ position: 'absolute', bottom: '10px', left: '50%', transform: 'translateX(-50%)', zIndex: 1 }}>
                {/* Retake button */}
                <Button variant="contained" onClick={() => handleRetake('question14')}>Retake</Button>
              </div>
            </>
          )}
        </div>
      </div>
    )}

    {/* Remarks for Improvement */}
    <div>
      <p style={{ marginBottom: '10px', fontSize: "14px", fontWeight: '500' }}>Remarks for Improvement</p>
      <textarea 
        value={remarks["question14"] || ''} 
        onChange={(e) => handleRemarksChange('question14', e.target.value)} 
        placeholder="Type here" 
        style={{ width: '100%', padding: '8px', outline: 'none', border: '1px solid #E6E6E6', borderRadius: '4px', resize: "none" }} 
      />
    </div>

    {/* Error message */}
    {errors["question14"] && <Typography style={{ color: 'red' }}>{errors["question14"]}</Typography>}
  </CardContent>
</Card>




                {/* <Button variant="contained" onClick={ handleSaveClick}>Save</Button>
 */}

                {/* Next button */}
                {/* <Button variant="contained" onClick={handleNextClick}>Next</Button> */}
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={handleCloseSnackbar}
                    message={snackbarMessage}
                />
                <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', marginTop: '20px' }}>
                    <Button variant="contained" color="primary" onClick={handleSaveClick} style={{
                        width: '100%',
                        background: 'white', // Set background color to white
                        borderRadius: '5px', // Add border radius for rounded corners
                        border: '1px solid #17428B', // Set initial border to 1px solid
                        color: '#15408A', // Set font color to blue
                        boxShadow: 'none',
                        position: 'relative',
                        // Remove default box shadow
                    }} disabled={loading}

                    >Save

                        {/* {loading && <CircularProgress size={24} style={{ position: 'absolute', left: '50%', marginTop: '4px', transform: 'translateX(-50%)' }} />}
              */}
                        {/* {loading && <Alert severity="info" style={{ marginTop: 20 }}>Saving assessment...</Alert>}
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <MuiAlert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar> */}
                        {/*              
                      {loading ? <CircularProgress size={24} color="inherit" /> : 'Save'}  */}
                    </Button>
                    <Button variant="contained" style={{
                        width: '100%',
                        background: '#17428B', // Adjust the gradient colors as needed
                        borderRadius: 3,
                        border: 0,
                        color: 'white',
                        height: 48,
                        padding: '0 30px',
                        boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
                    }} color="primary" onClick={handleNextClick} disabled={loading} >
                        Next
                    </Button>
                </div>

            </div>
            {loading && (
                <div
                    style={{
                        position: 'fixed',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: 9999,
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        padding: '20px',
                        borderRadius: '8px',
                        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                    }}
                >
                    {/* Replace this with your preferred loading indicator */}
                    <Typography variant="h6" style={{ textAlign: 'center', marginBottom: '10px' }}>
                        Saving Assessment...
                    </Typography>
                    {/* Example loading spinner */}
                    <div style={{ textAlign: 'center' }}>
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
            )}
        </Box>











      
    );
}

export default Straighten;
