import React from 'react';
import {IconButton} from '@mui/material';
import Header from '../../app/TopAppBar';
 import team from '../../Asset/Icons/Microsoft_teams.svg';
const containerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh', // 100% of the viewport height
};

const buttonStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '200px', // Adjust the width as needed
  height: '150px', // Adjust the height as needed
  backgroundColor: '#ffffff', // Change the background color
  color: 'black', // Change the text color
  border: '2px solid #2980b9', // Change the border color
  borderRadius: '8px',
  cursor: 'pointer',
  transition: 'background-color 0.3s',
};

const iconStyle = {
  marginBottom: '8px',
};

const textStyles = {
  fontSize: '12px',
  textAlign: 'center',
};

const text = {
  fontSize: '10px',
  textAlign: 'center',
};

const boldTextStyle = {
  fontWeight: 'bold',
};

const HelpAndSupportButton = () => {
  const handleButtonClick = () => {
    // Replace 'your-teams-channel-url' with the actual URL of your Teams channel
    const teamsChannelUrl = 'https://teams.microsoft.com/l/channel/19%3Adba1ede65bfd47eeb4599c277324dd30%40thread.tacv2/5S%20Support?groupId=a2c741c7-fe3f-480d-b702-e9362cfa3d7e&tenantId=&ngc=true';
    const newWindow = window.open('', '_blank');
    newWindow.opener = null;  // Set the opener to null for enhanced security
    newWindow.location.href = teamsChannelUrl;
   
  };
  return (
    <div style={containerStyle}>
      {/* Change the div to a button */}
      {/* <Header pageTitle="HelpAndSupport" isHelpAndSupport={true}/> */}
      <button
        type="button"
        style={buttonStyle}
        onClick={handleButtonClick}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleButtonClick();
          }
        }}
      >
        <div style={iconStyle}>
          <IconButton aria-label="Team">
            <img src={team} alt="Teams" style={{ width: '60px', height: '60px' }} />
          </IconButton>
        </div>
        <div style={textStyles}>
          <span style={boldTextStyle}>Talk to our <br /> support engineer</span>
        </div>
        <div style={text}>
          Let us know your difficulties with this application. We are happy to help.
        </div>
      </button>
    </div>
  );

};

export default HelpAndSupportButton;










