import React, { useState, useEffect } from 'react';
import { Typography, Button, Alert, Box,useMediaQuery } from '@mui/material';
import RequestForm from './RequestForm';
import Safety from './Safety';
import Shine from './Shine';
import Standardise from './Standardise';
import Straighten from './Straighten';
import Sustain from './Sustain';
import { stepState, formDataState } from '../../recoil/atoms';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import configParam from "../../config";

import { useTheme } from '@mui/material/styles';
import AlertDialog from '../input/AlertDialog';
import { CoPresent, CompassCalibrationTwoTone } from '@mui/icons-material';
import NewAssessment from './NewAssessment';
import Snackbar from '@mui/material/Snackbar';
import { Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import { useRecoilState, useResetRecoilState } from "recoil";

function HorizontalCombinedStepper({ open ,state,assesmentid}) {

  const steps = ['S1S: SORT(SEIRI)', 'Straighten', 'Shine', 'Standardise', 'Sustain', 'Safety'];
  const [activeStep, setActiveStep] = useRecoilState(stepState);

  const [formData, setFormData] = useRecoilState(formDataState);

  const [overallScore, setOverallScore] = useState(0);
  const [stepColors, setStepColors] = useState(steps.map((_, index) => index === 0 ? '#0086DC' : '#BDBDBD'));
  const [assessmentId, setAssessmentId] = useState(null);
  const [assessmentCreated, setAssessmentCreated] = useState(false);

  const [selectedAuditDate, setSelectedAuditDate] = useState(null);
  const [selectedAuditor, setSelectedAuditor] = useState('');
  const [selectedZone, setSelectedZone] = useState('');
  const [loading, setLoading] = useState(false);
  const ApiURL = configParam.API_URL;
  const location = useLocation();
  const access_token = JSON.parse(localStorage.getItem("accesstoken"));

  const { editedAssessment } = location.state || {};
  const [totalScore, setTotalScore] = useState(0);
  const assessmenteditflg = localStorage.getItem("assessmenteditflg") === "true";
  const { message, unselectedQuestions } = location.state || {};
  const [changesSaved, setChangesSaved] = useState(false);

  const [savedFormData, setSavedFormData] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const theme = useTheme();

  const onNext = () => {
    setActiveStep(prevStep => prevStep + 1);

  };

  // Function to calculate the overall score across all steps


  useEffect(() => {
    // Update stepper colors based on active step
    const stepColors = steps.map((_, index) => index === activeStep ? '#0086DC' : '#BDBDBD');
    setStepColors(stepColors);

  }, [activeStep, formData]);

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  const onStepClick = (stepIndex) => {
    // Update formDataState with the current form data
    setFormData(formData);
    setActiveStep(stepIndex);
  };



  // const onStepClick = async (stepIndex) => {
  //   // Get the current form data for the step
  //   const currentStepFormData = formData[activeStep];

  //   // Check if any radio button is selected in the current step
  //   const isStepAnswered = Object.values(currentStepFormData.selectedValues).some(value => value !== '');
  //   // Check if remarks are added in the current step
  //   const isRemarksAdded = Object.values(currentStepFormData.remarks).some(value => value !== '');
  //   // Check if images are added in the current step (you need to define this logic based on your implementation)
  //   const isImagesAdded = Object.values(currentStepFormData.images).some(value => value !== '');
  //   // Check if any changes are made but not saved in the current step
  //   const isUnsavedChanges = (isStepAnswered || isRemarksAdded || isImagesAdded) && !changesSaved;

  //   if (isUnsavedChanges) {
  //     setSnackbarMessage("Please save your changes before moving to another step.");
  //     setSnackbarOpen(true);
  //     return;
  //   }

  //   // Move to the selected step
  //   setActiveStep(stepIndex);
  // };

  //  const validateQuestion = (value) => {
  //     if (!value) {
  //       return "This field is required."; // Error message for required fields
  //     }
  //     // Add additional validation logic as needed for each question
  //     return null; // Return null if there are no validation errors
  //   };

  //   const validateFormData = async (stepIndex) => {
  //     if (stepIndex !== activeStep) {
  //       return null;
  //     }

  //     const currentStepFormData = formData[activeStep];
  //     const isStepAnswered = Object.values(currentStepFormData.selectedValues).some(value => value !== '');

  //     if (!isStepAnswered) {
  //       return null;
  //     }

  //     if (!changesSaved) {
  //       return { error: "Please save your changes before moving to another step_4." };
  //     }

  //     const formDataValidationPromises = [];
  //     for (const questionId in currentStepFormData.selectedValues) {
  //       if (currentStepFormData.selectedValues.hasOwnProperty(questionId)) {
  //         const value = currentStepFormData.selectedValues[questionId];
  //         const error = validateQuestion(value);
  //         if (error) {
  //           formDataValidationPromises.push(Promise.resolve({ questionId, error }));
  //         }
  //       }
  //     }

  //     const validationResults = await Promise.all(formDataValidationPromises);
  //     const validationErrors = validationResults.reduce((errors, { questionId, error }) => {
  //       if (error) {
  //         errors[questionId] = error;
  //       }
  //       return errors;
  //     }, {});

  //     return Object.keys(validationErrors).length > 0 ? validationErrors : null;
  //   };


  async function getLatestRecord() {
    try {
      const response = await axios.get(`${ApiURL}/GET_LATEST_FIVES`, {
        headers: {
          Authorization: `Bearer ${access_token}`
        }
      });

      //const response = await axios.get(`${API.GET_LATEST_MOC}`);
      if (response.data && response.data.length > 0) {
      
        return response.data[0].ass_no; // Return the latest MOC number

      } else {
        console.log("No data found in the response");
        return null;
      }

    } catch (error) {
      console.log("Error:", error);
      return null;
    }
  }

  async function generateassNumber() {
    const latestRecord = await getLatestRecord();
    let lastTwoDigits = 1; // Default value if no records are found
    if (latestRecord) {
      const lastMocNumber = parseInt(latestRecord.split("-")[3]); // Extract last three digits
      lastTwoDigits = lastMocNumber + 1;
    }
    const date = new Date();
    const year = date.getFullYear().toString();
    const yearMocNumber = `AS-FL2-${year}-${lastTwoDigits
      .toString()
      .padStart(2, "0")}`;
    return yearMocNumber;
  }

  // generateassNumber()
  //   .then((serialNumber) => {
  //     console.log("Generated Serial Number:", serialNumber);
  //   })
  //   .catch((error) => {
  //     console.error("Error occurred:", error);
  //   });






  const calculateOverallScoreedit = (selectedValues) => {
    let overallScore = 0;

    // Iterate through the selected values
    for (const questionId in selectedValues) {
      if (selectedValues.hasOwnProperty(questionId)) {
        // Add the score for each question to the overall score
        overallScore += parseInt(selectedValues[questionId]) || 0;
      }
    }

    return overallScore;
  };

  const calculateOverallScore = (formData) => {
    let overall = 0;
    for (const step in formData) {
      const stepData = formData[step];
      for (const questionId in stepData.selectedValues) {
        if (stepData.selectedValues.hasOwnProperty(questionId)) {
          const score = stepData.selectedValues[questionId];
          overall += parseInt(score) || 0;
        }
      }
    }
    return overall;
  };

  const handleSave = async (selectedValues, images, remarks) => {
    setLoading(true);

    try {
      // Calculate overall score
      const overallScore = calculateOverallScore(formData);
      // Update overall score in UI or state
      setOverallScore(overallScore);

      const imagesData = await generateImagesArray();
      const currentFormData = {
        selectedValues,
        images: Object.fromEntries(
          Object.entries(imagesData).map(([questionId, image]) => [questionId, image.imageName])
        ),
        remarks,
      };

      const userby = JSON.parse(localStorage.getItem("userDatas"));
      const userdet = userby.sgid.replace(/"/g, '');
      const assNumber = await generateassNumber();

      const userid = {
        userid: userdet,
        assNumber: assNumber,
        status_flg: 'P',
        auditorName: localStorage.getItem("auditor"),
        auditDate: localStorage.getItem("selectedDate"),
        zoneId: localStorage.getItem("zone"),
        overallScore: overallScore,
      };

      const assesmentid = localStorage.getItem("assessmentIdcreate");
      if (!assessmentCreated && !assessmenteditflg && !assesmentid) {
        const response = await axios.post(ApiURL + '/create_assessment',
          { assessmentData: currentFormData, userdetails: userid },
          {
            headers: {
              Authorization: `Bearer ${access_token}`
            },
          }
        );

        AlertDialog({
          type: 'success',
          title: 'Success',
          text: 'Assessment created successfully.',
        });

        if (response.data.success) {
          setAssessmentId(response.data.assessmentId);
          localStorage.setItem("assessmentIdcreate", response.data.assessmentId);
          setAssessmentCreated(true);
          //   setTimeout(() => {
          //     // Perform your save logic here, and then reset loading state
          //     setLoading(false);
          // }, 2000); 
          setLoading(false);
        }
      } else {
        const assessmenteditID = localStorage.getItem("assessmentId");
        if (assessmenteditflg) {
          setOverallScore(0);
          const overallScoreEdit = calculateOverallScoreedit(selectedValues);
          setOverallScore(overallScoreEdit);

          const response = await axios.post(`${ApiURL}/update_edit_assessment/${assessmenteditID}`,
            { assessmentData: currentFormData, userdetails: { overallScore: overallScoreEdit } },
            {
              headers: {
                Authorization: `Bearer ${access_token}`
              },
            }
          );
        
          //   setTimeout(() => {
          //     // Perform your save logic here, and then reset loading state
          //     setLoading(false);
          // }, 2000); 
          setLoading(false);
          setChangesSaved(true);
        } else {
          const assessmentId = localStorage.getItem("assessmentIdcreate");
          const response = await axios.post(`${ApiURL}/update_assessment/${assessmentId}`,
            { assessmentData: currentFormData, userdetails: userid },
            {
              headers: {
                Authorization: `Bearer ${access_token}`
              },
            }
          );
         
          //   setTimeout(() => {
          //     // Perform your save logic here, and then reset loading state
          //     setLoading(false);
          // }, 2000); 
          setLoading(false);
          setChangesSaved(true);
        }
      }
    } catch (error) {
      console.error('Error saving/updating assessment:', error);
      setLoading(false);
    }
  };



const generateImagesArrayOld = async () => {
  const imagesData = {};

    // Iterate over the keys of the formData
    for (const step in formData) {
      if (formData.hasOwnProperty(step) && formData[step].images) {
        const stepData = formData[step];

        // Iterate over the images object of each step
        for (const questionId in stepData.images) {
          if (stepData.images.hasOwnProperty(questionId)) {
            const imageDataUrl = stepData.images[questionId];

            // Check if imageDataUrl is valid
            if (typeof imageDataUrl === 'string' && imageDataUrl.includes(',')) {
              try {
                // Split data URL into "data:image/png;base64" and base64 string
                const imageData = imageDataUrl.split(',');

                // Ensure the split data URL has two parts before proceeding
                if (imageData.length === 2) {
                  const imageType = imageData[0].split(';')[0].split('/')[1]; // Extract file extension
                  const imageBase64 = imageData[1]; // Extract base64 string

                  const imageName = `image_${Date.now()}_${questionId}.${imageType}`;

                
                  // Assign the image data to imagesData with the questionId as key
                  imagesData[questionId] = {
                    imageName,
                    imageBase64,
                    imageType,
                    questionId,
                  };
                } else {
                  console.warn(`Skipping invalid image data for questionId: ${questionId}`);
                }
              } catch (error) {
                console.error(`Error processing image data for questionId: ${questionId}`, error);
              }
            } else {
              console.warn(`Skipping invalid image URL for questionId: ${questionId}`);
            }
          }
        }
      }
    }

 

    // Call the upload function after processing the images
    await uploadBlobImagesAPI(Object.values(imagesData));

    // Return the imagesData object
    return imagesData;
  };




const uploadBlobImagesAPIOLD = async (imagesData) => {
  try {
      const url = `${ApiURL}/upload_image_to_blob_v1`; // Construct the full URL using ApiURL

      const response = await axios.post(url, {
        images: imagesData, // Pass the imagesData array
      });

  
      // Handle the response and update your UI or state as needed
    } catch (error) {
      console.error('Error uploading images:', error);
      // Handle error, update UI, etc.
    }
  };

const generateImagesArray = async () => {
  const imagesData = {};

  for (const step in formData) {
    if (formData.hasOwnProperty(step) && formData[step].images) {
      const stepData = formData[step];

      for (const questionId in stepData.images) {
        if (stepData.images.hasOwnProperty(questionId)) {
          const imageDataUrl = stepData.images[questionId];

          if (typeof imageDataUrl === 'string' && imageDataUrl.includes(',')) {
            try {
              const imageData = imageDataUrl.split(',');
              if (imageData.length === 2) {
                const imageType = imageData[0].split(';')[0].split('/')[1];
                const imageBase64 = imageData[1];
                const imageName = `image_${Date.now()}_${questionId}.${imageType}`;

            

                imagesData[questionId] = {
                  imageName,
                  imageBase64,
                  imageType,
                  questionId,
                };
              } else {
                console.warn(`Skipping invalid image data for questionId: ${questionId}`);
              }
            } catch (error) {
              console.error(`Error processing image data for questionId: ${questionId}`, error);
            }
          } else {
            console.warn(`Skipping invalid image URL for questionId: ${questionId}`);
          }
        }
      }
    }
  }



  // Ensure imagesData is not empty before calling uploadBlobImagesAPI
  if (Object.keys(imagesData).length > 0) {
    try {
      await uploadBlobImagesAPI(Object.values(imagesData));
    } catch (error) {
      console.error('Error in uploadBlobImagesAPI:', error);
    }
  } else {
    console.warn('No valid images to upload.');
  }

  return imagesData;
};


const generateImagesArray_lat = async () => {
  const imagesData = {};

  // Iterate over the keys of the formData
  for (const step in formData) {
    if (formData.hasOwnProperty(step) && formData[step].images) {
      const stepData = formData[step];

      // Iterate over the images object of each step
      for (const questionId in stepData.images) {
        if (stepData.images.hasOwnProperty(questionId)) {
          const imageDataUrl = stepData.images[questionId];

          // Check if imageDataUrl is valid
          if (typeof imageDataUrl === 'string' && imageDataUrl.includes(',')) {
            try {
              // Split data URL into "data:image/png;base64" and base64 string
              const imageData = imageDataUrl.split(',');

              // Ensure the split data URL has two parts before proceeding
              if (imageData.length === 2) {
                const imageType = imageData[0].split(';')[0].split('/')[1]; // Extract file extension
                const imageBase64 = imageData[1]; // Extract base64 string

                const imageName = `image_${Date.now()}_${questionId}.${imageType}`;

           
                // Assign the image data to imagesData with the questionId as key
                imagesData[questionId] = {
                  imageName,
                  imageBase64,
                  imageType,
                  questionId,
                };
              } else {
                console.warn(`Skipping invalid image data for questionId: ${questionId}`);
              }
            } catch (error) {
              console.error(`Error processing image data for questionId: ${questionId}`, error);
            }
          } else {
            console.warn(`Skipping invalid image URL for questionId: ${questionId}`);
          }
        }
      }
    }
  }


  // Call the upload function after processing the images
  await uploadBlobImagesAPI(Object.values(imagesData));

  // Return the imagesData object
  return imagesData;
};

const uploadBlobImagesAPI = async (imagesData) => {
  try {
    const url = `${ApiURL}/upload_image_to_blob_v1`; // Construct the full URL using ApiURL

    const response = await axios.post(url, {
      images: imagesData, // Pass the imagesData array
    });

    // Handle the response and update your UI or state as needed
  } catch (error) {
    console.error('Error uploading images:', error);
    // Handle error, update UI, etc.
  }
};



useEffect(() => {
  if (assessmenteditflg) {
      const assessmentId = localStorage.getItem("assessmentId");

      geteditdetails(assessmentId);
    }
  }, []);

  async function geteditdetails(assessmentId) {
    try {
      setActiveStep(0)
      const response = await axios.get(`${ApiURL}/get_AssessmentByIds/${assessmentId}`, {
        headers: {
          Authorization: `Bearer ${access_token}`
        }
      });
      if (response.data) {
        const formData = JSON.parse(response.data.data.total_score);

        //const overallScore = calculateOverallScore(formData); // You need to define calculateOverallScore function
        setOverallScore(formData);


      } else {
        console.log("No data found in the response");
      }
    } catch (error) {
      console.log("Error:", error);
      return null;
    }
  }




  const renderStepComponent = () => {
    switch (activeStep) {
      case 0:
        return <RequestForm onNext={onNext} activeStep={activeStep} onSave={handleSave} loading={loading} />;
      case 1:
        return <Straighten onNext={onNext} activeStep={activeStep} onSave={handleSave} loading={loading} />;
      case 2:
        return <Shine onNext={onNext} activeStep={activeStep} onSave={handleSave} loading={loading} />;
      case 3:
        return <Standardise onNext={onNext} activeStep={activeStep} onSave={handleSave} loading={loading} />;
      case 4:
        return <Sustain onNext={onNext} activeStep={activeStep} onSave={handleSave} loading={loading} />;
      case 5:
        return <Safety onNext={onNext} activeStep={activeStep} onSave={handleSave} loading={loading} />;
      default:
        return null;
    }
  };
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Dialog open={open} fullScreen={fullScreen} fullWidth maxWidth="sm">
      <DialogTitle>
        <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center ' }}>
          {/* Display total score */}
          {/* Conditionally render Typography and p elements based on active step */}
          {activeStep === 0 && (
            <Box style={{ flexGrow: 1 }}>
              <Typography variant="h6" style={{ textTransform: 'uppercase', fontSize: "16px", fontWeight: '600', color: '#17428B', marginBottom: '10px' }}>1S: Sort(seiri)</Typography>
              <p style={{ marginBottom: '10px', fontSize: "10px", fontWeight: '500' }}>Tidiness, Organization. Refers To The Practice Of Sorting Through All The Tools, Materials, Etc., In The Work Area And Keeping Only Essential Items.</p>
            </Box>
          )}
          {activeStep === 1 && (
            <Box style={{ flexGrow: 1 }}>
              <Typography variant="h6" style={{ textTransform: 'uppercase', fontSize: "16px", fontWeight: '600', color: '#17428B', marginBottom: '10px' }}>2S: STRAIGHTEN(SEITON)</Typography>
              <p style={{ marginBottom: '10px', fontSize: "10px", fontWeight: '500' }}>Focuses on the need for an orderly workplace. Tools, equipment, and materials must be systematically arranged for the easiest and most efficient access.</p>
            </Box>
          )}
          {activeStep === 2 && (
            <Box style={{ flexGrow: 1 }}>
              <Typography variant="h6" style={{ textTransform: 'uppercase', fontSize: "16px", fontWeight: '600', color: '#17428B', marginBottom: '10px' }}>3S: SHINE(SEISO)</Typography>
              <p style={{ marginBottom: '10px', fontSize: "10px", fontWeight: '500' }}>Indicates the need to keep the workplace clean as well as neat. Cleaning in Japanese companies is a daily activity.</p>
            </Box>
          )}
          {activeStep === 3 && (
            <Box style={{ flexGrow: 1 }}>
              <Typography variant="h6" style={{ textTransform: 'uppercase', fontSize: "16px", fontWeight: '600', color: '#17428B', marginBottom: '10px' }}>4S: STANDARDISE (SEIKETSU)</Typography>
              <p style={{ marginBottom: '10px', fontSize: "10px", fontWeight: '500' }}>Allows for control and consistency. Basic housekeeping standards apply everywhere in the facility.</p>
            </Box>
          )}
          {activeStep === 4 && (
            <Box style={{ flexGrow: 1 }}>
              <Typography variant="h6" style={{ textTransform: 'uppercase', fontSize: "16px", fontWeight: '600', color: '#17428B', marginBottom: '10px' }}>5S: SUSTAIN(SHITSUKE)</Typography>
              <p style={{ marginBottom: '10px', fontSize: "10px", fontWeight: '500' }}>Refers to maintaining standards and keeping the facility in safe and efficient order day after day, year after year.</p>
            </Box>
          )}
          {activeStep === 5 && (
            <Box style={{ flexGrow: 1 }}>
              <Typography variant="h6" style={{ textTransform: 'uppercase', fontSize: "16px", fontWeight: '600', color: '#17428B', marginBottom: '10px' }}>5S : SAFETY</Typography>
              <p style={{ marginBottom: '10px', fontSize: "10px", fontWeight: '500' }}>Tidiness, Organization. Refers To The Practice Of Sorting Through All The Tools, Materials, Etc., In The Work Area And Keeping Only Essential Items.</p>
            </Box>
          )}
          <Typography style={{ marginBottom: '10px', fontSize: "18px", fontWeight: '600', color: '#4FB0B4', display: 'flex', flexDirection: 'column', alignItems: 'end',paddingLeft:'16px' }}> <Typography variant='small' sx={{fontWeight:'400',fontSize:'12px'}}>Score</Typography>{`${overallScore}/36`}</Typography>
        </Box>
        <Box style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0' }}>
          {steps.map((step, index) => (
            <Box key={index} style={{ flex: 1, position: 'relative', marginRight: '10px' }}>
              <Box
                onClick={() => onStepClick(index)}
                style={{
                  borderBottom: `5px solid ${stepColors[index]}`,
                  width: '100%',
                  marginBottom: '10px',
                  marginTop: '10px',
                  borderRadius: '7px',
                  cursor: 'pointer'
                }}
              />
            </Box>
          ))}
        </Box>
        {/* {steps[activeStep]} */}
      </DialogTitle>
      <DialogContent>


        {/* Render stepper content */}

        {/* Render components based on active step */}
        <Box>{renderStepComponent()}</Box>
        <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
          <MuiAlert elevation={6} variant="filled" onClose={handleSnackbarClose} severity="warning">
            {snackbarMessage}
          </MuiAlert>
        </Snackbar>

        {/* <NewAssessment
              open={isNewAssessmentOpen}
              onClose={handleCloseNewAssessment}
              onNext={({ auditor, zone, selectedDate }) => {
                setSelectedAuditDate(selectedDate);
                setSelectedAuditor(auditor);
                setSelectedZone(zone);
              }}
            /> */}
      </DialogContent>
      <DialogActions>
      </DialogActions>
    </Dialog>

  );

}

export default HorizontalCombinedStepper;