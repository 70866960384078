import React, { useEffect, useState } from "react";
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Divider, Typography, Avatar, Tooltip, Box,Grid } from "@mui/material";
import logout from "../Asset/Icons/logout.svg";
import { useNavigate } from "react-router-dom";
import Dashboard from "../Asset/Icons/Dashboard.svg";
import Audit from "../Asset/Icons/Document.svg";
import RedTag from "../Asset/Icons/Document.svg";
import Setting from "../Asset/Icons/System.svg";
// import ManageMoc from "../Asset/Icons/manage_moc.svg";
// import Settings from "../Asset/Icons/settings_icon.svg";
import HelpnSupport from "../Asset/Icons/Communication.svg";
import location from "../Asset/Icons/map-pin-2-line.svg";
import Settings from "./settings/Settings";
import Achievement from '../Asset/Icons/achievements.svg';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import IconButton from '@mui/material/IconButton';
import { styled, useTheme } from '@mui/material/styles';
import LaunchLogo from "../Asset/Icons/fiveslogo.svg";
import sgLogo from "../Asset/Icons/SgLogo.png";
const drawerWidth = 240;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const LeftDrawer = ({ open, handleDrawerClose }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [apiData, setApiData] = useState("");


  useEffect(() => {
    const storedUserData = localStorage.getItem("userDatas");
    const userData = storedUserData ? JSON.parse(storedUserData) : null;
    setApiData(userData)

  }, []);

  const textStyles = {
    fontWeight: 500,
    fontSize: 16,
    fontStyle: "normal",
    fontFamily: "Inter, sans-serif",
    color: "black",
    lineHeight: 1,
    textTransform: "capitalize",
    marginTop: "10px",
  };

  // onclick pages
  const handleNavigation = (route) => {
    navigate(route);
  };

  // Usage:
  const handleDashboardClick = () => handleNavigation("/Dashboard");
  const handleAuditClick = () => handleNavigation("/Audit");
  const handleRedTagClick = () => handleNavigation("/RedTagActivities");  
  const handleSettingsClick = () => handleNavigation("/settings");
  const handleAchievementClick = () => handleNavigation("/achievements");
  const handleHelpandSupportClick = () => handleNavigation("/helpandsupportButton");
  const handleMassClick = () => handleNavigation("/massfives");
  const handleReportsClick = () => handleNavigation("/reports");
  // const handleManageMocClick = () => handleNavigation("/ManageMoc");
  // const handleHelpNSupportClick = () => handleNavigation("/HelpnSupport");
  // const handleSettingsClick = () => handleNavigation("/settings");


  return (
    <Drawer sx={{
      width: drawerWidth,
      flexShrink: 0,
      
      '& .MuiDrawer-paper': {
        width: drawerWidth,
        boxSizing: 'border-box',
        
      },
    }}
    variant="persistent"
    
    anchor="left"
    open={open}>
     <DrawerHeader>
  <Box
    display="flex"
    alignItems="center"
    justifyContent="space-between"
    width="100%"
    sx={{ padding: '0 8px' }} // Adjust padding as needed
  >
    <Box display="flex" alignItems="center">
      <img
        src={LaunchLogo}
        alt="Logo"
        style={{ width: '40px', height: '40px', marginRight: '8px' }} // Reduce logo size and add margin
      />
      <Typography
        variant="h6"
        sx={{ fontSize: '18px', fontWeight: '600' }} // Adjust font size if needed
        noWrap // Ensure text doesn't overflow
      >
        5S Application
      </Typography>
    </Box>
    <IconButton onClick={handleDrawerClose}>
      {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
    </IconButton>
  </Box>
</DrawerHeader>

      {/* <DrawerHeader>
      <Grid item sx={{ marginRight: '10px',marginLeft:'1px' }}>
          <img src={LaunchLogo} alt="Logo" className="icon"style={{ width: '50px', height: '50px' }} />
        </Grid>
        <Typography variant="h2" sx={{ fontSize: '20px', fontWeight: '600', marginBottom: '16px', marginTop: '16px' }}>5S Application</Typography>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
       
      </DrawerHeader> */}
      <Box>
        <List>
          <Divider />
          <ListItem onClick={handleDashboardClick}>
            <ListItemIcon>
              <img
                src={Dashboard}
                alt="Dashboard"
                style={{ width: "24px", height: "24px", cursor: "pointer" }}
              />
            </ListItemIcon>
            <ListItemText
              primary="Dashboard"
              primaryTypographyProps={{ style: textStyles }}
            />
          </ListItem>
          <ListItem onClick={handleAuditClick}>
            <ListItemIcon>
              <img
                src={Audit}
                alt="Audit"
                style={{ width: "24px", height: "24px", cursor: "pointer" }}
              />
            </ListItemIcon>
            <ListItemText
              primary="Audit"
              primaryTypographyProps={{ style: textStyles }}
            />
          </ListItem>
          <ListItem onClick={handleAchievementClick}>
            <ListItemIcon>
              <img
                src={Achievement}
                alt="Achievements"
                style={{ width: "24px", height: "24px", cursor: "pointer" }}
              />
            </ListItemIcon>
            <ListItemText
              primary="Achievements"
              primaryTypographyProps={{ style: textStyles }}
            />
          </ListItem>
          <ListItem onClick={handleRedTagClick}>
            <ListItemIcon>
              <img
                src={RedTag}
                alt="RedTag"
                style={{ width: "24px", height: "24px", cursor: "pointer" }}
              />
            </ListItemIcon>
            <ListItemText
              primary="RedTag"
              primaryTypographyProps={{ style: textStyles }}
            />
          </ListItem>

          <ListItem onClick={handleMassClick}>
            <ListItemIcon>
              <img
                src={HelpnSupport}
                alt="Mass 5S"
                style={{ width: "24px", height: "24px", cursor: "pointer" }}
              />
            </ListItemIcon>
            <ListItemText
              primary="Mass 5S"
              primaryTypographyProps={{ style: textStyles }}
            />
          </ListItem>


          <ListItem onClick={handleReportsClick}>
            <ListItemIcon>
              <img
                src={HelpnSupport}
                alt="Reports"
                style={{ width: "24px", height: "24px", cursor: "pointer" }}
              />
            </ListItemIcon>
            <ListItemText
              primary="Reports"
              primaryTypographyProps={{ style: textStyles }}
            />
          </ListItem>
          <ListItem onClick={handleSettingsClick}>
            <ListItemIcon>
              <img
                src={Setting}
                alt="Settings"
                style={{ width: "24px", height: "24px", cursor: "pointer" }}
              />
            </ListItemIcon>
            <ListItemText
              primary="Settings"
              primaryTypographyProps={{ style: textStyles }}
            />
          </ListItem>
          <ListItem onClick={handleHelpandSupportClick}>
            <ListItemIcon>
              <img
                src={HelpnSupport}
                alt="HelpAndSupport"
                style={{ width: "24px", height: "24px", cursor: "pointer" }}
              />
            </ListItemIcon>
            <ListItemText
              primary="Help And Support"
              primaryTypographyProps={{ style: textStyles }}
            />
          </ListItem>

          {/* <ListItem onClick={handleManageMocClick}>
            <ListItemIcon>
              <img
                src={ManageMoc}
                alt="manageMOC"
                style={{ width: "24px", height: "24px", cursor: "pointer" }}
              />
            </ListItemIcon>
            <ListItemText
              primary="Manage MOC"
              primaryTypographyProps={{ style: textStyles }}
            />
          </ListItem>
          <ListItem onClick={handleSettingsClick}>
            <ListItemIcon>
              <img
                src={Settings}
                alt="Settings"
                style={{ width: "24px", height: "24px", cursor: "pointer" }}
              />
            </ListItemIcon>
            <ListItemText
              primary="Settings"
              primaryTypographyProps={{ style: textStyles }}
            />
          </ListItem> */}
          {/* <ListItem>
            <ListItemIcon>
              <img
                src={HelpnSupport}
                alt="manageMOC"
                style={{ width: "24px", height: "24px", cursor: "pointer" }}
              />
            </ListItemIcon>
            <ListItemText
              primary="Help & Support"
              primaryTypographyProps={{ style: textStyles }}
              onClick={handleHelpNSupportClick}
            />
          </ListItem> */}
        </List>
        <Grid item sx={{position: 'absolute',left: 0,right:'0',bottom: '0',margin:'auto',textAlign:'center'}}>
          <img src={sgLogo} alt="Logo" className="icon" />

          <Typography
            variant="body1"
            style={{
              marginTop: 8,
              fontSize: 14,
              textAlign: "center",
              marginBottom: "15px",
            }}
          >
            © 2024 INDEC4.0 Saint-Gobain
          </Typography>
        </Grid>
      
        {/* <Typography
          variant="body1"
          style={{
            fontSize: 14,
            textAlign: "center",
            color: "#17428B",
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            padding: "10px",
          }}
        >
          © 2024 INDEC4.0 SAINT-GOBAIN
        </Typography> */}
      </Box>
    </Drawer>
  );
};

export default LeftDrawer;