import React, { useEffect, useState } from 'react';
import Header from '../../app/TopAppBar';
import NewRedTag from "../RedTagging/NewRedTag";
import {Dialog, DialogTitle, DialogContent, DialogActions, Popover,Grid,Card,  IconButton, Tabs, Tab, Typography, Box, Button, MenuItem, TextField,List,ListItem,ListItemText,CircularProgress,FormControl,FormControlLabel,FormLabel,RadioGroup,Radio } from '@mui/material';

import { useNavigate } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'; // Import ExpandMoreIcon
import EditIcon from '../../Asset/Icons/ic_edit.svg';
import axios from 'axios';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import SearchIcon from '@mui/icons-material/Search';
import configParam from '../../config';
import FilterListIcon from '@mui/icons-material/FilterList';
import AlertDialog from '../input/AlertDialog';
import PlusIcon from "../../Asset/Icons/addnew_icon.svg";
import Eyeicon from "../../Asset/Icons/eye-open.svg";
import AddIcon from '@mui/icons-material/Add';
import RedTagForm from '../RedTagging/RedTagForm';
//my code

const RedTagActivities = () => {
  const navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [zoneActivities, setZoneActivities] = useState([]);
  const [commonRedTags, setCommonRedTags] = useState([]);
  const [scrapYardActivities, setScrapYardActivities] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [editUser, setEditUser] = useState(null);
  const [tabValue, setTabValue] = useState('0');
  const [loading, setLoading] = useState(false);
 const [userRole, setuser] = useState([]);
  // const [userRole, setUserRole] = useState(null);
  const [dialogFormOpen, setDialogFormOpen] = useState(false);
  const ApiURL = configParam.API_URL;
  const access_token = JSON.parse(localStorage.getItem("accesstoken"));
  const [redeployedActivities, setRedeployedActivities] = useState([]);
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [storeStatus, setStoreStatus] = useState('');
  const [dialogActionType, setDialogActionType] = useState('storeStatus');
  useEffect(() => {
    getRole();
    loadRedTagData();
    const userDatas = JSON.parse(localStorage.getItem("userDatas"));
    // if (userDatas) {
    //   const role = userDatas.role_id; // Assuming the role is stored in userDatas
    //   setUserRole(role);
    //   console.log("role", role);
    // }
  }, []);

  // const handleRedAddClick = () => {
  //   if (isRedTagPage) {
  //     setredTagDialogOpen(true); // Open the dialog
  //   }
  //    else {
  //     // Handle other pages
  //   }
 
  // };

  const handleRedAddClick = () => {
    setDialogOpen(true); // Open the dialog
};




  const handleMenuIconClick = (event, user) => {
    console.log("Clicked user", user);
    setAnchorEl(event.currentTarget);
    setEditUser(user);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
    setEditUser(null);
  };
  const handleDialogClose = () => {
    setDialogFormOpen(false); // Open the dialog
  };
  
  const handleEdit = (user) => {
    const RedTagId = user.id;
    const RedTagIdeditflg = true;
    localStorage.setItem("redtagId", RedTagId);
    localStorage.setItem("redtageditflg", RedTagIdeditflg);
  //  navigate('/RedTagForm');
    setDialogFormOpen(true)
  };

  const handleCommonEdit = (user) => {
    const RedTagId = user.id;
    console.log("commonid",RedTagId)
    const RedTagIdeditflg = true;
    localStorage.setItem("redtagId", RedTagId);
    localStorage.setItem("redtagcommoneditflg", RedTagIdeditflg);
    setDialogFormOpen(true)
  };

  const handleScrapEdit = (user) => {
    const RedTagId = user.id;
    const RedTagIdeditflg = true;
    localStorage.setItem("redtagId", RedTagId);
    localStorage.setItem("redtagscrapeditflg", RedTagIdeditflg);
    setDialogFormOpen(true)
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const handleRedTagDialogNext = () => {
    // Navigate to '/horizontalcombinedstepper' page
    //reseredtagtDealerValue();
    localStorage.setItem("redtagId", "");
    localStorage.setItem("redtageditflg", false);
   // setRedTagIconopen(true);
      navigate("/RedTagForm");
    // navigate("/horizontalcombinedstepper");
    
  };
  const handleRedTagDialogClose = () => {
    setDialogOpen(false); // Close the dialog
  };

  // const handleApprovalOpen = () => {
  //   setOpenStatusModal(true);
  // };

  const handleApprovalOpen = (actionType = 'storeStatus') => {
    setOpenStatusModal(true);
  
    if (actionType === 'approve' && editUser) {
      setStoreStatus(editUser.store_status || ''); // Prepopulate with the current store_status
    }
    
    // Set a flag or the action type to determine the button label and behavior
    setDialogActionType(actionType);
  };
  

  const handleStoreClose = () => {
    setOpenStatusModal(false);
    setStoreStatus('');
  };
  const handleStoreStatusChange = (event) => {
    setStoreStatus(event.target.value);
  };

  const handleStoreStatus = () => {
    if (editUser) {
      axios.put(`${ApiURL}/updateRedTagStatus`, {
        id: editUser.id,
        store_status: storeStatus
      }, {
        headers: {
          Authorization: `Bearer ${access_token}`
        }
      })
        .then(response => {
          if (response.status === 200) {
            loadRedTagData(); // Reload data after updating status
            handleStoreClose();
          }
        })
        .catch(error => {
          console.error("Error updating red tag status:", error);
        });
    }
  };
  const handleApproveStatus = () => {
    if (editUser) {
      axios.put(`${ApiURL}/updateRedTagStatus`, {
        id: editUser.id,
        status: storeStatus // Assuming status is updated to storeStatus
      }, {
        headers: {
          Authorization: `Bearer ${access_token}`
        }
      })
        .then(response => {
          if (response.status === 200) {
            loadRedTagData(); // Reload data after approving status
            handleStoreClose();
          }
        })
        .catch(error => {
          console.error("Error approving red tag status:", error);
        });
    }
  };

  const loadRedTagData = () => {
    setLoading(true);
    const plant_id = localStorage.getItem('selectedPlantId');
    axios.get(`${ApiURL}/getallredtagactivities`, {
      params: {
        plant_id: plant_id
      },
      headers: {
        Authorization: `Bearer ${access_token}`
      }
    })
      .then(response => {
        setLoading(false);
        if (response.status === 200 && response.data) {
          const allData = response.data;
          console.log('API Response:', allData);
          setAllUsers(allData);
          setZoneActivities(allData.filter(user => user.status === 'Created'));
          // setCommonRedTags(allData.filter(user => user.status === 'Move to common area'));
          setCommonRedTags(allData.filter(user => ['Move to common area','To be Inspected' ].includes(user.status)));

          setScrapYardActivities(allData.filter(user => ['Disposed/Move to scrap','Move to scrap'].includes(user.status)));
          setRedeployedActivities(allData.filter(user => user.status === 'Redeployed'));
        }
      })
      .catch(error => {
        setLoading(false);
        console.error("Error fetching red tag data:", error);
      });
  };
console.log("openRedTag",dialogFormOpen)

   
const getRole = () => {
  const userDatas = JSON.parse(localStorage.getItem("userDatas"));
const userid = userDatas.sgid;
const plantId = localStorage.getItem('selectedPlantId');
  axios.get(`${ApiURL}/getroleuser/${userid}/${plantId}`,{headers: {
      Authorization: `Bearer ${access_token}`,
     
    }})
      .then(response => {
          setLoading(false);
          if (response.status === 200) {
              if (response.data && response.data.length > 0) {
                  setuser(response.data);
                 
              }
          }
      })
      .catch(error => {
          setLoading(false);
          console.error("Error fetching assessment data:", error);
      });
};

const isCoordinator = userRole.some(user => user.wcm_coordinator_firstname !== '' &&  user.wcm_coordinator_firstname !== null);
console.log("isCoordinator",isCoordinator)
const iszoneleader = userRole.some(user => user.zone_first_name !== '' &&  user.zone_first_name !== null);
console.log("iszoneleader",iszoneleader)
const isgroupleader = userRole.some(user => user.group_firstname !== '' &&  user.group_firstname !== null);
console.log("isgroupleader",isgroupleader)


const isPlantHead = userRole.some(user => user.plant_headfirstname !== '' &&  user.plant_headfirstname !== null);
console.log("isPlantHead",isPlantHead)


const isStore = userRole.some(user => user.store_headfirstname !== '' &&  user.store_headfirstname !== null);
console.log("isStore",isStore)
// const isgroupleader = userRole.some(user => user.group_firstname !== '' &&  user.group_firstname !== null);
// console.log("isCoordinator",isgroupleader)

  const renderTabContent = (users) => {
    return users.length === 0 ? (
      <Typography variant="h6">No Red Tags</Typography>
    ) : (
      users.map((user, index) => (
        // <Grid container>
        <Card key={user.id} variant="outlined" sx={{ position: 'relative', marginBottom: 2 }}>
          <Box position="absolute" top={8} right={8}> {/* Button positioned in top-right corner */}
            <IconButton aria-label="options" onClick={(event) => handleMenuIconClick(event, user)}>
              <MoreVertIcon />
            </IconButton>
          </Box>
          <div style={{ display: "flex", flexDirection: "column", flexGrow: 1, padding: 16 }}>
            <div style={{ marginBottom: "20px" }}>
              <Typography style={{ fontWeight: 550, fontSize: "16px", color: '#1D1B20' }}>Red Tag Number</Typography>
              <Typography style={{ fontWeight: 500, fontSize: "15px", color: '#616161' }}>{user.red_tagno}</Typography>
            </div>
            <div style={{ marginBottom: "20px" }}>
              <Typography style={{ fontWeight: 550, fontSize: "16px", color: '#1D1B20' }}>Zone</Typography>
              <Typography style={{ fontWeight: 500, fontSize: "15px", color: '#616161' }}>{user.code} - {user.name}</Typography>
            </div>
            <div style={{ marginBottom: "20px" }}>
              <Typography style={{ fontWeight: 550, fontSize: "16px", color: '#1D1B20' }}>Item Name: <span style={{ fontWeight: 500, fontSize: "15px", color: '#616161' }}>{user.item_name}</span></Typography>
            </div>
            <div style={{ marginBottom: "20px" }}>
              <Typography style={{ fontWeight: 550, fontSize: "16px", color: '#1D1B20' }}>Qty: <span style={{ fontWeight: 500, fontSize: "15px", color: '#616161' }}>{user.qty} - {user.unit_of_measure}</span></Typography>
            </div>
            <div style={{ marginBottom: "20px" }}>
              <Typography style={{ fontWeight: 550, fontSize: "16px", color: '#1D1B20' }}>Amount: <span style={{ fontWeight: 500, fontSize: "15px", color: '#616161' }}>₹{user.approximate}</span></Typography>
            </div>
            <div style={{ marginBottom: "20px" }}>
              <Typography style={{ fontWeight: 550, fontSize: "16px", color: '#1D1B20' }}>Disposal Method: <span style={{ fontWeight: 500, fontSize: "15px", color: '#616161' }}>{user.disposal_method}</span></Typography>
            </div>
            <div style={{ marginBottom: "20px" }}>
              <Typography style={{ fontWeight: 550, fontSize: "16px", color: '#1D1B20' }}>Status: <span style={{ fontWeight: 500, fontSize: "15px", color: '#616161' }}>{user.status}</span></Typography>
            </div>
            <div style={{ borderBottom: "1px solid #E8E8E8", marginBottom: "10px" }}></div> {/* Line */}
            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "5px" }}>
              <Typography style={{ fontWeight: 500, fontSize: "14px", color: '#616161', display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                <div>Last Updated: {new Date(user.entry_date).toLocaleDateString()}</div>
              </Typography>
              <Typography style={{ fontWeight: 500, paddingRight: "52px", fontSize: "14px", color: '#616161', display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                <div style={{ marginBottom: "5px" }}>Entry By: {user.firstName + " " + user.lastName}</div>
              </Typography>
            </div>
          </div>
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClosePopover}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <Box p={2}>
              {/* <List>
                {isgroupleader &&(
                  <>
                {editUser && editUser.status === 'Created' && (
                  <ListItem button onClick={() => handleEdit(editUser)}>
                    <ListItemText primary="Review Red Tag" />
                  </ListItem>
                                  )}
   </>                           
):(
 {isCoordinator &&(
<>
                {editUser && ['Move to common area'].includes(editUser.status) && (
  <ListItem button onClick={() => handleCommonEdit(editUser)}>
    <ListItemText primary="Common Review Red Tag" />
  </ListItem>

)}  
 

{ editUser && editUser.status === "To be Inspected" && (
                <>
                  <ListItem button onClick={handleApprovalOpen}>
                    <ListItemText primary="Approve" />
                  </ListItem>
                  <ListItem button onClick={() => handleCommonEdit(editUser)}>
                    <ListItemText primary="Common Review Red Tag" />
                  </ListItem>
                </>
              )}
              </>
 )}
           ):(
                <>
                {editUser && ['Disposed/Move to scrap','Move to scrap'].includes(editUser.status) && (
                  <ListItem button onClick={() => handleScrapEdit(editUser)}>
                    <ListItemText primary="Scrap Review Red Tag" />
                  </ListItem>
                )}
                </>
              )
              }


                
              </List> */}



<List>
  {isgroupleader ? (
    <>
      {editUser && editUser.status === 'Created' && (
        <ListItem button onClick={() => handleEdit(editUser)}>
          <ListItemText primary="Review Red Tag" />
        </ListItem>
      )}

      {editUser && editUser.status === 'To be Inspected' && (
        <>
          {isStore && (
            <ListItem button onClick={handleApprovalOpen}>
              <ListItemText primary="Store status" />
            </ListItem>
          )}
          {isPlantHead && (
            <ListItem button onClick={() => handleApprovalOpen('approve')}>
              <ListItemText primary="Approve" />
            </ListItem>
          )}
          <ListItem button onClick={() => handleCommonEdit(editUser)}>
            <ListItemText primary="Common Review Red Tag" />
          </ListItem>
        </>
      )}
    </>
  ) : (
    <>
      {isCoordinator && (
        <>
          {editUser && editUser.status === 'Move to common area' && (
            <ListItem button onClick={() => handleCommonEdit(editUser)}>
              <ListItemText primary="Common Review Red Tag" />
            </ListItem>
          )}
          {editUser && editUser.status === 'To be Inspected' && (
            <>
              {isStore && (
                <ListItem button onClick={handleApprovalOpen}>
                  <ListItemText primary="Store status" />
                </ListItem>
              )}
              {isPlantHead && (
                <ListItem button onClick={() => handleApprovalOpen('approve')}>
                  <ListItemText primary="Approve" />
                </ListItem>
              )}
              <ListItem button onClick={() => handleCommonEdit(editUser)}>
                <ListItemText primary="Common Review Red Tag" />
              </ListItem>
            </>
          )}
        </>
      )}
    </>
  )}
  
  {editUser && ['Disposed/Move to scrap', 'Move to scrap'].includes(editUser.status) && (
    <ListItem button onClick={() => handleScrapEdit(editUser)}>
      <ListItemText primary="Scrap Review Red Tag" />
    </ListItem>
  )}
</List>


              {/* <List> */}
  {/* {isgroupleader ? (
    <>
      {editUser && editUser.status === 'Created' && (
        <ListItem button onClick={() => handleEdit(editUser)}>
          <ListItemText primary="Review Red Tag" />
        </ListItem>
      )}

{editUser && editUser.status === 'To be Inspected' && (
            <>
           
               {isStore && (
                 //considering zone leader as store manager
              <ListItem button onClick={handleApprovalOpen}>
                <ListItemText primary="Store status" />
              </ListItem>
            )}
            {isPlantHead && (
               //considering group leader as plant head
        <ListItem button onClick={() => handleApprovalOpen('approve')}>
          <ListItemText primary="Approve" />
        </ListItem>
      )}
              <ListItem button onClick={() => handleCommonEdit(editUser)}>
                <ListItemText primary="Common Review Red Tag" />
              </ListItem>
            </>
          )}

{/* {editUser && editUser.status === 'To be Inspected' && (
        <>
          <ListItem button onClick={() => handleApprovalOpen('approve')}>
            <ListItemText primary="Approve" />
          </ListItem>
          <ListItem button onClick={() => handleCommonEdit(editUser)}>
            <ListItemText primary="Common Review Red Tag" />
          </ListItem>
        </>
      )} */}
    {/* </>
  ) : (
    <>
      {isCoordinator ? (
        <>
          {editUser && ['Move to common area'].includes(editUser.status) && (
            <ListItem button onClick={() => handleCommonEdit(editUser)}>
              <ListItemText primary="Common Review Red Tag" />
            </ListItem>
          )}
          {editUser && editUser.status === 'To be Inspected' && (
            <>
           
               {isStore && (
                 //considering zone leader as store manager
              <ListItem button onClick={handleApprovalOpen}>
                <ListItemText primary="Store status" />
              </ListItem>
            )}
            {isPlantHead && (
               //considering group leader as plant head
        <ListItem button onClick={() => handleApprovalOpen('approve')}>
          <ListItemText primary="Approve" />
        </ListItem>
      )}
              <ListItem button onClick={() => handleCommonEdit(editUser)}>
                <ListItemText primary="Common Review Red Tag" />
              </ListItem>
            </>
          )}
        </>
      ) : 
      null
      // (
      //   <>
      //     {editUser && ['Disposed/Move to scrap','Move to scrap'].includes(editUser.status) && (
      //       <ListItem button onClick={() => handleScrapEdit(editUser)}>
      //         <ListItemText primary="Scrap Review Red Tag" />
      //       </ListItem>
      //     )}
      //   </>
      // )
      }
    </>
  )} */} 
  {/* {editUser && ['Disposed/Move to scrap', 'Move to scrap'].includes(editUser.status) && (
    <ListItem button onClick={() => handleScrapEdit(editUser)}>
      <ListItemText primary="Scrap Review Red Tag" />
    </ListItem>
  )} */}
{/* </List> */}

            </Box>
          </Popover>
        </Card>
      
      ))
    );
  };
  console.log("editUser status", editUser?.status);
  console.log("editUser ", editUser);
  return (
    
    <Box style={{ padding: "16px"}}>
                   <Paper
      component="form"
      sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: "100%",boxShadow:'none',borderBottom:'1px solid #e6e6e6',borderRadius:'0' }}
    >
      <Typography variant='h5' sx={{fontSize:'18px',fontWeight:'600',padding:'0 16px'}}>
                        Red Tag
                    </Typography>
      {/* <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Search Audit"
        inputProps={{ 'aria-label': 'Filter' }}
        value={searchText}
        onChange={handleSearchInputChange}
      /> */}
      {/* <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
        <SearchIcon />
      </IconButton> */}
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      {/* <IconButton onClick={(event) => setAnchorFilterEl(event.currentTarget)} aria-label="filter">
                        <FilterListIcon/>
                    </IconButton> */}
      {/* <IconButton onClick={(event) => setAnchorFilterEl(event.currentTarget)} aria-label="filter">
                        <VerticalAlignBottomIcon/>
                    </IconButton> */}
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      <Button
              variant="contained"
              style={{
                background: '#0086DC',
                width: '130px',
                border: 0,
                borderRadius: 3,
                color: 'white',
                height: '34px',
                fontSize:'14px',
                textTransform:'capitalize',
                margin: '0 8px'
              }}
              onClick={handleRedAddClick}
            >
                        <AddIcon/>
              New Entry
            </Button>
                    </Paper>
                
    {/* <Header pageTitle="Red Tag" isRedTagPage={true} /> */}
    <Box >
       
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab label="Zone Activities" value="0" />
          <Tab label="Common Red Tag Area" value="1" />
          <Tab label="Scrap Yard Activities" value="2" />
          <Tab label="Redeployed" value="3" />
        </Tabs>
      </Box>
      <Box p={3}>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            {tabValue === '0' && renderTabContent(zoneActivities)}
            {tabValue === '1' && renderTabContent(commonRedTags)}
            {tabValue === '2' && renderTabContent(scrapYardActivities)}
            {tabValue === '3' && renderTabContent(redeployedActivities)}
          </>
        )}

<Dialog open={openStatusModal} onClose={handleStoreClose}>
        {/* <DialogTitle>Store status Red Tag</DialogTitle> */}
        <DialogTitle>{dialogActionType === 'approve' ? 'Approve Red Tag' : 'Store status Red Tag'}</DialogTitle>
        <DialogContent>
          <FormControl>
            <FormLabel>{dialogActionType === 'approve' ? 'Approve Status' : 'Store status '}</FormLabel>
            <RadioGroup value={storeStatus} onChange={handleStoreStatusChange}>
              <FormControlLabel value="Disposed/Move to scrap" control={<Radio />} label="Disposed/Move to scrap" />
              <FormControlLabel value="Redeployed" control={<Radio />} label="Redeployed" />
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleStoreStatus} color="primary">Submit</Button> */}
          <Button onClick={dialogActionType === 'approve' ? handleApproveStatus : handleStoreStatus} color="primary">
      {dialogActionType === 'approve' ? 'Approve' : 'Submit'}
    </Button>
          <Button onClick={handleStoreClose} color="secondary">Cancel</Button>
        </DialogActions>
      </Dialog>
      </Box>
    </Box>
    <NewRedTag  open={dialogOpen}
        onClose={handleRedTagDialogClose}
        //onNext={handleRedTagDialogNext}
        />
         {dialogFormOpen && (
      <RedTagForm open={dialogFormOpen} onClose={handleDialogClose} />
    )}
    </Box>
    
    
  );
};

export default RedTagActivities;



//monisha code
// const RedTagActivities = () => {
//   const navigate = useNavigate();
//   const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
//   const [confirmationscrapDialogOpen, setConfirmationscrapDialogOpen] = useState(false);
//   const [originalUsers, setOriginalUsers] = useState([]);
//   const [filteredUsers, setFilteredUsers] = useState([]);  
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [editUser, setEditUser] = useState(null);
//   const [tabValue, setTabValue] = useState(0);
//   const [loading, setLoading] = useState(false);
//   const [userRole, setUserRole] = useState(null);
//   const ApiURL = configParam.API_URL;
//   const access_token = JSON.parse(localStorage.getItem("accesstoken"));


//   const handleCardClick = (title) => {
//     if (title === "Zone Activities") {
//       navigate("/NewRedTagDetails");
//     }
//   };

//   useEffect(() => {
//     loadRedTagData();
//     const userDatas = JSON.parse(localStorage.getItem("userDatas"));
//     if (userDatas) {
//       const role = userDatas.role_id; // Assuming the role is stored in userDatas
//       setUserRole(role);
//       console.log("role",role);
//     }
   
//   }, []);

//   const handleMenuIconClick = (event, user) => {
//     setAnchorEl(event.currentTarget);
//     setEditUser(user);
//   };

//   const handleClosePopover = () => {
//     setAnchorEl(null);
//     setEditUser(null);
//   };

//   const handleEdit = (user) => {
//     const RedTagId = user.id;
//     const RedTagIdeditflg = true;
//     localStorage.setItem("redtagId", RedTagId);
//     localStorage.setItem("redtageditflg", RedTagIdeditflg);
//     navigate('/RedTagForm');
//   };


//   const handleCommonEdit = (user) => {
//     const RedTagId = user.id;
//     const RedTagIdeditflg = true;
//     localStorage.setItem("redtagId", RedTagId);
//     localStorage.setItem("redtagcommoneditflg", RedTagIdeditflg);
//     navigate('/RedTagForm');
//   };

//   const handleScrapEdit = (user) => {
//     const RedTagId = user.id;
//     const RedTagIdeditflg = true;
//     localStorage.setItem("redtagId", RedTagId);
//     localStorage.setItem("redtagscrapeditflg", RedTagIdeditflg);
//     navigate('/RedTagForm');
//   };

//   const handleConfirmationCancel = () => {
//     setConfirmationDialogOpen(false);
//   };

//   const handlescrapConfirmationCancel = () => {
//     setConfirmationscrapDialogOpen(false);
//   };

//   const handlescrapConfirmationConfirm = () => {
//     setConfirmationscrapDialogOpen(false);
//     executeMovetoscrap(editUser);
//   };

//   const handleConfirmationConfirm = () => {
//     setConfirmationDialogOpen(false);
//     executeMovetoCommon(editUser);
//   };

//   const executeMovetoCommon = async (user) => {
//     const redTagId = user.id;
//     setLoading(true);

//     try {
//       const response = await axios.post(
//         `${ApiURL}/InsertCommon_area/${redTagId}`,
//         null, // No data to send in the request body
//         {
//           headers: {
//             Authorization: `Bearer ${access_token}`
//           }
//         }
//       );

//       console.log('Moveto Common Area successfully.');
//       AlertDialog({
//         type: 'success',
//         title: 'Success',
//         text: 'Moveto Common Area successfully.'
//       });

//       if (response.data.success) {
//         loadRedTagCommonData();
//       }
//     } catch (error) {
//       console.error('Error executing movetoCommon:', error);
//       // Handle the error as needed, e.g., display an error message to the user
//     } finally {
//       setLoading(false); // Ensure loading state is reset even if an error occurs
//     }
//   };

//   const handleMovetoCommon = (user) => {
//     setEditUser(user);
//     setConfirmationDialogOpen(true);
//   };

//   const handleScrapYard = (user) => {
//     setEditUser(user);
//     setConfirmationscrapDialogOpen(true);
//   };

//   const executeMovetoscrap = async (user) => {
//     const redTagId = user.id;
//     setLoading(true);

//     try {
//       const response = await axios.post(
//         `${ApiURL}/Insertscrap_yard/${redTagId}`,
//         null, // No data to send in the request body
//         {
//           headers: {
//             Authorization: `Bearer ${access_token}`
//           }
//         }
//       );

//       console.log('Moveto Scrap Area successfully.');
//       AlertDialog({
//         type: 'success',
//         title: 'Success',
//         text: 'Moveto Scrap Area successfully.'
//       });

//       if (response.data.success) {
//         loadRedTagScrapData();
//       }
//     } catch (error) {
//       console.error('Error executing movetoCommon:', error);
//       // Handle the error as needed, e.g., display an error message to the user
//     } finally {
//       setLoading(false); // Ensure loading state is reset even if an error occurs
//     }
//   };

//   const handleTabChange = (event, newValue) => {
//     setTabValue(newValue);
//     if (newValue === '0') {
//       loadRedTagData();
//     } else if (newValue === '1') {
//       loadRedTagCommonData();
//     } else if (newValue === '2') {
//       loadRedTagScrapData();
//     }
//   };

//   const loadRedTagData = () => {
//     setOriginalUsers([]);
//     setFilteredUsers([]);
//     setLoading(true);
//     const plant_id = localStorage.getItem('selectedPlantId'); 
//     axios.get(`${ApiURL}/getredtagzoneactivities`, {
//       params: {
//         plant_id: plant_id
//       },
//       headers: {
//         Authorization: `Bearer ${access_token}`
//       }
//     })
//       .then(response => {
//         setLoading(false);
//         if (response.status === 200) {
//           if (response.data && response.data.length > 0) {
//             setOriginalUsers(response.data);
//             setFilteredUsers(response.data);
//           }
//         }
//       })
//       .catch(error => {
//         setLoading(false);
//         console.error("Error fetching assessment data:", error);
//       });
//   };

//   const loadRedTagCommonData = () => {
//     setOriginalUsers([]);
//     setFilteredUsers([]);
//     setLoading(true);
//     const plant_id = localStorage.getItem('selectedPlantId'); 
//     axios.get(`${ApiURL}/getredtagcommonactivites`, {
//       params: {
//         plant_id: plant_id
//       },
//       headers: {
//         Authorization: `Bearer ${access_token}`
//       }
//     })
//       .then(response => {
//         setLoading(false);
//         if (response.status === 200) {
//           if (response.data && response.data.length > 0) {
//             setOriginalUsers(response.data);
//             setFilteredUsers(response.data);
//           }
//         }
//       })
//       .catch(error => {
//         setLoading(false);
//         console.error("Error fetching assessment data:", error);
//       });
//   };

//   const loadRedTagScrapData = () => {
//     setOriginalUsers([]);
//     setFilteredUsers([]);
//     setLoading(true);
//     const plant_id = localStorage.getItem('selectedPlantId'); 
//     axios.get(`${ApiURL}/getredtagscrapactivites`, {
//       params:{
//         plant_id: plant_id
//       },
//       headers: {
//         Authorization: `Bearer ${access_token}`
//       }
//     })
//       .then(response => {
//         setLoading(false);
//         if (response.status === 200) {
//           if (response.data && response.data.length > 0) {
//             setOriginalUsers(response.data);
//             setFilteredUsers(response.data);
//           }
//         }
//       })
//       .catch(error => {
//         setLoading(false);
//         console.error("Error fetching assessment data:", error);
//       });
//   };

//   return (
//     <div>
//       <Header pageTitle="Red Tag" isRedTagPage={true} />
//       <div style={{ marginTop: "60px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
//         {/* Tabs */}
//         <div style={{ flexGrow: 1 }}>
//           <Tabs value={tabValue} onChange={handleTabChange} aria-label="Audit tabs">
//             <Tab label="Zone Activities" value='0' />
//             <Tab label="Common Red Tag Area" value='1' />
//             <Tab label="Scrap Yard Activities" value='2' />
//           </Tabs>
//         </div>
//       </div>

//       <div style={{ marginTop: "20px", marginLeft: "20px", marginRight: "20px", marginBottom: "20px" }}>
//         {loading ? (
//           <div style={{ textAlign: 'center' }}>
//             <Typography variant="h6">Loading...</Typography>
//           </div>
//         ) : filteredUsers.length === 0 ? (
//           <Typography variant="h6">No Red Tags</Typography>
//         ) : (
//           filteredUsers.map((user, index) => (
//             <Card
//               key={user.row_id}
//               style={{
//                 width: "98%",
//                 padding: 10,
//                 borderRadius: "8px",
//                 border: "1px solid #E8E8E8",
//                 background: "#FFF",
//                 marginBottom: "30px",
//                 boxShadow: "0px 2px 12px 0px rgba(0, 0, 0, 0.15)",
//                 position: "relative", // Added for positioning
//               }}
//               variant="outlined"
//             >
//               <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
//                 <div style={{ marginBottom: "20px" }}>
//                   <Typography style={{ fontWeight: 550, fontSize: "16px", color: '#1D1B20' }}>Red Tag Number</Typography>
//                   <Typography style={{ fontWeight: 500, fontSize: "15px", color: '#616161' }}>{user.red_tagno}</Typography>
//                 </div>
//                 <div style={{ marginBottom: "20px" }}>
//                   <Typography style={{ fontWeight: 550, fontSize: "16px", color: '#1D1B20' }}>Zone</Typography>
//                   <Typography style={{ fontWeight: 500, fontSize: "15px", color: '#616161' }}>{user.code} - {user.name}</Typography>
//                 </div>
//                 <div style={{ marginBottom: "20px" }}>
//                   <Typography style={{ fontWeight: 550, fontSize: "16px", color: '#1D1B20' }}>Item Name: <span style={{ fontWeight: 500, fontSize: "15px", color: '#616161' }}>{user.item_name}</span> </Typography>
                  
//                 </div>
//                 <div style={{ marginBottom: "20px" }}>
//                   <Typography style={{ fontWeight: 550, fontSize: "16px", color: '#1D1B20' }}>Qty: <span style={{ fontWeight: 500, fontSize: "15px", color: '#616161' }}>{user.qty} - {user.unit_of_measure}</span> </Typography>
                  
//                 </div>
//                 <div style={{ marginBottom: "20px" }}>
//                   <Typography style={{ fontWeight: 550, fontSize: "16px", color: '#1D1B20' }}>Amount: <span style={{ fontWeight: 500, fontSize: "15px", color: '#616161' }}>₹{user.approximate}</span> </Typography>
                  
//                 </div>
//                 <div style={{ marginBottom: "20px" }}>
//                   <Typography style={{ fontWeight: 550, fontSize: "16px", color: '#1D1B20' }}>Disposal Method: <span style={{ fontWeight: 500, fontSize: "15px", color: '#616161' }}>{user.disposal_method}</span>  </Typography>
                 
//                 </div>
//                 <div style={{ borderBottom: "1px solid #E8E8E8", marginBottom: "10px" }}></div> {/* Line */}
//                 <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "5px" }}>
//                   <Typography style={{ fontWeight: 500, fontSize: "14px", color: '#616161', display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
//                     <div>Last Updated: {new Date(user.entry_date).toLocaleDateString()}</div>
//                   </Typography>
//                   <Typography style={{ fontWeight: 500, paddingRight: "52px", fontSize: "14px", color: '#616161', display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
//                     <div style={{ marginBottom: "5px" }}>Entry By: {user.firstName + " " + user.lastName}</div>
//                   </Typography>
//                 </div>
//               </div>
//               <Box position="absolute" top={8} right={8}> {/* Button positioned in top-right corner */}
//                 <IconButton aria-label="options" onClick={(event) => handleMenuIconClick(event, user)}>
//                   <MoreVertIcon style={{ color: 'black' }} />
//                 </IconButton>
//               </Box>
//             </Card>
//           ))
//         )}
//         <Popover
//           open={Boolean(anchorEl)}
//           anchorEl={anchorEl}
//           onClose={handleClosePopover}
//           anchorOrigin={{
//             vertical: 'bottom',
//             horizontal: 'right',
//           }}
//           transformOrigin={{
//             vertical: 'top',
//             horizontal: 'right',
//           }}
//         >
       
//        {!editUser?.common_area && !editUser?.scrap_yard && (
//           <>
//             <div onClick={() => handleEdit(editUser)} style={{ display: "flex", alignItems: 'center', cursor: 'pointer', padding: "10px" }}>
//               <img src={EditIcon} alt="Edit Icon" style={{ marginRight: '10px' }} />
//               <Typography
//                 style={{
//                   color: '#252C32',
//                   fontFamily: 'Inter',
//                   fontSize: '16px',
//                   fontStyle: 'normal',
//                   fontWeight: 800,
//                   lineHeight: 'normal',
//                   letterSpacing: '-0.084px',
//                 }}>Review Red Tag</Typography>
//             </div>

//             <div onClick={() => handleMovetoCommon(editUser)} style={{ display: "flex", alignItems: 'center', cursor: 'pointer', padding: "10px" }}>
//               <img src={Eyeicon} alt="Edit Icon" style={{ marginRight: '10px' }} />
//               <Typography
//                 style={{
//                   color: '#252C32',
//                   fontFamily: 'Inter',
//                   fontSize: '16px',
//                   fontStyle: 'normal',
//                   fontWeight: 800,
//                   lineHeight: 'normal',
//                   letterSpacing: '-0.084px',
//                 }}>Move to common Red Tag Area</Typography>
//             </div>
     
//             <div onClick={() => handleScrapYard(editUser)} style={{ display: "flex", alignItems: 'center', cursor: 'pointer', padding: "10px" }}>
//               <img src={PlusIcon} alt="Edit Icon" style={{ marginRight: '10px' }} />
//               <Typography
//                 style={{
//                   color: '#252C32',
//                   fontFamily: 'Inter',
//                   fontSize: '16px',
//                   fontStyle: 'normal',
//                   fontWeight: 800,
//                   lineHeight: 'normal',
//                   letterSpacing: '-0.084px',
//                 }}>Move Tags to ScrapYard</Typography>
//             </div>
//             </>
//        )}
//             {/* {!editUser?.common_area && !editUser?.scrap_yard && (
//                       <>
//             <div onClick={() => handleMovetoCommon(editUser)} style={{ display: "flex", alignItems: 'center', cursor: 'pointer', padding: "10px" }}>
//               <img src={Eyeicon} alt="Edit Icon" style={{ marginRight: '10px' }} />
//               <Typography
//                 style={{
//                   color: '#252C32',
//                   fontFamily: 'Inter',
//                   fontSize: '16px',
//                   fontStyle: 'normal',
//                   fontWeight: 800,
//                   lineHeight: 'normal',
//                   letterSpacing: '-0.084px',
//                 }}>Move to common Red Tag Area</Typography>
//             </div>
     
//             <div onClick={() => handleScrapYard(editUser)} style={{ display: "flex", alignItems: 'center', cursor: 'pointer', padding: "10px" }}>
//               <img src={PlusIcon} alt="Edit Icon" style={{ marginRight: '10px' }} />
//               <Typography
//                 style={{
//                   color: '#252C32',
//                   fontFamily: 'Inter',
//                   fontSize: '16px',
//                   fontStyle: 'normal',
//                   fontWeight: 800,
//                   lineHeight: 'normal',
//                   letterSpacing: '-0.084px',
//                 }}>Move Tags to ScrapYard</Typography>
//             </div>
//             </> */}
//                     {/* )} */}
//   {editUser?.common_area === "Y" && (
//                       <>
//                       <div onClick={() => handleCommonEdit(editUser)} style={{ display: "flex", alignItems: 'center', cursor: 'pointer', padding: "10px" }}>
//   <img src={EditIcon} alt="Edit Icon" style={{ marginRight: '10px' }} />
//   <Typography
//     style={{
//       color: '#252C32',
//       fontFamily: 'Inter',
//       fontSize: '16px',
//       fontStyle: 'normal',
//       fontWeight: 800,
//       lineHeight: 'normal',
//       letterSpacing: '-0.084px',
//     }}>Common Review Red Tag</Typography>
// </div>
                      
//                       </>
//   )}  

// {editUser?.scrap_yard === "Y" && (
//                       <>
//                       <div onClick={() => handleScrapEdit(editUser)} style={{ display: "flex", alignItems: 'center', cursor: 'pointer', padding: "10px" }}>
//   <img src={EditIcon} alt="Edit Icon" style={{ marginRight: '10px' }} />
//   <Typography
//     style={{
//       color: '#252C32',
//       fontFamily: 'Inter',
//       fontSize: '16px',
//       fontStyle: 'normal',
//       fontWeight: 800,
//       lineHeight: 'normal',
//       letterSpacing: '-0.084px',
//     }}>Scrap Review Red Tag</Typography>
// </div>
                      
//                       </>
//   )}  

//         </Popover>
//       </div>
//       <Dialog open={confirmationDialogOpen} onClose={handleConfirmationCancel}>
//         <DialogTitle>Confirmation</DialogTitle>
//         <DialogContent>
//           Are you sure you want to move this red tag to the common area?
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleConfirmationCancel}>Cancel</Button>
//           <Button onClick={handleConfirmationConfirm}>Confirm</Button>
//         </DialogActions>
//       </Dialog>

//       <Dialog open={confirmationscrapDialogOpen} onClose={handlescrapConfirmationCancel}>
//         <DialogTitle>Confirmation</DialogTitle>
//         <DialogContent>
//           Are you sure you want to move this red tag to the scrap area?
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handlescrapConfirmationCancel}>Cancel</Button>
//           <Button onClick={handlescrapConfirmationConfirm}>Confirm</Button>
//         </DialogActions>
//       </Dialog>
//     </div>
//   );
// };

// export default RedTagActivities;
