import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Header from '../../app/TopAppBar';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,Typography,Button,Select,MenuItem,IconButton} from '@mui/material';
import SgLogo from '../../Asset/Icons/SgLogo.png';
import axios from 'axios';
import configParam from '../../config';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '../../Asset/Icons/ic_edit.svg';
import { useNavigate } from 'react-router-dom';
import FilterIcon from '../../Asset/Icons/filter_icon.svg';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'; 
import GetAppIcon from '@mui/icons-material/GetApp';

import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';


const Reports = () => {
  const ApiURL = configParam.API_URL;
  const access_token = JSON.parse(localStorage.getItem("accesstoken"));
  const [data, setData] = useState([]);
  const [zonesWithoutAssessments, setZonesWithoutAssessments] = useState([]);
  const [monthsAndYears, setMonthsAndYears] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  

  useEffect(() => {
    const currentMonth = new Date().getMonth() + 1; // Months are 0-based
    const currentYear = new Date().getFullYear();
    setSelectedMonth(currentMonth);
    setSelectedYear(currentYear);
  }, []);

  useEffect(() => {
    fetchReportData();
    fetchZonesWithoutAssessments();
    fetchMonthsAndYears();
  }, [selectedMonth, selectedYear]);

  const fetchMonthsAndYears = async () => {
    try {
      const selectedPlantId = localStorage.getItem('selectedPlantId');
      const response = await axios.get(`${ApiURL}/get_available_months_and_years`, { headers: { Authorization: `Bearer ${access_token}`}, params: { selectedPlantId } });
      setMonthsAndYears(response.data);
    } catch (error) {
      console.error('Error fetching months and years:', error);
    }
  };


  const fetchReportData = async () => {
    try {
      const selectedPlantId = localStorage.getItem('selectedPlantId');
      const response = await axios.get(`${ApiURL}/get_avgscore_perzone`, { 
        headers: { Authorization: `Bearer ${access_token}` },
        params: { month: selectedMonth, year: selectedYear,selectedPlantId}
      });
      setData(response.data);
    } catch (error) {
      console.error('Error fetching reports:', error);
    }
  };
 


  const fetchZonesWithoutAssessments = async () => {
    try {
      const selectedPlantId = localStorage.getItem('selectedPlantId');
      const response = await axios.get(`${ApiURL}/get_zones_noassessments`, { 
        headers: { Authorization: `Bearer ${access_token}` },
        params: { month: selectedMonth, year: selectedYear,selectedPlantId}
      });
      setZonesWithoutAssessments(response.data);
    } catch (error) {
      console.error('Error fetching zones without assessments:', error);
    }
  };


  const getAuditLevel = (score) => {
    if (score >= 1 && score <= 6) return 1;
    if (score >= 7 && score <= 12) return 2;
    if (score >= 13 && score <= 18) return 2.5;
    if (score >= 19 && score <= 24) return 3;
    if (score >= 25 && score <= 30) return 3.5;
    if (score >= 31 && score <= 36) return 4;
    return 'Unknown';
  };
  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  }
  
  
 
  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };
 


  const handleDownloadRow = async (zoneId, auditDate) => {
  try {
    const response = await axios.get(`${ApiURL}/download-excel-reports`, {
      headers: { Authorization: `Bearer ${access_token}` },
      params: { zoneId, auditDate },
      responseType: 'blob', // Add this line
    });

    // Create a download link and trigger the download
    const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
    const a = document.createElement('a');
    a.href = url;
    a.download = `Zone_${zoneId}_Audit_${auditDate}.xlsx`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error downloading excel file:', error);
  }
};


  

//old code
  // const printPdf = async () => {
  //   try {
  //     // Get the HTML content of the table
  //     const tableElement = document.getElementById('auditedZoneTable');
  //     const htmlContent = `
  //       <html>
  //       <head>
  //         <title>Audited Zone Report</title>
  //         <style>
  //           /* Add any custom styling for the print version here */
  //           table {
  //             border-collapse: collapse;
  //             width: 100%;
  //           }
  //           th, td {
  //             border: 1px solid black;
  //             padding: 8px;
  //             text-align: left;
  //           }
  //           th {
  //             background-color: #f2f2f2;
  //           }
  //           tr {
  //             break-inside: avoid; /* Prevent table rows from being split across pages */
  //           }
  //               img {
                                
  //                               max-width: 100%;
  //                               height: auto;
  //                               page-break-inside: avoid;
  //                           }
  //                           .logo {
  //                               display: block;
  //                               margin: 0 auto;
  //                           }
  //         </style>
  //       </head>
  //       <body>
  //           <div>
  //                   <img class="logo" src="${SgLogo}" alt="Company Logo" />
  //               </div>
  //         <h2>Score for the Audited Zone</h2>
  //         ${tableElement.outerHTML}
  //       </body>
  //       </html>
  //     `;
  //     console.log('HTML Content:', htmlContent); // Log HTML content
  //     const logoImage = new Image();
  // logoImage.src = SgLogo;
  // logoImage.onload = () => {
  //     // Open print window with HTML content
  //     const printWindow = window.open('', '_blank');
  //     if (!printWindow) {
  //         throw new Error("Unable to open print window. Please check your browser settings.");
  //     }
  //     printWindow.document.write(htmlContent);
  //     printWindow.document.close();
  //     printWindow.print();
  // };
      
  //     // Open a new window and write the HTML content to it
  //     // const printWindow = window.open('', '_blank');
  //     // printWindow.document.write(htmlContent);
  //     // printWindow.document.close();
  
  //     // Print the contents of the new window
  //  //   printWindow.print();
  //   } catch (error) {
  //     console.error('Error printing PDF:', error);
  //   }
  // };
  

  const printPdf = async () => {
  try {
    // Get the HTML content of the table
    const tableElement = document.getElementById('auditedZoneTable');
    
    // Clone the table and remove the last column
    const tableClone = tableElement.cloneNode(true);
    for (let i = 0; i < tableClone.rows.length; i++) {
      tableClone.rows[i].deleteCell(-1); // Remove the last cell of each row
    }

    const htmlContent = `
      <html>
      <head>
        <title>Audited Zone Report</title>
        <style>
          table {
            border-collapse: collapse;
            width: 100%;
          }
          th, td {
            border: 1px solid black;
            padding: 8px;
            text-align: left;
          }
          th {
            background-color: #f2f2f2;
          }
          tr {
            break-inside: avoid;
          }
          img {
            max-width: 100%;
            height: auto;
            page-break-inside: avoid;
          }
          .logo {
            display: block;
            margin: 0 auto;
          }
        </style>
      </head>
      <body>
        <div>
          <img class="logo" src="${SgLogo}" alt="Company Logo" />
        </div>
        <h2>Score for the Audited Zone</h2>
        ${tableClone.outerHTML}
      </body>
      </html>
    `;

    const logoImage = new Image();
    logoImage.src = SgLogo;
    logoImage.onload = () => {
      const printWindow = window.open('', '_blank');
      if (!printWindow) {
        throw new Error("Unable to open print window. Please check your browser settings.");
      }
      printWindow.document.write(htmlContent);
      printWindow.document.close();
      printWindow.print();
    };

  } catch (error) {
    console.error('Error printing PDF:', error);
  }
};

  
  const printPdfTable = async () => {
    try {
      // Get the HTML content of the table
      const tableElement = document.getElementById('notAuditedZoneTable');
      const htmlContent = `
        <html>
        <head>
          <title>Zones not Audited Report</title>
          <style>
            /* Add any custom styling for the print version here */
            table {
              border-collapse: collapse;
              width: 100%;
            }
            th, td {
              border: 1px solid black;
              padding: 8px;
              text-align: left;
            }
            th {
              background-color: #f2f2f2;
            }
            tr {
              break-inside: avoid; /* Prevent table rows from being split across pages */
            }
              
                img {
                                
                                max-width: 100%;
                                height: auto;
                                page-break-inside: avoid;
                            }
                            .logo {
                                display: block;
                                margin: 0 auto;
                            }
          </style>
        </head>
        <body>
         <div>
                    <img class="logo" src="${SgLogo}" alt="Company Logo" />
                </div>
          <h2>Zones not Audited</h2>
          ${tableElement.outerHTML}
        </body>
        </html>
      `;
  
      // Open a new window and write the HTML content to it
      // const printWindow = window.open('', '_blank');
      // printWindow.document.write(htmlContent);
      // printWindow.document.close();
  
      // // Print the contents of the new window
      // printWindow.print();

      const logoImage = new Image();
      logoImage.src = SgLogo;
      logoImage.onload = () => {
          // Open print window with HTML content
          const printWindow = window.open('', '_blank');
          if (!printWindow) {
              throw new Error("Unable to open print window. Please check your browser settings.");
          }
          printWindow.document.write(htmlContent);
          printWindow.document.close();
          printWindow.print();
      };
          
    } catch (error) {
      console.error('Error printing PDF:', error);
    }
  };
  

  const exportTableToExcel = (tableId, filename) => {
    const table = document.getElementById(tableId);
  
    // Adjust column width for better display
    const columnWidths = [25, 25, 25, 25, 25, 25, 25, 25, 25]; // Adjust the widths as needed
    const wscols = columnWidths.map((width) => ({ wch: width }));
  
    // Create worksheet with column headings
    const ws = XLSX.utils.table_to_sheet(table, { sheet: "Sheet 1", raw: true });
    ws['!cols'] = wscols;
    ws['!autofilter'] = { ref: XLSX.utils.encode_range({ s: { r: 0, c: 0 }, e: { r: table.rows.length, c: table.rows[0].cells.length - 1 } }) };
  
    // Remove the download column (last column)
    for (let i = 0; i < table.rows.length; i++) {
      const row = table.rows[i];
      for (let j = row.cells.length - 1; j >= 0; j--) {
        if (j === row.cells.length - 1) {
          const cellRef = XLSX.utils.encode_cell({ r: i, c: j });
          delete ws[cellRef];
        }
      }
    }
  
    // Convert the worksheet to workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
  
    // Convert the workbook to binary data
    const binaryData = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  
    // Create a Blob object containing the binary data
    const blob = new Blob([binaryData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  
    // Trigger download by creating a link element
    saveAs(blob, filename);
  };
  
//old code
  // const exportTableToExcel = (tableId, filename) => {
  //   const table = document.getElementById(tableId);
  
  //   // Adjust column width for better display
  //   const columnWidths = [25, 25, 25, 25, 25, 25, 25, 25, 25, 25]; // Adjust the widths as needed
  //   const wscols = columnWidths.map((width, idx) => ({ wch: width }));
  
  //   // Iterate through the table rows and cells to adjust the date format
  //   for (let i = 1; i < table.rows.length; i++) {
  //     const row = table.rows[i];
  //     for (let j = 0; j < row.cells.length; j++) {
  //       const cell = row.cells[j];
  //       // Check if the cell is in the last column (Audit Date)
  //       if (j === row.cells.length - 1) {
  //         // Get the text content of the cell (date string)
  //         const dateString = cell.textContent.trim();
  //         // Format the date string using formatDate function
  //         const formattedDate = formatDate(dateString);
  //         // Update the cell content with the formatted date
  //         cell.textContent = formattedDate;
  //       }
  //     }
  //   }
  
  //   // Create worksheet with column headings
  //   const ws = XLSX.utils.table_to_sheet(table, { sheet: "Sheet 1", raw: true });
  //   ws['!cols'] = wscols;
  //   ws['!autofilter'] = { ref: XLSX.utils.encode_range({ s: { r: 0, c: 0 }, e: { r: table.rows.length, c: table.rows[0].cells.length } }) };
  
  //   // Ensure that the header row is preserved
  //   const headerRow = table.rows[0];
  //   for (let j = 0; j < headerRow.cells.length; j++) {
  //     ws[XLSX.utils.encode_cell({ r: 0, c: j })].v = headerRow.cells[j].textContent;
  //   }
  
  //   // Convert the worksheet to workbook
  //   const wb = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
  
  //   // Convert the workbook to binary data
  //   const binaryData = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  
  //   // Create a Blob object containing the binary data
  //   const blob = new Blob([binaryData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  
  //   // Trigger download by creating a link element
  //   saveAs(blob, filename);
  // };
  
  
  
  const handleDownloadAuditedZoneExcel = () => {
    exportTableToExcel('auditedZoneTable', 'audited_zone_report.xlsx');
  };

  const handleDownloadNotAuditedZoneExcel = () => {
    exportTableToExcel('notAuditedZoneTable', 'not_audited_zone_report.xlsx');
  };
 
  const filteredData = data.filter((row) => row.totpoints !== "0");
  console.log("filetereddataaaaaaaa",filteredData)
  console.log("dataaaaaaaaa",data)
  return (
   
    <div style={{ padding: "16px"}}>
      {/* <Header pageTitle="Reports" /> */}
      <div>
      <div style={{justifyContent:'flex-end',display:'flex',marginBottom:'35px'}}>
     
      <select value={selectedMonth} onChange={handleMonthChange}>
            <option value="">Select Month</option>
            {/* {monthsAndYears.map((item, index) => (
              <option key={index} value={item.month}>{new Date(0, item.month - 1).toLocaleString('en', { month: 'long' })}</option>
            ))} */}          
            <option value="1">January</option>
            <option value="2">February</option>
            <option value="3">March</option>
            <option value="4">April</option>
            <option value="5">May</option>
            <option value="6">June</option>
            <option value="7">July</option>
            <option value="8">August</option>
            <option value="9">September</option>
            <option value="10">October</option>
            <option value="11">November</option>
            <option value="12">December</option>
          </select>
          <select value={selectedYear} onChange={handleYearChange}>
            <option value="">Select Year</option>
            {[...new Set(monthsAndYears.map(item => item.year))].map((year, index) => (
              <option key={index} value={year}>{year}</option>
            ))}
          </select>

</div>
{/* <Typography variant="h6" gutterBottom>
          Score for the Audited Zone
        </Typography> */}
      <div style={{display:'flex',justifyContent:'space-between'}} >
        <div style={{ marginBottom: '10px',justifyContent: 'flex-start', display: 'flex'  }}>
        <Typography variant="h6" gutterBottom>
          Score for the Audited Zone
        </Typography>
        </div>
        <div style={{ marginBottom: '10px',justifyContent: 'flex-end', display: 'flex'  }}>
        <Button onClick={printPdf} style={{backgroundColor:'#3498DB',color:'white',marginRight:'15px'}}>View PDF</Button>
        <Button onClick={handleDownloadAuditedZoneExcel} style={{backgroundColor:'#3498DB',color:'white'}}>Download as Excel</Button>
        </div>
        </div>
    
        {/* {filteredData.length > 0 ? ( */}
        <TableContainer component={Paper}>
  <Table id="auditedZoneTable">
    <TableHead>
      <TableRow>
        <TableCell>S.No</TableCell>
        <TableCell>Zone Code</TableCell>
        <TableCell>Zone Name</TableCell>
        <TableCell>Audit Level</TableCell>
        <TableCell>Audit Score</TableCell>
        <TableCell> 6 months Average Score</TableCell>
        <TableCell>Auditor Name</TableCell>
        <TableCell>Leader</TableCell>
        <TableCell>Group Leader</TableCell>
        <TableCell>Audit Date</TableCell>
        <TableCell>Download</TableCell>
      </TableRow>
    </TableHead>
    
    {filteredData.length > 0 ? (
     <TableBody>
      {data
        .filter((row) => row.totpoints !== "0")
        .map((row, index) => (
          <TableRow key={row.zoneId + row.month}>
            <TableCell>{index + 1}</TableCell>
            <TableCell>{row.code}</TableCell>
            <TableCell>{row.name}</TableCell>
            <TableCell>{getAuditLevel(row.totpoints)}</TableCell>
            <TableCell>{row.totpoints}</TableCell>
            <TableCell>{row.totlsixpoints}</TableCell>
            <TableCell>{row.auditor_name}</TableCell>
            <TableCell>{row.zone_first} {row.zonelast}</TableCell>
            <TableCell>{row.grp_first} {row.grplast}</TableCell>
            <TableCell>{formatDate(row.audit_date)}</TableCell>
            <TableCell>
            <IconButton onClick={() => handleDownloadRow(row.zone_id, row.audit_date)}>
                    <GetAppIcon />
                  </IconButton>
                    </TableCell>
          </TableRow>
        ))}
    </TableBody> 
     ) : (
      <Typography
      variant="body1"
      gutterBottom
      style={{ textAlign: 'center', marginTop: '20px' }}
    >
        No data found.
      </Typography>
    )}
  </Table>
</TableContainer>
 

<div style={{display:'flex',justifyContent:'space-between',marginTop:'35px'}} >

<div style={{ marginBottom: '10px',justifyContent: 'flex-start', display: 'flex'  }}>
        <Typography variant="h6" gutterBottom>
         Zones not Audited
        </Typography>
        </div>
        <div style={{ marginBottom: '10px',justifyContent: 'flex-end', display: 'flex'}}>
        <Button onClick={printPdfTable} style={{backgroundColor:'#3498DB',color:'white',marginRight:'15px'}}>View PDF</Button>
        <Button onClick={handleDownloadNotAuditedZoneExcel}style={{backgroundColor:'#3498DB',color:'white'}}>Download as Excel</Button>
        </div>
        </div>
        <TableContainer component={Paper}>
          <Table id="notAuditedZoneTable">
            <TableHead>
              <TableRow>
                <TableCell>S.No</TableCell>
                <TableCell>Zone Code</TableCell>
                <TableCell>Zone Name</TableCell>
                <TableCell>Auditor</TableCell>
                <TableCell> Leader </TableCell>
                <TableCell>Group Leader</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {zonesWithoutAssessments.map((zone, index) => (
                <TableRow key={zone.zone_id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{zone.zone_code}</TableCell>
                  <TableCell>{zone.zone_name}</TableCell>
                  <TableCell>{zone.auditor_first_name} {zone.auditor_last_name}</TableCell>
                  <TableCell>{zone.zone_leader_first_name} {zone.zone_leader_last_name}</TableCell>
                  <TableCell>{zone.group_leader_first_name} {zone.group_leader_last_name}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default Reports;



//Old code

// const Reports = () => {
//   const ApiURL = configParam.API_URL;
//   const access_token = JSON.parse(localStorage.getItem("accesstoken"));
//   const secret_key = JSON.parse(localStorage.getItem("secret"));
//   const [data, setData] = useState([]);
//   const [zonesWithoutAssessments, setZonesWithoutAssessments] = useState([]);
//   const [monthsAndYears, setMonthsAndYears] = useState([]);
//   const [selectedMonth, setSelectedMonth] = useState('');
//   const [selectedYear, setSelectedYear] = useState('');
//   useEffect(() => {
//     fetchReportData();
//     fetchZonesWithoutAssessments();
//     fetchMonthsAndYears();
//   }, []);

//   useEffect(() => {
//     if (selectedMonth !== '' || selectedYear !== '') {
//       fetchReportData(); // Fetch report data when either month or year is selected
//     }
//   }, [selectedMonth, selectedYear]);



//   const fetchMonthsAndYears = async () => {
//     try {
//       const response = await axios.get(`${ApiURL}/get_available_months_and_years`, { headers: { Authorization: `Bearer ${access_token}`, secret_key } });
//       setMonthsAndYears(response.data);
//     } catch (error) {
//       console.error('Error fetching months and years:', error);
//     }
//   };


//   const fetchReportData = async () => {
//     try {
//       // const response = await axios.get(`${ApiURL}/get_avgscore_perzone`, { headers: { Authorization: `Bearer ${access_token}`, secret_key } });
//       const response = await axios.get(`${ApiURL}/get_avgscore_perzone`, { 
//         headers: { Authorization: `Bearer ${access_token}`, secret_key },
//         params: { month: selectedMonth, year: selectedYear }
//       });
//       // Filter the data to include only the latest month's data for each zone
//       const latestMonthData = filterLatestMonthData(response.data);
//       setData(latestMonthData);
//     } catch (error) {
//       console.error('Error fetching reports:', error);
//     }
//   };


//   const fetchZonesWithoutAssessments = async () => {
//     try {
//       const response = await axios.get(`${ApiURL}/get_zones_noassessments`, { 
//         headers: { Authorization: `Bearer ${access_token}`, secret_key },
//         params: { month: selectedMonth, year: selectedYear }
//       });
//       setZonesWithoutAssessments(response.data);
//     } catch (error) {
//       console.error('Error fetching zones without assessments:', error);
//     }
//   };
  


//   // Function to filter only the latest month's data for each zone
//   const filterLatestMonthData = (data) => {
//     const latestMonthData = {};
//     data.forEach(row => {
//       if (!latestMonthData[row.zoneId] || new Date(row.month) > new Date(latestMonthData[row.zoneId].month)) {
//         latestMonthData[row.zoneId] = row;
//       }
//     });
//     return Object.values(latestMonthData);
//   };
//   const getAuditLevel = (score) => {
//     if (score >= 1 && score <= 6) return 1;
//     if (score >= 7 && score <= 12) return 2;
//     if (score >= 13 && score <= 18) return 2.5;
//     if (score >= 19 && score <= 24) return 3;
//     if (score >= 25 && score <= 30) return 3.5;
//     if (score >= 31 && score <= 36) return 4;
//     return 'Unknown';
//   };
//   function formatDate(dateString) {
//     const date = new Date(dateString);
//     const day = date.getDate();
//     const month = date.toLocaleString('default', { month: 'short' });
//     const year = date.getFullYear();
//     return `${day} ${month} ${year}`;
//   }
  
//   const handleMonthChange = (event) => {
//     const selectedMonth = event.target.value;
//     setSelectedMonth(selectedMonth);
//     fetchReportData(selectedMonth, selectedYear); // Fetch data immediately after updating month
//   };
  
//   const handleYearChange = (event) => {
//     const selectedYear = event.target.value;
//     setSelectedYear(selectedYear);
//     fetchReportData(selectedMonth, selectedYear); // Fetch data immediately after updating year
//   };


//   const printPdf = async () => {
//     try {
//       // Get the HTML content of the table
//       const tableElement = document.getElementById('auditedZoneTable');
//       const htmlContent = `
//         <html>
//         <head>
//           <title>Audited Zone Report</title>
//           <style>
//             /* Add any custom styling for the print version here */
//             table {
//               border-collapse: collapse;
//               width: 100%;
//             }
//             th, td {
//               border: 1px solid black;
//               padding: 8px;
//               text-align: left;
//             }
//             th {
//               background-color: #f2f2f2;
//             }
//             tr {
//               break-inside: avoid; /* Prevent table rows from being split across pages */
//             }
//           </style>
//         </head>
//         <body>
//           <h2>Score for the Audited Zone</h2>
//           ${tableElement.outerHTML}
//         </body>
//         </html>
//       `;
  
//       // Open a new window and write the HTML content to it
//       const printWindow = window.open('', '_blank');
//       printWindow.document.write(htmlContent);
//       printWindow.document.close();
  
//       // Print the contents of the new window
//       printWindow.print();
//     } catch (error) {
//       console.error('Error printing PDF:', error);
//     }
//   };
  
  
//   const printPdfTable = async () => {
//     try {
//       // Get the HTML content of the table
//       const tableElement = document.getElementById('notAuditedZoneTable');
//       const htmlContent = `
//         <html>
//         <head>
//           <title>Zones not Audited Report</title>
//           <style>
//             /* Add any custom styling for the print version here */
//             table {
//               border-collapse: collapse;
//               width: 100%;
//             }
//             th, td {
//               border: 1px solid black;
//               padding: 8px;
//               text-align: left;
//             }
//             th {
//               background-color: #f2f2f2;
//             }
//             tr {
//               break-inside: avoid; /* Prevent table rows from being split across pages */
//             }
//           </style>
//         </head>
//         <body>
//           <h2>Zones not Audited</h2>
//           ${tableElement.outerHTML}
//         </body>
//         </html>
//       `;
  
//       // Open a new window and write the HTML content to it
//       const printWindow = window.open('', '_blank');
//       printWindow.document.write(htmlContent);
//       printWindow.document.close();
  
//       // Print the contents of the new window
//       printWindow.print();
//     } catch (error) {
//       console.error('Error printing PDF:', error);
//     }
//   };
  


//   const exportTableToExcel = (tableId, filename) => {
//     const table = document.getElementById(tableId);
  
//     // Adjust column width for better display
//     const columnWidths = [25, 25, 25, 25, 25, 25, 25, 25, 25, 25]; // Adjust the widths as needed
//     const wscols = columnWidths.map((width, idx) => ({ wch: width }));
  
//     // Iterate through the table rows and cells to adjust the date format
//     for (let i = 1; i < table.rows.length; i++) {
//       const row = table.rows[i];
//       for (let j = 0; j < row.cells.length; j++) {
//         const cell = row.cells[j];
//         // Check if the cell is in the last column (Audit Date)
//         if (j === row.cells.length - 1) {
//           // Get the text content of the cell (date string)
//           const dateString = cell.textContent.trim();
//           // Format the date string using formatDate function
//           const formattedDate = formatDate(dateString);
//           // Update the cell content with the formatted date
//           cell.textContent = formattedDate;
//         }
//       }
//     }
  
//     // Create worksheet with column headings
//     const ws = XLSX.utils.table_to_sheet(table, { sheet: "Sheet 1", raw: true });
//     ws['!cols'] = wscols;
//     ws['!autofilter'] = { ref: XLSX.utils.encode_range({ s: { r: 0, c: 0 }, e: { r: table.rows.length, c: table.rows[0].cells.length } }) };
  
//     // Ensure that the header row is preserved
//     const headerRow = table.rows[0];
//     for (let j = 0; j < headerRow.cells.length; j++) {
//       ws[XLSX.utils.encode_cell({ r: 0, c: j })].v = headerRow.cells[j].textContent;
//     }
  
//     // Convert the worksheet to workbook
//     const wb = XLSX.utils.book_new();
//     XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
  
//     // Convert the workbook to binary data
//     const binaryData = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  
//     // Create a Blob object containing the binary data
//     const blob = new Blob([binaryData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  
//     // Trigger download by creating a link element
//     saveAs(blob, filename);
//   };
  
  
  
//   const handleDownloadAuditedZoneExcel = () => {
//     exportTableToExcel('auditedZoneTable', 'audited_zone_report.xlsx');
//   };

//   const handleDownloadNotAuditedZoneExcel = () => {
//     exportTableToExcel('notAuditedZoneTable', 'not_audited_zone_report.xlsx');
//   };
 
//   return (
//     <div>
//       <Header pageTitle="Reports" />
//       <div style={{marginTop:'55px'}}>
//       <div style={{justifyContent:'flex-end',display:'flex'}}>
     



//   <select value={selectedMonth} onChange={handleMonthChange}>
//     <option value="">Select Month</option>
//     {monthsAndYears.map((item, index) => (
//       <option key={index} value={item.month}>{new Date(0, item.month - 1).toLocaleString('en', { month: 'long' })}</option>
//     ))}
//   </select>
//   <select value={selectedYear} onChange={handleYearChange}>
//     <option value="">Select Year</option>
//     {[...new Set(monthsAndYears.map(item => item.year))].map((year, index) => (
//       <option key={index} value={year}>{year}</option>
//     ))}
//   </select>
// </div>

//       <Typography variant="h6" gutterBottom>
//           Score for the Audited Zone
//         </Typography>
//         <div style={{ marginBottom: '10px' }}>
//         <Button onClick={printPdf}>View PDF</Button>
//         <Button onClick={handleDownloadAuditedZoneExcel}>Download as Excel</Button>
//         </div>
//         <TableContainer component={Paper}>
//           <Table id="auditedZoneTable">
//             <TableHead>
//               <TableRow>
//                 <TableCell>S.No</TableCell>
//                 <TableCell>Zone Code</TableCell>
//                 <TableCell>Zone Name</TableCell>
//                 <TableCell>Audit Level</TableCell>
//                 <TableCell>Audit Score</TableCell>
//                 <TableCell>Average Score</TableCell>
//                 <TableCell>Auditor Name</TableCell>
//                 <TableCell> Leader </TableCell>
//                 <TableCell>Group Leader </TableCell>
//                 <TableCell>Audit Date</TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {data.map((row, index) => (
//                 <TableRow key={row.zoneId + row.month}>
//                   <TableCell>{index + 1}</TableCell>
//                   <TableCell>{row.zoneCode}</TableCell>
//                   <TableCell>{row.zoneName}</TableCell>
//                   <TableCell>{getAuditLevel(row.totalScore)}</TableCell>
//                   <TableCell>{row.totalScore}</TableCell>
//                   <TableCell>{row.averageScore}</TableCell>
//                   <TableCell>{row.auditorName}</TableCell>
//                   <TableCell>{row.zoneLeaderName !== "null null" ? row.zoneLeaderName : ''}</TableCell>
//                   <TableCell>{row.groupLeaderName !== "null null" ? row.groupLeaderName : ''}</TableCell>
                 
                 
//                   <TableCell>{formatDate(row.auditDate)}</TableCell>
                  
//                   {/* <TableCell>{formatDate(row.month)}</TableCell>  */}
//                 </TableRow>
//               ))}
//             </TableBody>
//           </Table>
//         </TableContainer>


//         <Typography variant="h6" gutterBottom style={{ marginTop: '55px' }}>
//           Zones not Audited
//         </Typography>
//         <div style={{ marginBottom: '10px' }}>
//         <Button onClick={printPdfTable}>View PDF</Button>
//         <Button onClick={handleDownloadNotAuditedZoneExcel}>Download as Excel</Button>
//         </div>
//         <TableContainer component={Paper}>
//           <Table id="notAuditedZoneTable">
//             <TableHead>
//               <TableRow>
//                 <TableCell>S.No</TableCell>
//                 <TableCell>Zone Code</TableCell>
//                 <TableCell>Zone Name</TableCell>
//                 <TableCell> Leader </TableCell>
//                 <TableCell>Group Leader</TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {zonesWithoutAssessments.map((zone, index) => (
//                 <TableRow key={zone.zone_id}>
//                   <TableCell>{index + 1}</TableCell>
//                   <TableCell>{zone.zone_code}</TableCell>
//                   <TableCell>{zone.zone_name}</TableCell>
//                   <TableCell>{zone.zone_leader_first_name} {zone.zone_leader_last_name}</TableCell>
//                   <TableCell>{zone.group_leader_first_name} {zone.group_leader_last_name}</TableCell>
//                 </TableRow>
//               ))}
//             </TableBody>
//           </Table>
//         </TableContainer>
//       </div>
//     </div>
//   );
// };

// export default Reports;




 

 

