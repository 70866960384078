import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactApexChart from 'react-apexcharts';
import configParam from '../config';
import { Card, Typography, Avatar, Tooltip, CardContent, Button, Box, Grid,Divider } from '@mui/material';
import GoldBadge from '../Asset/Icons/gold_badge.svg';
import silverBadge from '../Asset/Icons/badge_silver.svg';
import fivesBanner from '../Asset/Icons/fivesBanner.jpg';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import { Bar } from 'react-chartjs-2';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { Chart, CategoryScale, LinearScale, BarElement,  Legend } from 'chart.js';
// Chart.register(CategoryScale, LinearScale, BarElement,  Legend);
// const Dashboard = () => {
 
//   const ApiURL = configParam.API_URL;
//   const access_token = JSON.parse(localStorage.getItem("accesstoken"));
//   const [userRole, setUserRole] = useState([]);
//   const [chartData, setChartData] = useState(null);
//   const [selectedZoneId, setSelectedZoneId] = useState(null);
//   const [loading, setLoading] = useState(false);
//   const [years, setYears] = useState([]);
//   const [selectedYear, setSelectedYear] = useState('');
//   const isCoordinator = userRole.some(user => user.wcm_coordinator_firstname !== '' &&  user.wcm_coordinator_firstname !== null);
//   console.log("isCoordinator",isCoordinator)
//   const iszoneleader = userRole.some(user => user.zone_first_name !== '' &&  user.zone_first_name !== null);
//   console.log("iszoneleaderdashboard",iszoneleader)
//   const isgroupleader = userRole.some(user => user.group_firstname !== '' &&  user.group_firstname !== null);
//   const isZoneMember = userRole.some(user => user.is_zone_member === "Yes");
//   console.log("isgroupleader",isgroupleader)
//  console.log("userRoleDAshboard",userRole)

//  useEffect(() => {
//   getRole();
 
// }, []);
// useEffect(() => {
 
//   fetchYears();
// }, [selectedYear]);
//  const getRole = () => {
//   setLoading(true);
//   const userDatas = JSON.parse(localStorage.getItem("userDatas"));
// const userid = userDatas.id;
// const plantId = localStorage.getItem('selectedPlantId');
//   axios.get(`${ApiURL}/getroleuserdashboard/${userid}/${plantId}`,{headers: {
//       Authorization: `Bearer ${access_token}`,
     
//     }})
//       .then(response => {
//           setLoading(false);
//           if (response.status === 200) {
//               if (response.data && response.data.length > 0) {
//                 setUserRole(response.data);
//                     console.log("userRole",response.data)                 
//               }
//           }
//       })
//       .catch(error => {
//           setLoading(false);
//           console.error("Error fetching assessment data:", error);
//       });
// };

// const fetchYears = async () => {
//   try {
//     const selectedPlantId = localStorage.getItem('selectedPlantId');
//     const response = await axios.get(`${ApiURL}/get_available_years`, { headers: { Authorization: `Bearer ${access_token}`}, params: { selectedPlantId } });
//     setYears(response.data);
//   } catch (error) {
//     console.error('Error fetching months and years:', error);
//   }
// };
// const canAccessDashboard = userRole.some(role => role.is_zone_member === "Yes" || role.zone_first_name !== null);

// useEffect(() => {
//   if (canAccessDashboard) {
//     const zoneRole = userRole.find(role => role.is_zone_member === "Yes" || role.zone_first_name);
//     if (zoneRole) {
//       setSelectedZoneId(zoneRole.zone_id);
//     }
//   }
// }, [userRole, canAccessDashboard]);

// useEffect(() => {
//   if (selectedZoneId) {
//     fetchData();
//   }
// }, [selectedZoneId]);

// const handleYearChange = (event) => {
//   setSelectedYear(event.target.value);
// };
// const fetchData = async () => {
//   setLoading(true);
//   if (selectedZoneId) {
//       try {
//         const selectedPlantId = localStorage.getItem('selectedPlantId');
//         const response = await axios.get(`${ApiURL}/get_dashoard_details`, {
//           params: {
//         zoneId: selectedZoneId,
//         plantId: selectedPlantId
//       },
//           headers: {
//             'Authorization': `Bearer ${access_token}`
//           }
//         });
//         setLoading(false);
//         const filteredData = (response.data || []).filter(item => item.status_flg === 'C');
//       setChartData(filteredData);
//         // setChartData(response.data || []);
//       } catch (error) {
//         console.error('Error fetching data:', error);
//       }
//     }
//     };

//     const calculateScores = (details) => {
//       const data = JSON.parse(details).selectedValues;
//       const positiveScores = Object.values(data).filter(value => value === '1').length;
//       const negativeScores = Object.values(data).filter(value => value === '0').length;
//       return { positiveScores, negativeScores };
//     };
// // Prepare chart data for rendering
// const getChartData = () => {
//   if (!chartData) return null;
//   return {
//     labels: chartData.map(item => item.month_name.trim()),
//     datasets: [
//       {
//         label: 'Assessment Scores',
//         data: chartData.map(item => item.total_score),
//         backgroundColor: '#17428B',
//         borderColor: '#17428B',
//         borderWidth: 2,
//       },
//     ],
//   };
// };
// const chartOptions = {
//   responsive: true,
//   maintainAspectRatio: false,
//   scales: {
//     x: {
//       barPercentage: 0.5, // Controls the width of the bars (lower value makes bars thinner)
//       categoryPercentage: 0.8,
//       grid: {
//         display: false, // Disables the vertical grid lines
//       }, 
//     },
//     y: {
//       grid: {
//         display: false, // Disables the horizontal grid lines
//       },
//     },
//   },
  
// };
// const getTotalAudits = () => 12; // Static count of total audits

//   const getCompletedAuditsCount = () => {
//     return (chartData || []).filter(item => item.status_flg === 'C').length;
//   };

//   const getPendingAuditsCount = () => {
//     const totalAudits = getTotalAudits();
//     const completedCount = getCompletedAuditsCount();
//     return totalAudits - completedCount; // Calculate pending audits based on total - completed
//   };

//   const getAveragePositiveScores = () => {
//     if (!chartData || !Array.isArray(chartData)) return 0; // Return 0 if chartData is null or not an array
  
//     const totalScores = chartData.reduce((sum, item) => {
//       const { positiveScores } = calculateScores(item.details);
//       return sum + positiveScores;
//     }, 0);
  
//     return (totalScores / chartData.length).toFixed(2);
//   };
  

//   const getLatestTotalScore = () => {
//     return (chartData && chartData.length > 0) ? chartData[chartData.length - 1].total_score : 0;
//   };
  
//   return (
//     <div>
       
//     <Box sx={{ padding: '16px' }}>
//       <Box sx={{ marginBottom: '16px' }}>
//         <img src={fivesBanner} style={{ maxWidth: '100%' }} alt="fivesBanner" />
//       </Box>
//       <select value={selectedYear} onChange={handleYearChange}>
//             <option value="">Select Year</option>
//             {[...new Set(years.map(item => item.year))].map((year, index) => (
//               <option key={index} value={year}>{year}</option>
//             ))}
//           </select>
//       {canAccessDashboard ? (
//         <>
//           <Card sx={{ padding: 2, display: 'flex', alignItems: 'center', height: '110px' }}>
//             <Box sx={{ flex: 1, textAlign: 'left' }}>
//               <Typography style={{ fontWeight: 400, fontSize: '12px' }}>Total Audits Per Year</Typography>
//               <Typography style={{ fontWeight: 700, fontSize: '40px', color: '#17428B' }}>{getTotalAudits()}</Typography>
//             </Box>
//             <Divider orientation="vertical" flexItem />
//             <Box sx={{ flex: 1, textAlign: 'left' }}>
//               <Typography style={{ fontWeight: 400, fontSize: '12px', marginLeft: '20px' }}>Completed Audits</Typography>
//               <Typography style={{ fontWeight: 700, fontSize: '40px', color: '#17428B', marginLeft: '20px' }}>{getCompletedAuditsCount()}</Typography>
//             </Box>
//             <Divider orientation="vertical" flexItem />
//             <Box sx={{ flex: 1, textAlign: 'left' }}>
//               <Typography style={{ fontWeight: 400, fontSize: '12px', marginLeft: '20px' }}>Pending Audits</Typography>
//               <Typography style={{ fontWeight: 700, fontSize: '40px', color: '#17428B', marginLeft: '20px' }}>{getPendingAuditsCount()}</Typography>
//             </Box>
//             <Divider orientation="vertical" flexItem />
//             <Box sx={{ flex: 1, textAlign: 'left' }}>
//               <Typography style={{ fontWeight: 400, fontSize: '12px', marginLeft: '20px' }}>Total Score</Typography>
//               <Typography style={{ fontWeight: 700, fontSize: '40px', color: '#17428B', marginLeft: '20px' }}>{getAveragePositiveScores()}</Typography>
//             </Box>
//             <Divider orientation="vertical" flexItem />
//             <Box sx={{ flex: 1, textAlign: 'left' }}>
//               <Typography style={{ fontWeight: 400, fontSize: '12px', marginLeft: '20px' }}>Last Month Score</Typography>
//               <Typography style={{ fontWeight: 700, fontSize: '40px', color: '#17428B', marginLeft: '20px' }}>{getLatestTotalScore()}</Typography>
//             </Box>
//           </Card>
  
//           <Box sx={{ padding: '16px' }}>
//             {chartData ? (
//               <>
//                 <Box sx={{ height: '300px' }}>
//                   <Bar data={getChartData()} options={chartOptions} />
//                 </Box>
//                 <Box sx={{ marginTop: '16px' }}>
//                   <TableContainer component={Paper} sx={{ marginTop: '16px' }}>
//                     <Table>
//                       <TableHead>
//                         <TableRow>
//                           <TableCell>S.No</TableCell>
//                           <TableCell>Months</TableCell>
//                           <TableCell>Zone Name</TableCell>
//                           <TableCell>Zone Code</TableCell>
//                           <TableCell>Auditor</TableCell>
//                           <TableCell>Positive Scores</TableCell>
//                           <TableCell>Negative Scores</TableCell>
//                         </TableRow>
//                       </TableHead>
//                       <TableBody>
//                         {chartData.map((item, index) => {
//                           const { positiveScores, negativeScores } = calculateScores(item.details);
//                           return (
//                             <TableRow key={item.assessment_id}>
//                               <TableCell>{index + 1}</TableCell>
//                               <TableCell>{item.month_name}</TableCell>
//                               <TableCell>{item.zone_name}</TableCell>
//                               <TableCell>{item.zone_code}</TableCell>
//                               <TableCell>{item.auditor_name}</TableCell>
//                               <TableCell>{positiveScores}</TableCell>
//                               <TableCell>{negativeScores}</TableCell>
//                             </TableRow>
//                           );
//                         })}
//                       </TableBody>
//                     </Table>
//                   </TableContainer>
//                 </Box>
//               </>
//             ) : (
//               <p>Loading chart data...</p>
//             )}
//           </Box>
//         </>
//       ) : (
//         <p>You do not have access to view this data.</p>
//       )}
//     </Box>
//     </div>
//   );




// };

// export default Dashboard;


//old code
const Dashboard = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);
  const [showMoreAchievements, setShowMoreAchievements] = useState(false);
  const [showMoreMassFive, setShowMoreMassFive] = useState(false);
  const [latestMassFive, setLatestMassFive] = useState([]);
  const ApiURL = configParam.API_URL;
  const access_token = JSON.parse(localStorage.getItem("accesstoken"));
  const [userRole, setuser] = useState([]);
  const isCoordinator = userRole.some(user => user.wcm_coordinator_firstname !== '' &&  user.wcm_coordinator_firstname !== null);
  console.log("isCoordinator",isCoordinator)
  const iszoneleader = userRole.some(user => user.zone_first_name !== '' &&  user.zone_first_name !== null);
  console.log("iszoneleader",iszoneleader)
  const isgroupleader = userRole.some(user => user.group_firstname !== '' &&  user.group_firstname !== null);
  console.log("isgroupleader",isgroupleader)
  const fetchData = async (month, year) => {
    try {
      const selectedPlantId = localStorage.getItem('selectedPlantId');
      const response = await axios.get(`${ApiURL}/get_dashoard_details`, {
        params: { month, year,plantId: selectedPlantId},
        headers: {
          'Authorization': `Bearer ${access_token}`
        }
      });
      setData(response.data || []);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const getUniqueYears = (data) => {
    const years = data.map(item => item.year);
    return [...new Set(years)];
  };

  const uniqueYears = getUniqueYears(data);

  useEffect(() => {
    fetchData(selectedMonth, selectedYear);
  }, [selectedMonth, selectedYear]);

  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 12,
    height: 12,
  }));
  useEffect(() => {
    const filterData = () => {
      if (selectedMonth === '' && selectedYear === '') {
        setFilteredData(data);
      } else {
        const filtered = data.filter(item => {
          const year = item.year?.toString();
          const month = item.month?.toString();
          return (month === selectedMonth || selectedMonth === '') &&
            (year === selectedYear || selectedYear === '');
        });
        setFilteredData(filtered);
      }
    };

    filterData();
  }, [data, selectedMonth, selectedYear]);

  useEffect(() => {
    // const calculateCounts = () => {
    //   let total = 0;
    //   if (selectedMonth === '' && selectedYear === '') {
    //     data.forEach(item => {
    //       total = item.overall_count || 0;
    //     });
    //     setTotalCount(total);
    //   } else {
    //     data.forEach(item => {
    //       total += item.count || 0;
    //     });
    //     setTotalCount(total);
    //   }

    //   const expectedCountPerMonth = 110;
    //   const completedMonths = data.length;
    //   const expectedTotalCount = completedMonths * expectedCountPerMonth;
    //   const pending = expectedTotalCount - total;
    //   setPendingCount(pending);
    // };
    const calculateCounts = () => {
      let total = 0;
      let overallCount = 0;

      if (selectedMonth === '' && selectedYear === '') {
        data.forEach(item => {
          overallCount = parseInt(item.overall_count, 10) || 0;
        });
        setTotalCount(overallCount);
      } else {
        data.forEach(item => {
          total += parseInt(item.count, 10) || 0;
        });
        setTotalCount(total);
      }

      const expectedCountPerMonth = 110;
      const completedMonths = selectedMonth === '' ? data.length : 1;
      const expectedTotalCount = completedMonths * expectedCountPerMonth;
      const pending = expectedTotalCount - (selectedMonth === '' ? overallCount : total);
      setPendingCount(pending);
    };


    calculateCounts();
  }, [filteredData, selectedMonth, selectedYear]);

  const barChartOptions = {
    chart: {
      height: '100%',
      type: 'bar',
      toolbar: {
        show: false,
      },
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350,
        },
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        columnWidth: '60%',
        dataLabels: {
          position: 'top',
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val;
      },
      offsetY: -20,
      style: {
        fontSize: '14px',
        colors: ['#304758'],
      },
    },
    xaxis: {
      categories: filteredData.map(item => item.month_name || 'Unknown'),
      position: 'bottom',
      axisBorder: {
        show: true,
        color: '#78909C',
      },
      axisTicks: {
        show: true,
        color: '#78909C',
      },
      crosshairs: {
        fill: {
          type: 'gradient',
          gradient: {
            colorFrom: '#D8E3F0',
            colorTo: '#BED1E6',
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
      },
      tooltip: {
        enabled: true,
      },
    },
    yaxis: {
      axisBorder: {
        show: true,
        color: '#78909C',
      },
      axisTicks: {
        show: true,
        color: '#78909C',
      },
      labels: {
        formatter: function (val) {
          return val.toString();
        },
      },
    },
    title: {
      text: 'Audit for 2024',
      align: 'center',
      style: {
        fontSize: '16px',
        fontWeight: '600',
        color: '#263238',
      },
    },
  };

  const barChartSeries = [
    {
      name: 'Audit Count',
      data: filteredData.map(item => item.count || 0),
    },
  ];

  const [tabValue, setTabValue] = useState(0);
  const [eliteCards, setEliteCards] = useState([]);
  const [nonEliteCards, setNonEliteCards] = useState([]);

  const styles = {
    card: {
      position: 'relative',
      display: 'flex',
      marginBottom: '16px',
      border: '1px solid #ddd',
      padding: '8px',
      borderRadius: '8px',
      alignItems: 'center',
      marginTop: '8px',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexDirection: 'row'
    },
    avatar: {
      width: '40px',
      height: '40px',
      backgroundColor: '#E0E0E0',
      marginRight: '8px'
    },
    content: {
      flex: '1 0 auto'
    },
    footer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '16px'
    },
    teamAvatars: {
      display: 'flex',
      marginTop: '8px',
      '& > *': {
        marginRight: '4px'
      }
    },
    badgeContainer: {
      position: 'absolute',
      top: '20px',
      right: '8px'
    },
    gridContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gap: '16px',
    },
    scrollableSection: {
      maxHeight: '300px',
      overflowY: 'auto',
    },
    seeMoreButton: {
      marginTop: '10px',
      cursor: 'pointer',
      backgroundColor: '#007bff',
      color: 'white',
      border: 'none',
      padding: '10px',
      borderRadius: '5px',
    },
    image: {
      width: '100%',
      height: 'auto',
      borderRadius: '8px',
      marginBottom: '8px',
    }
  };

  const fetchDataAchivements = async () => {
    try {
      const response = await axios.get(`${ApiURL}/get_avgscore_perzone_achievements`, {
        headers: {
          'Authorization': `Bearer ${access_token}`
        }
      });
      classifyZones(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchLatestMassFive = async () => {
    try {
      const selectedPlantId = localStorage.getItem('selectedPlantId');
      const response = await axios.get(`${ApiURL}/get_latest_mass_five`, {
        params: { plantId: selectedPlantId },
        headers: {
          'Authorization': `Bearer ${access_token}`
        }
      });
      console.log(response.data || [], 'response.data || []');
      setLatestMassFive(response.data || []);
    } catch (error) {
      console.error('Error fetching latest mass five:', error);
    }
  };

  const classifyZones = (data) => {
    const activeEliteZones = [];
    const droppedEliteZones = [];
    const zoneMap = new Map();

    data.forEach(zone => {
      const zoneId = zone.zoneId;
      const rollingAverage = zone.rollingAverage;
      const totalScore = zone.totalScore;
      const month = zone.month;
      const zoneCode = zone.zoneCode;
      const zoneMembers = zone.zoneMembers;
      const zoneLeader = zone.zoneLeader;

      if (!zoneMap.has(zoneId)) {
        zoneMap.set(zoneId, { rollingAverage, totalScore, month, zoneCode, zoneMembers, zoneLeader });
      } else {
        const existingData = zoneMap.get(zoneId);
        if (new Date(month) > new Date(existingData.month)) {
          zoneMap.set(zoneId, { rollingAverage, totalScore, month, zoneCode, zoneMembers, zoneLeader });
        }
      }
    });

    zoneMap.forEach((data, zoneId) => {
      const { rollingAverage, totalScore, month, zoneCode, zoneMembers, zoneLeader } = data;
      const zone = { zoneId, rollingAverage, totalScore, month, zoneCode, zoneMembers, zoneLeader };

      if (totalScore >= 90) {
        activeEliteZones.push(zone);
      } else {
        droppedEliteZones.push(zone);
      }
    });

    setEliteCards(activeEliteZones);
    setNonEliteCards(droppedEliteZones);
  };

  useEffect(() => {
    fetchDataAchivements();
    fetchLatestMassFive();
  }, []);
  console.log(latestMassFive, 'latestMassFive');

  // const sliderSettings = {
  //   dots: true,
  //   infinite: false,
  //   speed: 500,
  //   slidesToShow: 3,
  //   slidesToScroll: 1,
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 2,
  //       },
  //     },
  //     {
  //       breakpoint: 600,
  //       settings: {
  //         slidesToShow: 1,
  //       },
  //     },
  //   ],
  // };

  const sliderSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <Box sx={{ padding: '16px' }}>
      <Box sx={{ marginBottom: '16px' }}>
        <img src={fivesBanner} style={{ maxWidth: '100%' }} alt="fivesBanner" />
      </Box>

      <Grid container spacing={3}>
        <Grid item md={6} xs={12}>

          <Box style={{ width: "100%", background: "linear-gradient(to right, rgb(54 174 213), rgb(40 74 177))", padding: "20px", borderRadius: "10px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", marginBottom: "20px" }}>
            <Box style={{ textAlign: "left", color: "white", fontSize: "16px", fontWeight: "600" }}>Completed Count </Box>
            <span style={{ textAlign: "left", color: "white", fontSize: "40px", fontWeight: "700" }}>{totalCount}</span>
            <Box style={{ textAlign: "right", color: "white", fontSize: "16px", fontWeight: "600" }}>Pending Count </Box>
            <Box style={{ textAlign: "right", color: "white", fontSize: "30px", fontWeight: "600" }}>{pendingCount}</Box>
          </Box>
          <select
            value={selectedYear}
            onChange={(e) => setSelectedYear(e.target.value)}
            style={{ marginRight: "10px", padding: "8px", borderRadius: "5px", border: "1px solid #ccc" }}
          >
            <option value="">Select Year</option>
            <option value="2024">2024</option>
          </select>

          {/* <select
      value={selectedYear}
      onChange={(e) => setSelectedYear(e.target.value)}
      style={{ marginRight: "10px", padding: "8px", borderRadius: "5px", border: "1px solid #ccc" }}
    >
      <option value="">Select Year</option>
      {uniqueYears.map(year => (
        <option key={year} value={year}>{year}</option>
      ))}
    </select> */}
          <select
            value={selectedMonth}
            onChange={(e) => setSelectedMonth(e.target.value)}
            style={{ marginRight: "10px", padding: "8px", borderRadius: "5px", border: "1px solid #ccc" }}
          >
            <option value="">Select Month</option>
            <option value="1">January</option>
            <option value="2">February</option>
            <option value="3">March</option>
            <option value="4">April</option>
            <option value="5">May</option>
            <option value="6">June</option>
            <option value="7">July</option>
            <option value="8">August</option>
            <option value="9">September</option>
            <option value="10">October</option>
            <option value="11">November</option>
            <option value="12">December</option>
          </select>
          <ReactApexChart options={barChartOptions} series={barChartSeries} type="bar" height={350} />
        </Grid>
        <Grid item md={3} xs={12}>
          <Box sx={{ background: '#fff', borderRadius: '5px', padding: "8px" }}>
            <Typography variant="h6" gutterBottom>
              Achievements
            </Typography>
            {eliteCards.slice(0, showMoreAchievements ? eliteCards.length : 3).map((card, index) => (
              <Card key={index} style={styles.card}>
                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  badgeContent={
                    <WorkspacePremiumIcon sx={{right:'6px',color: '#f1c232' }} alt="Remy Sharp" src="" />
                  }
                >
                <Avatar style={styles.avatar}>{card.zoneLeader.charAt(0).toUpperCase()}</Avatar>
                </Badge>
                <Box sx={{ flex: '1 1 0' }}>
                  <Typography variant="body2" fontSize='14px'>{card.zoneLeader}</Typography>
                  <Typography variant="h6" fontSize='12px'>{card.zoneCode}</Typography>
                </Box>
                <Box>
                  <Typography variant="span" sx={{ fontSize: '10px', color: 'eeee' }}>Sore</Typography>
                  <Typography variant="body2">{card.rollingAverage}</Typography>
                  {/* <Box style={styles.teamAvatars}>
                    {card.zoneMembers.map((member, i) => (
                      <Tooltip title={member} key={i}>
                        <Avatar>{member[0]}</Avatar>
                      </Tooltip>
                    ))}
                  </Box> */}
                  {/* <Box style={styles.badgeContainer}>
                    <img src={card.totalScore >= 90 ? GoldBadge : silverBadge} alt="badge" />
                  </Box> */}
                </Box>
              </Card>
            ))}
            {/* {nonEliteCards.length > 10 && (
            <Button style={styles.seeMoreButton} onClick={() => setShowMoreAchievements(!showMoreAchievements)}>
              {showMoreAchievements ? 'See Less' : 'See More'}
            </Button>
          )} */}

          </Box> </Grid>
        <Grid item md={3} xs={12}>
          <Box sx={{ background: '#fff', borderRadius: '5px', padding: "8px" }}>
            <Typography variant="h6" gutterBottom style={{}}>
              Latest Mass Five
            </Typography>
            {/* <Slider {...sliderSettings}> */}
            {latestMassFive.map((massFive, index) => (
              <Card key={index} style={styles.card}>
                {/* {massFive.img_bef || ''} */}
                <img src={`${ApiURL}/api/imagesMass/${massFive.img_bef[massFive.img_bef.length - 1]}`} alt="Mass Five" style={styles.image} />
                {/* <CardContent>
                <Typography variant="h6">{massFive.title}</Typography>
                <Typography variant="body2">{massFive.description}</Typography>
              </CardContent> */}
              </Card>
            ))}
            {/* </Slider> */}
            {/* <button style={styles.seeMoreButton} onClick={() => setShowMoreMassFive(!showMoreMassFive)}> */}
            {/* {showMoreMassFive ? 'See Less' : 'See More'} */}
            {/* </button> */}
          </Box> </Grid>


      </Grid>
    </Box>
  );
};

export default Dashboard;
