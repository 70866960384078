import React, { useState, useEffect } from 'react';
import {
  CardActions, CardMedia, Card, Dialog, DialogTitle,
  DialogContent, Button, Grid, Box, Typography
} from "@mui/material";
import axios from 'axios';
import configParam from "../../config";
import CreateRedTag from "../../Asset/Icons/Frame.svg";
import { useNavigate } from 'react-router-dom';
import { useResetRecoilState } from "recoil";
import { RedTagData } from '../../recoil/atoms';
import RedTagForm from './RedTagForm';
import RedTagRegister from './RedTagRegister';
import WasteToWealth from './WasteToWealth';
const moment = require('moment');

const RedTagging = ({ open, onClose }) => {
  const [zoneAreas, setZoneAreas] = useState([]);
  const [dialogFormOpen, setDialogFormOpen] = useState(false);
  const [dialogRedTagRegisterOpen, setDialogRedTagRegisterOpen] = useState(false);
  const [dialogWasteToWealthOpen, setDialogWasteToWealthOpen] = useState(false);
  
  const [auditorOptions, setAuditorOptions] = useState([]);
  const [auditor, setAuditor] = useState('');
  const navigate = useNavigate();
  const reseredtagtDealerValue = useResetRecoilState(RedTagData);
  const ApiURL = configParam.API_URL;
  const access_token = JSON.parse(localStorage.getItem("accesstoken"));

  useEffect(() => {
    fetchUserDetails();
    fetchZoneAreas();
  }, []);

  const fetchUserDetails = async () => {
    try {
      const userDatas = JSON.parse(localStorage.getItem("userDatas"));
      if (userDatas) {
        const sgid = userDatas.sgid;
        axios.get(`${ApiURL}/checkUserBySGID/${sgid}`, {
          headers: { Authorization: `Bearer ${access_token}` }
        })
        .then(response => {
          if (response.data.exists) {
            const user = response.data.user;
            setAuditorOptions([`${user.firstName} ${user.lastName}`]);
            setAuditor(sgid);
          }
        })
        .catch(error => {
          console.error('Error checking user:', error);
        });
      }
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };

  const fetchZoneAreas = async () => {
    try {
      const plant_id = localStorage.getItem('selectedPlantId');
      const response = await axios.get(`${ApiURL}/get_ZoneAreasByPlantId/${plant_id}`, {
        headers: { Authorization: `Bearer ${access_token}` }
      });
      const sortedZones = response.data.sort((a, b) => a.code - b.code);
      setZoneAreas(sortedZones);
    } catch (error) {
      console.error('Error fetching ZoneAreas:', error);
    }
  };

  const handleRedTagRegisterNext = () => {
    reseredtagtDealerValue();
    localStorage.setItem("redtagId", "");
    localStorage.setItem("redtageditflg", false);
   
    setDialogRedTagRegisterOpen(true);
    onClose();
  };

  const handleWasteToWealthNext = () => {
    reseredtagtDealerValue();
    localStorage.setItem("redtagId", "");
    localStorage.setItem("redtageditflg", false);
   
    setDialogWasteToWealthOpen(true);
    onClose();
  };

  const handleCreateNext = () => {
    reseredtagtDealerValue();
    localStorage.setItem("redtagId", "");
    localStorage.setItem("redtageditflg", false);
   
    setDialogFormOpen(true);
    onClose();
  };

  const handleDialogClose = () => {
    setDialogFormOpen(false);
  };

  const handleRedTagRegisterDialogClose = () => {
    setDialogRedTagRegisterOpen(false);
  };

  const handleWasteToWealthDialogClose = () => {
    setDialogWasteToWealthOpen(false);
  };

  return (
    <div>
      <Box>
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
          <DialogTitle style={{ textAlign: 'center', color: 'black', fontWeight: 600, fontSize: '16px' }}>
            Red Tag Entry
          </DialogTitle>
          
          <DialogContent style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Grid container spacing={3}>
              <Grid item md={4} xs={12}>
                <Card sx={{ maxWidth: 345, background: "#33AFFF", margin: "10px" }}>
                  <CardMedia
                    component="img"
                    alt="Create Red Tag"
                    image={CreateRedTag}
                    style={{ width: '30%', height: '11%', padding: '11px' }}
                  />
                  <CardActions>
                    <Button size="small" onClick={handleCreateNext} style={{ color: "white" }}>
                      Create Red Tag
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item md={4} xs={12}>
                <Card sx={{ maxWidth: 345, background: "#33AFFF", margin: "10px" }}>
                  <CardMedia
                    component="img"
                    alt="Red Tag Register"
                    image={CreateRedTag}
                    style={{ width: '30%', height: '11%', padding: '11px' }}
                  />
                  <CardActions>
                    <Button size="small" onClick={handleRedTagRegisterNext}  style={{ color: "white", textAlign: "center", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                      Red Tag Register
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item md={4} xs={12}>
                <Card sx={{ maxWidth: 345, background: "#88FFA8", margin: "10px" }}>
                  <CardMedia
                    component="img"
                    alt="Waste To Wealth"
                    image={CreateRedTag}
                    style={{ width: '30%', height: '11%', padding: '11px' }}
                  />
                  <CardActions>
                    <Button size="small" onClick={handleWasteToWealthNext}  style={{ color: "white", textAlign: "center", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                      Waste To Wealth
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </Box>
      {dialogFormOpen && (
        <RedTagForm open={dialogFormOpen} onClose={handleDialogClose} />
      )}
      {dialogRedTagRegisterOpen && (
        <RedTagRegister open={dialogRedTagRegisterOpen} onClose={handleRedTagRegisterDialogClose} />
      )}
      {dialogWasteToWealthOpen && (
        <WasteToWealth open={dialogWasteToWealthOpen} onClose={handleWasteToWealthDialogClose} />
      )}
    </div>
  );
};

export default RedTagging;
