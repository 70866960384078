import React, { useState, useEffect } from 'react';
import Header from '../../app/TopAppBar';
import axios from 'axios';
import configParam from "../../config";
import AlertDialog from '../input/AlertDialog';
import { useNavigate } from 'react-router-dom';
import { CardActions,CardMedia,Card,CardContent,Dialog, DialogTitle, DialogContent, DialogActions, Button, Select, MenuItem, FormControl, InputLabel, TextField,Autocomplete,Typography,Box} from "@mui/material";
import { Navigate } from 'react-router-dom';
const moment = require('moment');
const currentday = new Date().toISOString().split('T')[0];
const modalInputLabelStyle = {
 
  fontSize: 16,
  fontStyle: 'normal',
  fontWeight: 500,
  color: '#000',
};

const WasteToWealth = ({open,onClose}) => {
  const [redTagNo, setRedTagNo] = useState('');
  const [category, setCategory] = useState('');
  const [itemName, setItemName] = useState('');
  const [sapNo, setSapNo] = useState('');
  const [quantity, setQuantity] = useState('');
  const [unitOfMeasurement, setUnitOfMeasurement] = useState('');
  const [approxValue, setApproxValue] = useState('');
  const [reason, setReason] = useState('');
  const [disposalMethod, setDisposalMethod] = useState('');
  const ApiURL = configParam.API_URL;
  const  access_token =  JSON.parse(localStorage.getItem("accesstoken"));

  const [zoneAreas, setZoneAreas] = useState([]); // State to store ZoneAreas
    const [zone, setZone] = useState('');
    const [selectedDate, setSelectedDate] = useState(null); 
     const [auditor, setAuditor] = useState('');
  const [loading, setLoading] = useState(true); 
  const [error, setError] = useState('');
  // const [auditorOptions, setAuditorOptions] = useState([]); // State to store auditor options
  // const [auditorSgid, setAuditorSgid] = useState('');
  // const [userdet, setUserdet] = useState('');
  const today = new Date().toISOString().split('T')[0];
  // const plantId = localStorage.getItem('selectedPlantId');
  // const [errorMessageDate, setErrorMessageDate] = useState(null);
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState('');
  // const [data, setData] = useState([]);
  // const [filterType, setFilterType] = useState('');
  const [filterType, setFilterType] = useState('zone');
    // const RedTagIdeditflg = localStorage.getItem("redtageditflg");
    // const RedTagIdeditflg = localStorage.getItem("redtageditflg") === "true";
  
  const handleZoneChange = (event, newValue) => {
    console.log(newValue,"newValue")
    setZone(newValue ? newValue.id : null);
  };

useEffect(() => {
  setSelectedDate(currentday)  

    }, []);

    
  useEffect(() => {
  

     fetchZoneAreas();
  
  
   
  }, []);


 

  // const handleCategoryChange = (event) => {
  //   setCategory(event.target.value);
  // };


  const fetchZoneAreas = async () => {
    try {
        const plant_id = localStorage.getItem('selectedPlantId');
        const response = await axios.get(`${ApiURL}/get_ZoneAreasByPlantId/${plant_id}`,{headers: {
          Authorization: `Bearer ${access_token}`
        }});
        const sortedZones = response.data.sort((a, b) => a.code - b.code); // Sort zones by code
      setZoneAreas(sortedZones);
        // setZoneAreas(response.data); // Set ZoneAreas state with retrieved data
        // setLoading(false);
    } catch (error) {
        console.error('Error fetching ZoneAreas:', error);
        setLoading(false);
        // Handle the error by displaying a message to the user
        // For example, you can set an error state to display an error message in your UI
        setError('Failed to fetch zone areas. Please try again later.');
    }
};

const clearForm = () => {
  setRedTagNo('');
  setCategory('');
  setItemName('');
  setSapNo('');
  setQuantity('');
  setUnitOfMeasurement('');
  setApproxValue('');
  setReason('');
  setDisposalMethod('');
  setZone('');
  setSelectedDate(currentday);
  setAuditor('');
};






const handleOptionChange = (event) => {
  setSelectedOption(event.target.value);
};

const handleDateChange = (event) => {
  setSelectedDate(event.target.value);
};


//   const handleDateChange = (event) => {
//     const dateTimeString = event.target.value; // Use default value if date is invalid
//    const formattedDate = moment.utc(dateTimeString).format("YYYY-MM-DD");
//    const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format
// console.log("formattedDate",formattedDate)
//    if (formattedDate > today) {
//      setErrorMessageDate("You cannot select a future date.");
//    } else {
//      setErrorMessageDate('');
//      setSelectedDate(formattedDate);
//    }
//   };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    // Assuming the endpoint for submission is '/submit_redtag'
    const formData = {
    
      zone_no: zone,
      filterType: filterType,
      status: selectedOption,
      
     
    

    
    }

    try {
   
        const queryString = new URLSearchParams(formData).toString();
        console.log(queryString,"queryString");
        navigate(`/WasteWealthDetails?${queryString}`);
      
    } catch (error) {
      console.error('Error submitting Red Tag data:', error);
      setError('Submission failed. Please try again.');
    }
  };


  // const handleSubmit = async (event) => {
  //   event.preventDefault();

  //   const queryParams = new URLSearchParams();
  //   if (selectedDate) queryParams.append('date', selectedDate);
  //   // if (selectedZone) queryParams.append('zone', selectedZone.code);
  //   if (selectedOption) queryParams.append('option', selectedOption);
  //   if (category) queryParams.append('category', category);

  //   axios.get(`${ApiURL}/Get_Wastetowealth/${queryParams.toString()}`,{headers: {
  //     Authorization: `Bearer ${access_token}`
  //   }})
  //     .then(response => {
  //       if (response.data.exists) {
  //         console.log("response.data.exists", response.data);
  //         const user = response.data.user;
  //         //setAuditorOptions([`${user.firstName} ${user.lastName}`]); // Set auditor options with name
  //        // setAuditor(sgid);
  //         setData(user);
  //         navigate(`/WasteWealthDetails?${queryParams.toString()}`);
  //         // setAuditor(`${user.firstName} ${user.lastName}`);
  //       }
  //       setLoading(false); // Set loading state to false when data is fetched
  //     })
  //     .catch(error => {
  //       console.error('Error checking user:', error);
  //       setLoading(false); // Set loading state to false even if there's an error
  //     });

   
  // };

  const handleFilterTypeChange = (event) => {
    setFilterType(event.target.value);
  };
  
  return (
    
    <div>

    {/* <Header pageTitle="View Entry" isRedTagPage={true} /> */}
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
          <DialogTitle style={{ textAlign: 'center', color: 'black', fontWeight: 600, fontSize: '16px' }}>
          Waste To Wealth
          </DialogTitle>
    <Box style={{ maxWidth: '600px',  padding: '16px', backgroundColor: '#f9f9f9', borderRadius: '8px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
      <form onSubmit={handleSubmit}>
        <InputLabel style={{ marginBottom: 10 }}>Entry Date</InputLabel>
        <TextField
            size="small"
            className="input-nis"
            fullWidth
            id="date"
            type="date"
            value={selectedDate}
            onChange={handleDateChange}
            inputProps={{ max: today }}
            style={{ marginBottom: 10 }}
          />
        <InputLabel style={{ marginBottom: 10 }}>Zone</InputLabel>
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <Autocomplete
            options={zoneAreas}
            getOptionLabel={(option) => `Zone ${option.code} - ${option.name}`}
            onChange={handleZoneChange}
            renderInput={(params) => <TextField {...params} fullWidth />}
            style={{ marginBottom: 10 }}
          />
        </FormControl>
        <InputLabel style={{ marginBottom: 10 }}>Status:</InputLabel>
        <div style={{ marginBottom: 10 }}>
          <label style={{ marginRight: 20 }}>
            <input
              type="radio"
              name="status"
              value="Redeployed"
              checked={selectedOption === 'Redeployed'}
              onChange={handleOptionChange}
            />
            Redeployment
          </label>
          <label style={{ marginRight: 20 }}>
            <input
              type="radio"
              name="status"
              value="Disposed"
              checked={selectedOption === 'Disposed'}
              onChange={handleOptionChange}
            />
            Disposal
          </label>
          <label style={{ marginRight: 20 }}>
            <input
              type="radio"
              name="status"
              value="Move to scrap"
              checked={selectedOption === 'Move to scrap'}
              onChange={handleOptionChange}
            />
            Scrap
          </label>
        </div>
        <InputLabel style={{ marginBottom: 10 }}>Group By:</InputLabel>
          <div style={{ marginBottom: 10 }}>
            <label style={{ marginRight: 20 }}>
              <input
                type="radio"
                name="filterType"
                value="zone"
                checked={filterType === 'zone'}
                onChange={handleFilterTypeChange}
              />
              Zone
            </label>
            <label style={{ marginRight: 20 }}>
              <input
                type="radio"
                name="filterType"
                value="category"
                checked={filterType === 'category'}
                onChange={handleFilterTypeChange}
              />
              Category
            </label>
           
          </div>
          <Button type="submit"   variant="contained" color="primary">Submit</Button>
      </form>
   
    </Box>
    </Dialog>

    </div>
    
  );
  
};

export default WasteToWealth;
