import React, {useState, useEffect} from 'react';
import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import { useNavigate } from "react-router-dom";
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Header = ({ open, handleDrawerOpen }) => {
  const [apiData, setApiData] = useState("");
  const navigate = useNavigate();


  useEffect(() => {
    const storedUserData = localStorage.getItem("userDatas");
    const userData = storedUserData ? JSON.parse(storedUserData) : null;
    setApiData(userData)

  }, []);

  const handleNextButtonClick = () => {
    localStorage.clear(); // This clears all the items in local storage
    handleNavigation("/login"); // Then navigate to the login page
};

 // const handleNextButtonClick = () => handleNavigation("/login");
   // onclick pages
   const handleNavigation = (route) => {
    navigate(route);
  };

  return (
    <AppBar position="fixed" open={open} sx={{ background: '#fff', boxShadow: 'none', borderBottom: '1px solid #e6e6e6' }}>
      <Toolbar style={{padding: '0 16px'}}>
        <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon sx={{ color: '#000' }} />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ color: '#000' }}>
            5S Audit Assessment
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
          <Tooltip title= {apiData ?  apiData.firstName.toUpperCase() +" "+ apiData.lastName.toUpperCase(): " "}>
            <IconButton
              size="small"
              sx={{ mr: 2 }}
            >
              <Avatar sx={{ fontSize:'16px' }}>{apiData ? apiData.lastName.charAt(0).toUpperCase() + apiData.firstName.charAt(0).toUpperCase() : " "}</Avatar>
            </IconButton>
          </Tooltip>
          <IconButton
              onClick={handleNextButtonClick}
            >
              <Logout fontSize="24px" />
            </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
