import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import imageSrc from "../../Asset/Icons/Mask.svg";
import imagecomple from "../../Asset/Icons/Auditcomp.svg";
import HorizontalCombinedStepper from "../RequestForm/HorizontalCombinedStepper";
import { useRecoilState } from 'recoil';
import { formDataState } from '../../recoil/atoms';
import { Typography, Button, Box } from '@mui/material';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';

const AuditAssessment = () => {
    const location = useLocation();
    const [AssessDialogOpen, setAssessDialogOpen] = useState(false);
    const [formData, setFormData] = useRecoilState(formDataState);
    const navigate = useNavigate();
    const { message, unselectedQuestions } = location.state || {};

    const isCompleted = !unselectedQuestions || Object.keys(unselectedQuestions).length === 0;

    const getCategoryName = (stepNumber) => {
        switch (stepNumber) {
            case 0: return "SORT";
            case 1: return "STRAIGHTEN";
            case 2: return "SHINE";
            case 3: return "STANDARDIZE";
            case 4: return "SUSTAIN";
            case 5: return "SAFETY";
            default: return "UNKNOWN";
        }
    };

    

    const renderIncompleteAssessments = () => {
        const incompleteGroups = {};

        for (const step in unselectedQuestions) {
            if (unselectedQuestions.hasOwnProperty(step)) {
                let category;
                if (step === "Step 0") category = "1S : SORT";
                else if (step === "Step 1") category = "2S : STRAIGHTEN";
                else if (step === "Step 2") category = "3S : SHINE";
                else if (step === "Step 3") category = "4S : STANDARDIZE";
                else if (step === "Step 4") category = "5S : SUSTAIN";
                else if (step === "Step 5") category = "5S : SAFETY";
                else {
                    const stepNumber = parseInt(step.split(" ")[1]);
                    category = `${stepNumber}S : ${getCategoryName(stepNumber)}`;
                }

                const questions = unselectedQuestions[step].map(question => `Q${question}`).join(', ');
                incompleteGroups[category] = questions;
            }
        }

        return Object.entries(incompleteGroups).map(([category, questions]) => (
            <Box sx={{ display: 'flex', marginBottom: '8px' }} key={category}>
                <ErrorOutlineRoundedIcon sx={{ color: 'red', fontSize: '18px', marginRight: '5px', marginBottom: '3px' }} />
                <Typography variant='body1' sx={{ fontSize: '16px', color: '#666' }}>{category} - {questions}</Typography>
            </Box>
        ));
    };

    const goToDashboard = () => {
        navigate('/dashboard');
    };

//    const goToAudit = () => {
        const goToAudit = () => {
            navigate('/audit', { state: { openDialog: true } });
        };
        
        // setAssessDialogOpen(true);
        // navigate('/audit', { replace: true }); // Open the dialog without redirecting
    

    const handleclose = () => {
        setAssessDialogOpen(false); // Open the dialog without redirecting
    };


    const GoToCancel = () => {
        navigate('/Audit');
    };

    return (
        <div style={{ position: 'relative' }}>
            <div style={{
                position: 'absolute',
                width: '150px',
                height: '150px',
                borderRadius: '50%',
                top: '-125px',
                left: '50%',
                transform: 'translateX(-50%)'
            }}>
                <img src={isCompleted ? imagecomple : imageSrc} alt="Your Image Alt Text" style={{ width: '100%', height: '100%' }} />
            </div>
            <div style={{ maxWidth: '800px', margin: 'auto', padding: '20px', marginTop: '150px' }}>
                {isCompleted ? (
                    <>
                        <h2 style={{ textAlign: 'center' }}>Audit Assessment is Completed</h2>
                        <p style={{ textAlign: 'center' }}>Thank you for completing the assessment!</p>
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                            <Button variant="contained" style={{
                                width: '100%',
                                background: 'linear-gradient(45deg, #173481 30%, #02A4DA 90%)',
                                borderRadius: 3,
                                border: 0,
                                color: 'white',
                                height: 48,
                                padding: '0 30px',
                                boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
                            }} color="primary" onClick={goToDashboard}>
                                Go to Dashboard
                            </Button>
                        </div>
                    </>
                ) : (
                    <>
                        <h2 style={{ textAlign: 'center' }}>Audit Assessment is Incomplete</h2>
                        <p style={{ textAlign: 'center' }}>Below mentioned 5S audits are not completed.</p>
                        <p style={{ textAlign: 'center' }}>Please ensure every mandatory 5S is completed before submitting.</p>
                        {message && <p style={{ textAlign: 'center' }}>{message}</p>}
                        {unselectedQuestions && Object.keys(unselectedQuestions).length > 0 && (
                            <div>
                                <p style={{ textAlign: 'center' }}>Incomplete Assessments:</p>
                                {renderIncompleteAssessments()}
                            </div>
                        )}
                        <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', marginTop: '20px' }}>
                            <Button variant="text" color="primary" onClick={GoToCancel} style={{
                                width: '100%',
                                fontWeight: 600,
                                color: '#15408A',
                                textTransform: 'capitalize',
                                fontSize: '16px'
                            }}>
                                Cancel
                            </Button>
                            <Button variant="contained" style={{
                                width: '100%',
                                background: '#17428B',
                                borderRadius: 5,
                                border: 0,
                                fontSize: '16px',
                                color: 'white',
                                height: 48,
                                textTransform: 'capitalize',
                                padding: '0 30px',
                                boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
                            }} color="primary" onClick={goToAudit}>
                                Go to Audit
                            </Button>
                        </div>
                    </>
                )}
            </div>

            {/* HorizontalCombinedStepper opens as a dialog */}
            <HorizontalCombinedStepper open={AssessDialogOpen} state={formData} assesmentid={localStorage.getItem("assessmentIdcreate")} onClose={handleclose} />
        </div>
    );
};

export default AuditAssessment;
