import React, { useState, useEffect } from 'react';
import Header from '../../app/TopAppBar';
import axios from 'axios';
import configParam from "../../config";
import AlertDialog from '../input/AlertDialog';
import { useNavigate } from 'react-router-dom';
import { CardActions, CardMedia, Card, CardContent, Dialog, DialogTitle, DialogContent, DialogActions, Button, Select, MenuItem, FormControl, InputLabel, TextField, Autocomplete, Typography, FormControlLabel, Radio, RadioGroup, Box } from "@mui/material";
import { Navigate } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
const moment = require('moment');
const currentday = new Date().toISOString().split('T')[0];
const modalInputLabelStyle = {

  fontSize: 16,
  fontStyle: 'normal',
  fontWeight: 500,
  color: '#000',
};

const RedTagForm = ({open, onClose}) => {

  // console.log("onCloseonClose",onClose);
   console.log("openRedTag", open)

  const [redTagNo, setRedTagNo] = useState('');
  const [category, setCategory] = useState("");
  const [itemName, setItemName] = useState('');
  const [sapNo, setSapNo] = useState('');
  const [quantity, setQuantity] = useState('');
  const [serialNumber, setSerialNumber] = useState(null);
  const [errors, setErrors] = useState({});
  const [unitOfMeasurement, setUnitOfMeasurement] = useState('');
  const [approxValue, setApproxValue] = useState('');
  const [reason, setReason] = useState('');
  const [disposalMethod, setDisposalMethod] = useState('');
  const ApiURL = configParam.API_URL;
  const access_token = JSON.parse(localStorage.getItem("accesstoken"));
  const theme = useTheme();
  const [zoneAreas, setZoneAreas] = useState([]);
  const [recZoneAreas, setRecZoneAreas] = useState([]);
  // State to store ZoneAreas
  const [zone, setZone] = useState('');
  // const [recommedzone, setRecoZone] = useState('');
  const [recommedzone, setRecoZone] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);
  const [auditor, setAuditor] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [auditorOptions, setAuditorOptions] = useState([]); // State to store auditor options
  const [auditorSgid, setAuditorSgid] = useState('');
  const [userdet, setUserdet] = useState('');
  const today = new Date().toISOString().split('T')[0];
  const plantId = localStorage.getItem('selectedPlantId');
  const [errorMessageDate, setErrorMessageDate] = useState(null);
  const [subarea, setSubArea] = useState(null);
  const [userRole, setUserRole] = useState([]);

  const [CommonArea, setCommonArea] = useState('');
  const [Scrapyard, setScrapArea] = useState('');

  const [status, setStatus] = useState('');
  const [commonstatus, setCommonStatus] = useState('');
  const [reviewstatus, setReviewStatus] = useState('');
  const navigate = useNavigate();
  // const RedTagIdeditflg = localStorage.getItem("redtageditflg");
  const RedTagIdeditflg = localStorage.getItem("redtageditflg") === "true";
  const redtagcommoneditflg = localStorage.getItem("redtagcommoneditflg") === "true";
  const redtagscrapeditflg = localStorage.getItem("redtagscrapeditflg") === "true";

  // const handleZoneChange = (event, newValue) => {
  //   console.log(newValue,"newValue")
  //   setZone(newValue ? newValue.id : null);
  // };
  const handleZoneChange = (event, newValue) => {
    if (newValue) {
      // Ensure newValue is not undefined
      console.log(newValue, "newValue");
      setZone(newValue);

    }
  };



  const fetchUserRole = async () => {
    try {
      const userDatas = JSON.parse(localStorage.getItem("userDatas"));
      if (userDatas) {
        const role = userDatas.role_id; // Assuming the role is stored in userDatas
        setUserRole(role);
        console.log("role", role);
      }
    } catch (error) {
      console.error('Error fetching user role:', error);
    }
  };


  // const handleRecZoneChange = (event, newValue) => {
  //   if (newValue) {
  //     // Ensure newValue is not undefined
  //     console.log(newValue, "newValuerecommendzone");

  //     setRecoZone(newValue);
  //   }
  // };
  const handleRecZoneChange = (event, newValue) => {
    if (newValue) {
      console.log("newvalue12343", newValue)

      setRecoZone(newValue); // Store the zone ID
    } else {
      setRecoZone('');  // Clear the value if no selection or newValue is null
    }
  };


  useEffect(() => {
    setSelectedDate(currentday)

  }, []);


  useEffect(() => {


    //fetchZoneAreas();
    fetchuserdetails();
    fetchUserRole();
    if (RedTagIdeditflg) {
      // Fetch red tag data if it's for editing
      const redTagId = localStorage.getItem("redtagId");
      fetchRedTagData(redTagId);

    }

    else if (redtagcommoneditflg) {
      // Fetch red tag data if it's for editing
      const redTagId = localStorage.getItem("redtagId");
      fetchRedTagData(redTagId);

    }
    else if (redtagscrapeditflg) {
      const redTagId = localStorage.getItem("redtagId");
      fetchRedTagData(redTagId);
    }

    else {
      // Clear any existing data if it's a new red tag
      clearForm();


    }

  }, []);


  // const fetchZoneAreas = async () => {
  //   try {
  //     const plant_id = localStorage.getItem('selectedPlantId');
  //     const response = await axios.get(`${ApiURL}/get_ZoneAreasByPlantId/${plant_id}`, {
  //       headers: {
  //         Authorization: `Bearer ${access_token}`
  //       }
  //     });
  //     const sortedZones = response.data.sort((a, b) => a.code - b.code); // Sort zones by code
  //     setZoneAreas(sortedZones);
  //     setRecZoneAreas(sortedZones)

  //     // setZoneAreas(response.data); // Set ZoneAreas state with retrieved data
  //     // setLoading(false);
  //   } catch (error) {
  //     console.error('Error fetching ZoneAreas:', error);
  //     setLoading(false);
  //     // Handle the error by displaying a message to the user
  //     // For example, you can set an error state to display an error message in your UI
  //     setError('Failed to fetch zone areas. Please try again later.');
  //   }
  // };

  const clearForm = () => {
    setRedTagNo('');
    setCategory('');
    setItemName('');
    setSapNo('');
    setQuantity('');
    setUnitOfMeasurement('');
    setApproxValue('');
    setReason('');
    setDisposalMethod('');
    setZone('');
    setSelectedDate(currentday);
    setAuditor('');
  };
  const fetchRedTagData_old = async (redTagId) => {
    try {

      const response = await axios.get(`${ApiURL}/get_red_tag_edit/${redTagId}`, {

        headers: {
          Authorization: `Bearer ${access_token}`
        }
      });
      if (response.data) {
        const redTagData = response.data.data[0];

        const entryDate = redTagData.entry_date.split('T')[0]; // Extract date portion
        const dateObject = new Date(entryDate); // Convert date string to Date object
        dateObject.setDate(dateObject.getDate() + 1); // Increase day by 1
        const increasedDate = dateObject.toISOString().split('T')[0];
        console.log("redTagData.zone_id", redTagData);
        // const defaultZone = zoneAreas.find(zone => zone.id === redTagData.zone_no);
        const defaultZone = {
          id: redTagData.zone_no,
          name: redTagData.name,
          code: redTagData.code
        };

        const RecomZone = {
          id: redTagData.recommend_zone,
          name: redTagData.recommend_zone_name,
          code: redTagData.recommend_zone_code

        };
        console.log("defaultZone", defaultZone)
        setZone(defaultZone);
        setSelectedDate(increasedDate);
        setAuditor(redTagData.auditorName || redTagData.sgid);
        setCategory(redTagData.category_no);
        setItemName(redTagData.item_name);
        setSapNo(redTagData.sap_no);
        setQuantity(redTagData.qty);
        setUnitOfMeasurement(redTagData.unit_of_measure);
        setApproxValue(redTagData.approximate);
        setReason(redTagData.reason);
        setDisposalMethod(redTagData.disposal_method);
        setRecoZone(RecomZone);
        setSubArea(redTagData.sub_area);

        setCommonArea(redTagData.common_area);

        setScrapArea(redTagData.scrap_yard);
        setStatus(redTagData.status || '');
        setCommonStatus(redTagData.commonstatus || '')
        console.log("redTagData.common_area", redTagData.common_area)
        console.log("redTagData.status", redTagData.status)
      } else {
        console.log("No data found in the response");
      }
    }
    catch (error) {
      console.error('Error fetching Red Tag data:', error);
      // Handle error, such as displaying an error message to the user
    }
  };

  const fetchRedTagData = async (redTagId) => {
    try {
        const response = await axios.get(`${ApiURL}/get_red_tag_edit/${redTagId}`, {
            headers: {
                Authorization: `Bearer ${access_token}`
            }
        });

        if (response.data) {
            const redTagData = response.data.data[0];
         //   const entryDate = redTagData.entry_date.split(' ')[0];
            const entryDate = new Date(redTagData.entry_date).toLocaleDateString('en-CA'); // YYYY-MM-DD format
            console.log("Converted Entry Date:", entryDate);

         //   const entryDate = redTagData.entry_date.split('T')[0]; // Extract date portion without converting to Date object
console.log(entryDate,"entryDate");
            const defaultZone = {
                id: redTagData.zone_no,
                name: redTagData.name,
                code: redTagData.code
            };

            const RecomZone = {
                id: redTagData.recommend_zone,
                name: redTagData.recommend_zone_name,
                code: redTagData.recommend_zone_code
            };

            console.log("defaultZone", defaultZone);
            setZone(defaultZone);
            setSelectedDate(entryDate); // Use the original date string directly
          //  setAuditor(redTagData.auditorName || redTagData.sgid);
            setCategory(redTagData.category_no);
            setItemName(redTagData.item_name);
            setSapNo(redTagData.sap_no);
            setQuantity(redTagData.qty);
            setUnitOfMeasurement(redTagData.unit_of_measure);
            setApproxValue(redTagData.approximate);
            setReason(redTagData.reason);
            setDisposalMethod(redTagData.disposal_method);
            setRecoZone(RecomZone);
            setSubArea(redTagData.sub_area);
            setCommonArea(redTagData.common_area);
            setScrapArea(redTagData.scrap_yard);
            setStatus(redTagData.status || '');
            setCommonStatus(redTagData.commonstatus || '');

            console.log("redTagData.common_area", redTagData.common_area);
            console.log("redTagData.status", redTagData.status);
        } else {
            console.log("No data found in the response");
        }
    } catch (error) {
        console.error('Error fetching Red Tag data:', error);
        // Handle error, such as displaying an error message to the user
    }
};


  console.log("")

  const fetchuserdetails = async () => {
    try {
      const userDatas = JSON.parse(localStorage.getItem("userDatas")); // Parse the JSON string
      if (userDatas) {
        const sgid = userDatas.sgid; // Access the sgid property
        console.log("sgid", sgid);
        setUserdet(sgid);
        axios.get(`${ApiURL}/checkUserBySGID/${sgid}`, {
          headers: {
            Authorization: `Bearer ${access_token}`,

          }
        })
          .then(response => {
            if (response.data.exists) {
              console.log("response.data.exists", response.data);
              const user = response.data.user;
              setAuditorOptions([`${user.firstName} ${user.lastName}`]); // Set auditor options with name
              setAuditor(sgid);
              // setAuditor(`${user.firstName} ${user.lastName}`);
            }
            setLoading(false); // Set loading state to false when data is fetched
          })
          .catch(error => {
            console.error('Error checking user:', error);
            setLoading(false); // Set loading state to false even if there's an error
          });
        const plant_id = localStorage.getItem('selectedPlantId');

        const roleResponse = await axios.get(`${ApiURL}/getroleuser/${sgid}/${plant_id}`, {
          headers: {
            Authorization: `Bearer ${access_token}`
          }
        });

        if (roleResponse.status === 200 && roleResponse.data && roleResponse.data.length > 0) {
          setUserRole(roleResponse.data);
          fetchZoneAreas(roleResponse.data); // Pass roles data to fetchZoneAreas
        }
      }
    } catch (error) {
      console.error('Error fetching ZoneAreas:', error);
      setLoading(false);
    }
  }


  // const fetchUserDetailsAndRoles = async () => {
  //   try {
  //     const plant_id = localStorage.getItem('selectedPlantId');
  //     const userDatas = JSON.parse(localStorage.getItem("userDatas"));
  //     if (userDatas) {
  //       const sgid = userDatas.sgid;
  //       setUserdet(sgid);

  //       const userResponse = await axios.get(`${ApiURL}/checkUserBySGID/${sgid}`, {
  //         headers: {
  //           Authorization: `Bearer ${access_token}`
  //         }
  //       });

  //       if (userResponse.data.exists) {
  //         const user = userResponse.data.user;
  //         setAuditorOptions([`${user.firstName} ${user.lastName}`]);
  //         setAuditor(sgid);
  //       }

  //       const roleResponse = await axios.get(`${ApiURL}/getroleuser/${sgid}/${plant_id}`, {
  //         headers: {
  //           Authorization: `Bearer ${access_token}`
  //         }
  //       });

  //       if (roleResponse.status === 200 && roleResponse.data && roleResponse.data.length > 0) {
  //         setUserRole(roleResponse.data);
  //         fetchZoneAreas(roleResponse.data); // Pass roles data to fetchZoneAreas
  //       }
  //     }
  //   } catch (error) {
  //     console.error('Error fetching user details and roles:', error);
  //     setLoading(false); // Set loading state to false on error
  //   }
  // };
  

  const fetchZoneAreas = async (roles) => {
    try {
      const userDatas = JSON.parse(localStorage.getItem("userDatas"));
      const sgid = userDatas.sgid;
      const plant_id = localStorage.getItem('selectedPlantId');

      const response = await axios.get(`${ApiURL}/get_ZoneAreasByPlantId/${plant_id}`, {
        headers: {
          Authorization: `Bearer ${access_token}`
        }
      });

      let sortedZones = response.data.sort((a, b) => a.code - b.code); // Sort zones by code
      setRecZoneAreas(sortedZones);

      const isCoordinator = roles.some(role => role.wcm_coordinator_firstname !== null);
      const isAuditor = roles.some(role => role.audit_first_name !== null);
      const isZoneOrGroupLeader = !isCoordinator && !isAuditor;

      if (isCoordinator) {
        // Show all zones if user is a coordinator
        sortedZones = sortedZones;
      } else if (isAuditor) {
        // Show only zones assigned to the auditor
        const auditorZones = roles
          .filter(role => role.auditname === sgid)
          .map(role => role.code);

        sortedZones = sortedZones.filter(zone => auditorZones.includes(zone.code));
      } else if (isZoneOrGroupLeader) {
        // Show no zones if user is a zone leader or group leader
        sortedZones = [];
      }

      console.log("sortedZones", sortedZones);
      setZoneAreas(sortedZones);

    } catch (error) {
      console.error('Error fetching ZoneAreas:', error);
      setLoading(false); // Set loading state to false on error
      setError('Failed to fetch zone areas. Please try again later.');
    }
  };






  const handleDateChange = (event) => {
    const dateTimeString = event.target.value; // Use default value if date is invalid
    const formattedDate = moment.utc(dateTimeString).format("YYYY-MM-DD");
    const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format
    console.log("formattedDate", formattedDate)
    if (formattedDate > today) {
      setErrorMessageDate("You cannot select a future date.");
    } else {
      setErrorMessageDate('');
      setSelectedDate(formattedDate);
    }
  };

  // async function generateassNumber() {
  //   const latestRecord = await getLatestRecord();
  //   let lastTwoDigits = 1; // Default value if no records are found
  //   if (latestRecord) {
  //     const lastMocNumber = parseInt(latestRecord.split("-")[2]); // Extract last three digits
  //     lastTwoDigits = lastMocNumber + 1;
  //   }
  //   const date = new Date();
  //   const year = date.getFullYear().toString();
  //   const yearMocNumber = `RD-${year}-${lastTwoDigits
  //     .toString()
  //     .padStart(5, "0")}`;
  //   return yearMocNumber;
  // }


  // async function getLatestRecord() {
  //   try {
  //     const response = await axios.get(`${ApiURL}/get_latest_red_tag`, {
  //       headers: {
  //         Authorization: `Bearer ${access_token}`
  //       }
  //     });

  //     //const response = await axios.get(`${API.GET_LATEST_MOC}`);
  //     if (response.data && response.data.length > 0) {
  //       console.log(response.data[0].red_tagno, '21');
  //       return response.data[0].red_tagno; // Return the latest MOC number

  //     } else {
  //       console.log("No data found in the response");
  //       return null;
  //     }

  //   } catch (error) {
  //     console.log("Error:", error);
  //     return null;
  //   }
  // }

  async function getLatestRecord() {
    try {
      const response = await axios.get(`${ApiURL}/get_latest_red_tag`, {
        headers: {
          Authorization: `Bearer ${access_token}`
        }
      });
  
      if (response.data && response.data.length > 0) {
        return response.data[0].red_tagno; // Return the latest MOC number
      } else {
        return null;
      }
  
    } catch (error) {
      console.log("Error:", error);
      return null;
    }
  }

  async function generateassNumber_old() {
    const latestRecord = await getLatestRecord();
    let lastTwoDigits = 1; // Default value if no records are found
    if (latestRecord) {
      const lastMocNumber = parseInt(latestRecord.split("-")[2]); // Extract last three digits
      lastTwoDigits = lastMocNumber + 1;
    }
    const date = new Date();
    const year = date.getFullYear().toString();
    const yearMocNumber = `RD-${year}-${lastTwoDigits
      .toString()
      .padStart(5, "0")}`;
    return yearMocNumber;
  }
  

  let currentSerialNumber = null;

async function generateassNumber() {
  if (currentSerialNumber) {
    return currentSerialNumber; // Return existing serial number
  }

  const latestRecord = await getLatestRecord();
  let lastTwoDigits = 1; // Default value if no records are found
  if (latestRecord) {
    const lastMocNumber = parseInt(latestRecord.split("-")[2]); // Extract last three digits
    lastTwoDigits = lastMocNumber + 1;
  }
  const date = new Date();
  const year = date.getFullYear().toString();
  currentSerialNumber = `RD-${year}-${lastTwoDigits.toString().padStart(5, "0")}`;
  
  return currentSerialNumber;
}

// Call generateassNumber only when needed, e.g., when creating a new red tag
generateassNumber()
  .then((serialNumber) => {
    console.log("Generated Serial Number:", serialNumber);
  })
  .catch((error) => {
    console.error("Error occurred:", error);
  });


  // generateassNumber()
  //   .then((serialNumber) => {
  //     console.log("Generated Serial Number:", serialNumber);
  //   })
  //   .catch((error) => {
  //     console.error("Error occurred:", error);
  //   });
  //mycode
  const handleSubmit = async (event) => {
    event.preventDefault();
    let formData = {};
    let redTagNo = '';

    const newErrors = {};

    if (!auditor) newErrors.auditor = 'Auditor is required';
    if (!zone) newErrors.zone = 'Zone is required';
    if (!selectedDate) newErrors.selectedDate = 'Date is required';
    if (!category) newErrors.category = 'Category is required';
    if (!itemName) newErrors.itemName = 'Item Name is required';
    if (!quantity) newErrors.quantity = 'Quantity is required';
    if (!unitOfMeasurement) newErrors.unitOfMeasurement = 'Unit of Measurement is required';
    if (!approxValue) newErrors.approxValue = 'Approximate Value is required';
    if (!reason) newErrors.reason = 'Reason for tagging is required';
    if (!disposalMethod) newErrors.disposalMethod = 'Disposal Method is required';
    
    if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
    }
    else{
    if (RedTagIdeditflg) {
      const redTagId = localStorage.getItem("redtagId"); // Assuming redTagId is stored in local storage

      formData = {
        category: category,
        itemName: itemName,
        sapNo: sapNo,
        quantity: quantity,
        unitOfMeasurement: unitOfMeasurement,
        approxValue: approxValue,
        reason: reason,
        disposalMethod: disposalMethod,
        auditorName: auditor,
        auditDate: selectedDate,
        zoneId: zone,
        status: reviewstatus,
        plantId: localStorage.getItem('selectedPlantId')
      };

      const response = await axios.post(`${ApiURL}/update_red_tag_edit/${redTagId}`, { formData: formData }, {
        headers: {
          Authorization: `Bearer ${access_token}`
        }
      });

      console.log('RedTag updated successfully.');
      AlertDialog({
        type: 'success',
        title: 'Success',
        text: 'RedTag updated successfully.',
      });

      if (response.data.success) {
       
          
      // Other navigation code
  // const path ="/redtagactivities";
  //         console.log(`Navigating to ${path}`);
  //         navigate(path);
  window.location.href = '/RedTagActivities';
      
       //  navigate("/redtagactivities");
         onClose();
     //   navigate("/redtagactivities");

      }
    } 
    
    else {
      redTagNo = await generateassNumber();
      formData = {
        redTagNo: redTagNo,
        category: category,
        itemName: itemName,
        sapNo: sapNo,
        quantity: quantity,
        unitOfMeasurement: unitOfMeasurement,
        approxValue: approxValue,
        reason: reason,
        disposalMethod: disposalMethod,
        auditorName: auditor,
        auditDate: selectedDate,
        zoneId: zone,
        plantId: localStorage.getItem('selectedPlantId')
      };

      const response = await axios.post(ApiURL + '/Insert_red_tagging', { formData: formData }, {
        headers: {
          Authorization: `Bearer ${access_token}`
        }
      });

      console.log('RedTag created successfully.');
      AlertDialog({
        type: 'success',
        title: 'Success',
        text: 'RedTag created successfully.',
        onConfirm: async () => {
          if (response.data.success) {
            const redtag_id = response.data.redtagId;
            localStorage.setItem("redtag_id", redtag_id);
          
          }
        
      },
      });
      // const path ="/redtagactivities";
      // console.log(`Navigating to ${path}`);
      // navigate(path);
      window.location.href = '/RedTagActivities';
  //    navigate("/redtagactivities");
      onClose();
    
     
    }
  }
  };




  const handleSubmitforstore = async (event) => {
    event.preventDefault();

    const formData = {
      // redTagNo: redTagNo,
      category: category,
      itemName: itemName,
      sapNo: sapNo,
      quantity: quantity,
      unitOfMeasurement: unitOfMeasurement,
      approxValue: approxValue,
      reason: reason,
      disposalMethod: disposalMethod,
      auditorName: auditor,
      auditDate: selectedDate,
      zoneId: zone,
      plantId: localStorage.getItem('selectedPlantId'),
      RecommedzoneId: recommedzone,

      subarea: subarea,
      status: commonstatus ? commonstatus : status,


    };


    const redTagId = localStorage.getItem("redtagId");
    console.log(redTagId, "redTagId")
    if (RedTagIdeditflg || redtagscrapeditflg || redtagcommoneditflg) {

      const response = await axios.post(`${ApiURL}/update_red_tag_store/${redTagId}`, { formData: formData }, {
        headers: {
          Authorization: `Bearer ${access_token}`
        }
      });
      console.log('RedTag Updated successfully.');
      AlertDialog({
        type: 'success',
        title: 'success',
        text: 'RedTag Updated successfully.',


      });

      // Update assessmentId state with the newly created assessment ID
      if (response.data.success) {
        const redtag_id = response.data.redtag_id;
        localStorage.setItem("redtag_id", response.data.redtag_id);
        window.location.href = '/RedTagActivities';
       // navigate("/redtagactivities");
        onClose();

      }

      setLoading(false);


    }





  };

  // Ensure userRole is an array
  const isUserRoleArray = Array.isArray(userRole);

  const isCoordinator = isUserRoleArray && userRole.some(user => user.wcm_coordinator_firstname !== '' && user.wcm_coordinator_firstname !== null);
  const isZoneleader = isUserRoleArray && userRole.some(user => user.zone_first_name !== '' && user.zone_first_name !== null);
  const isGroupleader = isUserRoleArray && userRole.some(user => user.group_firstname !== '' && user.group_firstname !== null);


  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
      <Dialog open={open} fullScreen={fullScreen} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle></DialogTitle>
        
        {/* <Header pageTitle={RedTagIdeditflg ? "Review Red Tag" : "Red Tag Entry"} isRedTagPage={true} /> */}


        {

          status === "Move to common area" || status === "Redeployed" || status === "To be Inspected" ? (
            <Box style={{ maxWidth: '600px', padding: '20px', backgroundColor: '#f9f9f9', borderRadius: '8px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>



              <form onSubmit={handleSubmitforstore}>

                <InputLabel style={modalInputLabelStyle}>Entry By</InputLabel>
                <FormControl fullWidth margin="normal">

                  <Select
                    value={auditor}
                    onChange={(event) => setAuditor(event.target.value)}
                    fullWidth disabled={isCoordinator}
                  >
                    {loading ? (
                      <MenuItem disabled>Loading...</MenuItem>
                    ) : (
                      auditorOptions.map((auditorOption) => (
                        <MenuItem key={auditorOption} value={userdet}>
                          {auditorOption}
                        </MenuItem>
                      ))
                    )}

                  </Select>


                </FormControl>
                <InputLabel style={modalInputLabelStyle}>Zone</InputLabel>

                <FormControl fullWidth margin="normal">

                  <Autocomplete

                    options={zoneAreas}

                    getOptionLabel={(option) => `Zone ${option.code} - ${option.name}`}
                    value={zone}
                    onChange={handleZoneChange}

                    renderInput={(params) => <TextField {...params} fullWidth />}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    // Use custom ListboxComponent
                    style={{ marginBottom: 10 }} disabled={isCoordinator}
                  />

                </FormControl>

                <InputLabel style={modalInputLabelStyle}>Entry Date</InputLabel>
                <FormControl fullWidth margin="normal">
                  <TextField
                    className="input-nis"
                    fullWidth
                    id="date"
                    type="date"
                    value={selectedDate}
                    onChange={handleDateChange}
                    inputProps={{ max: today }}
                    style={{ marginBottom: 10 }} disabled={isCoordinator}
                  />
                </FormControl>

                {/* <Box style={{ marginBottom: '20px' }}>
  <label style={{ display: 'block', marginBottom: '5px' }}>Red Tag No:</label>
  <input type="text" value={redTagNo} onChange={(e) => setRedTagNo(e.target.value)} style={{ width: '100%', padding: '8px', borderRadius: '5px', border: '1px solid #ccc' }}  />
</Box> */}


                <InputLabel style={modalInputLabelStyle}>Category :</InputLabel>
                <FormControl fullWidth margin="normal">
                  <Select
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                    //style={{ width: '100%', padding: '8px', borderRadius: '5px', border: '1px solid #ccc' }}
                    MenuProps={{ PaperProps: { style: { maxHeight: 300 } } }} // Adjust the height of the dropdown menu if needed
                    disabled={isCoordinator}>
                    <MenuItem value="">Select</MenuItem>
                    <MenuItem value="Equipment">Equipment</MenuItem>
                    <MenuItem value="Tools">Tools</MenuItem>
                    <MenuItem value="Measuring Instruments">Measuring Instruments</MenuItem>
                    <MenuItem value="Materials">Materials</MenuItem>
                    <MenuItem value="Parts">Parts</MenuItem>
                    <MenuItem value="Process Inventory">Process Inventory</MenuItem>
                    <MenuItem value="Semi Finished w/p">Semi Finished w/p</MenuItem>
                    <MenuItem value="Finished Products">Finished Products</MenuItem>
                    <MenuItem value="Office Files">Office Files</MenuItem>
                    <MenuItem value="Documents">Documents</MenuItem>
                    <MenuItem value="Papers/Stationeries">Papers/Stationeries</MenuItem>
                    {/* Add options for categories */}
                  </Select>
                </FormControl>


                <InputLabel style={modalInputLabelStyle}> Item Name:</InputLabel>
                <FormControl fullWidth margin="normal">
                  <TextField
                    type="text"
                    value={itemName}
                    onChange={(e) => setItemName(e.target.value)}
                    style={{ marginBottom: 10, width: '100%' }}
                    disabled={isCoordinator} />

                </FormControl>


                <InputLabel style={modalInputLabelStyle}> SAP No:</InputLabel>
                <FormControl fullWidth margin="normal">
                  <TextField
                    type="text"
                    value={sapNo}
                    onChange={(e) => setSapNo(e.target.value)}
                    style={{ marginBottom: 10, width: '100%' }} disabled={isCoordinator}
                  />
                </FormControl>

                <InputLabel style={modalInputLabelStyle}>Quantity:</InputLabel>
                <FormControl fullWidth margin="normal">
                  <TextField
                    type="text"
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                    style={{ width: '100%', marginBottom: 10 }}
                    required disabled={isCoordinator} />
                </FormControl>
                <InputLabel style={modalInputLabelStyle}>Unit Of Measurement:</InputLabel>
                <FormControl fullWidth margin="normal">
                  <Select
                    value={unitOfMeasurement}
                    onChange={(e) => setUnitOfMeasurement(e.target.value)}
                    //   style={{ width: '100%', padding: '8px', borderRadius: '5px', border: '1px solid #ccc' }}
                    disabled={isCoordinator} >
                    <MenuItem value="">Select</MenuItem>
                    <MenuItem value="Nos">No(s)</MenuItem>
                    <MenuItem value="Kg">Kg(s)</MenuItem>
                    <MenuItem value="Litres">Litre(s) </MenuItem>
                    <MenuItem value="set">Set(s)</MenuItem>
                    <MenuItem value="Meter">Meter(s)</MenuItem>
                    <MenuItem value="Roll">Roll(s)</MenuItem>
                    <MenuItem value="Lot">Lot(s)</MenuItem>
                    <MenuItem value="Box">Box(es)</MenuItem>
                    {/* Add options for categories */}
                  </Select>
                </FormControl>
                <InputLabel style={modalInputLabelStyle}> Approximate value of Each(in Rs):</InputLabel>
                <FormControl fullWidth margin="normal">
                  <TextField
                    type="text"
                    value={approxValue}
                    onChange={(e) => setApproxValue(e.target.value)}
                    style={{ width: '100%', marginBottom: 10 }}
                    required disabled={isCoordinator} />
                </FormControl>
                <InputLabel style={modalInputLabelStyle}>Reason For tagging :</InputLabel>
                <FormControl fullWidth margin="normal">

                  <Select
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                    //   style={{ width: '100%', padding: '8px', borderRadius: '5px', border: '1px solid #ccc' }}
                    MenuProps={{ PaperProps: { style: { maxHeight: 300 } } }} // Adjust the height of the dropdown menu if needed
                    disabled={isCoordinator} >
                    <MenuItem value="">Select</MenuItem>
                    <MenuItem value="Not Needed">Not Needed</MenuItem>
                    <MenuItem value="Defective">Defective</MenuItem>
                    <MenuItem value="Not Needed Now">Not Needed Now </MenuItem>
                    <MenuItem value="Scrap Material">Scrap Material</MenuItem>
                    <MenuItem value="Unknown">Unknown</MenuItem>
                    <MenuItem value="Others">Others</MenuItem>
                    {/* Add options for categories */}
                  </Select>
                </FormControl>


                <InputLabel style={modalInputLabelStyle}>Disposal Method Suggested:</InputLabel>
                <FormControl fullWidth margin="normal">
                  <Select
                    value={disposalMethod}
                    onChange={(e) => setDisposalMethod(e.target.value)}
                    // style={{ width: '100%', padding: '8px', borderRadius: '5px', border: '1px solid #ccc' }}
                    disabled={isCoordinator}   >
                    <MenuItem value="">Select</MenuItem>
                    <MenuItem value="To Be Scrapped">To Be Scrapped</MenuItem>
                    <MenuItem value="To Be Reused /Redeployed">To Be Reused /Redeployed</MenuItem>
                    <MenuItem value="To Be Rectified/Reused">To Be Rectified/Reused</MenuItem>
                    <MenuItem value="To Be Disposed">To Be Disposed</MenuItem>
                    {/* Add options for categories */}
                  </Select>

                </FormControl>


                <InputLabel style={modalInputLabelStyle}>Recommended Zone</InputLabel>
                <FormControl fullWidth margin="normal">

                  <Autocomplete
                    options={recZoneAreas}

                    getOptionLabel={(option) => `Zone ${option.code} - ${option.name}`}
                    // value={recZoneAreas.find(zone => zone.id === recommedzone) || ''}
                    value={recommedzone}
                    onChange={handleRecZoneChange}
                    renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                  />

                  {/* <Autocomplete

                options={zoneAreas}

                getOptionLabel={(option) => `Zone ${option.code} - ${option.name}`}
                value={zoneAreas.find(zone => zone.id === recommedzone) || ''}
                // value={Recommedzone}
                onChange={handleRecZoneChange}

                renderInput={(params) => <TextField {...params} fullWidth />}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                // Use custom ListboxComponent
                style={{ marginBottom: 10 }}
              //  disabled={userRole !== '29121312-6afa-46dd-bfaf-2afdb2d72b70'} 
                /> */}


                </FormControl>


                <InputLabel style={modalInputLabelStyle}>Sub Area:</InputLabel>
                <FormControl fullWidth margin="normal">
                  <TextField
                    type="text"
                    value={subarea}
                    onChange={(e) => setSubArea(e.target.value)}
                    style={{ marginBottom: 10, width: '100%' }}
                  //     disabled={userRole !== '29121312-6afa-46dd-bfaf-2afdb2d72b70'} 
                  />
                </FormControl>
                <InputLabel>Status</InputLabel>

                <FormControl component="fieldset" fullWidth margin="normal">

                  <RadioGroup
                    value={commonstatus}
                    onChange={(event) => setCommonStatus(event.target.value)}
                    aria-labelledby="status-radio-group"
                    name="status-radio-group"
                  >
                    <FormControlLabel value="Redeployed" control={<Radio />} label="Redeployed" />
                    <FormControlLabel value="Disposed/Move to scrap" control={<Radio />} label="Disposed/Move to scrap" />
                    <FormControlLabel value="To be Inspected" control={<Radio />} label="To be Inspected" />

                  </RadioGroup>
                </FormControl>

                {/* Other form fields */}
                <button type="submit" style={{ backgroundColor: '#007bff', color: '#fff', border: 'none', padding: '10px 20px', borderRadius: '5px', cursor: 'pointer', display: 'block', margin: '0 auto' }}> Common </button>
              </form>
            </Box>

          ) :
            status === "Move to scrap" || status === "Disposed/Move to scrap" ? (
              <Box style={{ maxWidth: '600px', padding: '20px', backgroundColor: '#f9f9f9', borderRadius: '8px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>



                <form onSubmit={handleSubmitforstore}>

                  <InputLabel style={modalInputLabelStyle}>Entry By-Scrap</InputLabel>
                  <FormControl fullWidth margin="normal">

                    <Select
                      value={auditor}
                      onChange={(event) => setAuditor(event.target.value)}
                      fullWidth disabled={isCoordinator}
                    >
                      {loading ? (
                        <MenuItem disabled>Loading...</MenuItem>
                      ) : (
                        auditorOptions.map((auditorOption) => (
                          <MenuItem key={auditorOption} value={userdet}>
                            {auditorOption}
                          </MenuItem>
                        ))
                      )}

                    </Select>


                  </FormControl>
                  <InputLabel style={modalInputLabelStyle}>Zone</InputLabel>

                  <FormControl fullWidth margin="normal">

                    <Autocomplete

                      options={zoneAreas}

                      getOptionLabel={(option) => `Zone ${option.code} - ${option.name}`}
                      value={zone}
                      onChange={handleZoneChange}

                      renderInput={(params) => <TextField {...params} fullWidth />}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      // Use custom ListboxComponent
                      style={{ marginBottom: 10 }} disabled={isCoordinator}
                    />

                  </FormControl>

                  <InputLabel style={modalInputLabelStyle}>Entry Date</InputLabel>
                  <FormControl fullWidth margin="normal">
                    <TextField
                      className="input-nis"
                      fullWidth
                      id="date"
                      type="date"
                      value={selectedDate}
                      onChange={handleDateChange}
                      inputProps={{ max: today }}
                      style={{ marginBottom: 10 }} disabled={isCoordinator}
                    />
                  </FormControl>

                  {/* <Box style={{ marginBottom: '20px' }}>
<label style={{ display: 'block', marginBottom: '5px' }}>Red Tag No:</label>
<input type="text" value={redTagNo} onChange={(e) => setRedTagNo(e.target.value)} style={{ width: '100%', padding: '8px', borderRadius: '5px', border: '1px solid #ccc' }}  />
</Box> */}


                  <InputLabel style={modalInputLabelStyle}>Category :</InputLabel>
                  <FormControl fullWidth margin="normal">
                    <Select
                      value={category}
                      onChange={(e) => setCategory(e.target.value)}
                      //style={{ width: '100%', padding: '8px', borderRadius: '5px', border: '1px solid #ccc' }}
                      MenuProps={{ PaperProps: { style: { maxHeight: 300 } } }} // Adjust the height of the dropdown menu if needed
                      disabled={isCoordinator}>
                      <MenuItem value="">Select</MenuItem>
                      <MenuItem value="Equipment">Equipment</MenuItem>
                      <MenuItem value="Tools">Tools</MenuItem>
                      <MenuItem value="Measuring Instruments">Measuring Instruments</MenuItem>
                      <MenuItem value="Materials">Materials</MenuItem>
                      <MenuItem value="Parts">Parts</MenuItem>
                      <MenuItem value="Process Inventory">Process Inventory</MenuItem>
                      <MenuItem value="Semi Finished w/p">Semi Finished w/p</MenuItem>
                      <MenuItem value="Finished Products">Finished Products</MenuItem>
                      <MenuItem value="Office Files">Office Files</MenuItem>
                      <MenuItem value="Documents">Documents</MenuItem>
                      <MenuItem value="Papers/Stationeries">Papers/Stationeries</MenuItem>
                      {/* Add options for categories */}
                    </Select>
                  </FormControl>


                  <InputLabel style={modalInputLabelStyle}> Item Name:</InputLabel>
                  <FormControl fullWidth margin="normal">
                    <TextField
                      type="text"
                      value={itemName}
                      onChange={(e) => setItemName(e.target.value)}
                      style={{ marginBottom: 10, width: '100%' }}
                      disabled={isCoordinator} />

                  </FormControl>


                  <InputLabel style={modalInputLabelStyle}> SAP No:</InputLabel>
                  <FormControl fullWidth margin="normal">
                    <TextField
                      type="text"
                      value={sapNo}
                      onChange={(e) => setSapNo(e.target.value)}
                      style={{ marginBottom: 10, width: '100%' }} disabled={isCoordinator}
                    />
                  </FormControl>

                  <InputLabel style={modalInputLabelStyle}>Quantity:</InputLabel>
                  <FormControl fullWidth margin="normal">
                    <TextField
                      type="text"
                      value={quantity}
                      onChange={(e) => setQuantity(e.target.value)}
                      style={{ width: '100%', marginBottom: 10 }}
                      required disabled={isCoordinator} />
                  </FormControl>
                  <InputLabel style={modalInputLabelStyle}>Unit Of Measurement:</InputLabel>
                  <FormControl fullWidth margin="normal">
                    <Select
                      value={unitOfMeasurement}
                      onChange={(e) => setUnitOfMeasurement(e.target.value)}
                      //   style={{ width: '100%', padding: '8px', borderRadius: '5px', border: '1px solid #ccc' }}
                      disabled={isCoordinator} >
                      <MenuItem value="">Select</MenuItem>
                      <MenuItem value="Nos">No(s)</MenuItem>
                      <MenuItem value="Kg">Kg(s)</MenuItem>
                      <MenuItem value="Litres">Litre(s) </MenuItem>
                      <MenuItem value="set">Set(s)</MenuItem>
                      <MenuItem value="Meter">Meter(s)</MenuItem>
                      <MenuItem value="Roll">Roll(s)</MenuItem>
                      <MenuItem value="Lot">Lot(s)</MenuItem>
                      <MenuItem value="Box">Box(es)</MenuItem>
                      {/* Add options for categories */}
                    </Select>
                  </FormControl>
                  <InputLabel style={modalInputLabelStyle}> Approximate value of Each(in Rs):</InputLabel>
                  <FormControl fullWidth margin="normal">
                    <TextField
                      type="text"
                      value={approxValue}
                      onChange={(e) => setApproxValue(e.target.value)}
                      style={{ width: '100%', marginBottom: 10 }}
                      required disabled={isCoordinator} />
                  </FormControl>
                  <InputLabel style={modalInputLabelStyle}>Reason For tagging :</InputLabel>
                  <FormControl fullWidth margin="normal">

                    <Select
                      value={reason}
                      onChange={(e) => setReason(e.target.value)}
                      //   style={{ width: '100%', padding: '8px', borderRadius: '5px', border: '1px solid #ccc' }}
                      MenuProps={{ PaperProps: { style: { maxHeight: 300 } } }} // Adjust the height of the dropdown menu if needed
                      disabled={isCoordinator} >
                      <MenuItem value="">Select</MenuItem>
                      <MenuItem value="Not Needed">Not Needed</MenuItem>
                      <MenuItem value="Defective">Defective</MenuItem>
                      <MenuItem value="Not Needed Now">Not Needed Now </MenuItem>
                      <MenuItem value="Scrap Material">Scrap Material</MenuItem>
                      <MenuItem value="Unknown">Unknown</MenuItem>
                      <MenuItem value="Others">Others</MenuItem>
                      {/* Add options for categories */}
                    </Select>
                  </FormControl>


                  <InputLabel style={modalInputLabelStyle}>Disposal Method Suggested:</InputLabel>
                  <FormControl fullWidth margin="normal">
                    <Select
                      value={disposalMethod}
                      onChange={(e) => setDisposalMethod(e.target.value)}
                      // style={{ width: '100%', padding: '8px', borderRadius: '5px', border: '1px solid #ccc' }}
                      disabled={isCoordinator}   >
                      <MenuItem value="">Select</MenuItem>
                      <MenuItem value="To Be Scrapped">To Be Scrapped</MenuItem>
                      <MenuItem value="To Be Reused /Redeployed">To Be Reused /Redeployed</MenuItem>
                      <MenuItem value="To Be Rectified/Reused">To Be Rectified/Reused</MenuItem>
                      <MenuItem value="To Be Disposed">To Be Disposed</MenuItem>
                      {/* Add options for categories */}
                    </Select>

                  </FormControl>


                  <InputLabel style={modalInputLabelStyle}>Recommended Zone</InputLabel>
                  <FormControl fullWidth margin="normal">

                    <Autocomplete
                      options={recZoneAreas}

                      getOptionLabel={(option) => `Zone ${option.code} - ${option.name}`}
                      // value={recZoneAreas.find(zone => zone.id === recommedzone) || ''}
                      value={recommedzone}
                      onChange={handleRecZoneChange}
                      renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                    />

                    {/* <Autocomplete

      options={zoneAreas}

      getOptionLabel={(option) => `Zone ${option.code} - ${option.name}`}
      value={zoneAreas.find(zone => zone.id === recommedzone) || ''}
      // value={Recommedzone}
      onChange={handleRecZoneChange}

      renderInput={(params) => <TextField {...params} fullWidth />}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      // Use custom ListboxComponent
      style={{ marginBottom: 10 }}
    //  disabled={userRole !== '29121312-6afa-46dd-bfaf-2afdb2d72b70'} 
      /> */}


                  </FormControl>


                  <InputLabel style={modalInputLabelStyle}>Sub Area:</InputLabel>
                  <FormControl fullWidth margin="normal">
                    <TextField
                      type="text"
                      value={subarea}
                      onChange={(e) => setSubArea(e.target.value)}
                      style={{ marginBottom: 10, width: '100%' }}
                    //     disabled={userRole !== '29121312-6afa-46dd-bfaf-2afdb2d72b70'} 
                    />
                  </FormControl>

                  {/* <InputLabel>Status</InputLabel>
        
            <FormControl component="fieldset" fullWidth margin="normal">
             
              <RadioGroup
                value={status}
                onChange={(event) => setStatus(event.target.value)}
                aria-labelledby="status-radio-group"
                name="status-radio-group"
              >
                <FormControlLabel value="Redeployed" control={<Radio />} label="Redeployed" />
                <FormControlLabel value="Disposed" control={<Radio />} label="Disposed" />
               
              </RadioGroup>
            </FormControl> */}



                  {/* Other form fields */}
                  <button type="submit" style={{ backgroundColor: '#007bff', color: '#fff', border: 'none', padding: '10px 20px', borderRadius: '5px', cursor: 'pointer', display: 'block', margin: '0 auto' }}> Scrapped </button>
                </form>
              </Box>
            ) :
              (

                <Box style={{ maxWidth: '600px', padding: '20px', backgroundColor: '#f9f9f9', borderRadius: '8px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>



                  <form onSubmit={handleSubmit}>

                    <InputLabel style={modalInputLabelStyle}>Entry By</InputLabel>
                    <FormControl fullWidth margin="normal">

                      <Select
                        value={auditor}
                        onChange={(event) => setAuditor(event.target.value)}
                        fullWidth
                      >
                        {loading ? (
                          <MenuItem disabled>Loading...</MenuItem>
                        ) : (
                          auditorOptions.map((auditorOption) => (
                            <MenuItem key={auditorOption} value={userdet}>
                              {auditorOption}
                            </MenuItem>
                          ))
                        )}

                      </Select>

                      {errors.auditor && <p style={{ color: 'red' }}>{errors.auditor}</p>}
                    </FormControl>
                    <InputLabel style={modalInputLabelStyle}>Zone</InputLabel>

                    <FormControl fullWidth margin="normal">

                      <Autocomplete

                        options={zoneAreas}

                        getOptionLabel={(option) => `Zone ${option.code} - ${option.name}`}
                        value={zone}
                        onChange={handleZoneChange}

                        renderInput={(params) => <TextField {...params} fullWidth />}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        // Use custom ListboxComponent
                        style={{ marginBottom: 10 }}
                      />
  {errors.zone && <p style={{ color: 'red' }}>{errors.zone}</p>}
                    </FormControl>

                    <InputLabel style={modalInputLabelStyle}>Entry Date</InputLabel>
                    <FormControl fullWidth margin="normal">
                      <TextField
                        className="input-nis"
                        fullWidth
                        id="date"
                        type="date"
                        value={selectedDate}
                        onChange={handleDateChange}
                        inputProps={{ max: today }}
                        style={{ marginBottom: 10 }}
                      />
                          {errors.selectedDate && <p style={{ color: 'red' }}>{errors.selectedDate}</p>}
                    </FormControl>

                    {/* <Box style={{ marginBottom: '20px' }}>
<label style={{ display: 'block', marginBottom: '5px' }}>Red Tag No:</label>
<input type="text" value={redTagNo} onChange={(e) => setRedTagNo(e.target.value)} style={{ width: '100%', padding: '8px', borderRadius: '5px', border: '1px solid #ccc' }}  />
</Box> */}


                    <InputLabel style={modalInputLabelStyle}>Category :</InputLabel>
                    <FormControl fullWidth margin="normal">
                      <Select
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                        //style={{ width: '100%', padding: '8px', borderRadius: '5px', border: '1px solid #ccc' }}
                        MenuProps={{ PaperProps: { style: { maxHeight: 300 } } }} // Adjust the height of the dropdown menu if needed
                      >
                        <MenuItem value="">Select</MenuItem>
                        <MenuItem value="Equipment">Equipment</MenuItem>
                        <MenuItem value="Tools">Tools</MenuItem>
                        <MenuItem value="Measuring Instruments">Measuring Instruments</MenuItem>
                        <MenuItem value="Materials">Materials</MenuItem>
                        <MenuItem value="Parts">Parts</MenuItem>
                        <MenuItem value="Process Inventory">Process Inventory</MenuItem>
                        <MenuItem value="Semi Finished w/p">Semi Finished w/p</MenuItem>
                        <MenuItem value="Finished Products">Finished Products</MenuItem>
                        <MenuItem value="Office Files">Office Files</MenuItem>
                        <MenuItem value="Documents">Documents</MenuItem>
                        <MenuItem value="Papers/Stationeries">Papers/Stationeries</MenuItem>
                        {/* Add options for categories */}
                      </Select>
                      {errors.category && <p style={{ color: 'red' }}>{errors.category}</p>}
                    </FormControl>


                    <InputLabel style={modalInputLabelStyle}> Item Name:</InputLabel>
                    <FormControl fullWidth margin="normal">
                      <TextField
                        type="text"
                        value={itemName}
                        onChange={(e) => setItemName(e.target.value)}
                        style={{ marginBottom: 10, width: '100%' }}
                      />
     {errors.itemName && <p style={{ color: 'red' }}>{errors.itemName}</p>}
                    </FormControl>


                    <InputLabel style={modalInputLabelStyle}> SAP No:</InputLabel>
                    <FormControl fullWidth margin="normal">
                      <TextField
                        type="text"
                        value={sapNo}
                        onChange={(e) => setSapNo(e.target.value)}
                        style={{ marginBottom: 10, width: '100%' }}
                      />
                    </FormControl>

                    <InputLabel style={modalInputLabelStyle}>Quantity:</InputLabel>
                    <FormControl fullWidth margin="normal">
                      <TextField
                        type="text"
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                        style={{ width: '100%', marginBottom: 10 }}
                         />
                           {errors.quantity && <p style={{ color: 'red' }}>{errors.quantity}</p>}
                    </FormControl>
                    <InputLabel style={modalInputLabelStyle}>Unit Of Measurement:</InputLabel>
                    <FormControl fullWidth margin="normal">
                      <Select
                        value={unitOfMeasurement}
                        onChange={(e) => setUnitOfMeasurement(e.target.value)}
                      //   style={{ width: '100%', padding: '8px', borderRadius: '5px', border: '1px solid #ccc' }}
                      >
                        <MenuItem value="">Select</MenuItem>
                        <MenuItem value="Nos">No(s)</MenuItem>
                        <MenuItem value="Kg">Kg(s)</MenuItem>
                        <MenuItem value="Litres">Litre(s) </MenuItem>
                        <MenuItem value="set">Set(s)</MenuItem>
                        <MenuItem value="Meter">Meter(s)</MenuItem>
                        <MenuItem value="Roll">Roll(s)</MenuItem>
                        <MenuItem value="Lot">Lot(s)</MenuItem>
                        <MenuItem value="Box">Box(es)</MenuItem>
                        {/* Add options for categories */}
                      </Select>
                      {errors.unitOfMeasurement && <p style={{ color: 'red' }}>{errors.unitOfMeasurement}</p>}
                    </FormControl>
                    <InputLabel style={modalInputLabelStyle}> Approximate value of Each(in Rs):</InputLabel>
                    <FormControl fullWidth margin="normal">
                      <TextField
                        type="text"
                        value={approxValue}
                        onChange={(e) => setApproxValue(e.target.value)}
                        style={{ width: '100%', marginBottom: 10 }}
                         />
                         {errors.approxValue && <p style={{ color: 'red' }}>{errors.approxValue}</p>}
                    </FormControl>
                    <InputLabel style={modalInputLabelStyle}>Reason For tagging :</InputLabel>
                    <FormControl fullWidth margin="normal">

                      <Select
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}
                        //   style={{ width: '100%', padding: '8px', borderRadius: '5px', border: '1px solid #ccc' }}
                        MenuProps={{ PaperProps: { style: { maxHeight: 300 } } }} // Adjust the height of the dropdown menu if needed
                      >
                        <MenuItem value="">Select</MenuItem>
                        <MenuItem value="Not Needed">Not Needed</MenuItem>
                        <MenuItem value="Defective">Defective</MenuItem>
                        <MenuItem value="Not Needed Now">Not Needed Now </MenuItem>
                        <MenuItem value="Scrap Material">Scrap Material</MenuItem>
                        <MenuItem value="Unknown">Unknown</MenuItem>
                        <MenuItem value="Others">Others</MenuItem>
                        {/* Add options for categories */}
                      </Select>
                      {errors.reason && <p style={{ color: 'red' }}>{errors.reason}</p>}
                    </FormControl>


                    <InputLabel style={modalInputLabelStyle}>Disposal Method Suggested:</InputLabel>
                    <FormControl fullWidth margin="normal">
                      <Select
                        value={disposalMethod}
                        onChange={(e) => setDisposalMethod(e.target.value)}
                      // style={{ width: '100%', padding: '8px', borderRadius: '5px', border: '1px solid #ccc' }}
                      >
                        <MenuItem value="">Select</MenuItem>
                        <MenuItem value="To Be Scrapped">To Be Scrapped</MenuItem>
                        <MenuItem value="To Be Reused /Redeployed">To Be Reused /Redeployed</MenuItem>
                        <MenuItem value="To Be Rectified/Reused">To Be Rectified/Reused</MenuItem>
                        <MenuItem value="To Be Disposed">To Be Disposed</MenuItem>
                        {/* Add options for categories */}
                      </Select>
                      {errors.disposalMethod && <p style={{ color: 'red' }}>{errors.disposalMethod}</p>}
                    </FormControl>

                    {RedTagIdeditflg && (

                      <FormControl component="fieldset" fullWidth margin="normal">
                        <InputLabel>Status</InputLabel>
                        <RadioGroup
                          value={reviewstatus}
                          onChange={(event) => setReviewStatus(event.target.value)}
                          aria-labelledby="status-radio-group"
                          name="status-radio-group"
                        >
                          <FormControlLabel value="Move to common area" control={<Radio />} label="Move to common area" />
                          <FormControlLabel value="Move to scrap" control={<Radio />} label="Move to scrap" />

                        </RadioGroup>
                      </FormControl>
                    )}



                    {/* Other form fields */}
                    <button type="submit" style={{ backgroundColor: '#007bff', color: '#fff', border: 'none', padding: '10px 20px', borderRadius: '5px', cursor: 'pointer', display: 'block', margin: '0 auto' }}> {RedTagIdeditflg ? "Reviewed" : "Submit"} </button>
                  </form>

                  
                </Box>


              )}
      </Dialog>
  );

};

export default RedTagForm;





// monisha code
//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     const redTagNo = await generateassNumber();
//     const formData = {
//       redTagNo: redTagNo,
//       categoryNo: category,
//       itemName: itemName,
//       sapNo: sapNo,
//       quantity: quantity,
//       unitOfMeasurement: unitOfMeasurement,
//       approxValue: approxValue,
//       reason: reason,
//       disposalMethod: disposalMethod,
//       auditorName: auditor,
//       auditDate: selectedDate,
//       zoneId: zone,
//       plantId: localStorage.getItem('selectedPlantId')


//     };

//     if (!RedTagIdeditflg) {
//       const response = await axios.post(ApiURL + '/Insert_red_tagging', { formData: formData }, {
//         headers: {
//           Authorization: `Bearer ${access_token}`
//         }
//       });
//       console.log('RedTag created successfully.');
//       AlertDialog({
//         type: 'success',
//         title: 'success',
//         text: 'RedTag created successfully.',


//       });

//       // Update assessmentId state with the newly created assessment ID
//       if (response.data.success) {
//         const redtag_id = response.data.redtag_id;
//         localStorage.setItem("redtag_id", response.data.redtag_id);
//         navigate("/RedTagActivities")
//         // setAssessmentCreated(true);
//         // setLoading(false)
//       }

//     }
//     else {
//       const redTagId = localStorage.getItem("redtagId");
//       console.log(redTagId, "redTagId")
//       if (RedTagIdeditflg) {

//         const response = await axios.post(`${ApiURL}/update_red_tag_edit/${redTagId}`, { formData: formData }, {
//           headers: {
//             Authorization: `Bearer ${access_token}`
//           }
//         });
//         console.log('RedTag Updated successfully.');
//         AlertDialog({
//           type: 'success',
//           title: 'success',
//           text: 'RedTag Updated successfully.',


//         });

//         // Update assessmentId state with the newly created assessment ID
//         if (response.data.success) {
//           const redtag_id = response.data.redtag_id;
//           localStorage.setItem("redtag_id", response.data.redtag_id);
//           navigate("/RedTagActivities")

//         }

//         setLoading(false);


//       }


//     }


//   };