import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Header from '../../app/TopAppBar';
import { Card, Popover, IconButton, Tabs, Tab, Typography, Box, Button, MenuItem, Checkbox, Accordion, AccordionSummary, AccordionDetails, FormControlLabel,TextField,Container,Grid,CardContent, Avatar,Tooltip} from '@mui/material';

import axios from 'axios';
import configParam from '../../config';
import GoldBadge from '../../Asset/Icons/gold_badge.svg';
import silverBadge from '../../Asset/Icons/badge_silver.svg';



const Achievement = () => {
  const [tabValue, setTabValue] = useState(0);
  const [eliteCards, setEliteCards] = useState([]);
  const [nonEliteCards, setNonEliteCards] = useState([]);
  const access_token = JSON.parse(localStorage.getItem("accesstoken"));
  
  const ApiURL = configParam.API_URL;
  const styles = {
    card: {
      position: 'relative', 
      display: 'flex',
      marginBottom: '16px',
      border: '1px solid #ddd',
      padding: '16px',
      borderRadius: '8px',
      alignItems: 'center',
      marginTop:'8px',
      
    },
    avatar: {
      width: '80px',
      height: '80px',
      backgroundColor: '#E0E0E0',
      marginRight: '30px'
    },
    content: {
      flex: '1 0 auto'
    },
   
    footer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '16px'
    },
    teamAvatars: {
      display: 'flex', // Ensure the avatars are in a row
      gap: '4px', // Add some space between the avatars
      marginTop: '8px',
    },
    // teamAvatars: {
    //   display: 'flex',
    //   marginTop: '8px',
    //   '& > *': {
    //     marginRight: '4px'
    //   }
    // },
    badgeContainer: {
      position: 'absolute',
      top: '20px',
      right: '8px'
    },
   
  };
  // Function to fetch data from backend API
  const fetchData = async () => {
    try {
      const response = await axios.get(`${ApiURL}/get_avgscore_perzone_achievements`, {
       
        headers: {
          'Authorization': `Bearer ${access_token}`
           
        }
      });
      // const data = await response.json();
      classifyZones(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // Function to classify zones as active or dropped
  // Function to classify zones as active or dropped and ensure only one card per zone is displayed
const classifyZones = (data) => {
  const activeEliteZones = [];
  const droppedEliteZones = [];
  const zoneMap = new Map(); // Use a Map to store the latest data for each zone

  data.forEach(zone => {
    const zoneId = zone.zoneId;
    const rollingAverage = zone.rollingAverage;
    const totalScore = zone.totalScore;
    const month = zone.month;
const zoneCode=zone.zoneCode;
const zoneMembers=zone.zoneMembers;
const zoneLeader=zone.zoneLeader;
    if (!zoneMap.has(zoneId)) {
      zoneMap.set(zoneId, { rollingAverage, totalScore, month,zoneCode, zoneMembers,zoneLeader});
    } else {
      const existingData = zoneMap.get(zoneId);
      // Update existing data only if the current month is newer
      if (new Date(month) > new Date(existingData.month)) {
        zoneMap.set(zoneId, { rollingAverage, totalScore, month,zoneCode,zoneMembers,zoneLeader});
      }
    }
  });

  // Iterate over the map to classify zones and construct cards
  zoneMap.forEach((data, zoneId) => {
    const { rollingAverage, totalScore, month,zoneCode,zoneMembers,zoneLeader} = data;
    const zone = { zoneId, rollingAverage, totalScore, month,zoneCode,zoneMembers,zoneLeader};

    if (rollingAverage >= 31) {
      activeEliteZones.push(zone);
    } else {
      droppedEliteZones.push(zone);
    }
  });

  setEliteCards(activeEliteZones);
  setNonEliteCards(droppedEliteZones);
};

  // const classifyZones = (data) => {
  //   const activeEliteZones = [];
  //   const droppedEliteZones = [];
  
  //   data.forEach(zone => {
  //     if (zone.rollingAverage >= 31) {
  //       activeEliteZones.push(zone);
  //     } else {
  //       droppedEliteZones.push(zone);
  //     }
  //   });
  
  //   setEliteCards(activeEliteZones);
  //   setNonEliteCards(droppedEliteZones);
  // };

  useEffect(() => {
    fetchData();
  }, []); // Run only once on component mount
  const formatMonth = (dateString) => {
    const date = new Date(dateString);
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    return `${month} ${year}`;
  }
  const renderCards = (cards) => {
    return cards.map((card, index) => (
      <Card key={index} style={styles.card} variant="outlined">
        <Avatar style={styles.avatar} />
        <div style={styles.footer}>
        <Typography style={{position: 'absolute', bottom: '8px',left: '8px',fontSize: '14px',fontWeight: 400,color: '#616161' }}>Joined Date: {formatMonth(card.month)}</Typography>
        </div>
        <CardContent style={styles.content}>
         
          <Typography style={{ fontSize: '16px', fontWeight: 400, color: '#616161' }}>Zone Number </Typography>
          <Typography style={{ fontSize: '16px', fontWeight: 600, color: '#000000' }}>{card.zoneCode}</Typography>
          <Typography style={{ fontSize: '16px', fontWeight: 400, color: '#616161' }}>Average</Typography>
          <Typography style={{ fontSize: '16px', fontWeight: 600, color: '#000000' }}>{card.rollingAverage}</Typography>
          <Typography style={{ fontSize: '16px', fontWeight: 400, color: '#616161' }}>Team Members</Typography>
        
          
          <div style={styles.teamAvatars}>
          {card.zoneMembers && card.zoneMembers.map((member, memberIndex) => (
            <Avatar key={memberIndex} style={{ fontSize: "16px", width: "30px", height: "30px" }}>
              <Tooltip title={member}>
                {member ? member.split(' ').map(name => name.charAt(0)).join('').toUpperCase() : " "}
              </Tooltip>
            </Avatar>
          ))}
          </div>
           
          <div style={styles.footer}>
          
            <Typography style={{ position: 'absolute',   bottom: '8px',right: '16px',fontSize: '14px',fontWeight: 400,color: '#616161'}}> Zone Leader: {card.zoneLeader}</Typography>
          
          </div>
          
        </CardContent>
        <div style={styles.badgeContainer}>
          {(tabValue === 0) && (
            <img
              src={GoldBadge}
              alt="gold icon"
              style={{ width: "50px", height: "60px" }}
            />
          )}
          {(tabValue === 1) && (
            <img
              src={silverBadge}
              alt="silver icon"
              style={{ width: "50px", height: "60px" }}
            />
          )}
        </div>
      </Card>
    ));
  };

  return (
    <div style={{ padding: "16px"}}>
      {/* <Header pageTitle="Achievements" /> */}
      <div style={{ flexGrow: 1 }}>
        <Tabs value={tabValue} onChange={(e, newValue) => setTabValue(newValue)} aria-label="Audit tabs">
        <Tab
            label={
              <Box display="flex" alignItems="center">
                <img src={GoldBadge} alt="gold icon" style={{ width: '20px', height: '25px', marginRight: '8px' }} />
                Active in Elite Club
              </Box>
            }
          />
          <Tab
            label={
              <Box display="flex" alignItems="center">
                <img src={silverBadge} alt="silver icon" style={{ width: '20px', height: '25px', marginRight: '8px' }} />
                Dropped from Elite Club
              </Box>
            }
          />
          {/* <Tab label="Active in Elite Club" />
          <Tab label="Dropped from Elite Club" /> */}
        </Tabs>

    
      </div>

      {/* Render elite club cards */}
      {tabValue === 0 && renderCards(eliteCards)}

      {/* Render dropped from elite club cards */}
      {tabValue === 1 && renderCards(nonEliteCards)}
    </div>
  );
};

export default Achievement;




// const Achievement = () => {
//   const [tabValue, setTabValue] = useState(0);
//   const [eliteCards, setEliteCards] = useState([]);
//   const [nonEliteCards, setNonEliteCards] = useState([]);
//   const access_token = JSON.parse(localStorage.getItem("accesstoken"));
//   const secret_key = JSON.parse(localStorage.getItem("secret"));
//   const ApiURL = configParam.API_URL;
  // const styles = {
  //   card: {
  //     position: 'relative', 
  //     display: 'flex',
  //     marginBottom: '16px',
  //     border: '1px solid #ddd',
  //     padding: '16px',
  //     borderRadius: '8px',
  //     alignItems: 'center',
  //     marginTop:'8px',
      
  //   },
  //   avatar: {
  //     width: '80px',
  //     height: '80px',
  //     backgroundColor: '#E0E0E0',
  //     marginRight: '16px'
  //   },
  //   content: {
  //     flex: '1 0 auto'
  //   },
   
  //   footer: {
  //     display: 'flex',
  //     justifyContent: 'space-between',
  //     marginTop: '16px'
  //   },
  //   teamAvatars: {
  //     display: 'flex',
  //     marginTop: '8px',
  //     '& > *': {
  //       marginRight: '4px'
  //     }
  //   },
  //   badgeContainer: {
  //     position: 'absolute',
  //     top: '20px',
  //     right: '8px'
  //   },
   
  // };
//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get(`${ApiURL}/get_avgscore_perzone`, {
//           headers: {
//             Authorization: `Bearer ${access_token}`,
//           }
//         });
//         const data = response.data;
//         const elite = [];
//         const nonElite = [];

//         data.forEach(item => {
//           const { rollingAverage, totalScore, zoneId, zoneName, zoneCode, month, previousMonthScore } = item;
//           const previousSixMonths = data.filter(d => new Date(d.month) < new Date(month) && new Date(d.month) >= new Date(new Date(month).setMonth(new Date(month).getMonth() - 6)));

//           const allPreviousScoresBelow30 = previousSixMonths.every(d => d.totalScore < 30);

//           if (rollingAverage > 30 && totalScore > 30) {
//             elite.push({ type: 'Elite', zoneId, month, rollingAverage, totalScore, zoneName, zoneCode });
//           } else if (rollingAverage > 30 && totalScore < 30) {
//             elite.push({ type: 'Elite Caution', zoneId, month, rollingAverage, totalScore, zoneName, zoneCode, });
//           } else if (rollingAverage < 30 && previousMonthScore !== null && previousMonthScore < 30) {
//             elite.push({ type: 'Elite Looser', zoneId, month, rollingAverage, totalScore, zoneName, zoneCode, });
//           } else {
//             nonElite.push({ type: 'Non Elite', zoneId, month, rollingAverage, totalScore, zoneName, zoneCode, });
//           }
//         });

//         setEliteCards(elite);
//         setNonEliteCards(nonElite);
//       } catch (error) {
//         console.error('Error fetching data', error);
//       }
//     };

//     fetchData();
//   }, []);

  // const renderCards = (cards) => {
  //   return cards.map((card, index) => (
  //     <Card key={index} style={styles.card} variant="outlined">
  //       <Avatar style={styles.avatar} />
  //       <CardContent style={styles.content}>
       
  //         <Typography variant="h6">{card.type}</Typography>
  //         <Typography style={{fontSize:'16px',fontWeight:400,color:'#616161'}}>Zone Number </Typography>
  //         <Typography style={{fontSize:'16px',fontWeight:600,color:'#000000'}}>{card.zoneCode}</Typography>
  //         <Typography style={{fontSize:'16px',fontWeight:400,color:'#616161'}}>Average</Typography>
  //         <Typography style={{fontSize:'16px',fontWeight:600,color:'#000000'}}>{card.rollingAverage}</Typography>
          
  //         <div style={styles.footer}>
  //         <Typography  style={{fontSize:'16px',fontWeight:400,color:'#616161'}}>Month: {new Date(card.month).toLocaleDateString()}</Typography>
          
  //         <Typography variant="body2"> Total Score: {card.totalScore}</Typography>
  //         </div>
  //       </CardContent>
  //       <div style={styles.badgeContainer}>
  //       {(tabValue === 0 ) && (
  //       <img
  //         src={GoldBadge}
  //         alt="gold icon"
  //         style={{ width: "50px", height: "60px" }} 
  //       />
  //     )}
  //     {(tabValue === 1 ) && (
  //       <img
  //       src={silverBadge}
  //         alt="silver icon"
  //         style={{ width: "50px", height: "60px" }} 
  //       />
  //     )}
  //     </div>
  //     </Card>
  //   ));
  // };

//   return (
//     <div>
//       <Header pageTitle="Achievements" />
      
//       <div style={{ flexGrow: 1,marginTop:45}}>
//         <Tabs value={tabValue} onChange={(e, newValue) => setTabValue(newValue)} aria-label="Audit tabs">
//           <Tab label="Active in Elite Club" />
//           <Tab label="Dropped from Elite Club" />
//         </Tabs>
//       </div>
//       <div >
//         {tabValue === 0 && (
//           <div>
//             {renderCards(eliteCards)}
//           </div>
//         )}
//         {tabValue === 1 && (
//           <div>
//             {renderCards(nonEliteCards)}
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default Achievement;


