import { Subject } from "@mui/icons-material";
import API from "../../Services/API";
import axios from "axios";




export const handleSend = async (from_email, to_email) => {
    const access_token = JSON.parse(localStorage.getItem("accesstoken"));
    

    try {
        
        // await insertReportStatus(from_email, to_email);
        if (to_email.length > 0) {
            await insertReportStatus(from_email, to_email);
        } else {
            console.log("No valid email addresses to send to.");
        }
    } catch (error) {
        console.error("An error occurred in handleSend:", error);
        // Handle error state or display error message to the user
    }
    // try {
    //     await insertReportStatus(from_email, to_email, cft_team);
    //     const assessmentsID = localStorage.getItem("assessmentId");
    //     const storedUserData = JSON.parse(localStorage.getItem("userDatas"));

    //     const approverSGID = storedUserData.N1SGID;
    //     const userSGID = assessmentsID ? storedUserData.SGID : "";
    //     const mocApprovalID = localStorage.getItem("mocApprovalID");
    //     const userAppName = assessmentsID ? storedUserData.FName + " " + storedUserData.LName : "";
    //     const sendMailID = localStorage.getItem("sendMailID");

    //     const mocData = {
    //         id: mocApprovalID,
    //         assess_id: assessmentsID,
    //         approver_sgid: approverSGID,
    //         // user_sgid: userSGID,
    //         // user_approval_name: userAppName,
    //         // send_mail_id: sendMailID,
    //     };

    //     const mocResponse = await axios.put(`${API.UPDATE_MOC_APPROVALS}?id=${mocApprovalID}`, mocData, {
    //         headers: {
    //             Authorization: `Bearer ${access_token}`
    //         }
    //     }
    //     );

    //     if (mocResponse.status === 200) {
    //         // setSave(true);
    //         // setShowSuccessPopup(true);
    //     }
    // } catch (error) {
    //     console.error("An error occurred in handleSend:", error);
    //     // Handle error state or display error message to the user
    // }
};

const insertReportStatus = async (from_email, to_email,assess_id) => {
    // console.log("assess_id11",assess_id)
    // const assessmentsID = localStorage.getItem("assessmentId"); // Dynamic assessmentId
    const access_token = JSON.parse(localStorage.getItem("accesstoken")); // Access token
    const userData = JSON.parse(localStorage.getItem("userDatas")); // User data from localStorage
// console.log("assessmentsIDassessmentsID",assessmentsID)
    // Retrieve dynamically saved assessmentData from localStorage
    const assessmentData = JSON.parse(localStorage.getItem("assessmentData"));
    console.log("assessmentDataassessmentData123",assessmentData)
    
    // Check if assessmentData exists and extract dynamic values
    const ass_no = assessmentData?.ass_no || 'N/A'; // Assessment number
    const id = assessmentData?.id || 'N/A'; // Creator of the assessment

console.log("assessmentDataassessmentData123id",assessmentData.id)
    const auditorFullName = localStorage.getItem("auditorFullName") || 'N/A';
    const groupLeaderFullName = localStorage.getItem("groupLeaderFullName") || 'N/A';
    const coordinatorFullName = localStorage.getItem("coordinatorFullName") || 'N/A';
    // Construct the email message using the dynamically fetched assessmentData
    const messageText = `Dear  ${groupLeaderFullName}, and ${coordinatorFullName},
    Greetings,
    Assessment no. ${ass_no} needs your kind attention.

    Assessment Details:
    - Assessment Number: ${ass_no}
    - Created By: ${auditorFullName}
    
    Kindly review the assessment details.

    Assessment Link: https://dev.sg5s.saint-gobain.com/login`;

    try {
        const emailFieldsData = {
            from_email,
            to_email,
            // cft_team,
            assess_id:id,
            message: messageText,
        };
console.log("emailFieldsDataemailFieldsData",emailFieldsData)
        // Post request to insert email report

        if (to_email.length > 0) {
            const response = await axios.post(API.INSERT_EMAIL_REPORT, emailFieldsData, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
            if (response.status === 200) {
                await sendEmail(from_email, to_email, messageText);
            }
        }
        // const response = await axios.post(API.INSERT_EMAIL_REPORT, emailFieldsData, {
        //     headers: {
        //         Authorization: `Bearer ${access_token}`
        //     }
        // });

        // if (response.status === 200) {
        //     // Trigger actual email sending function
        //     await sendEmail(from_email, to_email,messageText);
        // }
    } catch (error) {
        console.log("Error sending email:", error);
    }
};


// const insertReportStatus = async (from_email, to_email, cft_team) => {

//     const assessmentsID = localStorage.getItem("assessmentId");
//     const access_token = JSON.parse(localStorage.getItem("accesstoken"));
//     const userData = JSON.parse(localStorage.getItem("userDatas"));
//     const mocData = JSON.parse(localStorage.getItem("mocData"));
//     const mocNumberData = localStorage.getItem("mocNumber");
//     let messageHint = '';
//     console.log(userData, "userData50");
//     console.log(mocData, "mocData51", mocNumberData, "mocNumberData51");
//     const mocDetData = JSON.parse(localStorage.getItem("mocDataByID"));
//     console.log(mocDetData, "mocDetData534");
//     console.log(userData.SGID, "userData.SGID55");
//     console.log(mocData?.user_id, "mocData?.user_id56");
//     console.log(mocDetData?.mocForm?.user_id, "mocDetData?.mocForm?.user_sgid58");
//     const userId = mocData?.user_id ? mocData?.user_id : mocDetData?.mocForm?.user_id;
//     console.log(userId, "userId60");
//     const created_by = mocData?.created_by;
//     if (userData.SGID === userId) {
//         messageHint = 'Kindly review and update the MOC details with all evidences.';
//     }
//     if (userData.SGID !== userId) {
//         messageHint = 'Kindly review the MOC details with all evidences and approve the same.';
//     }
//     console.log(messageHint, "messageHint57")
//     const messageText = `Dear ${userData.FName + " " + userData.LName},

//     Greetings,
    
//     MOC request no. ${mocNumberData ? mocNumberData : mocDetData?.mocForm?.moc_no} needs your kind attention.
    
//     ${messageHint}
    
//     MOC created by: ${created_by ? created_by : mocDetData?.mocForm?.created_by}
    
//     MOC Link: https://dev.sgmoc.saint-gobain.com/login`;


//     try {
//         const emailFieldsData = {
//             from_email: from_email,
//             to_email: to_email,
//             cft_team: cft_team,
//             assess_id: assessmentsID,
//             message: messageText,
//         };
//         const response = await axios.post(API.INSERT_EMAIL_REPORT, emailFieldsData, {
//             headers: {
//                 Authorization: `Bearer ${access_token}`
//             }
//         });
//         if (response.status === 200) {
//             const sendMailID = response.data["identifiers"][0].id;
//             localStorage.setItem("sendMailID", sendMailID);
//             await sendEmail(from_email, to_email, cft_team, messageText);
//             // handleClose();
//         }
//     } catch (error) {
//         console.log(error);
//     }
// };

const sendEmail = async (from_email, to_email, message) => {
    const access_token = JSON.parse(localStorage.getItem("accesstoken"));
    const userData = JSON.parse(localStorage.getItem("userDatas"));
    const mocDetData = JSON.parse(localStorage.getItem("mocDataByID"));
    const assessmentData = JSON.parse(localStorage.getItem("assessmentData"));
    const ass_no = assessmentData?.ass_no || 'N/A';
    // const mocNumberData = localStorage.getItem("mocNumber");
    try {
        const emailParameters = {
            from_email: from_email,
            to_email: to_email,
            // cft_team: cft_team,
            subject: ass_no,
            // subject:"this is a test mail",
            message: message,
            // name: userData.name,
            // created_by: mocDetData.mocForm.created_by,
            // moc_no: mocDetData.mocForm.moc_no,
        }
        const response = await axios.post(API.SEND_MAIL_API, emailParameters, {
            headers: {
                Authorization: `Bearer ${access_token}`
            }
        });
        // setEmailStatus(response.data.message);
        // handleClose();
        // navigate("/ManageMoc");
    } catch (error) {
        // setEmailStatus('Failed to send email');
        console.error(error);
    }
};