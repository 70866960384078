import React, { useEffect, useState } from 'react';
import { styled } from '@mui/system';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Modal, Box, Typography, IconButton, Tooltip, CircularProgress, FormHelperText, FormControl, InputLabel, Dialog, DialogTitle, DialogContent, Select, MenuItem, DialogActions, TextField,Autocomplete
} from '@mui/material';
import TextBox from '../input/TextBox';
import SelectBox from '../input/SelectBox';
import { ReactComponent as EditIcon } from "../../Asset/Icons/ic_edit.svg";
import { ReactComponent as DeleteIcon } from "../../Asset/Icons/ic_delete.svg";
import { ReactComponent as SearchIcon } from "../../Asset/Icons/search_icon.svg";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { ReactComponent as CloseIcon } from "../../Asset/Icons/ic_close.svg";
import AlertDialog from '../input/AlertDialog';
import StyledButton from '../../Inputs/StyledButton';
import axios from 'axios';
import API from '../../Services/API';
import { useRecoilState } from "recoil";
import { zoneData } from '../../recoil/atoms';
import configParam from '../../config';

const ModalContent = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  height: 800,
  backgroundColor: "#fff",
  padding: theme.spacing(4)
}));

const firstRowStyles = {
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '20px',
  letterSpacing: '-0.006em',
  textAlign: 'left',
  color: "#0A090B"
};
const modalInputLabelStyle = {
 
  fontSize: 16,
  fontStyle: 'normal',
  fontWeight: 500,
  color: '#000',
};
const ZoneDefinitions = () => {
  const [zoneNo, setZoneNo] = useState([]);
  const [userRole, setuser] = useState([]);
  const [formData, setFormData] = useRecoilState(zoneData);
  const [open, setOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isInputVisible, setInputVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredZone, setFilteredZone] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [noRecords, setNoRecords] = useState(false);
  const [selectedZoneIndex, setSelectedZoneIndex] = useState(null);
  const [zoneInformation, setZoneInformation] = useState([]);
  const [leaders, setLeaders] = useState([]);
  const [ZoneMember, setZoneMember] = useState([]);
  const access_token = JSON.parse(localStorage.getItem("accesstoken"));
  const ApiURL = configParam.API_URL;
   const [addMemberModalOpen, setAddMemberModalOpen] = useState(false);
  const [teamMembers, setTeamMembers] = useState([]);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [members, setMembers] = useState([]);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [existingZone, setExistingZone] = useState(null);
  const [newMember, setNewMember] = useState({
    name: '',
    zone_id: '',
  });
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

  const [originalFormData, setOriginalFormData] = useState({});
  const [memberToDelete, setMemberToDelete] = useState(null);
  useEffect(() => {
    getRole();
    fetchZoneInformation();
    fetchLeaders();
    fetchteamMembers();
  }, []);


  
  const getRole = () => {
    const userDatas = JSON.parse(localStorage.getItem("userDatas"));
  const userid = userDatas.sgid;
  const plantId = localStorage.getItem('selectedPlantId');
    axios.get(`${ApiURL}/getroleuser/${userid}/${plantId}`,{headers: {
        Authorization: `Bearer ${access_token}`,
       
      }})
        .then(response => {
            setLoading(false);
            if (response.status === 200) {
                if (response.data && response.data.length > 0) {
                    setuser(response.data);
                      console.log("userRole",response.data)                 
                }
            }
        })
        .catch(error => {
            setLoading(false);
            console.error("Error fetching assessment data:", error);
        });
  };
  
  const isCoordinator = userRole.some(user => user.wcm_coordinator_firstname !== '' &&  user.wcm_coordinator_firstname !== null);
  console.log("isCoordinator",isCoordinator)
  const iszoneleader = userRole.some(user => user.zone_first_name !== '' &&  user.zone_first_name !== null);
  console.log("iszoneleader",iszoneleader)
  const isgroupleader = userRole.some(user => user.group_firstname !== '' &&  user.group_firstname !== null);
  console.log("isgroupleader",isgroupleader)
  const isPlantHead = userRole.some(user => user.plant_headfirstname !== '' &&  user.plant_headfirstname !== null);
console.log("isPlantHead",isPlantHead)


const isStore = userRole.some(user => user.store_headfirstname !== '' &&  user.store_headfirstname !== null);
console.log("isStore",isStore)
  const fetchLeaders = async () => {
    try {

      const selectedPlantId = localStorage.getItem('selectedPlantId');
      const response = await axios.get(`${ApiURL}/get_zone_leader/${selectedPlantId}`, { headers: { Authorization: `Bearer ${access_token}` } });
      setLeaders(response.data);
      console.log("zoneleadersrespeonse",response.data)
    } catch (error) {
      console.error('Error fetching leaders:', error);
    }
  };

  const fetchteamMembers = async () => {
    try {
      const response = await axios.get(`${ApiURL}/getZoneTeamMember`, { headers: { Authorization: `Bearer ${access_token}` } });
      setZoneMember(response.data);
      console.log("zonemembersrespeonse",response.data)
    } catch (error) {
      console.error('Error fetching leaders:', error);
    }
  };
  const handleOpenModal = () => {
    setOpen(true);
    setIsEditMode(false);
    // setFormData([]);
    setFormData({
      zone_area_id: '',
      zone: '',
      zone_leader_name: null, // Set to null for the Autocomplete
      zone_leader: '',
      group_leader_name: null, // Set to null for the Autocomplete
      group_leader: '',
      wcm_coordinator_name: null, // Set to null for the Autocomplete
      wcm_coordinator: '',
      Auditor_name: null, // Set to null for the Autocomplete
      Auditor: '',
      Plant_head_name: null, // Set to null for the Autocomplete
      Plant_head: '',
      Store_head_name: null, // Set to null for the Autocomplete
      Store_head: ''
  });
  };

  const handleSearchIconClick = () => {
    setInputVisible(true);
  };

  const handleInputChange = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    if (query.trim() === "") {
      setFilteredZone(zoneInformation); // Show all data when search is cleared
    } else {
      const filtered = zoneInformation.filter((zone) => {
        const zoneCodeQuery = zone.code.toString().includes(query);
        const zoneLeaderQuery = `${zone.zonefirstname} ${zone.zonelastname}`.toLowerCase().includes(query);
        const groupLeaderQuery = `${zone.groupfirstname} ${zone.grouplastname}`.toLowerCase().includes(query);
        const wcmCoOrdinatorQuery = `${zone.coordinatefirstname} ${zone.coordinatelastname}`.toLowerCase().includes(query);
        const plantheadQuery = `${zone.plantheadfirstname} ${zone.plantheadlastname}`.toLowerCase().includes(query);
        const storeheadQuery = `${zone.storeheadfirstname} ${zone.storeheadlastname}`.toLowerCase().includes(query);
       
        return zoneCodeQuery || zoneLeaderQuery || groupLeaderQuery || wcmCoOrdinatorQuery || plantheadQuery || storeheadQuery;
      });
      setFilteredZone(filtered);
    }
    setNoRecords(filteredZone.length === 0);
  };

  

  

  const handleCloseIconClick = () => {
    setInputVisible(false);
    setSearchQuery('');
    setFilteredZone([]);
     // Reset to full data
    setNoRecords(false);
  };

  const handleCloseModal = () => {
    setOpen(false);
};



const handleView = async (index) => {
  setSelectedZoneIndex(index);
  

  const currentZoneList = searchQuery.trim() ? filteredZone : zoneInformation;
  const selectedZone = currentZoneList[index];
  setSelectedZoneIndex(index);
  const zoneid = selectedZone.zone_area_id;
  const selectedPlant = localStorage.getItem('selectedPlantId');
  localStorage.setItem("Zone_area_no", zoneid);
  try {
    const response = await axios.get(`${ApiURL}/get_team_members/${zoneid}/${selectedPlant}`, {
      headers: {
        Authorization: `Bearer ${access_token}`
      }
    });
    if (response.status === 200) {
      setTeamMembers(response.data);
      
      console.log(response.data, "setTeamMembers");
      setViewModalOpen(true);
  } else {
      console.error('Failed to fetch team members:', response);
  }
  
  } catch (error) {
  console.error('Error fetching team members:', error);
  }
  };
  

const handleRemoveMember = (index) => {
  // Remove a member from the members array based on index
  const updatedMembers = members.filter((_, i) => i !== index);
  setMembers(updatedMembers);
};



const handleViewModalClose = () => {
  setViewModalOpen(false);
};

const handleAddMember = () => {
  setFormData(prevState => ({
    ...prevState,
    Team_member_name: null // Reset the autocomplete value
  }));
  setAddMemberModalOpen(true);
 
};



const handleAddMemberSubmit = async (event) => {
  event.preventDefault();
  try {
    const ZoneID = localStorage.getItem("Zone_area_no");
    const plant = localStorage.getItem('selectedPlantId');
    console.log(formData.Team_member_name, ZoneID, "formData");
    const data = {
      ZoneID: ZoneID,
      plantID:plant,
      Team_member_name: formData.Team_member_name.id,
    };

    const response = await axios.post(ApiURL + '/ADD_TEAM_MEMBER', { data: data }, {
      headers: {
        Authorization: `Bearer ${access_token}`
      }
    });

    if (response.status === 200) {
      AlertDialog({
        type: 'success',
        title: 'Success',
        text: 'Team Member Added Successfully',
        confirmButtonText: 'Ok',
        onConfirm: () => setAddMemberModalOpen(false),
      });

      const newMember = {
        sgid: formData.Team_member_name.searchValue.split(' - ')[1], // Extract SGID
  firstName: formData.Team_member_name.searchValue.split(' - ')[0], // Extract the name
  lastName: '',
      };
      
      // Update teamMembers state to include the new member
      setTeamMembers(prevMembers => [...prevMembers, newMember]);
    
    }
    
  } catch (error) {
    if (error.response && error.response.status === 400) {
     
      AlertDialog({
        type: 'delete',
        title: 'User Already Exists',
        text: `User is already a member in zone .Please delete user in existing zone to add to new zone`,
        confirmButtonText: 'Ok',
      });
      setExistingZone(error.response.data.existingZone);
      setConfirmationOpen(true);
    } else {
      AlertDialog({
        type: 'warning',
        title: 'Error',
        text: error.message || 'An error occurred',
      });
    }
  }
};

const handleConfirmReassign = async () => {
  setConfirmationOpen(false);
  try {
    const ZoneID = localStorage.getItem("Zone_area_no");
    const plant = localStorage.getItem('selectedPlantId');
    console.log(formData.Team_member_name, ZoneID, "formData");
    const data = {
      ZoneID: ZoneID,
      plantID:plant,
      Team_member_name: formData.Team_member_name.id,
    };

    const response = await axios.post(ApiURL + '/ADDREASSIGNTEAMMEMBER', { data: data }, {
      headers: {
        Authorization: `Bearer ${access_token}`
      }
    });

    if (response.status === 200) {
      AlertDialog({
        type: 'success',
        title: 'Success',
        text: 'Team Member Added Successfully',
        confirmButtonText: 'Ok',
        onConfirm: () => setAddMemberModalOpen(false),
      });
    }
  } catch (error) {
    AlertDialog({
      type: 'warning',
      title: 'Error',
      text: error.message || 'An error occurred',
    });
  }
};

const handleCancelReassign = () => {
  setConfirmationOpen(false);
};



const handleAddMemberModalClose = () => {
  setAddMemberModalOpen(false);
};

// const handleNewMemberChange = (event) => {
//   const { name, value } = event.target;
//   setNewMember((prevState) => ({
//     ...prevState,
//     [name]: value,
//   }));
// };

const handleEdit = async (index) => {
  setSelectedZoneIndex(index);
  setIsEditMode(true);

  const selectedZone = filteredZone.length > 0 ? filteredZone[index] : zoneInformation[index];
  console.log("selectedZone", selectedZone);
  localStorage.setItem("zoneID", selectedZone.id);

  const getFullNameOrEmpty = (firstName, lastName, sgid) =>
    firstName && lastName ? `${firstName} ${lastName} - ${sgid}` : '';

  const initialFormData = {
    zone_area_id: selectedZone.zone_area_id,
    zone: selectedZone.name,
    zone_leader_name: {
      id: selectedZone.zone_leader,
      searchValue: getFullNameOrEmpty(selectedZone.zonefirstname, selectedZone.zonelastname, selectedZone.zone_sgid),
    },
    zone_leader: selectedZone.zone_leader || '',
    group_leader_name: {
      id: selectedZone.group_leader,
      searchValue: getFullNameOrEmpty(selectedZone.groupfirstname, selectedZone.grouplastname, selectedZone.group_sgid),
    },
    group_leader: selectedZone.group_leader || '',
    wcm_coordinator_name: {
      id: selectedZone.wcm_coordinator,
      searchValue: getFullNameOrEmpty(selectedZone.coordinatefirstname, selectedZone.coordinatelastname, selectedZone.coordinator_sgid),
    },
    wcm_coordinator: selectedZone.wcm_coordinator || '',
    Auditor_name: {
      id: selectedZone.auditor,
      searchValue: getFullNameOrEmpty(selectedZone.auditorfirstname, selectedZone.auditorlastname, selectedZone.auditor_sgid),
    },
    auditor: selectedZone.auditor || '',
    Plant_head_name: {
      id: selectedZone.plant_head,
      searchValue: getFullNameOrEmpty(selectedZone.plantheadfirstname, selectedZone.plantheadlastname, selectedZone.planthead_sgid),
    },
    plant_head: selectedZone.plant_head || '',
    Store_head_name: {
      id: selectedZone.store_head,
      searchValue: getFullNameOrEmpty(selectedZone.storeheadfirstname, selectedZone.storeheadlastname, selectedZone.storehead_sgid),
    },
    store_head: selectedZone.store_head || ''
  };

  setFormData(initialFormData);
  setOriginalFormData(initialFormData); // Store original form data
  setOpen(true);
};


// const handleEdit = async (index) => {
//   setSelectedZoneIndex(index);
//   setIsEditMode(true);

//   const selectedZone = filteredZone.length > 0 ? filteredZone[index] : zoneInformation[index];
//   console.log("selectedZone", selectedZone);
//   localStorage.setItem("zoneID", selectedZone.id);

//   const initialFormData = {
//       zone_area_id: selectedZone.zone_area_id,
//       zone: selectedZone.name,
//       zone_leader_name: { id: selectedZone.zone_leader, searchValue: `${selectedZone.zonefirstname} ${selectedZone.zonelastname} - ${selectedZone.zone_sgid}` },
//       zone_leader: selectedZone.zone_leader || '',
//       group_leader_name: { id: selectedZone.group_leader, searchValue: `${selectedZone.groupfirstname} ${selectedZone.grouplastname} - ${selectedZone.group_sgid}` },
//       group_leader: selectedZone.group_leader || '',
//       wcm_coordinator_name: { id: selectedZone.wcm_coordinator, searchValue: `${selectedZone.coordinatefirstname} ${selectedZone.coordinatelastname} - ${selectedZone.coordinator_sgid}` },
//       wcm_coordinator: selectedZone.wcm_coordinator || '',
//       Auditor_name: { id: selectedZone.auditor, searchValue: `${selectedZone.auditorfirstname} ${selectedZone.auditorlastname} - ${selectedZone.auditor_sgid}` },
//       auditor: selectedZone.auditor || '',
//       Plant_head_name: { id: selectedZone.plant_head, searchValue: `${selectedZone.plantheadfirstname} ${selectedZone.plantheadlastname} - ${selectedZone.planthead_sgid}` },
//       plant_head: selectedZone.plant_head || '',
//       Store_head_name: { id: selectedZone.store_head, searchValue: `${selectedZone.storeheadfirstname} ${selectedZone.storeheadlastname} - ${selectedZone.storehead_sgid}` },
//       store_head: selectedZone.store_head || ''
//   };

//   setFormData(initialFormData);
//   setOriginalFormData(initialFormData); // Store original form data
//   setOpen(true);
// };




  const handleDelete = async (index) => {
    setSelectedZoneIndex(index);
    const selectedZone = zoneInformation[index];
    const id = selectedZone.id;
    AlertDialog({
      type: 'delete',
      title: 'Delete?',
      text: 'Are you sure you want to delete?',
      confirmButtonText: 'Yes',
      onConfirm: async () => {
        const response = await axios.delete(`${API.DELETE_ZONE_DEFINITIONS}/${id}`, {
          headers: {
            Authorization: `Bearer ${access_token}`
          }
        });
        if (response) {
          fetchZoneInformation();
        }
      },
    });
  };


  const fetchZoneArea = async () => {
    try {
      const plant_id = localStorage.getItem('selectedPlantId');
      const response = await axios.get(`${ApiURL}/get_ZoneAreasByPlantId/${plant_id}`, {
        headers: { Authorization: `Bearer ${access_token}` }
      });
      const sortedZones = response.data.sort((a, b) => a.code - b.code);
      setZoneNo(sortedZones);
    } catch (error) {
      console.error('Error fetching ZoneAreas:', error);
    }
  };


  const handleZoneChange = (selectedZoneId) => {
    // Find the selected zone from zoneNo array
    const selectedZone = zoneNo.find(zone => zone.id === selectedZoneId);
    // Update the formData with the selected zone name
    handleChange({ target: { name: 'zone', value: selectedZone.name } });
};
// const leaderOptions = leaders.map(leader => ({ id: leader.id, name: leader.firstName + leader.lastName }));
const leaderOptions = leaders.map(leader => ({
  id: leader.id,
  name: `${leader.firstName} ${leader.lastName} - ${leader.sgid}`,
  searchValue: `${leader.firstName} ${leader.lastName} - ${leader.sgid}` || ''

}));

   
const ZoneMemberOptions = ZoneMember.map(ZoneMembers => ({ id: ZoneMembers.id, name: ZoneMembers.firstName }));
   
const handleChange = (event) => {
  const { name, value } = event.target;

  if (name.endsWith('_name')) {
      const selectedLeader = leaders.find(leader => leader.id === value);
      if (selectedLeader) {
          const key = name.replace('_name', '');
          setFormData(prevState => ({
              ...prevState,
              [key]: selectedLeader.id, // Set the leader ID
              [name]: {
                  id: selectedLeader.id,
                  searchValue: `${selectedLeader.firstName} ${selectedLeader.lastName} - ${selectedLeader.sgid}`
              } // Set the leader object
          }));
      }
  } else {
      setFormData(prevState => ({
          ...prevState,
          [name]: value
      }));
  }
};





  const fetchZoneInformation = async () => {
    setError(null);
    try {
      setLoading(true);
      const selectedPlant = localStorage.getItem('selectedPlantId');
      const response = await axios.get(`${API.GET_ZONE_DEFINITIONS}/${selectedPlant}` , {
        headers: {
          Authorization: `Bearer ${access_token}`
        }
      });
      const data = response.data;
      setZoneInformation(data);
      setFilteredZone(data); // Initialize filteredZone with all data
      fetchZoneArea();
    } catch (error) {
      setError("Error fetching data");
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

console.log("zoneInformation",zoneInformation)
console.log("selectedZoneIndex",selectedZoneIndex)
console.log("leaders",leaders)

  const handleSave = async () => {
    try {
        const isValid = validateFormData();
        if (!isValid) return;
        setLoading(true); // Start loader
        // const jsonData = formData;
        
        const jsonData = {
          zone_area_id: formData.zone_area_id || null,
          zone_leader: formData.zone_leader !== originalFormData.zone_leader ? formData.zone_leader : originalFormData.zone_leader,
          group_leader: formData.group_leader !== originalFormData.group_leader ? formData.group_leader : originalFormData.group_leader,
          wcm_coordinator: formData.wcm_coordinator !== originalFormData.wcm_coordinator ? formData.wcm_coordinator : originalFormData.wcm_coordinator,
          auditor: formData.Auditor !== originalFormData.Auditor ? formData.Auditor : originalFormData.Auditor,
          ...(formData.Plant_head && { plant_head: formData.Plant_head }),
          ...(formData.Store_head && { store_head: formData.Store_head })
          // plant_head: formData.Plant_head !== originalFormData.Plant_head ? formData.Plant_head : originalFormData.Plant_head,
          // store_head: formData.Store_head !== originalFormData.Store_head ? formData.Store_head : originalFormData.Store_head
      };

       
        const plant = localStorage.getItem('selectedPlantId');
        console.log(jsonData, "jsondata155")
        let response;
        let successMessage;
        if (isEditMode && selectedZoneIndex !== null) {
            const zoneId =  localStorage.getItem("zoneID");
            //zoneInformation[selectedZoneIndex].id;
            console.log("Updating zone with ID:", zoneId);
            response = await axios.put(`${API.UPDATE_ZONE_DEFINITIONS}/${zoneId}`, jsonData, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
            successMessage = 'Zone Updated Successfully';
        } else {
            response = await axios.post(`${ API.INSERT_ZONE_DEFINITIONS}/${plant}`, jsonData, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
            successMessage = 'Zone Inserted Successfully';
        }
        if (response.status === 200) {
            AlertDialog({
                type: 'success',
                title: 'Success',
                text: successMessage,
                confirmButtonText: 'Ok',
                onConfirm: async () => {
                    await fetchZoneInformation();
                },
            });
        } else {
            console.error('Error saving data:', response.data);
            AlertDialog({
                type: 'warning',
                title: 'Error',
                text: 'Failed to save data',
            });
        }

        setOpen(false);
        // fetchZoneInformation(); // Close the modal after saving
    } catch (error) {
        console.error('Error saving data:', error);
        AlertDialog({
            type: 'warning',
            title: 'Error',
            text: error.message || 'An error occurred',
        });
    } finally {
        setLoading(false); // Stop loader regardless of success or failure
    }
};


  const validateFormData = () => {
    const errors = {};
    if (!formData.zone_area_id) {
      errors.zone_area_id = 'Zone number is required';
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };
console.log("formdata9999",formData)


const handleDeleteClick = (member) => {
  setMemberToDelete(member)
  setDeleteConfirmationOpen(true);
};

const handleCancelDelete = () => {
  setDeleteConfirmationOpen(false);
  setMemberToDelete(null);
};

const handleConfirmDelete = async () => {
  try {
    const response = await axios.post(ApiURL + '/DELETE_TEAM_MEMBER', {
      id: memberToDelete.id // Send the member ID for the soft delete
    }, {
      headers: {
        Authorization: `Bearer ${access_token}`
      }
    });
console.log("responsemember",response)
    if (response.status === 200) {
      setTeamMembers(prevMembers => prevMembers.filter(member => member.id !== memberToDelete.id));
      AlertDialog({
        type: 'success',
        title: 'Success',
        text: 'Team Member Deleted Successfully',
        confirmButtonText: 'Ok',
        onConfirm: () => {
          setDeleteConfirmationOpen(false);
          setMemberToDelete(null);
          // Optionally refresh team members or update state
        }
      });
    }
  } catch (error) {
    AlertDialog({
      type: 'warning',
      title: 'Error',
      text: error.message || 'An error occurred',
    });
  }
};
console.log("teamMembersteamMembers",teamMembers)
  return (
    <div>
      {/* <Button onClick={handleOpenModal}>Add Zone</Button> */}
      <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between' }}>
        {isInputVisible ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              value={searchQuery}
              onChange={handleInputChange}
              placeholder="Search by zone code or leader name"
              style={{ marginRight: '8px' }}
            />
            <IconButton onClick={handleCloseIconClick}>
              <CloseIcon />
            </IconButton>
          </div>
        ) : (
          <IconButton onClick={handleSearchIconClick}>
            <SearchIcon />
          </IconButton>
        )}
        {isCoordinator ?(<>
          <Button variant="contained" color="primary"
                                sx={{
                                    textTransform: 'none', // Remove all-caps transformation
                                }}
                                onClick={handleOpenModal}
                            >
                                Add Zone
                            </Button>
        </>):(<div></div>)}




      </div>
      {noRecords && <Typography>No records found.</Typography>}
      <TableContainer component={Paper} style={{ marginTop: '20px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={firstRowStyles}>Zone No</TableCell>
              <TableCell style={firstRowStyles}>Zone Leader</TableCell>
              <TableCell style={firstRowStyles}>Group Leader</TableCell>
              <TableCell style={firstRowStyles}>WCM Co-Ordinator</TableCell>
              <TableCell style={firstRowStyles}>Auditor</TableCell>
              <TableCell style={firstRowStyles}>Plant Head</TableCell>
              <TableCell style={firstRowStyles}>Store</TableCell>
              <TableCell style={firstRowStyles}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(filteredZone.length > 0 ? filteredZone : zoneInformation).map((zone, index) => (
         

            <TableRow key={index}>
                <TableCell>{zone.code}</TableCell>
                <TableCell>{zone.zonefirstname && zone.zonelastname ? `${zone.zonefirstname} ${zone.zonelastname}-${zone.zone_sgid}` : ''}</TableCell>
  <TableCell>{zone.groupfirstname && zone.grouplastname ? `${zone.groupfirstname} ${zone.grouplastname}-${zone.group_sgid}` : ''}</TableCell>
  <TableCell>{zone.coordinatefirstname && zone.coordinatelastname ? `${zone.coordinatefirstname} ${zone.coordinatelastname}-${zone.coordinator_sgid}` : ''}</TableCell>
  <TableCell>{zone.auditorfirstname && zone.auditorlastname ? `${zone.auditorfirstname} ${zone.auditorlastname}-${zone.auditor_sgid}` : ''}</TableCell>
  <TableCell>{zone.plantheadfirstname && zone.plantheadlastname ? `${zone.plantheadfirstname} ${zone.plantheadlastname}-${zone.planthead_sgid}` : ''}</TableCell>
  <TableCell>{zone.storeheadfirstname && zone.storeheadlastname ? `${zone.storeheadfirstname} ${zone.storeheadlastname}-${zone.storehead_sgid}` : ''}</TableCell>
               
                {/* <TableCell>{`${zone.zonefirstname} ${zone.zonelastname}-${zone.zone_sgid}`}</TableCell>
                <TableCell>{`${zone.groupfirstname} ${zone.grouplastname}-${zone.group_sgid}`}</TableCell>
                <TableCell>{`${zone.coordinatefirstname} ${zone.coordinatelastname}-${zone.coordinator_sgid}`}</TableCell>
                <TableCell>{`${zone.auditorfirstname} ${zone.auditorlastname}-${zone.auditor_sgid}`}</TableCell>
                <TableCell>{`${zone.plantheadfirstname} ${zone.plantheadlastname}-${zone.planthead_sgid}`}</TableCell>
                <TableCell>{`${zone.storeheadfirstname} ${zone.storeheadlastname}-${zone.storehead_sgid}`}</TableCell> */}
               
                <TableCell style={{display: 'flex'}}>
                {isCoordinator ?(<>
                  <Tooltip title="Edit">
                    <IconButton onClick={() => handleEdit(index)}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  </>):(<div></div>)}
                  {/* <Tooltip title="Delete">
                    <IconButton onClick={() => handleDelete(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip> */}
                  <Tooltip title="View">
                  <IconButton onClick={() => handleView(index)}>
                    <RemoveRedEyeIcon />
                  </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
        <DialogTitle>{isEditMode ? 'Edit Zone' : 'Add Zone'}</DialogTitle>
        <DialogContent>
          <form>
        
        
          <div style={{ marginTop: "5px" }}>
          
          <SelectBox
              name="zone_area_id"
              labelValue="Zone No*"
              value={formData.zone_area_id}
              // onChange={handleChange}
              onChange={(e) => {
                  handleChange(e); // Handle normal change
                  handleZoneChange(e.target.value); // Handle zone change
              }}
              options={zoneNo}
              labelCondition="code"
              isLabel={true}
              error={formErrors.zone_area_id}
              disabled={isEditMode}
          />
      </div>

      <div style={{ marginTop: "5px" }}>
          <TextBox
              name="zone"
              label="Zone*"
              value={formData.zone}
              onChange={handleChange}
              disabled={true}
          />
      </div>

{/* new code */}
<InputLabel style={modalInputLabelStyle}>Zone Leader</InputLabel>
<div style={{ marginTop: "5px" }}>

<Autocomplete
              value={formData.zone_leader_name ||null}
              onChange={(event, newValue) => handleChange({ target: { name: 'zone_leader_name', value: newValue ? newValue.id : ''} })}
              options={leaderOptions}
              getOptionLabel={(option) => option.searchValue}
              renderInput={(params) => <TextField {...params} label="Zone Leader" />}
              fullWidth
              margin="normal"
            />
            </div>
            <InputLabel style={modalInputLabelStyle}>Group Leader</InputLabel>
            <div style={{ marginTop: "5px" }}>
            <Autocomplete
              value={formData.group_leader_name || null}
              onChange={(event, newValue) => handleChange({ target: { name: 'group_leader_name', value: newValue ? newValue.id : '' } })}
              options={leaderOptions}
              getOptionLabel={(option) => option.searchValue}
              renderInput={(params) => <TextField {...params} label="Group Leader" />}
              fullWidth
              margin="normal"
            />
            </div>
            <InputLabel style={modalInputLabelStyle}>WCM Co-Ordinator</InputLabel>
            <div style={{ marginTop: "5px" }}>
            <Autocomplete
              value={formData.wcm_coordinator_name || null}
              onChange={(event, newValue) => handleChange({ target: { name: 'wcm_coordinator_name', value: newValue ? newValue.id : ''} })}
              options={leaderOptions}
              getOptionLabel={(option) => option.searchValue}
              renderInput={(params) => <TextField {...params} label="WCM Co-Ordinator" />}
              fullWidth
              margin="normal"
            />
            </div>
            <InputLabel style={modalInputLabelStyle}>Plant Head</InputLabel>
            <div style={{ marginTop: "5px" }}>
            <Autocomplete
              value={formData.Plant_head_name || null}
              onChange={(event, newValue) => handleChange({ target: { name: 'Plant_head_name', value: newValue ? newValue.id : '' } })}
              options={leaderOptions}
              getOptionLabel={(option) => option.searchValue}
              renderInput={(params) => <TextField {...params} label="Plant Head" />}
              fullWidth
              margin="normal"
            />
            </div>
            <InputLabel style={modalInputLabelStyle}>Store Head</InputLabel>
            <div style={{ marginTop: "5px" }}>
            <Autocomplete
              value={formData.Store_head_name || null}
              onChange={(event, newValue) => handleChange({ target: { name: 'Store_head_name',value: newValue ? newValue.id : '' } })}
              options={leaderOptions}
              getOptionLabel={(option) => option.searchValue}
              renderInput={(params) => <TextField {...params} label="Store Head" />}
              fullWidth
              margin="normal"
            />
            </div>
            <InputLabel style={modalInputLabelStyle}>Auditor</InputLabel>
            <div style={{ marginTop: "5px" }}>
            <Autocomplete
              value={formData.Auditor_name || null}
              onChange={(event, newValue) => handleChange({ target: { name: 'Auditor_name', value: newValue ? newValue.id : ''} })}
              options={leaderOptions}
              getOptionLabel={(option) => option.searchValue}
              renderInput={(params) => <TextField {...params} label="Auditor" />}
              fullWidth
              margin="normal"
            />
</div>


      <div style={{ flexDirection: "row", flex: 1, display: "flex" }}>
          <StyledButton
              variant="outlined"
              text="Cancel" onClick={handleCloseModal}>Cancel</StyledButton>
          <StyledButton
              variant="contained"
              text={isEditMode ? "Update" : "Save"}
              onClick={handleSave}>Save</StyledButton>
      </div>
          </form>
        </DialogContent>
      
      </Dialog>
      

   
      <Dialog open={viewModalOpen} onClose={handleViewModalClose} fullWidth>
    <DialogTitle>Team Members</DialogTitle>
    {isCoordinator ?(<>
    <Button onClick={handleAddMember} variant="contained" color="primary">
            Add Member
          </Button>
          </>):(<div></div>)}
    <DialogContent>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
            <TableCell>SGID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {teamMembers.map((member, index) => (
              <TableRow key={index}>
                  <TableCell>{member.sgid}</TableCell>
                <TableCell>{member.firstName} {member.lastName}</TableCell>
                <TableCell>
        <IconButton onClick={() => handleDeleteClick(member)}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
                {/* <TableCell>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => handleRemoveMember(index)}
                  >
                    Remove
                  </Button>
                </TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleViewModalClose} color="primary">
        Close
      </Button>
    </DialogActions>
    </Dialog>

      <Modal open={addMemberModalOpen} onClose={handleAddMemberModalClose}>
        <Box component="form" onSubmit={handleAddMemberSubmit} p={4} maxWidth="500px" margin="auto" bgcolor="background.paper">
          <Typography variant="h6">Add New Member</Typography>
      
 <div style={{ marginTop: "5px" }}>
        {/* <SelectBox
  name="Team_member_name"
  label="Member Name"
  labelValue="Member Name"
  value={formData.name}
  onChange={handleChange}

  options={leaderOptions}
  // options={groupLeaderOptions}



  labelCondition="name"
  isLabel={true}
  error={formErrors.name}
  /> */}
      
      <Autocomplete
              value={formData.Team_member_name || null}
              onChange={(event, newValue) => handleChange({ target: { name: 'Team_member_name', value: newValue ? newValue.id : ''} })}
              options={leaderOptions}
              getOptionLabel={(option) => option.searchValue}
              renderInput={(params) => <TextField {...params} label="Member Name" />}
              fullWidth
              margin="normal"
            />
          
        </div>

          {/* <FormControl fullWidth margin="normal">
            <InputLabel id="zone-id-label">Zone</InputLabel>
            <Select
              labelId="zone-id-label"
              id="zone_id"
              name="zone_id"
              value={newMember.zone_id}
              onChange={handleNewMemberChange}
              required
            >
              {zoneInformation.map((zone) => (
                <MenuItem key={zone.id} value={zone.id}>
                  {zone.zone}
                </MenuItem>
              ))}
            </Select> */}

{/*             
          </FormControl> */}
          <Box display="flex" justifyContent="flex-end" mt={2}>
            <Button onClick={handleAddMemberModalClose} color="secondary">
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Add Member
            </Button>
          </Box>
        </Box>
      </Modal>

      <Dialog open={deleteConfirmationOpen} onClose={handleCancelDelete}>
      <DialogTitle>Delete Team Member</DialogTitle>
      <DialogContent>
        <Typography>Are you sure you want to remove this user from this zone?</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancelDelete} color="primary">
          Cancel
        </Button>
        <Button onClick={handleConfirmDelete} color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  

      {/* <Dialog open={confirmationOpen} onClose={handleCancelReassign}>
        <DialogTitle>Reassign Team Member</DialogTitle>
        <DialogContent>
          <Typography>User is already assigned to zone {existingZone}. Do you want to reassign?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelReassign} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmReassign} color="primary">
            Reassign
          </Button>
        </DialogActions>
      </Dialog> */}
    </div>
  );
};

export default ZoneDefinitions;











