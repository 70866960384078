import React from "react";
import { TextField, Typography, InputAdornment, IconButton } from "@mui/material";
import { styled } from "@mui/system"; 
// import CalendarIcon from '../../Asset/icons/ic_calendar.svg';


const StyledTextField = styled(TextField)({
  "& .MuiSelect-root": {
    color: "#0086DC",
  },
  "& .MuiInputLabel-root": {
    color: "#49454F",
    padding: '0 8px',
    backgroundColor: 'white',
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "#00448B",
  },
  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: "#E6E6E6",
    borderRadius:"8px"
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#0086DC",
  },
  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "red",
  },
  "& .MuiOutlinedInput-inputMultiline": {
    minHeight: '200px', // Adjust the height as needed
  },
});

const TextBox = (props) => {
  const renderInputAdornment = () => {
    if (props.type === "date" && props.showCalendarIcon) {
      return (
        <InputAdornment position="end">
          <IconButton>
            {/* <img src={CalendarIcon} alt="Calendar Icon" /> */}
          </IconButton>
        </InputAdornment>
      );
    } else if (props.type === "time" && props.showClockIcon) {
      return (
        <InputAdornment position="end">
          <IconButton>
            {/* <img src={CalendarIcon} alt="Clock Icon" /> */}
          </IconButton>
        </InputAdornment>
      );
    } else {
      return null;
    }
  };

  return (
    <React.Fragment>
      <Typography 
       sx={{
        fontFamily: 'Inter',
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '17px',
        letterSpacing: '-0.006em',
        textAlign: 'left',
        marginBottom: '8px',
        color: '#2D2B32',
      }}
    >
        {props.label}
      </Typography>
      <StyledTextField
        required={props.required ? props.required : null}
        style={{
          width: "100%",
          marginBottom: "16px",
          borderRadius: "8px",
          borderColor: props.error ? "red" : "",
        }}
        id={props.id}
        type={props.type}
        value={props.value}
        InputLabelProps={{
          shrink: true
        }}
        name={props.name}
        onChange={props.onChange}
        inputRef={props.inputRef}
        error={props.error ? props.error : false}
        helperText={props.helperText ? props.helperText : ""}
        disabled={props.disabled ? props.disabled : false}
        variant="outlined"
        multiline={props.multiline} // Enable multiline based on props
        rows={3} // Set the number of rows (adjust as needed)
        endAdornment={renderInputAdornment()}
      />
    </React.Fragment>
  );
};

export default TextBox;
