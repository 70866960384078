import React, { useEffect, useState,useRef, useCallback  } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Header from '../../app/TopAppBar';
import { Card, Popover, IconButton, Tabs, Tab, Typography, Box, Button, MenuItem, Checkbox, Accordion, AccordionSummary, AccordionDetails, FormControlLabel,TextField,Container,Grid,CardContent, Avatar,InputLabel,Select,Autocomplete,DialogActions,Dialog,DialogTitle,DialogContent} from '@mui/material';

import axios from 'axios';
import configParam from '../../config';
import CloudUpload from "../../Asset/Icons/file_upload_icon.svg";
import AlertDialog from '../input/AlertDialog';
import { useParams } from 'react-router-dom';
import API from '../../Services/API';
import { useMediaQuery,  createTheme } from '@mui/material';
import ImageSlider from '../MassFiveS/ImageSlider';
import Swal from 'sweetalert2';
import { useTheme } from '@mui/material/styles';
const moment = require('moment');

const modalInputLabelStyle = {
 
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 500,
    color: '#000',
  };



const NewEntry = ({ open, onClose, entryId,onSave}) => {
    const navigate = useNavigate();
    const [selectedDate, setSelectedDate] = useState(null); 
    const [errorMessageDate, setErrorMessageDate] = useState(null);
    const [startTime, setStartTime] = useState({ hour: '', minute: '' });
    const [endTime, setEndTime] = useState({ hour: '', minute: '' });
    
    const [imageName, setImageName] = useState({});
  const [referenceNumber, setReferenceNumber] = useState('');
    const [location, setLocation] = useState('');
    const [peopleAttended, setPeopleAttended] = useState([]);
    const [spaceReclaimed, setSpaceReclaimed] = useState('');
    const [valueGenerated, setValueGenerated] = useState('');
    const [otherBenefits, setOtherBenefits] = useState('');
   const [entryDetails, setEntryDetails] = useState({});
    const today = new Date().toISOString().split('T')[0];
    const access_token = JSON.parse(localStorage.getItem("accesstoken"));
    const ApiURL = configParam.API_URL;
    const { state } = useLocation();
    // const { massId } = state || {};
    const [users, setUsers] = useState([]);
    const fileInputRefBefore = useRef(null);
    const [imageSrcBefore, setImageSrcBefore] = useState([]);
    const fileInputRefAfter = useRef(null);
    const [imageSrcAfter, setImageSrcAfter] = useState([]);
    const isMobile = useMediaQuery('(max-width:600px)');

   const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    // useEffect(() => {
    //     if (massId) {
    //       fetchEntryDetails();
    //     // Fetch entry details when massId changes
    //     }
    //     fetchUsers();
    //   }, [massId]);

    useEffect(() => {
      if (entryId) {
        fetchEntryDetails(entryId);
      }
      fetchUsers();
    }, [entryId]);

      const  fetchUsers= async () => {
        
        try {
            const selectedPlantId = localStorage.getItem('selectedPlantId');
            const response = await axios.get(`${API.GET_USERS_MASS}/${selectedPlantId}`,{headers: {
                Authorization: `Bearer ${access_token}`,
               
                
              }});
            setUsers(response.data);
        } catch (error) {
           
            console.error('Error fetching data:', error);
        }
    };

    const fetchEntryDetails = async (massId) => {
        try {
            const selectedPlantId = localStorage.getItem('selectedPlantId');
            const response = await axios.get(`${ApiURL}/get_mass_entries_id/${massId}`, {
                params: { plantId: selectedPlantId },
                headers: { Authorization: `Bearer ${access_token}` }
            });
           
            const { entryData, users } = response.data;
            const entry = entryData[0];
            setSelectedDate(entry.entry_date ? moment(entry.entry_date).format('YYYY-MM-DD') : '');
            setReferenceNumber(entry.ref_no || '');
            setLocation(entry.location || '');

            const peopleAttendedData = entry.people_attend.map(id => {
                const user = users.find(user => user.id === id);
                return {
                    id,
                    firstName: user ? user.firstName : '',
                    lastName: user ? user.lastName : ''
                };
            });
            setPeopleAttended(peopleAttendedData);

            setSpaceReclaimed(entry.space_reclaim !== null ? entry.space_reclaim.toString() : '');
            setValueGenerated(entry.value_gen !== null ? entry.value_gen.toString() : '');
            setOtherBenefits(entry.other_ben || '');
            setStartTime({
                hour: entry.start_time ? entry.start_time.split(':')[0] : '',
                minute: entry.start_time ? entry.start_time.split(':')[1] : ''
            });
            setEndTime({
                hour: entry.end_time ? entry.end_time.split(':')[0] : '',
                minute: entry.end_time ? entry.end_time.split(':')[1] : ''
            });


           
            const imageUrlsBefore = entry.img_bef ? entry.img_bef.map(img => `${ApiURL}/api/imagesMass/${img}`) : [];
        const imageUrlsAfter = entry.img_aft ? entry.img_aft.map(img => `${ApiURL}/api/imagesMass/${img}`) : [];

        setImageSrcBefore(imageUrlsBefore);
        setImageSrcAfter(imageUrlsAfter);
       
   
            // if (entry.mass_img) {
            //     setImageSrcBefore(prevImages => ({ ...prevImages, 'mass_img': `${ApiURL}/api/images/${entry.mass_img}` }));
            // }
           
        } catch (error) {
            console.error('Error fetching entry details:', error);
        }
    };
    


    const handleDateChange = (event) => {
        const dateTimeString = event.target.value; // Use default value if date is invalid
       const formattedDate = moment.utc(dateTimeString).format("YYYY-MM-DD");
       const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format
    console.log("formattedDate",formattedDate)
       if (formattedDate > today) {
         setErrorMessageDate("You cannot select a future date.");
       } else {
         setErrorMessageDate('');
         setSelectedDate(formattedDate);
       }
      };

      const handleReferenceNumberChange = (event) => {
        setReferenceNumber(event.target.value);
    };
      const handleTimeChange = (type, field, value) => {
        if (type === 'start') {
            setStartTime({ ...startTime, [field]: value });
        } else {
            setEndTime({ ...endTime, [field]: value });
        }
    };

    const generateOptions = (count) => {
        return Array.from({ length: count }, (_, index) => (
            <MenuItem key={index} value={index}>
                {index.toString().padStart(2, '0')}
            </MenuItem>
        ));
    }

  

    const handleLocationChange = (event) => {
        
        setLocation(event.target.value);
        
    };

    
    const handlePeopleAttendedChange = (event, value) => {
        setPeopleAttended(value);
    };

    const handleSpaceReclaimedChange = (event) => {
        setSpaceReclaimed(event.target.value);
    };

    const handleValueGeneratedChange = (event) => {
        setValueGenerated(event.target.value);
    };

    const handleOtherBenefitsChange = (event) => {
        setOtherBenefits(event.target.value);
    }
    const handleCancel  = () => {
        console.log("handelcnacel")
        navigate('/massfives')

    };



    const handleBeforeCapture = () => {
        // Open the gallery for image selection
        if (fileInputRefBefore.current) {
          fileInputRefBefore.current.captureAttribute = 'gallery'; // Specify 'gallery' to open the gallery
          fileInputRefBefore.current.click(); // Trigger the input element to open the gallery
          fileInputRefBefore.current.onchange = (e) => {
            const selectedFiles = e.target.files;
            const selectedfilesarray = Array.from(e.target.files)
            if (selectedFiles.length > 0) {
              // Process the selected images using the setUploadedBeforeFile function
              const hasInvalidFile = selectedfilesarray.map(file => !file.type.startsWith("image/")).some(isInvalid => isInvalid);
              if (hasInvalidFile) {
                // setErrorMessageBeforeImage('Please upload a valid image');
              } else {
                // setErrorMessageBeforeImage(''); // Clear the error message if all files are valid
                setUploadedBeforeFile({ target: { files: selectedFiles } });
              }
    
            }
          };
        }
      };
    
    //    const setUploadedBeforeFile = async (event) => {
    //     const files = event.target.files;
    //     if (files) {
    //         await generateImagesArrayBefore(files);
    //     }
        
    // };

    const setUploadedBeforeFile = async (event) => {
      if (!event || !event.target || !event.target.files) {
          console.error('Event or event target is undefined');
          return;
      }
      const files = event.target.files;
      if (files) {
          await generateImagesArrayBefore(files);
      }
  };
      const generateImagesArrayBefore = (files) => {
        const imageBeforeArray = [];
        Array.from(files).forEach(file => {
          const reader = new FileReader();
          reader.onload = (e) => {
            // Set the selected image URLs
            imageBeforeArray.push(e.target.result);
            if (imageBeforeArray.length === files.length) {
              setImageSrcBefore((prevBeforeImage) => [...prevBeforeImage, ...imageBeforeArray])
            }
          };
          reader.readAsDataURL(file);
        });
      }; 
    

      const handleDeleteIconClickBefore = (imageUrl, index) => {
        Swal.fire({
          title: "Are you sure?",
          text: "Once deleted, you will not be able to recover this image!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "red",
          cancelButtonColor: "gray",
          confirmButtonText: "Yes, delete it",
        }).then((result) => {
          if (result.isConfirmed) {
            setImageSrcBefore((prevImages) => {
              const newImageList = [...prevImages];
              newImageList.splice(index, 1);
              return newImageList;
            });
            setUploadedBeforeFile((prevImages) => {
              const newImageList = [...prevImages];
              newImageList.splice(index, 1);
              return newImageList;
            });
     
            Swal.fire("Deleted!", "Your image has been deleted.", "success");
          }
        });
      };




      const handleAfterCapture = () => {
        // Open the gallery for image selection
        if (fileInputRefAfter.current) {
          fileInputRefAfter.current.captureAttribute = 'gallery'; // Specify 'gallery' to open the gallery
          fileInputRefAfter.current.click(); // Trigger the input element to open the gallery
          fileInputRefAfter.current.onchange = (e) => {
            const selectedFiles = e.target.files;
            const selectedfilesarray = Array.from(e.target.files)
            if (selectedFiles.length > 0) {
              // Process the selected images using the setUploadedBeforeFile function
              const hasInvalidFile = selectedfilesarray.map(file => !file.type.startsWith("image/")).some(isInvalid => isInvalid);
              if (hasInvalidFile) {
                // setErrorMessageBeforeImage('Please upload a valid image');
              } else {
                // setErrorMessageBeforeImage(''); // Clear the error message if all files are valid
                setUploadedAfterFile({ target: { files: selectedFiles } });
              }
    
            }
          };
        }
      };
    
    //    const setUploadedAfterFile = async (event) => {
    //     const files = event.target.files;
    //     if (files) {
    //         await generateImagesArrayAfter(files);
    //     }
    // };

    const setUploadedAfterFile = async (event) => {
      if (!event || !event.target || !event.target.files) {
          console.error('Event or event target is undefined');
          return;
      }
      const files = event.target.files;
      if (files) {
          await generateImagesArrayAfter(files);
      }
  };
  
      // const generateImagesArrayAfter = (files) => {
      //   const imageAfterArray = [];
      //   Array.from(files).forEach(file => {
      //     const reader = new FileReader();
      //     reader.onload = (e) => {
      //       // Set the selected image URLs
      //       imageAfterArray.push(e.target.result);
      //       if (imageAfterArray.length === files.length) {
      //         setImageSrcAfter((prevAfterImage) => [...prevAfterImage, ...imageAfterArray])
      //       }
      //     };
      //     reader.readAsDataURL(file);
      //   });
      // }; 

      const generateImagesArrayAfter = (files) => {
        const imageAfterArray = [];
        Array.from(files).forEach(file => {
            const reader = new FileReader();
            reader.onload = (e) => {
                // Set the selected image URLs
                imageAfterArray.push(e.target.result);
                if (imageAfterArray.length === files.length) {
                    setImageSrcAfter((prevAfterImage) => [...prevAfterImage, ...imageAfterArray]);
                }
            };
            reader.onerror = (error) => {
                console.error('File reading error:', error);
            };
            reader.readAsDataURL(file);
        });
    };
    
    
      const handleDeleteIconClickAfter = (imageUrl, index) => {
        console.log('Delete icon clicked for:', imageUrl, index);
        Swal.fire({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this image!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "red",
            cancelButtonColor: "gray",
            confirmButtonText: "Yes, delete it",
        }).then((result) => {
            if (result.isConfirmed) {
                setImageSrcAfter((prevImages) => {
                    const newImageList = [...prevImages];
                    newImageList.splice(index, 1);
                    return newImageList;
                });
                setUploadedAfterFile((prevImages) => {
                    const newImageList = [...prevImages];
                    newImageList.splice(index, 1);
                    return newImageList;
                });
    
                Swal.fire("Deleted!", "Your image has been deleted.", "success");
            }
        });
    };
    

      // const handleDeleteIconClickAfter = (imageUrl, index) => {
      //   Swal.fire({
      //     title: "Are you sure?",
      //     text: "Once deleted, you will not be able to recover this image!",
      //     icon: "warning",
      //     showCancelButton: true,
      //     confirmButtonColor: "red",
      //     cancelButtonColor: "gray",
      //     confirmButtonText: "Yes, delete it",
      //   }).then((result) => {
      //     if (result.isConfirmed) {
      //       setImageSrcAfter((prevImages) => {
      //         const newImageList = [...prevImages];
      //         newImageList.splice(index, 1);
      //         return newImageList;
      //       });
      //       setUploadedAfterFile((prevImages) => {
      //         const newImageList = [...prevImages];
      //         newImageList.splice(index, 1);
      //         return newImageList;
      //       });
     
      //       Swal.fire("Deleted!", "Your image has been deleted.", "success");
      //     }
      //   });
      // };


//new code
      const uploadImage = async (imageData) => {
        try {
            if (!imageData) {
                return null; // No image to upload
            }
    
            const imageName = `image_${Date.now()}.png`;
            const imageBase64 = imageData.split(',')[1]; // Remove the data URL scheme part
    
            const response = await axios.post(`${ApiURL}/upload_image_mass`, {
                images: [{ imageName, imageBase64 }]
            });
    
            if (response.status === 200) {
                return response.data[0].name; // Return the image name
            } else {
                console.error('Error uploading image:', response.statusText);
                return null;
            }
        } catch (error) {
            console.error('Network error:', error);
            return null;
        }
    };
    
    

    const handleSubmit = async () => {
      try {
          let imgBefUrls = [];
          let imgAftUrls = [];
  
          // Upload before images
          if (imageSrcBefore.length) {
              for (let imgSrc of imageSrcBefore) {
                  const imageName = await uploadImage(imgSrc);
                  if (imageName) {
                      imgBefUrls.push(imageName);
                  }
              }
          }
  
          // Upload after images
          if (imageSrcAfter.length) {
              for (let imgSrc of imageSrcAfter) {
                  const imageName = await uploadImage(imgSrc);
                  if (imageName) {
                      imgAftUrls.push(imageName);
                  }
              }
          }
  
          const userData = JSON.parse(localStorage.getItem("userDatas")); // Retrieve user data from local storage
          const sgid = userData?.sgid;
          const selectedPlantId = localStorage.getItem('selectedPlantId');
          const formData = {
              entry_date: selectedDate,
              ref_no: referenceNumber,
              start_time: `${startTime.hour}:${startTime.minute}`,
              end_time: `${endTime.hour}:${endTime.minute}`,
              location,
              people_attend: peopleAttended.map(user => user.id),
              no_people: peopleAttended.length,
              space_reclaim: parseInt(spaceReclaimed),
              value_gen: parseInt(valueGenerated),
              other_ben: otherBenefits,
              img_bef: imgBefUrls,
              img_aft: imgAftUrls,
              created_by: sgid,
              plant_id: selectedPlantId
          };
  
          // Always make the API call regardless of whether an image is uploaded
          const headers = {
              Authorization: `Bearer ${access_token}`
          };
  
        const response = await axios.post(`${ApiURL}/create_mass_entry`, formData, { headers });
          
          if (response.status === 200) {
              AlertDialog({
                  type: 'success',
                  title: 'Success',
                  text: `MassEntry ${entryId ? 'updated' : 'created'} successfully.`,
              });
              onSave(); // Call the onSave function to fetch updated entries and close the dialog
      onClose();
              console.log(`MassEntry ${entryId ? 'updated' : 'created'} successfully`);
          } else {
              console.error(`Error ${entryId ? 'updating' : 'creating'} entry:`, response.statusText);
          }
      } catch (error) {
          console.error('Network error:', error);
      }
  };

  const handleUpdate = async () => {
    try {
        console.log("imageSrcBefore:", imageSrcBefore);
        let imgBefUrls = [];
        let imgAftUrls = [];

        // Upload before images
        if (imageSrcBefore.length) {
          for (let imgSrc of imageSrcBefore) {
              if (imgSrc.startsWith("data:image")) {
                  // It's a new base64 image, upload it
                  const imageName = await uploadImage(imgSrc);
                  if (imageName) {
                      imgBefUrls.push(imageName);
                  }
              } else {
                  // Extract image name from the URL
                  const imageName = imgSrc.split('/').pop();
                  imgBefUrls.push(imageName);
              }
          }
      }

      // Upload after images
      if (imageSrcAfter.length) {
        for (let imgSrc of imageSrcAfter) {
            if (imgSrc.startsWith("data:image")) {
                // It's a new base64 image, upload it
                const imageName = await uploadImage(imgSrc);
                if (imageName) {
                    imgAftUrls.push(imageName);
                }
            } else {
                // Extract image name from the URL
                const imageName = imgSrc.split('/').pop();
                imgAftUrls.push(imageName);
            }
        }
    }

        const userData = JSON.parse(localStorage.getItem("userDatas")); // Retrieve user data from local storage
        const sgid = userData?.sgid;
        const selectedPlantId = localStorage.getItem('selectedPlantId');
        const formData = {
            entry_date: selectedDate,
            ref_no: referenceNumber,
            start_time: `${startTime.hour}:${startTime.minute}`,
            end_time: `${endTime.hour}:${endTime.minute}`,
            location,
            people_attend: peopleAttended.map(user => user.id),
            no_people: peopleAttended.length,
            space_reclaim: parseInt(spaceReclaimed),
            value_gen: parseInt(valueGenerated),
            other_ben: otherBenefits,
            img_bef: imgBefUrls,
            img_aft: imgAftUrls,
            created_by: sgid,
            plant_id: selectedPlantId
        };

        const headers = {
            Authorization: `Bearer ${access_token}`
        };

        let response;
        const url = `${ApiURL}/update_mass_entry/${entryId}`;
        if (entryId) {
            // Editing existing entry
            response = await axios.put(url, formData, { headers });
        } 
        if (response.status === 200) {
            AlertDialog({
                type: 'success',
                title: 'Success',
                text: `MassEntry ${entryId ? 'updated' : 'created'} successfully.`,
            });
            onSave();
            onclose()
            // navigate("/massfives");
            console.log(`MassEntry ${entryId ? 'updated' : 'created'} successfully`);
        } else {
            console.error(`Error ${entryId ? 'updating' : 'creating'} entry:`, response.statusText);
        }
    } catch (error) {
        console.error('Network error:', error);
    }
};


    console.log("imageSrcAfter",imageSrcAfter)
    
    console.log("imageSrcBefore",imageSrcBefore)

    return (
      <div>
        {/* <Header pageTitle="New Entry" isMassPage={true} /> */}

        <Dialog open={open} 
        onClose={onClose} 
        fullWidth
 fullScreen={fullScreen} 
 maxWidth="sm"
        >
          <DialogTitle>New Entry</DialogTitle>
          <DialogContent>
            <form onSubmit={(e) => e.preventDefault()}>
             <InputLabel style={modalInputLabelStyle}>Entry Date</InputLabel>
                <TextField
size="small"  className="input-nis"
  fullWidth
  id="date"
  type="date"
  value={selectedDate}
  onChange={handleDateChange}
  inputProps={{ max: today }}
  style={{ marginBottom: 10}}
/>
<InputLabel style={modalInputLabelStyle}> Reference Number</InputLabel>
             <TextField
              type="text"
          style={{ marginBottom: 10, width: '100%'}}
          value={referenceNumber}
          onChange={handleReferenceNumberChange}
            />

<InputLabel style={modalInputLabelStyle} >Start Time</InputLabel>
<div style={{ display: 'flex', gap: '10px', marginBottom: '10px' }}>
<Select
                            value={startTime.hour}
                            onChange={(e) => handleTimeChange('start', 'hour', e.target.value)}
                            style={{ flex: 1 }}
                        >
                            {generateOptions(24)}
                        </Select>
                        <Typography style={{ marginLeft: '10px', marginRight: '10px',marginTop:'12px' }}>hrs</Typography>
                        <Select
                            value={startTime.minute}
                            onChange={(e) => handleTimeChange('start', 'minute', e.target.value)}
                            style={{ flex: 1 }}
                        >
                            {generateOptions(60)}
                        </Select>
                        <Typography style={{ marginLeft: '10px', marginRight: '10px',marginTop:'12px' }}>mins</Typography>
                        </div>
  <InputLabel style={modalInputLabelStyle}  >End Time</InputLabel>
  <div style={{ display: 'flex', gap: '10px', marginBottom: '10px' }}>
  <Select
                            value={endTime.hour}
                            onChange={(e) => handleTimeChange('end', 'hour', e.target.value)}
                            style={{ flex: 1 }}
                        >
                            {generateOptions(24)}
                        </Select>
                        <Typography style={{ marginLeft: '10px', marginRight: '10px',marginTop:'12px' }}>hrs</Typography>
                        <Select
                            value={endTime.minute}
                            onChange={(e) => handleTimeChange('end', 'minute', e.target.value)}
                            style={{ flex: 1 }}
                        >
                            {generateOptions(60)}
                        </Select>
                        <Typography style={{ marginLeft: '10px', marginRight: '10px',marginTop:'12px' }}>mins</Typography>
                        </div>
  <InputLabel style={modalInputLabelStyle} >Location</InputLabel>
  <TextField
size="small"  className="input-nis"
  fullWidth
 style={{ marginBottom: 10}}
 value={location}
 onChange={handleLocationChange}
/>



<InputLabel style={modalInputLabelStyle}>People Attended</InputLabel>
<Autocomplete
                        multiple
                        options={users}
                        getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                        value={peopleAttended}
                        onChange={handlePeopleAttendedChange}
                        renderInput={(params) => (
                            <TextField {...params} variant="outlined" placeholder="Select People" />
                        )}
                        getOptionSelected={(option, value) => option.id === value.id}
                    />



              <InputLabel style={modalInputLabelStyle}>Space Reclaimed</InputLabel>
           <TextField
              type="text"
             
              style={{ width: '100%',marginBottom: 10 }}
              required 
              value={spaceReclaimed}
              onChange={handleSpaceReclaimedChange} 
              />
              <InputLabel style={modalInputLabelStyle}>Value Generated</InputLabel>
           <TextField
              type="text"
             
              style={{ width: '100%',marginBottom: 10 }}
              value={valueGenerated}
              onChange={handleValueGeneratedChange}
              required  />
               <InputLabel style={modalInputLabelStyle}>Other Benefits</InputLabel>
           <TextField
              type="text"
             
              style={{ width: '100%',marginBottom: 10 }}
              value={otherBenefits}
              onChange={handleOtherBenefitsChange}
              required  />

       
 <div id="camera-container-mass_img" style={{ border: "2px dashed rgb(164 160 161)", display: "flex", alignItems: "center", justifyContent: "center", height: '400px', marginBottom: '20px', position: 'relative' }}>
                            {imageSrcBefore&& (
                                <>
                                    {/* Camera button */}
                                     <input
                id="upload-image_mass_img"
                multiple
                type="file"
                style={{ display: "none" }}
                // onChange={setUploadedFile}
                ref={fileInputRefBefore}
               />
                                     <button onClick={() => handleBeforeCapture()} style={{ position: 'absolute', cursor: 'pointer', background: 'none', border: 'none', width: '100%', height: '100%' }}>
                                         <img src={CloudUpload} alt="Upload File" style={{ width: '50px', height: '50px' }} />
                                     </button>
                                     <Typography style={{ textAlign: 'center', color: 'black', fontWeight: 600, fontSize: '16px' }}>Click to Capture</Typography>
                                </>
                            )} 


<div style={{ marginTop: "30px" }}>
          {imageSrcBefore.length > 0 && (
            <div>
              <ImageSlider
                images={imageSrcBefore}
                isEdit={true}
                onDeleteIconClick={handleDeleteIconClickBefore}
                handlePopover={handleBeforeCapture}
                
              />
            </div>
          )}
        </div>  
         </div>     
           

           
                      <div id="camera-container-mass_img" style={{ border: "2px dashed rgb(164 160 161)", display: "flex", alignItems: "center", justifyContent: "center", height: '400px', marginBottom: '20px', position: 'relative' }}>
                            {imageSrcAfter&& (
                                <>
                                    {/* Camera button */}
                                     <input
                id="upload-image_mass_img"
                multiple
                type="file"
                style={{ display: "none" }}
                // onChange={setUploadedFile}
                ref={fileInputRefAfter}
               />
                                     <button onClick={() => handleAfterCapture()} style={{ position: 'absolute', cursor: 'pointer', background: 'none', border: 'none', width: '100%', height: '100%' }}>
                                         <img src={CloudUpload} alt="Upload File" style={{ width: '50px', height: '50px' }} />
                                     </button>
                                     <Typography style={{ textAlign: 'center', color: 'black', fontWeight: 600, fontSize: '16px' }}>Click to Capture</Typography>
                                </>
                            )} 


<div style={{ marginTop: "30px" }}>
          {imageSrcAfter.length > 0 && (
            <div>
              <ImageSlider
                images={imageSrcAfter}
                isEdit={true}
                onDeleteIconClick={handleDeleteIconClickAfter}
                handlePopover={handleAfterCapture}
                
              />
            </div>
          )}
        </div> 
                       </div> 
              
            </form>
          </DialogContent>
          <DialogActions>

                     <Button 
        style={{
          
          background: 'white', // Set background color to white
          borderRadius: '4px', // Add border radius for rounded corners
          border: '1px solid', // Set initial border to 1px solid
          borderImageSource: 'linear-gradient(90deg, #0594CD 0%, #15408A 100%)', // Apply double gradient as border image source
          borderImageSlice: '1', // Ensure the entire border is covered by the gradient
          color: '#15408A', // Set font color to blue
          boxShadow: 'none',
          width:'70%' // Remove default box shadow
      }} onClick={onClose}>Cancel</Button>

           {entryId ? (
                    <Button  style={{
                
                      background: 'linear-gradient(45deg, #173481 30%, #02A4DA 90%)', // Adjust the gradient colors as needed
                      borderRadius: 3,
                      border: 0,
                      color: 'white',
                      height: 48,
                      padding: '0 30px',
                      boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
                      width:'70%'
                  }}  onClick={handleUpdate}>Update</Button>
                ) : (
                    <Button 
                 style={{
             background: 'linear-gradient(45deg, #173481 30%, #02A4DA 90%)', // Adjust the gradient colors as needed
                      borderRadius: 3,
                      border: 0,
                      color: 'white',
                      height: 48,
                      padding: '0 30px',
                      boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
                      width:'70%'
                  }} 
                    
                    onClick={handleSubmit}>Save</Button>
                )}
            {/* <Button onClick={handleCancel} color="secondary">Cancel</Button>
            <Button onClick={handleSave} color="primary">Save</Button>
            <Button onClick={handleUpdate} color="primary">Update</Button> */}
          </DialogActions>
        </Dialog>
      </div>
    );
//   return (
//     <div>
//         <Header pageTitle="New Entry" isMassPage={true}/>
//         <div style={{ maxWidth: '600px', margin: '0 auto', padding: '20px', backgroundColor: '#f9f9f9', borderRadius: '8px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' ,marginTop: "65px"}}>
          
//         <form onSubmit={(e) => e.preventDefault()} >
//  <InputLabel style={modalInputLabelStyle}>Entry Date</InputLabel>
//                 <TextField
// size="small"  className="input-nis"
//   fullWidth
//   id="date"
//   type="date"
//   value={selectedDate}
//   onChange={handleDateChange}
//   inputProps={{ max: today }}
//   style={{ marginBottom: 10}}
// />
// <InputLabel style={modalInputLabelStyle}> Reference Number</InputLabel>
//              <TextField
//               type="text"
//           style={{ marginBottom: 10, width: '100%'}}
//           value={referenceNumber}
//           onChange={handleReferenceNumberChange}
//             />

// <InputLabel style={modalInputLabelStyle} >Start Time</InputLabel>
// <div style={{ display: 'flex', gap: '10px', marginBottom: '10px' }}>
// <Select
//                             value={startTime.hour}
//                             onChange={(e) => handleTimeChange('start', 'hour', e.target.value)}
//                             style={{ flex: 1 }}
//                         >
//                             {generateOptions(24)}
//                         </Select>
//                         <Typography style={{ marginLeft: '10px', marginRight: '10px',marginTop:'12px' }}>hrs</Typography>
//                         <Select
//                             value={startTime.minute}
//                             onChange={(e) => handleTimeChange('start', 'minute', e.target.value)}
//                             style={{ flex: 1 }}
//                         >
//                             {generateOptions(60)}
//                         </Select>
//                         <Typography style={{ marginLeft: '10px', marginRight: '10px',marginTop:'12px' }}>mins</Typography>
//                         </div>
//   <InputLabel style={modalInputLabelStyle}  >End Time</InputLabel>
//   <div style={{ display: 'flex', gap: '10px', marginBottom: '10px' }}>
//   <Select
//                             value={endTime.hour}
//                             onChange={(e) => handleTimeChange('end', 'hour', e.target.value)}
//                             style={{ flex: 1 }}
//                         >
//                             {generateOptions(24)}
//                         </Select>
//                         <Typography style={{ marginLeft: '10px', marginRight: '10px',marginTop:'12px' }}>hrs</Typography>
//                         <Select
//                             value={endTime.minute}
//                             onChange={(e) => handleTimeChange('end', 'minute', e.target.value)}
//                             style={{ flex: 1 }}
//                         >
//                             {generateOptions(60)}
//                         </Select>
//                         <Typography style={{ marginLeft: '10px', marginRight: '10px',marginTop:'12px' }}>mins</Typography>
//                         </div>
//   <InputLabel style={modalInputLabelStyle} >Location</InputLabel>
//   <TextField
// size="small"  className="input-nis"
//   fullWidth
//  style={{ marginBottom: 10}}
//  value={location}
//  onChange={handleLocationChange}
// />



// <InputLabel style={modalInputLabelStyle}>People Attended</InputLabel>
// <Autocomplete
//                         multiple
//                         options={users}
//                         getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
//                         value={peopleAttended}
//                         onChange={handlePeopleAttendedChange}
//                         renderInput={(params) => (
//                             <TextField {...params} variant="outlined" placeholder="Select People" />
//                         )}
//                         getOptionSelected={(option, value) => option.id === value.id}
//                     />



//               <InputLabel style={modalInputLabelStyle}>Space Reclaimed</InputLabel>
//            <TextField
//               type="text"
             
//               style={{ width: '100%',marginBottom: 10 }}
//               required 
//               value={spaceReclaimed}
//               onChange={handleSpaceReclaimedChange} 
//               />
//               <InputLabel style={modalInputLabelStyle}>Value Generated</InputLabel>
//            <TextField
//               type="text"
             
//               style={{ width: '100%',marginBottom: 10 }}
//               value={valueGenerated}
//               onChange={handleValueGeneratedChange}
//               required  />
//                <InputLabel style={modalInputLabelStyle}>Other Benefits</InputLabel>
//            <TextField
//               type="text"
             
//               style={{ width: '100%',marginBottom: 10 }}
//               value={otherBenefits}
//               onChange={handleOtherBenefitsChange}
//               required  />

       
//  <div id="camera-container-mass_img" style={{ border: "2px dashed rgb(164 160 161)", display: "flex", alignItems: "center", justifyContent: "center", height: '400px', marginBottom: '20px', position: 'relative' }}>
//                             {imageSrcBefore&& (
//                                 <>
//                                     {/* Camera button */}
//                                      <input
//                 id="upload-image_mass_img"
//                 multiple
//                 type="file"
//                 style={{ display: "none" }}
//                 // onChange={setUploadedFile}
//                 ref={fileInputRefBefore}
//                />
//                                      <button onClick={() => handleBeforeCapture()} style={{ position: 'absolute', cursor: 'pointer', background: 'none', border: 'none', width: '100%', height: '100%' }}>
//                                          <img src={CloudUpload} alt="Upload File" style={{ width: '50px', height: '50px' }} />
//                                      </button>
//                                      <Typography style={{ textAlign: 'center', color: 'black', fontWeight: 600, fontSize: '16px' }}>Click to Capture</Typography>
//                                 </>
//                             )} 


// <div style={{ marginTop: "30px" }}>
//           {imageSrcBefore.length > 0 && (
//             <div>
//               <ImageSlider
//                 images={imageSrcBefore}
//                 isEdit={true}
//                 onDeleteIconClick={handleDeleteIconClickBefore}
//                 handlePopover={handleBeforeCapture}
                
//               />
//             </div>
//           )}
//         </div>  
//          </div>     
           

           
//                       <div id="camera-container-mass_img" style={{ border: "2px dashed rgb(164 160 161)", display: "flex", alignItems: "center", justifyContent: "center", height: '400px', marginBottom: '20px', position: 'relative' }}>
//                             {imageSrcAfter&& (
//                                 <>
//                                     {/* Camera button */}
//                                      <input
//                 id="upload-image_mass_img"
//                 multiple
//                 type="file"
//                 style={{ display: "none" }}
//                 // onChange={setUploadedFile}
//                 ref={fileInputRefAfter}
//                />
//                                      <button onClick={() => handleAfterCapture()} style={{ position: 'absolute', cursor: 'pointer', background: 'none', border: 'none', width: '100%', height: '100%' }}>
//                                          <img src={CloudUpload} alt="Upload File" style={{ width: '50px', height: '50px' }} />
//                                      </button>
//                                      <Typography style={{ textAlign: 'center', color: 'black', fontWeight: 600, fontSize: '16px' }}>Click to Capture</Typography>
//                                 </>
//                             )} 


// <div style={{ marginTop: "30px" }}>
//           {imageSrcAfter.length > 0 && (
//             <div>
//               <ImageSlider
//                 images={imageSrcAfter}
//                 isEdit={true}
//                 onDeleteIconClick={handleDeleteIconClickAfter}
//                 handlePopover={handleAfterCapture}
                
//               />
//             </div>
//           )}
//         </div> 
//                        </div>    
              
// <div style={{ display: 'flex', justifyContent: 'space-between' ,gap:'10px'}}>


//                <Button 
//         style={{
          
//           background: 'white', // Set background color to white
//           borderRadius: '4px', // Add border radius for rounded corners
//           border: '1px solid', // Set initial border to 1px solid
//           borderImageSource: 'linear-gradient(90deg, #0594CD 0%, #15408A 100%)', // Apply double gradient as border image source
//           borderImageSlice: '1', // Ensure the entire border is covered by the gradient
//           color: '#15408A', // Set font color to blue
//           boxShadow: 'none',
//           width:'70%' // Remove default box shadow
//       }} onClick={ handleCancel}>Cancel</Button>



// {massId ? (
//                     <Button  style={{
                
//                       background: 'linear-gradient(45deg, #173481 30%, #02A4DA 90%)', // Adjust the gradient colors as needed
//                       borderRadius: 3,
//                       border: 0,
//                       color: 'white',
//                       height: 48,
//                       padding: '0 30px',
//                       boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
//                       width:'70%'
//                   }}  onClick={handleUpdate}>Update</Button>
//                 ) : (
//                     <Button 
//                  style={{
//              background: 'linear-gradient(45deg, #173481 30%, #02A4DA 90%)', // Adjust the gradient colors as needed
//                       borderRadius: 3,
//                       border: 0,
//                       color: 'white',
//                       height: 48,
//                       padding: '0 30px',
//                       boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
//                       width:'70%'
//                   }} 
                    
//                     onClick={handleSave}>Save</Button>
//                 )}
       
//           </div>
//             </form>
//     </div>
//     </div>
//   );
};

export default NewEntry;