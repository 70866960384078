import React, { useRef,useState,useEffect} from "react";
import { Typography, Button, Snackbar,Alert,Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Header from '../../app/TopAppBar';
import imageCompression from 'browser-image-compression';
import CloudUpload from "../../Asset/Icons/file_upload_icon.svg";
import { useRecoilState } from 'recoil';
import { stepState, formDataState,assessmentCreatedState} from '../../recoil/atoms';
import axios from "axios";
import configParam from "../../config";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import MuiAlert from '@mui/material/Alert';
import LogoutIcon from '@mui/icons-material/Logout';
// import HorizontalCombinedStepper from '../HorizontalCombinedStepper';
const Shine = ({ onNext,activeStep,onSave,loading  }) => {
    const [formData, setFormData] = useRecoilState(formDataState);
    const fileInputRef = useRef(null);
    const [selectedValues, setSelectedValues] = useState({});
    const [images, setImages] = useState({});
    const [remarks, setRemarks] = useState({});
    const [errors, setErrors] = useState({});
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const[set,setStream] = useState({});
    const [isCameraOpen, setIsCameraOpen] = useState(false);
    const ApiURL = configParam.API_URL;
    const [saveflg, setSaveflg] = useState(false);
    const [assessmentCreated, setAssessmentCreated] = useRecoilState(assessmentCreatedState);
    const [assessmentId, setAssessmentId] = useState(null);
    const assessmenteditflg = localStorage.getItem("assessmenteditflg") === "true";
    const  access_token =  JSON.parse(localStorage.getItem("accesstoken"));
    useEffect(() => {
        return () => {
            // Store form data in Recoil state when unmounting
            setFormData(prevFormData => ({
                ...prevFormData,
                [activeStep]: {
                    selectedValues,
                    images,
                    remarks
                }
            }));
        };
    }, [activeStep, selectedValues, images, remarks, setFormData])

    const handleRemarksChange = (questionId, value) => {
        // Update selectedValues state
        setRemarks({ ...remarks, [questionId]: value });

        // Update formData state by merging selectedValues into the current step's data
        setFormData(prevFormData => ({
            ...prevFormData,
            [activeStep]: {
                ...prevFormData[activeStep],
                remarks: { ...prevFormData[activeStep].remarks, [questionId]: value }
            }
        }));
    };
   
    useEffect(() => {
        if (assessmenteditflg) {
            localStorage.setItem("assessmentIdcreate","");
            const assessmentId = localStorage.getItem("assessmentId");
            geteditdetails(assessmentId);
        }
    }, []);

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setSnackbarOpen(false);
      };


    async function geteditdetails(assessmentId) {
        try {
            const response = await axios.get(`${ApiURL}/get_AssessmentByIds/${assessmentId}`,{headers: {
                Authorization: `Bearer ${access_token}`
              }});
            if (response.data) {
                const formData = JSON.parse(response.data.data.details);
                setSelectedValues(formData.selectedValues || {});
                setRemarks(formData.remarks || {});
    
                // Construct image paths for each image
                const updatedImages = {};
                for (const questionId in formData.images) {
                    if (formData.images.hasOwnProperty(questionId)) {
                        const imageName = formData.images[questionId];
                        const imagePath = `${ApiURL}/api/images/${imageName}`; // Replace this with your actual image directory path
                        updatedImages[questionId] = imagePath;
                    }
                }
                setImages(updatedImages);
    
                console.log("Updated Images", updatedImages);
            } else {
                console.log("No data found in the response");
            }
        } catch (error) {
            console.log("Error:", error);
            return null;
        }
    }
    

    // async function geteditdetails(assessmentId) {
    //     try {
    //       const response = await axios.get(`${ApiURL}/get_AssessmentByIds/${assessmentId}`);
    //       // const assessmentData = response.data.data;
    //       //const response = await axios.get(`${API.GET_LATEST_MOC}`);
    //       if (response.data) {
    //         const formData = JSON.parse(response.data.data.details);
    //         setSelectedValues(formData.selectedValues || {});
    //         setImages(formData.images || {});
    //         setRemarks(formData.remarks || {});
    //     } else {
    //         console.log("No data found in the response");
    //     }
          
    //     } catch (error) {
    //       console.log("Error:", error);
    //       return null;
    //     }
    //   }


    const handleNextClick = () => {
        // Validate form fields
        const newErrors = {};
        if (selectedValues['question15'] === "0") {
            // If radio button value is "0", both image and remarks fields are mandatory
            if (!images['question15']) {
                newErrors["question15"] = "Please capture an image.";
            }
            if (!remarks['question15']) {
                newErrors["question15"] = "Please provide remarks.";
            }
        }
          if (selectedValues['question15'] === "1") {
            // If radio button value is "1", only remarks field is mandatory
            if (!remarks['question15']) {
                newErrors["question15"] = "Please provide remarks.";
            }
        }
        if (selectedValues['question16'] === "0") {
            // If radio button value is "0", both image and remarks fields are mandatory
            if (!images['question16']) {
                newErrors["question16"] = "Please capture an image.";
            }
            if (!remarks['question16']) {
                newErrors["question16"] = "Please provide remarks.";
            }
        }
          if (selectedValues['question16'] === "1") {
            // If radio button value is "1", only remarks field is mandatory
            if (!remarks['question16']) {
                newErrors["question16"] = "Please provide remarks.";
            }
        }
        if (selectedValues['question17'] === "0") {
            // If radio button value is "0", both image and remarks fields are mandatory
            if (!images['question17']) {
                newErrors["question17"] = "Please capture an image.";
            }
            if (!remarks['question17']) {
                newErrors["question17"] = "Please provide remarks.";
            }
        }
          if (selectedValues['question17'] === "1") {
            // If radio button value is "1", only remarks field is mandatory
            if (!remarks['question17']) {
                newErrors["question17"] = "Please provide remarks.";
            }
        }

            if (selectedValues['question18'] === "0") {
                // If radio button value is "0", both image and remarks fields are mandatory
                if (!images['question18']) {
                    newErrors["question18"] = "Please capture an image.";
                }
                if (!remarks['question18']) {
                    newErrors["question18"] = "Please provide remarks.";
                }
            }
              if (selectedValues['question18'] === "1") {
                // If radio button value is "1", only remarks field is mandatory
                if (!remarks['question18']) {
                    newErrors["question18"] = "Please provide remarks.";
                }
            }
                if (selectedValues['question19'] === "0") {
                    // If radio button value is "0", both image and remarks fields are mandatory
                    if (!images['question19']) {
                        newErrors["question19"] = "Please capture an image.";
                    }
                    if (!remarks['question19']) {
                        newErrors["question19"] = "Please provide remarks.";
                    }
                }
                  if (selectedValues['question19'] === "1") {
                    // If radio button value is "1", only remarks field is mandatory
                    if (!remarks['question19']) {
                        newErrors["question19"] = "Please provide remarks.";
                    }
                }
                    if (selectedValues['question20'] === "0") {
                        // If radio button value is "0", both image and remarks fields are mandatory
                        if (!images['question20']) {
                            newErrors["question20"] = "Please capture an image.";
                        }
                        if (!remarks['question20']) {
                            newErrors["question20"] = "Please provide remarks.";
                        }
                    }
                      if (selectedValues['question20'] === "1") {
                        // If radio button value is "1", only remarks field is mandatory
                        if (!remarks['question20']) {
                            newErrors["question20"] = "Please provide remarks.";
                        }
                    }

        

        
        setErrors(newErrors);

        // If there are errors, display Snackbar and prevent moving to the next step
        if (Object.keys(newErrors).length > 0) {
            setSnackbarMessage("Please fill the questions.");
            setSnackbarOpen(true);
        } 
        
        else {

            if(selectedValues['question15']  ||selectedValues['question16'] ||selectedValues['question17'] || selectedValues['question18'] || selectedValues['question19'] || selectedValues['question20']){
              
                if( saveflg === true ){
                    onNext();
                    setSaveflg(false)
                }
                else{
                    setSnackbarMessage("Please save your changes before moving to the next step.");
                    setSnackbarOpen(true);
                }
            }
            else{
                onNext();
            }
            // If no errors, proceed to the next step
           
        }
    };
    

    
    // const handleCapture = async (questionId) => {
    //     if (!isCameraOpen) {
    //         try {
    //             const newStream = await navigator.mediaDevices.getUserMedia({ video: true });
    //             setStream(newStream);
    //             const video = document.createElement('video');
    //             document.getElementById(`camera-container-${questionId}`).appendChild(video);
    //             video.srcObject = newStream;
    //             video.play();
    //             const captureImage = () => {
    //                 const canvas = document.createElement('canvas');
    //                 canvas.width = video.videoWidth;
    //                 canvas.height = video.videoHeight;
    //                 canvas.getContext('2d').drawImage(video, 0, 0);
    //                 const imageUrl = canvas.toDataURL('image/png');
    //                 setImages(prevImages => ({ ...prevImages, [questionId]: imageUrl }));
    //                 newStream.getTracks().forEach(track => track.stop());
    //                 document.getElementById(`camera-container-${questionId}`).removeChild(video);
    //                 setStream(null);
    //                 setIsCameraOpen(false);
    //             };
    //             video.addEventListener('click', captureImage);
    //             setIsCameraOpen(true);
    //         } catch (error) {
    //             console.error('Error accessing camera:', error);
    //         }
    //     }
    // };



    
const handleCapture = async (questionId) => {
    // Open the gallery for image selection
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Trigger the input element to open the gallery
      fileInputRef.current.onchange = (e) => {
        const selectedFile = e.target.files[0];
        console.log("selectedfile",selectedFile)
        if (selectedFile) {
          // Process the selected image using the setUploadedFile function
          if (!selectedFile.type.startsWith("image/")) {
        
          }
          else{
          
            setUploadedFile({ target: { files: [selectedFile] } },questionId);
          }
         
        }
      };
   
    }
  };
  
  
  const setUploadedFile = async (event,questionId) => {
      const file = event.target.files[0];
      console.log("file",file)
      if (file) {
        const options = {
          maxSizeMB: 0.5,  // Reduce max size further to 0.5MB
          maxWidthOrHeight: 800,  // Reduce dimensions to 800px
          useWebWorker: true
        };
        try {
          const compressedFile = await imageCompression(file, options);
          if (compressedFile.size < 1000000) { // Ensure the file is less than 1MB
            await generateImagesArray(compressedFile, questionId);
          } else {
            console.error('Compressed image size is still too large.');
          }
        } catch (error) {
          console.error('Error compressing image:', error);
        }
      } else {
        // If no image is uploaded, reset the imagePath state
       // setImagePath('');
      }
    };
  
  
  const generateImagesArray = async (file,questionId) => {
    const imagesData = [];
    console.log("fileimage",file)
    console.log("fileimage",file.type)
    const reader = new FileReader();
    reader.onloadend = async () => {
      const imageType = file.type.split('/')[1];
      console.log("imageType",imageType)
      const imageBase64 = reader.result.split(',')[1];
      const imageName = `image_${Date.now()}.${imageType}`;
  
      console.log("imageBase6411",imageBase64)
      imagesData.push({
        imageName,
        imageBase64,
        imageType,
      });
      setImages(prevImages => ({ ...prevImages, [questionId]: `data:image/${imageType};base64,`+ imageBase64 }));
    
      // Call the upload function after processing the images
      //await uploadBlobImagesAPI(imagesData,file);
    };
   
  reader.readAsDataURL(file);
    console.log("imagesData",imagesData)
  }

    const handleRetake = (questionId) => {
        // Clear the previously captured image and allow the user to capture a new one
        setImages(prevImages => ({ ...prevImages, [questionId]: null }));
        handleCapture(questionId);
    };





    useEffect(() => {
        populateFormFields();
    }, [activeStep]);
    const populateFormFields = () => {
        const stepFormData = formData[activeStep];
        setSelectedValues(stepFormData.selectedValues || {});
        setImages(stepFormData.images || {});
        setRemarks(stepFormData.remarks || {});
    };
    // const handleNextClick = () => {
       
    
    //     onNext();
    // };
  
    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };
    
    const handleRadioChange = (questionId, value) => {
        // Update selectedValues state
        setSelectedValues({ ...selectedValues, [questionId]: value });

        // Update formData state by merging selectedValues into the current step's data
        setFormData(prevFormData => ({
            ...prevFormData,
            [activeStep]: {
                ...prevFormData[activeStep],
                selectedValues: { ...prevFormData[activeStep].selectedValues, [questionId]: value }
            }
        }));
        
    };
    // const handleSaveClick = () => {
    //     // Call the onSave function passed from the parent component
    //     onSave();
    //   };
      const handleSaveClick = () => {
        if (!loading) {
        let functionEntered = false; 
        const newErrors = {};
        if (selectedValues['question15'] === "0") {
            // If radio button value is "0", both image and remarks fields are mandatory
            if (!images['question15']) {
                newErrors["question15"] = "Please capture an image.";
            }
            if (!remarks['question15']) {
                newErrors["question15"] = "Please provide remarks.";
            }
            functionEntered = true; 
        }
          if (selectedValues['question15'] === "1") {
            // If radio button value is "1", only remarks field is mandatory
            if (!remarks['question15']) {
                newErrors["question15"] = "Please provide remarks.";
            }
            functionEntered = true; 
        }
        if (selectedValues['question16'] === "0") {
            // If radio button value is "0", both image and remarks fields are mandatory
            if (!images['question16']) {
                newErrors["question16"] = "Please capture an image.";
            }
            if (!remarks['question16']) {
                newErrors["question16"] = "Please provide remarks.";
            }
            functionEntered = true; 
        }
          if (selectedValues['question16'] === "1") {
            // If radio button value is "1", only remarks field is mandatory
            if (!remarks['question16']) {
                newErrors["question16"] = "Please provide remarks.";
            }
            functionEntered = true; 
        }
        if (selectedValues['question17'] === "0") {
            // If radio button value is "0", both image and remarks fields are mandatory
            if (!images['question17']) {
                newErrors["question17"] = "Please capture an image.";
            }
            if (!remarks['question17']) {
                newErrors["question17"] = "Please provide remarks.";
            }
            functionEntered = true; 
        }
          if (selectedValues['question17'] === "1") {
            // If radio button value is "1", only remarks field is mandatory
            if (!remarks['question17']) {
                newErrors["question17"] = "Please provide remarks.";
            }
            functionEntered = true; 
        }

            if (selectedValues['question18'] === "0") {
                // If radio button value is "0", both image and remarks fields are mandatory
                if (!images['question18']) {
                    newErrors["question18"] = "Please capture an image.";
                }
                if (!remarks['question18']) {
                    newErrors["question18"] = "Please provide remarks.";
                }
                functionEntered = true; 
            }
              if (selectedValues['question18'] === "1") {
                // If radio button value is "1", only remarks field is mandatory
                if (!remarks['question18']) {
                    newErrors["question18"] = "Please provide remarks.";
                }
                functionEntered = true; 
            }
                if (selectedValues['question19'] === "0") {
                    // If radio button value is "0", both image and remarks fields are mandatory
                    if (!images['question19']) {
                        newErrors["question19"] = "Please capture an image.";
                    }
                    if (!remarks['question19']) {
                        newErrors["question19"] = "Please provide remarks.";
                    }
                    functionEntered = true; 
                }
                  if (selectedValues['question19'] === "1") {
                    // If radio button value is "1", only remarks field is mandatory
                    if (!remarks['question19']) {
                        newErrors["question19"] = "Please provide remarks.";
                    }
                    functionEntered = true; 
                }
                    if (selectedValues['question20'] === "0") {
                        // If radio button value is "0", both image and remarks fields are mandatory
                        if (!images['question20']) {
                            newErrors["question20"] = "Please capture an image.";
                        }
                        if (!remarks['question20']) {
                            newErrors["question20"] = "Please provide remarks.";
                        }
                        functionEntered = true; 
                    }
                      if (selectedValues['question20'] === "1") {
                        // If radio button value is "1", only remarks field is mandatory
                        if (!remarks['question20']) {
                            newErrors["question20"] = "Please provide remarks.";
                        }
                        functionEntered = true; 
                    }

        

        
        setErrors(newErrors);

        // If there are errors, display Snackbar and prevent moving to the next step
        if (Object.keys(newErrors).length > 0) {
            setSnackbarMessage("Please fill the questions.");
            setSnackbarOpen(true);
        } 

        if (!functionEntered) {
            setSnackbarMessage("Please fill at least one field.");              
            setSnackbarOpen(true);
           // newErrors["general"] = "Please fill at least one field.";
        }
     
        else {

            if (selectedValues['question15'] === "0") {
                // If radio button value is "0", both image and remarks fields are mandatory
                if (!images['question15']) {
                    newErrors["question15"] = "Please capture an image.";
                }
                if (!remarks['question15']) {
                    newErrors["question15"] = "Please provide remarks.";
                }
            }
              if (selectedValues['question15'] === "1") {
                // If radio button value is "1", only remarks field is mandatory
                if (!remarks['question15']) {
                    newErrors["question15"] = "Please provide remarks.";
                }
            }
            if (selectedValues['question16'] === "0") {
                // If radio button value is "0", both image and remarks fields are mandatory
                if (!images['question16']) {
                    newErrors["question16"] = "Please capture an image.";
                }
                if (!remarks['question16']) {
                    newErrors["question16"] = "Please provide remarks.";
                }
            }
              if (selectedValues['question16'] === "1") {
                // If radio button value is "1", only remarks field is mandatory
                if (!remarks['question16']) {
                    newErrors["question16"] = "Please provide remarks.";
                }
            }
            if (selectedValues['question17'] === "0") {
                // If radio button value is "0", both image and remarks fields are mandatory
                if (!images['question17']) {
                    newErrors["question17"] = "Please capture an image.";
                }
                if (!remarks['question17']) {
                    newErrors["question17"] = "Please provide remarks.";
                }
            }
              if (selectedValues['question17'] === "1") {
                // If radio button value is "1", only remarks field is mandatory
                if (!remarks['question17']) {
                    newErrors["question17"] = "Please provide remarks.";
                }
            }
    
                if (selectedValues['question18'] === "0") {
                    // If radio button value is "0", both image and remarks fields are mandatory
                    if (!images['question18']) {
                        newErrors["question18"] = "Please capture an image.";
                    }
                    if (!remarks['question18']) {
                        newErrors["question18"] = "Please provide remarks.";
                    }
                }
                  if (selectedValues['question18'] === "1") {
                    // If radio button value is "1", only remarks field is mandatory
                    if (!remarks['question18']) {
                        newErrors["question18"] = "Please provide remarks.";
                    }
                }
                    if (selectedValues['question19'] === "0") {
                        // If radio button value is "0", both image and remarks fields are mandatory
                        if (!images['question19']) {
                            newErrors["question19"] = "Please capture an image.";
                        }
                        if (!remarks['question19']) {
                            newErrors["question19"] = "Please provide remarks.";
                        }
                    }
                      if (selectedValues['question19'] === "1") {
                        // If radio button value is "1", only remarks field is mandatory
                        if (!remarks['question19']) {
                            newErrors["question19"] = "Please provide remarks.";
                        }
                    }
                        if (selectedValues['question20'] === "0") {
                            // If radio button value is "0", both image and remarks fields are mandatory
                            if (!images['question20']) {
                                newErrors["question20"] = "Please capture an image.";
                            }
                            if (!remarks['question20']) {
                                newErrors["question20"] = "Please provide remarks.";
                            }
                        }
                          if (selectedValues['question20'] === "1") {
                            // If radio button value is "1", only remarks field is mandatory
                            if (!remarks['question20']) {
                                newErrors["question20"] = "Please provide remarks.";
                            }
                        }
    
            
    
            
            setErrors(newErrors);
    
            // If there are errors, display Snackbar and prevent moving to the next step
            if (Object.keys(newErrors).length > 0) {
                setSnackbarMessage("Please fill the questions.");
                setSnackbarOpen(true);
            }
            else{
                setSaveflg(true);
                onSave(selectedValues, images, remarks);
            }
            
        }
    }
   //     onSave(selectedValues, images, remarks);
      };
 
    
    return (
        <Box>

        {/* <Header pageTitle={assessmenteditflg ? "Edit Assessment" : "New Assessment"} isAuditPage={true} />
         */}
        {/* <Header pageTitle="New Assessment" isAuditPage={true} /> */}
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            {/* Question 1 */}
            <Card variant="outlined" style={{ marginBottom: '20px' }}>
        <CardContent>
        <Typography variant="h5" style={{ textTransform: 'uppercase', fontSize: "14px", fontWeight: '500', color: '#17428B', marginBottom: '10px' }}>
        Floor cleanliness
        </Typography>
        <div style={{ marginBottom: '20px' }}>
        <p style={{ marginBottom: '10px', fontSize: "14px", fontWeight: '500' }}>
        15. No rubbish, dirt, oil, water, or broken glass on the ground. The ground is in good condition (grating, staircase, concrete, carpet, painting …) - no "potholes".
        </p>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <p style={{ marginRight: '10px', fontSize: "14px", fontWeight: '500' }}>SCORE:</p>
          <label style={{ marginRight: '20px' }}>
            <input
              type="radio"
              name="radioButton15"
              value="0"
              checked={selectedValues['question15'] === "0"}
              onChange={(e) => handleRadioChange('question15', e.target.value)}
            /> 0
          </label>
          <label>
            <input
              type="radio"
              name="radioButton15"
              value="1"
              checked={selectedValues['question15'] === "1"}
              onChange={(e) => handleRadioChange('question15', e.target.value)}
            /> 1
          </label>
        </div>
        </div>
        
        {selectedValues.question15 === "0" && (
        <div>
          <div id="camera-container-question15" style={{ border: "1px dashed #e6e6e6", height: '160px', textAlign: 'center', marginBottom: '20px', position: 'relative', borderRadius: '10px' }}>
            {!images['question15'] ? (
              <>
                <input
                  id="upload-image_question15"
                  multiple
                  type="file"
                  style={{ display: "none" }}
                  ref={fileInputRef}
                />
                <button onClick={() => handleCapture('question15')}  onChange={(e) => setFormData({ ...selectedValues, image15: e.target.value })} style={{ position: 'absolute', cursor: 'pointer', background: 'none', border: 'none', width: '100%', height: '100%', left: '0', top: '0' }}>
                  <LogoutIcon sx={{ transform: 'rotate(-90deg)', color: '#C9C9CC', margin: '30px 0 10px' }} />
                  <Typography style={{ textAlign: 'center', color: 'black', fontWeight: 500, fontSize: '16px' }}>Drag & drop images or <span style={{ color: '#17428B' }}>browse images</span></Typography>
                  <Typography style={{ textAlign: 'center', color: '#4F4D55', fontSize: '12px', marginTop: '10px' }}>.xlsx - max file size 2MB</Typography>
                </button>
              </>
            ) : (
              <>
                <img  src={images['question15']} onChange={(e) => setFormData({ ...selectedValues, image15: e.target.value })}  alt="Captured" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                <div style={{ position: 'absolute', bottom: '10px', left: '50%', transform: 'translateX(-50%)', zIndex: 1 }}>
                  <Button variant="contained" onClick={() => handleRetake('question15')}>Retake</Button>
                </div>
              </>
            )}
          </div>
        </div>
        )}
        
        <div>
        <p style={{ marginBottom: '10px', fontSize: "14px", fontWeight: '500' }}>Remarks for Improvement</p>
        <textarea
          value={remarks["question15"] || ''}
          onChange={(e) => handleRemarksChange('question15', e.target.value)}
          placeholder="Type here"
          style={{ width: '100%', padding: '8px', outline: 'none', border: '1px solid #E6E6E6', borderRadius: '4px', resize: "none" }}
        />
        </div>
        
        {errors["question15"] && <Typography style={{ color: 'red' }}>{errors["question15"]}</Typography>}
        </CardContent>
        </Card>
        
        <Card variant="outlined" style={{ marginBottom: '20px' }}>
        <CardContent>
        <Typography variant="h5" style={{ textTransform: 'uppercase', fontSize: "14px", fontWeight: '500', color: '#17428B', marginBottom: '10px' }}>
        Dustbin
        </Typography>
        <div style={{ marginBottom: '20px' }}>
        <p style={{ marginBottom: '10px', fontSize: "14px", fontWeight: '500' }}>
        16. The dustbins are not overflowing, clean externally, and in order.
        </p>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <p style={{ marginRight: '10px', fontSize: "14px", fontWeight: '500' }}>SCORE:</p>
          <label style={{ marginRight: '20px' }}>
            <input
              type="radio"
              name="radioButton16"
              value="0"
              checked={selectedValues['question16'] === "0"}
              onChange={(e) => handleRadioChange('question16', e.target.value)}
            /> 0
          </label>
          <label>
            <input
              type="radio"
              name="radioButton16"
              value="1"
              checked={selectedValues['question16'] === "1"}
              onChange={(e) => handleRadioChange('question16', e.target.value)}
            /> 1
          </label>
        </div>
        </div>
        
        {selectedValues.question16 === "0" && (
        <div>
          <div id="camera-container-question16" style={{ border: "1px dashed #e6e6e6", height: '160px', textAlign: 'center', marginBottom: '20px', position: 'relative', borderRadius: '10px' }}>
            {!images['question16'] ? (
              <>
                <input
                  id="upload-image_question16"
                  multiple
                  type="file"
                  style={{ display: "none" }}
                  ref={fileInputRef}
                />
                <button onClick={() => handleCapture('question16')} onChange={(e) => setFormData({ ...selectedValues, image16: e.target.value })}  style={{ position: 'absolute', cursor: 'pointer', background: 'none', border: 'none', width: '100%', height: '100%', left: '0', top: '0' }}>
                  <LogoutIcon sx={{ transform: 'rotate(-90deg)', color: '#C9C9CC', margin: '30px 0 10px' }} />
                  <Typography style={{ textAlign: 'center', color: 'black', fontWeight: 500, fontSize: '16px' }}>Drag & drop images or <span style={{ color: '#17428B' }}>browse images</span></Typography>
                  <Typography style={{ textAlign: 'center', color: '#4F4D55', fontSize: '12px', marginTop: '10px' }}>.xlsx - max file size 2MB</Typography>
                </button>
              </>
            ) : (
              <>
                <img src={images['question16']} onChange={(e) => setFormData({ ...selectedValues, image16: e.target.value })}  alt="Captured" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                <div style={{ position: 'absolute', bottom: '10px', left: '50%', transform: 'translateX(-50%)', zIndex: 1 }}>
                  <Button variant="contained" onClick={() => handleRetake('question16')}>Retake</Button>
                </div>
              </>
            )}
          </div>
        </div>
        )}
        
        <div>
        <p style={{ marginBottom: '10px', fontSize: "14px", fontWeight: '500' }}>Remarks for Improvement</p>
        <textarea
          value={remarks["question16"] || ''}
          onChange={(e) => handleRemarksChange('question16', e.target.value)}
          placeholder="Type here"
          style={{ width: '100%', padding: '8px', outline: 'none', border: '1px solid #E6E6E6', borderRadius: '4px', resize: "none" }}
        />
        </div>
        
        {errors["question16"] && <Typography style={{ color: 'red' }}>{errors["question16"]}</Typography>}
        </CardContent>
        </Card>
        
        
        
        <Card variant="outlined" style={{ marginBottom: '20px' }}>
        <CardContent>
        <Typography variant="h5" style={{ textTransform: 'uppercase', fontSize: "14px", fontWeight: '500', color: '#17428B', marginBottom: '10px' }}>
        Clean work Surface
        </Typography>
        <div style={{ marginBottom: '20px' }}>
        <p style={{ marginBottom: '10px', fontSize: "14px", fontWeight: '500' }}>
        17. Work surfaces, work benches and desks, are clean: ...no trace of oil, grease, dust, "coffee"...
        </p>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <p style={{ marginRight: '10px', fontSize: "14px", fontWeight: '500' }}>SCORE:</p>
          <label style={{ marginRight: '20px' }}>
            <input
              type="radio"
              name="radioButton17"
              value="0"
              checked={selectedValues['question17'] === "0"}
              onChange={(e) => handleRadioChange('question17', e.target.value)}
            /> 0
          </label>
          <label>
            <input
              type="radio"
              name="radioButton17"
              value="1"
              checked={selectedValues['question17'] === "1"}
              onChange={(e) => handleRadioChange('question17', e.target.value)}
            /> 1
          </label>
        </div>
        </div>
        
        {selectedValues.question17 === "0" && (
        <div>
          <div id="camera-container-question17" style={{ border: "1px dashed #e6e6e6", height: '160px', textAlign: 'center', marginBottom: '20px', position: 'relative', borderRadius: '10px' }}>
            {!images['question17'] ? (
              <>
                <input
                  id="upload-image_question17"
                  multiple
                  type="file"
                  style={{ display: "none" }}
                  ref={fileInputRef}
                />
                <button onClick={() => handleCapture('question17')} onChange={(e) => setFormData({ ...selectedValues, image17: e.target.value })}  style={{ position: 'absolute', cursor: 'pointer', background: 'none', border: 'none', width: '100%', height: '100%', left: '0', top: '0' }}>
                  <LogoutIcon sx={{ transform: 'rotate(-90deg)', color: '#C9C9CC', margin: '30px 0 10px' }} />
                  <Typography style={{ textAlign: 'center', color: 'black', fontWeight: 500, fontSize: '16px' }}>Drag & drop images or <span style={{ color: '#17428B' }}>browse images</span></Typography>
                  <Typography style={{ textAlign: 'center', color: '#4F4D55', fontSize: '12px', marginTop: '10px' }}>.xlsx - max file size 2MB</Typography>
                </button>
              </>
            ) : (
              <>
                <img src={images['question17']} onChange={(e) => setFormData({ ...selectedValues, image17: e.target.value })}  alt="Captured" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                <div style={{ position: 'absolute', bottom: '10px', left: '50%', transform: 'translateX(-50%)', zIndex: 1 }}>
                  <Button variant="contained" onClick={() => handleRetake('question17')}>Retake</Button>
                </div>
              </>
            )}
          </div>
        </div>
        )}
        
        <div>
        <p style={{ marginBottom: '10px', fontSize: "14px", fontWeight: '500' }}>Remarks for Improvement</p>
        <textarea
          value={remarks["question17"] || ''}
          onChange={(e) => handleRemarksChange('question17', e.target.value)}
          placeholder="Type here"
          style={{ width: '100%', padding: '8px', outline: 'none', border: '1px solid #E6E6E6', borderRadius: '4px', resize: "none" }}
        />
        </div>
        
        {errors["question17"] && <Typography style={{ color: 'red' }}>{errors["question17"]}</Typography>}
        </CardContent>
        </Card>
        
        
        
        <Card variant="outlined" style={{ marginBottom: '20px' }}>
        <CardContent>
        <Typography variant="h5" style={{ textTransform: 'uppercase', fontSize: "14px", fontWeight: '500', color: '#17428B', marginBottom: '10px' }}>
        Clean tools
        </Typography>
        <div style={{ marginBottom: '20px' }}>
        <p style={{ marginBottom: '10px', fontSize: "14px", fontWeight: '500' }}>
        18. The tools are clean and usable and marked, as appropriate.
        </p>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <p style={{ marginRight: '10px', fontSize: "14px", fontWeight: '500' }}>SCORE:</p>
          <label style={{ marginRight: '20px' }}>
            <input
              type="radio"
              name="radioButton18"
              value="0"
              checked={selectedValues['question18'] === "0"}
              onChange={(e) => handleRadioChange('question18', e.target.value)}
            /> 0
          </label>
          <label>
            <input
              type="radio"
              name="radioButton18"
              value="1"
              checked={selectedValues['question18'] === "1"}
              onChange={(e) => handleRadioChange('question18', e.target.value)}
            /> 1
          </label>
        </div>
        </div>
        
        {selectedValues.question18 === "0" && (
        <div>
          <div id="camera-container-question18" style={{ border: "1px dashed #e6e6e6", height: '160px', textAlign: 'center', marginBottom: '20px', position: 'relative', borderRadius: '10px' }}>
            {!images['question18'] ? (
              <>
                <input
                  id="upload-image_question18"
                  multiple
                  type="file"
                  style={{ display: "none" }}
                  ref={fileInputRef}
                />
                <button onClick={() => handleCapture('question18')} onChange={(e) => setFormData({ ...selectedValues, image18: e.target.value })}  style={{ position: 'absolute', cursor: 'pointer', background: 'none', border: 'none', width: '100%', height: '100%', left: '0', top: '0' }}>
                  <LogoutIcon sx={{ transform: 'rotate(-90deg)', color: '#C9C9CC', margin: '30px 0 10px' }} />
                  <Typography style={{ textAlign: 'center', color: 'black', fontWeight: 500, fontSize: '16px' }}>Drag & drop images or <span style={{ color: '#17428B' }}>browse images</span></Typography>
                  <Typography style={{ textAlign: 'center', color: '#4F4D55', fontSize: '12px', marginTop: '10px' }}>.xlsx - max file size 2MB</Typography>
                </button>
              </>
            ) : (
              <>
                <img src={images['question18']} onChange={(e) => setFormData({ ...selectedValues, image18: e.target.value })}  alt="Captured" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                <div style={{ position: 'absolute', bottom: '10px', left: '50%', transform: 'translateX(-50%)', zIndex: 1 }}>
                  <Button variant="contained" onClick={() => handleRetake('question18')}>Retake</Button>
                </div>
              </>
            )}
          </div>
        </div>
        )}
        
        <div>
        <p style={{ marginBottom: '10px', fontSize: "14px", fontWeight: '500' }}>Remarks for Improvement</p>
        <textarea
          value={remarks["question18"] || ''}
          onChange={(e) => handleRemarksChange('question18', e.target.value)}
          placeholder="Type here"
          style={{ width: '100%', padding: '8px', outline: 'none', border: '1px solid #E6E6E6', borderRadius: '4px', resize: "none" }}
        />
        </div>
        
        {errors["question18"] && <Typography style={{ color: 'red' }}>{errors["question18"]}</Typography>}
        </CardContent>
        </Card>
        <Card variant="outlined" style={{ marginBottom: '20px' }}>
        <CardContent>
        <Typography variant="h5" style={{ textTransform: 'uppercase', fontSize: "14px", fontWeight: '500', color: '#17428B', marginBottom: '10px' }}>
        Clean machines
        </Typography>
        <div style={{ marginBottom: '20px' }}>
        <p style={{ marginBottom: '10px', fontSize: "14px", fontWeight: '500' }}>
        19. The machines and production resources are clean, safe, checked, marked and in working order (PC, printer… for offices)
        </p>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <p style={{ marginRight: '10px', fontSize: "14px", fontWeight: '500' }}>SCORE:</p>
          <label style={{ marginRight: '20px' }}>
            <input
              type="radio"
              name="radioButton19"
              value="0"
              checked={selectedValues['question19'] === "0"}
              onChange={(e) => handleRadioChange('question19', e.target.value)}
            /> 0
          </label>
          <label>
            <input
              type="radio"
              name="radioButton19"
              value="1"
              checked={selectedValues['question19'] === "1"}
              onChange={(e) => handleRadioChange('question19', e.target.value)}
            /> 1
          </label>
        </div>
        </div>
        
        {selectedValues.question19 === "0" && (
        <div>
          <div id="camera-container-question19" style={{ border: "1px dashed #e6e6e6", height: '160px', textAlign: 'center', marginBottom: '20px', position: 'relative', borderRadius: '10px' }}>
            {!images['question19'] ? (
              <>
                <input
                  id="upload-image_question19"
                  multiple
                  type="file"
                  style={{ display: "none" }}
                  ref={fileInputRef}
                />
                <button onClick={() => handleCapture('question19')} onChange={(e) => setFormData({ ...selectedValues, image19: e.target.value })}  style={{ position: 'absolute', cursor: 'pointer', background: 'none', border: 'none', width: '100%', height: '100%', left: '0', top: '0' }}>
                  <LogoutIcon sx={{ transform: 'rotate(-90deg)', color: '#C9C9CC', margin: '30px 0 10px' }} />
                  <Typography style={{ textAlign: 'center', color: 'black', fontWeight: 500, fontSize: '16px' }}>Drag & drop images or <span style={{ color: '#17428B' }}>browse images</span></Typography>
                  <Typography style={{ textAlign: 'center', color: '#4F4D55', fontSize: '12px', marginTop: '10px' }}>.xlsx - max file size 2MB</Typography>
                </button>
              </>
            ) : (
              <>
                <img src={images['question19']} onChange={(e) => setFormData({ ...selectedValues, image19: e.target.value })}  alt="Captured" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                <div style={{ position: 'absolute', bottom: '10px', left: '50%', transform: 'translateX(-50%)', zIndex: 1 }}>
                  <Button variant="contained" onClick={() => handleRetake('question19')}>Retake</Button>
                </div>
              </>
            )}
          </div>
        </div>
        )}
        
        <div>
        <p style={{ marginBottom: '10px', fontSize: "14px", fontWeight: '500' }}>Remarks for Improvement</p>
        <textarea
          value={remarks["question19"] || ''}
          onChange={(e) => handleRemarksChange('question19', e.target.value)}
          placeholder="Type here"
          style={{ width: '100%', padding: '8px', outline: 'none', border: '1px solid #E6E6E6', borderRadius: '4px', resize: "none" }}
        />
        </div>
        
        {errors["question19"] && <Typography style={{ color: 'red' }}>{errors["question19"]}</Typography>}
        </CardContent>
        </Card>
        
        
        <Card variant="outlined" style={{ marginBottom: '20px' }}>
            <CardContent>
            <Typography variant="h5" style={{ textTransform: 'uppercase', fontSize: "14px", fontWeight: '500', color: '#17428B', marginBottom: '10px' }}>
            clean Building
            </Typography>
            <div style={{ marginBottom: '20px' }}>
            <p style={{ marginBottom: '10px', fontSize: "14px", fontWeight: '500' }}>
            20. The buildings are clean. The walls are in good condition and painted. No visible grafitti.
            </p>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
              <p style={{ marginRight: '10px', fontSize: "14px", fontWeight: '500' }}>SCORE:</p>
              <label style={{ marginRight: '20px' }}>
                <input
                  type="radio"
                  name="radioButton20"
                  value="0"
                  checked={selectedValues['question20'] === "0"}
                  onChange={(e) => handleRadioChange('question20', e.target.value)}
                /> 0
              </label>
              <label>
                <input
                  type="radio"
                  name="radioButton20"
                  value="1"
                  checked={selectedValues['question20'] === "1"}
                  onChange={(e) => handleRadioChange('question20', e.target.value)}
                /> 1
              </label>
            </div>
            </div>
            
            {selectedValues.question20 === "0" && (
            <div>
              <div id="camera-container-question20" style={{ border: "1px dashed #e6e6e6", height: '160px', textAlign: 'center', marginBottom: '20px', position: 'relative', borderRadius: '10px' }}>
                {!images['question20'] ? (
                  <>
                    <input
                      id="upload-image_question20"
                      multiple
                      type="file"
                      style={{ display: "none" }}
                      ref={fileInputRef}
                    />
                    <button onClick={() => handleCapture('question20')} onChange={(e) => setFormData({ ...selectedValues, image20: e.target.value })}  style={{ position: 'absolute', cursor: 'pointer', background: 'none', border: 'none', width: '100%', height: '100%', left: '0', top: '0' }}>
                      <LogoutIcon sx={{ transform: 'rotate(-90deg)', color: '#C9C9CC', margin: '30px 0 10px' }} />
                      <Typography style={{ textAlign: 'center', color: 'black', fontWeight: 500, fontSize: '16px' }}>Drag & drop images or <span style={{ color: '#17428B' }}>browse images</span></Typography>
                      <Typography style={{ textAlign: 'center', color: '#4F4D55', fontSize: '12px', marginTop: '10px' }}>.xlsx - max file size 2MB</Typography>
                    </button>
                  </>
                ) : (
                  <>
                    <img src={images['question20']} onChange={(e) => setFormData({ ...selectedValues, image20: e.target.value })} alt="Captured" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                    <div style={{ position: 'absolute', bottom: '10px', left: '50%', transform: 'translateX(-50%)', zIndex: 1 }}>
                      <Button variant="contained" onClick={() => handleRetake('question20')}>Retake</Button>
                    </div>
                  </>
                )}
              </div>
            </div>
            )}
            
            <div>
            <p style={{ marginBottom: '10px', fontSize: "14px", fontWeight: '500' }}>Remarks for Improvement</p>
            <textarea
              value={remarks["question20"] || ''}
              onChange={(e) => handleRemarksChange('question20', e.target.value)}
              placeholder="Type here"
              style={{ width: '100%', padding: '8px', outline: 'none', border: '1px solid #E6E6E6', borderRadius: '4px', resize: "none" }}
            />
            </div>
            
            {errors["question20"] && <Typography style={{ color: 'red' }}>{errors["question20"]}</Typography>}
            </CardContent>
            </Card>
        
         
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                message={snackbarMessage}
            />
            <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', marginTop: '20px' }}>
                <Button variant="contained" color="primary" onClick={handleSaveClick} style={{
                    width: '100%',
                    background: 'white', // Set background color to white
                    borderRadius: '5px', // Add border radius for rounded corners
                    border: '1px solid #17428B', // Set initial border to 1px solid
                    color: '#15408A', // Set font color to blue
                    boxShadow: 'none',
                    position: 'relative',
                    // Remove default box shadow
                }} disabled={loading}
        
                >Save
        
                </Button>
                <Button variant="contained" style={{
                    width: '100%',
                    background: '#17428B', // Adjust the gradient colors as needed
                    borderRadius: 3,
                    border: 0,
                    color: 'white',
                    height: 48,
                    padding: '0 30px',
                    boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
                }} color="primary" onClick={handleNextClick} disabled={loading} >
                    Next
                </Button>
            </div>
        
        </div>
        {loading && (
            <div
                style={{
                    position: 'fixed',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 9999,
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    padding: '20px',
                    borderRadius: '8px',
                    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                }}
            >
                {/* Replace this with your preferred loading indicator */}
                <Typography variant="h6" style={{ textAlign: 'center', marginBottom: '10px' }}>
                    Saving Assessment...
                </Typography>
                {/* Example loading spinner */}
                <div style={{ textAlign: 'center' }}>
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
        )}
        </Box>
    );
}

export default Shine;
