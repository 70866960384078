import React, { useState, useEffect, useRef,useCallback} from "react";
import { Typography, Button, Snackbar, Alert, Box } from '@mui/material';
import CloudUpload from "../../Asset/Icons/file_upload_icon.svg";
import imageCompression from 'browser-image-compression';
import Header from '../../app/TopAppBar';
import { useRecoilState } from 'recoil';
import { stepState, formDataState, assessmentCreatedState } from '../../recoil/atoms';
import useMediaQuery from '@mui/material/useMediaQuery';
import axios from "axios";
import configParam from "../../config";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import CancelIcon from '@mui/icons-material/Cancel';
import MuiAlert from '@mui/material/Alert';
import LogoutIcon from '@mui/icons-material/Logout';
import debounce from 'lodash.debounce';

const RequestForm = ({ onNext, activeStep, onSave, loading }) => {
    const [formData, setFormData] = useRecoilState(formDataState);
    const [set, setStream] = useState({});
    const fileInputRef = useRef(null);
    const [images1, setImage] = useState({});
    const [selectedValues, setSelectedValues] = useState({});
    const [images, setImages] = useState({});
    const [remarks, setRemarks] = useState({});
    const [errors, setErrors] = useState({});
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [isCameraOpen, setIsCameraOpen] = useState(false);
    const [assessmentCreated, setAssessmentCreated] = useRecoilState(assessmentCreatedState);
    const [zoneId, setZoneId] = useState('');
    const [auditorName, setAuditorName] = useState('');
    const [overallScore, setOverallScore] = useState(0);
    const [, setLoading] = useState(false);
    // const [assessmentId, setAssessmentId] = useState(null);
    // const [assessmentId, setAssessmentId] = useState(null);
    const assessmenteditflg = localStorage.getItem("assessmenteditflg") === "true";
    const access_token = JSON.parse(localStorage.getItem("accesstoken"));

    const [saveflg, setSaveflg] = useState(false);

    const ApiURL = configParam.API_URL;


    useEffect(() => {
        return () => {
            // Store form data in Recoil state when unmounting
            setFormData(prevFormData => ({
                ...prevFormData,
                [activeStep]: {
                    selectedValues,
                    images,
                    remarks
                }
            }));
        };
    }, [activeStep, selectedValues, images, remarks, setFormData])

    useEffect(() => {
        if (assessmenteditflg) {
            localStorage.setItem("assessmentIdcreate", "");
            const assessmentId = localStorage.getItem("assessmentId");
       
            geteditdetails(assessmentId);
        }
    }, []);

   


    const handleRemarksChange = (questionId, value) => {
        // Update selectedValues state
        setRemarks({ ...remarks, [questionId]: value });

        // Update formData state by merging selectedValues into the current step's data
        setFormData(prevFormData => ({
            ...prevFormData,
            [activeStep]: {
                ...prevFormData[activeStep],
                remarks: { ...prevFormData[activeStep].remarks, [questionId]: value }
            }
        }));
        const overallScore = calculateOverallScore();
        // Update overall score in UI or state
        setOverallScore(overallScore);
    };

    

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const calculateOverallScore = () => {
        let overall = 0;
        for (const step in formData) {
            const stepData = formData[step];
            for (const questionId in stepData.selectedValues) {
                if (stepData.selectedValues.hasOwnProperty(questionId)) {
                    const score = stepData.selectedValues[questionId];
                    overall += parseInt(score);
                }
            }
        }
        return overall;
    };
    useEffect(() => {
        // Calculate overall score when active step changes
        const overallScore = calculateOverallScore();
        // Update overall score in UI or state
        setOverallScore(overallScore);
    }, [activeStep, formData]); // Trigger recalculation when activeStep or formData changes


    async function geteditdetails(assessmentId) {
        try {
            const response = await axios.get(`${ApiURL}/get_AssessmentByIds/${assessmentId}`, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
            if (response.data) {
                const formData = JSON.parse(response.data.data.details);
                setSelectedValues(formData.selectedValues || {});
                setRemarks(formData.remarks || {});

                // Construct image paths for each image
                const updatedImages = {};
                for (const questionId in formData.images) {
                    if (formData.images.hasOwnProperty(questionId)) {
                        const imageName = formData.images[questionId];

                        //  getImages(imageName);
                        const imagePath = `${ApiURL}/api/images/${imageName}`; // API endpoint to retrieve images
                        updatedImages[questionId] = imagePath;
                        // const imagePath = `C:/5s/5s-1/src/uploads/${imageName}`; // Replace this with your actual image directory path
                        //updatedImages[questionId] = imagePath;
                    }
                }
                setImages(updatedImages);


             
            } else {
                console.log("No data found in the response");
            }
        } catch (error) {
          
            return null;
        }
    }




    const convertImageToBase64 = async (imageData) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(imageData);
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.onerror = (error) => {
                reject(error);
            };
        });
    };
    const handleNextClick = () => {
        // Validate form fields
        const newErrors = {};
        if (selectedValues['question1'] === "0") {
            // If radio button value is "0", both image and remarks fields are mandatory
            if (!images['question1']) {
                newErrors["question1"] = "Please capture an image.";
            }
            if (!remarks['question1']) {
                newErrors["question1"] = "Please provide remarks.";
            }
        }
        if (selectedValues['question1'] === "1") {
            // If radio button value is "1", only remarks field is mandatory
            if (!remarks['question1']) {
                newErrors["question1"] = "Please provide remarks.";
            }
        }
        if (selectedValues['question2'] === "0") {
            // If radio button value is "0", both image and remarks fields are mandatory
            if (!images['question2']) {
                newErrors["question2"] = "Please capture an image.";
            }
            // if (!formData.remarks2) {
            //     newErrors["question2"] = "Please provide remarks.";
            // }
            if (!remarks['question2']) {
                newErrors["question2"] = "Please provide remarks.";
            }
        }
        if (selectedValues['question2'] === "1") {
            // If radio button value is "1", only remarks field is mandatory
            if (!remarks['question2']) {
                newErrors["question2"] = "Please provide remarks.";
            }
        }
        if (selectedValues['question3'] === "0") {
            // If radio button value is "0", both image and remarks fields are mandatory
            if (!images['question3']) {
                newErrors["question3"] = "Please capture an image.";
            }
            if (!remarks['question3']) {
                newErrors["question3"] = "Please provide remarks.";
            }
        }
        if (selectedValues['question3'] === "1") {
            // If radio button value is "1", only remarks field is mandatory
            if (!remarks['question3']) {
                newErrors["question3"] = "Please provide remarks.";
            }
        }


        setErrors(newErrors);

        // If there are errors, display Snackbar and prevent moving to the next step
        if (Object.keys(newErrors).length > 0) {
            setSnackbarMessage("Please fill the questions.");
            setSnackbarOpen(true);
        }

        else {
            if (selectedValues['question1'] || selectedValues['question2'] || selectedValues['question3']) {

                if (saveflg === true) {
                    onNext();
                    setSaveflg(false)
                }
                else {
                    setSnackbarMessage("Please save your changes before moving to the next step.");
                    setSnackbarOpen(true);
                }
            }
            else {
                onNext();
            }

            // If no errors, proceed to the next step

        }
    };
    const handleSaveClick = () => {
        if (!loading) {

            let functionEntered = false;
            const newErrors = {};
            if (selectedValues['question1'] === "0") {
                // If radio button value is "0", both image and remarks fields are mandatory
                if (!images['question1']) {
                    newErrors["question1"] = "Please capture an image.";
                }
                if (!remarks['question1']) {
                    newErrors["question1"] = "Please provide remarks.";
                }
                functionEntered = true;
            }
            if (selectedValues['question1'] === "1") {
                // If radio button value is "1", only remarks field is mandatory
                if (!remarks['question1']) {
                    newErrors["question1"] = "Please provide remarks.";
                }
                functionEntered = true;
            }
            if (selectedValues['question2'] === "0") {
                // If radio button value is "0", both image and remarks fields are mandatory
                if (!images['question2']) {
                    newErrors["question2"] = "Please capture an image.";
                }
                // if (!formData.remarks2) {
                //     newErrors["question2"] = "Please provide remarks.";
                // }
                if (!remarks['question2']) {
                    newErrors["question2"] = "Please provide remarks.";
                }
                functionEntered = true;
            }
            if (selectedValues['question2'] === "1") {
                // If radio button value is "1", only remarks field is mandatory
                if (!remarks['question2']) {
                    newErrors["question2"] = "Please provide remarks.";
                }
                functionEntered = true;
            }
            if (selectedValues['question3'] === "0") {
                // If radio button value is "0", both image and remarks fields are mandatory
                if (!images['question3']) {
                    newErrors["question3"] = "Please capture an image.";
                }
                if (!remarks['question3']) {
                    newErrors["question3"] = "Please provide remarks.";
                }
                functionEntered = true;
            }
            if (selectedValues['question3'] === "1") {
                // If radio button value is "1", only remarks field is mandatory
                if (!remarks['question3']) {
                    newErrors["question3"] = "Please provide remarks.";
                }
                functionEntered = true;
            }


            setErrors(newErrors);

            // If there are errors, display Snackbar and prevent moving to the next step
            if (Object.keys(newErrors).length > 0) {
                setSnackbarMessage("Please fill the questions.");
                setSnackbarOpen(true);

            }


            if (!functionEntered) {
                setSnackbarMessage("Please fill at least one field.");
                setSnackbarOpen(true);

                // newErrors["general"] = "Please fill at least one field.";
            }

            else {
                if (selectedValues['question1'] === "0") {
                    // If radio button value is "0", both image and remarks fields are mandatory
                    if (!images['question1']) {
                        newErrors["question1"] = "Please capture an image.";
                    }
                    if (!remarks['question1']) {
                        newErrors["question1"] = "Please provide remarks.";
                    }
                }
                if (selectedValues['question1'] === "1") {
                    // If radio button value is "1", only remarks field is mandatory
                    if (!remarks['question1']) {
                        newErrors["question1"] = "Please provide remarks.";
                    }
                }
                if (selectedValues['question2'] === "0") {
                    // If radio button value is "0", both image and remarks fields are mandatory
                    if (!images['question2']) {
                        newErrors["question2"] = "Please capture an image.";
                    }
                    // if (!formData.remarks2) {
                    //     newErrors["question2"] = "Please provide remarks.";
                    // }
                    if (!remarks['question2']) {
                        newErrors["question2"] = "Please provide remarks.";
                    }
                }
                if (selectedValues['question2'] === "1") {
                    // If radio button value is "1", only remarks field is mandatory
                    if (!remarks['question2']) {
                        newErrors["question2"] = "Please provide remarks.";
                    }
                }
                if (selectedValues['question3'] === "0") {
                    // If radio button value is "0", both image and remarks fields are mandatory
                    if (!images['question3']) {
                        newErrors["question3"] = "Please capture an image.";
                    }
                    if (!remarks['question3']) {
                        newErrors["question3"] = "Please provide remarks.";
                    }
                }
                if (selectedValues['question3'] === "1") {
                    // If radio button value is "1", only remarks field is mandatory
                    if (!remarks['question3']) {
                        newErrors["question3"] = "Please provide remarks.";
                    }
                }


                setErrors(newErrors);

                // If there are errors, display Snackbar and prevent moving to the next step
                if (Object.keys(newErrors).length > 0) {
                    setSnackbarMessage("Please fill the questions.");
                    setSnackbarOpen(true);

                }
                else {
                    setSaveflg(true);

                    onSave(selectedValues, images, remarks);
                }


            }

        }
        // Call the onSave function passed from the parent component

    };


    const handleCapture = async (questionId) => {
        // Open the gallery for image selection
        if (fileInputRef.current) {
          fileInputRef.current.click(); // Trigger the input element to open the gallery
          fileInputRef.current.onchange = (e) => {
            const selectedFile = e.target.files[0];
        
            if (selectedFile) {
              // Process the selected image using the setUploadedFile function
              if (!selectedFile.type.startsWith("image/")) {
            
              }
              else{
              
                setUploadedFile({ target: { files: [selectedFile] } },questionId);
              }
             
            }
          };
       
        }
      };
      
      
      const setUploadedFile = async (event,questionId) => {
          const file = event.target.files[0];
     

          if (file) {
            const options = {
              maxSizeMB: 0.5,  // Reduce max size further to 0.5MB
              maxWidthOrHeight: 800,  // Reduce dimensions to 800px
              useWebWorker: true
            };
            try {
              const compressedFile = await imageCompression(file, options);
              if (compressedFile.size < 1000000) { // Ensure the file is less than 1MB
                await generateImagesArray(compressedFile, questionId);
              } else {
                console.error('Compressed image size is still too large.');
              }
            } catch (error) {
              console.error('Error compressing image:', error);
            }
          } else {
            // If no image is uploaded, reset the imagePath state
           // setImagePath('');
          }
        //   if (file) {
        //     // If an image is uploaded, set the imagePath state to the URL of the uploaded image
           
        //     await generateImagesArray(file,questionId);
        //    // await uploadBlobImagesAPI(imageData,file);
        //   } 
          
        
        };
      
      
      const generateImagesArray = async (file,questionId) => {
        const imagesData = [];
      
        const reader = new FileReader();
        reader.onloadend = async () => {
          const imageType = file.type.split('/')[1];
        
          const imageBase64 = reader.result.split(',')[1];
          const imageName = `image_${Date.now()}.${imageType}`;
      
          
          imagesData.push({
            imageName,
            imageBase64,
            imageType,
          });
          setImages(prevImages => ({ ...prevImages, [questionId]: `data:image/${imageType};base64,`+ imageBase64 }));
        
          // Call the upload function after processing the images
          //await uploadBlobImagesAPI(imagesData,file);
        };
       
      reader.readAsDataURL(file);
        
      }

 



    const handleCapture_ds = async (questionId) => {
        // Check if mediaDevices API is available
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
          try {
            // Request access to the camera
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            
            // Create a video element to display the camera feed
            const video = document.createElement('video');
            video.srcObject = stream;
            video.play();
            
            // Create a canvas to capture the image
            const canvas = document.createElement('canvas');
            
            // Create a button to capture the image
            const captureButton = document.createElement('button');
            captureButton.textContent = 'Capture';
            
            // Append video and button to the body or a specific container
            document.body.appendChild(video);
            document.body.appendChild(captureButton);
            
            // Handle capture button click
            captureButton.onclick = () => {
              canvas.width = video.videoWidth;
              canvas.height = video.videoHeight;
              const context = canvas.getContext('2d');
              context.drawImage(video, 0, 0, canvas.width, canvas.height);
              
              // Get the image data URL
              const imageDataUrl = canvas.toDataURL('image/png');
              
              // Process the captured image
              processCapturedImage(imageDataUrl, questionId);
              
              // Stop the video stream and remove elements
              stream.getTracks().forEach(track => track.stop());
              document.body.removeChild(video);
              document.body.removeChild(captureButton);
            };
          } catch (error) {
         
            fallbackToFileInput(questionId);
          }
        } else {
          fallbackToFileInput(questionId);
        }
      };
      
      // Fallback to file input for browsers that do not support getUserMedia
      const fallbackToFileInput = (questionId) => {
        if (fileInputRef.current) {
          fileInputRef.current.click();
          fileInputRef.current.onchange = async (e) => {
            const selectedFile = e.target.files[0];
            if (selectedFile && selectedFile.type.startsWith('image/')) {
              await setUploadedFile(e, questionId);
            }
          };
        }
      };
      
      // Process the captured image from the camera
      const processCapturedImage = async (imageDataUrl, questionId) => {
        const file = dataURLtoFile(imageDataUrl, `capture_${questionId}.png`);
        const event = { target: { files: [file] } };
        await setUploadedFile(event, questionId);
      };
      
      // Convert data URL to file
      const dataURLtoFile = (dataurl, filename) => {
        const arr = dataurl.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
      };
      

    const handleImageUpload = (e, question) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };
    const handleRetake = (questionId) => {
        // Clear the previously captured image and allow the user to capture a new one
        setImages(prevImages => ({ ...prevImages, [questionId]: null }));
        handleCapture(questionId);
    };




    // Function to validate the form data

    useEffect(() => {
        populateFormFields();
    }, [activeStep]);
    const populateFormFields = () => {
        const stepFormData = formData[activeStep];
        setSelectedValues(stepFormData.selectedValues || {});
        setImages(stepFormData.images || {});
        setRemarks(stepFormData.remarks || {});
    };
    // const handleNextClick = () => {


    //     onNext();
    // };


    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };


    const handleRadioChange = (questionId, value) => {
        // Update selectedValues state
     
        setSelectedValues({ ...selectedValues, [questionId]: value });



        // Update formData state by merging selectedValues into the current step's data
        setFormData(prevFormData => ({
            ...prevFormData,
            [activeStep]: {
                ...prevFormData[activeStep],
                selectedValues: { ...prevFormData[activeStep].selectedValues, [questionId]: value }
            }
        }));

        let overall = 0;
        for (const step in formData) {
            const stepData = formData[step];
            for (const questionId in stepData.selectedValues) {
                if (stepData.selectedValues.hasOwnProperty(questionId)) {
                    const score = stepData.selectedValues[questionId];
                    overall += parseInt(score);
                }
            }
        }
        setOverallScore(overall);

    };


    return (



        <Box>

            {/* <Header pageTitle={assessmenteditflg ? "Edit Assessment" : "New Assessment"} isAuditPage={true} />
             */}
            {/* <Header pageTitle="New Assessment" isAuditPage={true} /> */}
            <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                {/* Question 1 */}
                <Card variant="outlined" style={{ marginBottom: '20px' }}>
                    <CardContent>
                        {/* <Typography variant="h6" style={{ textTransform: 'uppercase',fontSize: "16px",  fontWeight: '600', color: '#17428B', marginBottom: '10px' }}>1S: Sort(seiri)</Typography>
                <p style={{ marginBottom: '10px',fontSize: "10px",fontWeight: '500' }}>Tidiness, Organization. Refers To The Practice Of Sorting Through All The Tools, Materials, Etc., In The Work Area And Keeping Only Essential Items.</p> */}
                        <Typography variant="h5" style={{ textTransform: 'uppercase', fontSize: "14px", fontWeight: '500', color: '#17428B', marginBottom: '10px' }}>Unusable Items</Typography>
                        <div>
                            <p style={{ marginBottom: '10px', fontSize: "14px", fontWeight: '500' }}>1. No faulty, unusable or worn consumables, tools, or materials in the workplace. (On the ground or on racks & cupboards)</p>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                <p style={{ marginRight: '10px', fontSize: "14px", fontWeight: '500' }}>SCORE:</p>
                                <label>
                                    <input
                                        type="radio"
                                        name="radioButton1"
                                        value="0"
                                        checked={selectedValues['question1'] === "0"}
                                        // checked={formData.question1 === "0"}
                                        // onChange={(e) => setFormData({ ...formData, question1: e.target.value })}
                                        onChange={(e) => handleRadioChange('question1', e.target.value)}

                                    /> 0
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        name="radioButton1"
                                        value="1"
                                        checked={selectedValues['question1'] === "1"}
                                        // checked={formData.question1 === "1"}
                                        // onChange={(e) => setFormData({ ...formData, question1: e.target.value })}
                                        onChange={(e) => handleRadioChange('question1', e.target.value)}

                                    /> 1
                                </label>
                            </div>
                        </div>

                        {selectedValues.question1 === "0" && (
                            <div>

                                <div id="camera-container-question1" style={{ border: "1px dashed #e6e6e6", height: '160px',textAlign:'center', marginBottom: '20px', position: 'relative', borderRadius:'10px' }}>
                                    {!images['question1'] ? (
                                        <>
                                            {/* Camera button */}
                                            <input
                                                id="upload-image_question1"
                                                multiple
                                                type="file"
                                                style={{ display: "none" }}
                                                // onChange={setUploadedFile}
                                                ref={fileInputRef}
                                            />
                                            <button onClick={() => handleCapture('question1')} onChange={(e) => setFormData({ ...selectedValues, image1: e.target.value })} style={{ position: 'absolute', cursor: 'pointer', background: 'none', border: 'none', width: '100%', height: '100%',left:'0',top:'0' }}>
                                            </button>
                                            <LogoutIcon sx={{
                                                transform: 'rotate(-90deg)',
                                                color: '#C9C9CC',
                                                margin:'30px 0 10px'
                                            }} />
                                            <Typography style={{ textAlign: 'center', color: 'black', fontWeight: 500, fontSize: '16px' }}>Drag & drop images or <span style={{ color: '#17428B' }}>browse images</span></Typography>
                                            {/* <Typography style={{ textAlign: 'center', color: '#4F4D55', fontSize: '12px', marginTop: '10px' }}>.xlsx - max file size 2MB</Typography> */}
                                        </>
                                    ) : (
                                        <>
                                            {/* Display captured image */}
                                            <img src={images['question1']} onChange={(e) => setFormData({ ...selectedValues, image1: e.target.value })} alt="Captured" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                                            <div style={{ position: 'absolute', bottom: '10px', left: '50%', transform: 'translateX(-50%)', zIndex: 1 }}>
                                                {/* Retake button */}
                                                <Button variant="contained" onClick={() => handleRetake('question1')}>Retake</Button>
                                            </div>
                                        </>
                                    )}</div>
                            </div>
                        )}



                        <div>
                            <p style={{ marginBottom: '10px', fontSize: "14px", fontWeight: '500' }}>Remarks for Improvement</p>
                            {/* <textarea value={remarks["question1"] || ''} onChange={(e) => handleChange('remarks', 'question1', e.target.value)} placeholder="Type here" style={{ width: '100%', padding: '8px', border: '1px solid #E6E6E6', borderRadius: '4px' }} /> */}
                            <textarea value={remarks["question1"] || ''} onChange={(e) => handleRemarksChange('question1', e.target.value)} placeholder="Type here" style={{ width: '100%', padding: '8px', outline: 'none', border: '1px solid #E6E6E6', borderRadius: '4px', resize: "none" }} />
                        </div>
                        {errors["question1"] && <Typography style={{ color: 'red' }}>{errors["question1"]}</Typography>}
                    </CardContent>
                </Card>
                {/* Question 2 */}
                <Card variant="outlined" style={{ marginBottom: '20px' }}>
                    <CardContent>
                        <Typography variant="h5" style={{ textTransform: 'uppercase', fontSize: "14px", fontWeight: '500', color: '#17428B', marginBottom: '10px' }}>Unnecessary Items</Typography>
                        <div>
                            <p style={{ marginBottom: '10px', fontSize: "14px", fontWeight: '500' }}>2. No items which are unneccessary or in excess quantity are present in the workplace. Are all items used on a regular basis?</p>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                <p style={{ marginRight: '10px', fontSize: "14px", fontWeight: '500' }}>SCORE:</p>
                                <label>
                                    <input
                                        type="radio"
                                        name="radioButton2"
                                        value="0"
                                        checked={selectedValues['question2'] === "0"}
                                        // checked={formData.question2 === "0"}
                                        // onChange={(e) => setFormData({ ...formData, question2: e.target.value })}
                                        onChange={(e) => handleRadioChange('question2', e.target.value)}


                                    /> 0
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        name="radioButton2"
                                        value="1"
                                        checked={selectedValues['question2'] === "1"}
                                        // checked={formData.question2 === "1"}
                                        // onChange={(e) => setFormData({ ...formData, question2: e.target.value })}
                                        onChange={(e) => handleRadioChange('question2', e.target.value)}


                                    /> 1
                                </label>
                            </div>
                        </div>
                        {/* </div> */}

                        {selectedValues.question2 === "0" && (
                            <div>
                                <div id="camera-container-question2" style={{ border: "1px dashed #e6e6e6", height: '160px',textAlign:'center', marginBottom: '20px', position: 'relative', borderRadius:'10px' }}>
                                    {!images['question2'] ? (
                                        <>
                                            {/* Camera button */}
                                            <input
                                                id="upload-image_question2"
                                                multiple
                                                type="file"
                                                style={{ display: "none" }}
                                                // onChange={setUploadedFile}
                                                ref={fileInputRef}
                                            />
                                            <button onClick={() => handleCapture('question2')} onChange={(e) => setFormData({ ...selectedValues, image2: e.target.value })} style={{ position: 'absolute', cursor: 'pointer', background: 'none',left:'0',top:'0', border: 'none', width: '100%', height: '100%' }}>

                                            </button>
                                            <LogoutIcon sx={{
                                                transform: 'rotate(-90deg)',
                                                color: '#C9C9CC',
                                                margin:'30px 0 10px'
                                            }} />
                                            <Typography style={{ textAlign: 'center', color: 'black', fontWeight: 500, fontSize: '16px' }}>Drag & drop images or <span style={{ color: '#17428B' }}>browse images</span></Typography>
                                            {/* <Typography style={{ textAlign: 'center', color: '#4F4D55', fontSize: '12px', marginTop: '10px' }}>.xlsx - max file size 2MB</Typography> */}
                                        </>
                                    ) : (
                                        <>
                                            {/* Display captured image */}
                                            <img src={images['question2']} alt="Captured" onChange={(e) => setFormData({ ...selectedValues, image2: e.target.value })} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                                            <div style={{ position: 'absolute', bottom: '10px', left: '50%', transform: 'translateX(-50%)', zIndex: 1 }}>
                                                {/* Retake button */}
                                                <Button variant="contained" onClick={() => handleRetake('question2')}>Retake</Button>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        )}


                        {/* Remarks */}

                        <div>
                            <p style={{ marginBottom: '10px', fontSize: "14px", fontWeight: '500' }}>Remarks for Improvement</p>
                            {/* <textarea value={remarks["question1"] || ''} onChange={(e) => handleChange('remarks', 'question1', e.target.value)} placeholder="Type here" style={{ width: '100%', padding: '8px', border: '1px solid #E6E6E6', borderRadius: '4px' }} /> */}
                            <textarea value={remarks["question2"] || ''} onChange={(e) => handleRemarksChange('question2', e.target.value)} placeholder="Type here" style={{ width: '100%', padding: '8px', outline: 'none', border: '1px solid #E6E6E6', borderRadius: '4px', resize: "none" }} />
                        </div>

                        {errors["question2"] && <Typography style={{ color: 'red' }}>{errors["question2"]}</Typography>}
                    </CardContent>
                </Card>

                {/* Question 3 */}
                <Card variant="outlined" style={{ marginBottom: '20px' }}>
                    <CardContent>
                        <Typography variant="h5" style={{ textTransform: 'uppercase', fontSize: "14px", fontWeight: '500', color: '#17428B', marginBottom: '10px' }}>
                            Red Tag Procedure
                        </Typography>
                        <div>
                            <p style={{ marginBottom: '10px', fontSize: "14px", fontWeight: '500' }}>3. 1- Red Tag procedure in place. 2- Red Tag area defined. 3- Red Tagging materials available. 4- List of items in the red tag area. 5- Monthly review of red tag content. (To score a 1 you need all 5 implemented)</p>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                <p style={{ marginRight: '10px', fontSize: "14px", fontWeight: '500' }}>SCORE:</p>
                                <label>
                                    <input
                                        type="radio"
                                        name="radioButton3"
                                        value="0"
                                        checked={selectedValues['question3'] === "0"}
                                        // checked={formData.question3 === "0"}
                                        // onChange={(e) => setFormData({ ...formData, question3: e.target.value })}
                                        onChange={(e) => handleRadioChange('question3', e.target.value)}

                                    /> 0
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        name="radioButton3"
                                        value="1"
                                        checked={selectedValues['question3'] === "1"}
                                        // checked={formData.question3 === "1"}
                                        // onChange={(e) => setFormData({ ...formData, question3: e.target.value })}
                                        onChange={(e) => handleRadioChange('question3', e.target.value)}


                                    /> 1
                                </label>
                            </div>
                        </div>

                    
                        {selectedValues.question3 === "0" && (
                            <div>

                                <div id="camera-container-question3" style={{ border: "1px dashed #e6e6e6", height: '160px',textAlign:'center', marginBottom: '20px', position: 'relative', borderRadius:'10px' }}>
                                    {!images['question3'] ? (
                                        <>
                                            {/* Camera button */}
                                            <input
                                                id="upload-image_question3"
                                                multiple
                                                type="file"
                                                style={{ display: "none" }}
                                                // onChange={setUploadedFile}
                                                ref={fileInputRef}
                                            />
                                            <button onClick={() => handleCapture('question3')} onChange={(e) => setFormData({ ...selectedValues, image3: e.target.value })} style={{ position: 'absolute', cursor: 'pointer', background: 'none', border: 'none', width: '100%', height: '100%',left:'0',top:'0' }}>
                                            </button>
                                            <LogoutIcon sx={{
                                                transform: 'rotate(-90deg)',
                                                color: '#C9C9CC',
                                                margin:'30px 0 10px'
                                            }} />
                                            <Typography style={{ textAlign: 'center', color: 'black', fontWeight: 500, fontSize: '16px' }}>Drag & drop images or <span style={{ color: '#17428B' }}>browse images</span></Typography>
                                            {/* <Typography style={{ textAlign: 'center', color: '#4F4D55', fontSize: '12px', marginTop: '10px' }}>.xlsx - max file size 2MB</Typography> */}
                                        </>
                                    ) : (
                                        <>
                                            {/* Display captured image */}
                                            <img src={images['question3']} onChange={(e) => setFormData({ ...selectedValues, image3: e.target.value })} alt="Captured" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                                            <div style={{ position: 'absolute', bottom: '10px', left: '50%', transform: 'translateX(-50%)', zIndex: 1 }}>
                                                {/* Retake button */}
                                                <Button variant="contained" onClick={() => handleRetake('question3')}>Retake</Button>
                                            </div>
                                        </>
                                    )}</div>
                            </div>
                        )}



                        <div>
                            <p style={{ marginBottom: '10px', fontSize: "14px", fontWeight: '500' }}>Remarks for Improvement</p>
                            {/* <textarea value={remarks["question1"] || ''} onChange={(e) => handleChange('remarks', 'question1', e.target.value)} placeholder="Type here" style={{ width: '100%', padding: '8px', border: '1px solid #E6E6E6', borderRadius: '4px' }} /> */}
                            <textarea value={remarks["question3"] || ''} onChange={(e) => handleRemarksChange('question3', e.target.value)} placeholder="Type here" style={{ width: '100%', padding: '8px', outline: 'none', border: '1px solid #E6E6E6', borderRadius: '4px', resize: "none" }} />
                        </div>
                        {errors["question3"] && <Typography style={{ color: 'red' }}>{errors["question3"]}</Typography>}
                    </CardContent>
                </Card>
                {/* <Button variant="contained" onClick={ handleSaveClick}>Save</Button>
 */}

                {/* Next button */}
                {/* <Button variant="contained" onClick={handleNextClick}>Next</Button> */}
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={handleCloseSnackbar}
                    message={snackbarMessage}
                />
                <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', marginTop: '20px' }}>
                    <Button variant="contained" color="primary" onClick={handleSaveClick} style={{
                        width: '100%',
                        background: 'white', // Set background color to white
                        borderRadius: '5px', // Add border radius for rounded corners
                        border: '1px solid #17428B', // Set initial border to 1px solid
                        color: '#15408A', // Set font color to blue
                        boxShadow: 'none',
                        position: 'relative',
                        // Remove default box shadow
                    }} disabled={loading}

                    >Save

                        {/* {loading && <CircularProgress size={24} style={{ position: 'absolute', left: '50%', marginTop: '4px', transform: 'translateX(-50%)' }} />}
              */}
                        {/* {loading && <Alert severity="info" style={{ marginTop: 20 }}>Saving assessment...</Alert>}
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <MuiAlert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar> */}
                        {/*              
                      {loading ? <CircularProgress size={24} color="inherit" /> : 'Save'}  */}
                    </Button>
                    <Button variant="contained" style={{
                        width: '100%',
                        background: '#17428B', // Adjust the gradient colors as needed
                        borderRadius: 3,
                        border: 0,
                        color: 'white',
                        height: 48,
                        padding: '0 30px',
                        boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
                    }} color="primary" onClick={handleNextClick} disabled={loading} >
                        Next
                    </Button>
                </div>

            </div>
            {loading && (
                <div
                    style={{
                        position: 'fixed',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: 9999,
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        padding: '20px',
                        borderRadius: '8px',
                        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                    }}
                >
                    {/* Replace this with your preferred loading indicator */}
                    <Typography variant="h6" style={{ textAlign: 'center', marginBottom: '10px' }}>
                        Saving Assessment...
                    </Typography>
                    {/* Example loading spinner */}
                    <div style={{ textAlign: 'center' }}>
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
            )}
        </Box>

    );
}

export default RequestForm;

