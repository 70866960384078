import React, { useState,useEffect,useRef} from "react";
import { Typography, Button,Snackbar,Alert,Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Header from '../../app/TopAppBar';
import CloudUpload from "../../Asset/Icons/file_upload_icon.svg";
import configParam from "../../config";
import { useRecoilState,useRecoilValue } from 'recoil';
import AlertDialog from '../input/AlertDialog';
import imageCompression from 'browser-image-compression';
import { stepState, formDataState,assessmentCreatedState} from '../../recoil/atoms';
import axios from "axios";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import MuiAlert from '@mui/material/Alert';
import LogoutIcon from '@mui/icons-material/Logout';

import { handleSend } from "./EmailView";
const Safety = ({ onNext,activeStep,onSave,loading}) => {
   const navigate = useNavigate();
   
   const [formData, setFormData] = useRecoilState(formDataState);
   const [selectedValues, setSelectedValues] = useState({});
   const fileInputRef = useRef(null);
   const [images, setImages] = useState({});
   const [remarks, setRemarks] = useState({});
   const [errors, setErrors] = useState({});
   const [snackbarOpen, setSnackbarOpen] = useState(false);
   const [snackbarMessage, setSnackbarMessage] = useState("");
   const[set,setStream] = useState({});
   const [isCameraOpen, setIsCameraOpen] = useState(false);
   const ApiURL = configParam.API_URL;
   const [assessmentCreated, setAssessmentCreated] = useRecoilState(assessmentCreatedState);
   const [assessmentId, setAssessmentId] = useState(null); 
   const assessmenteditflg = localStorage.getItem("assessmenteditflg") === "true"; 
   const  access_token =  JSON.parse(localStorage.getItem("accesstoken"));
   
  const[,setLoading]=useState(false);
    useEffect(() => {
        populateFormFields();
    }, [activeStep]);
    const populateFormFields = () => {
        const stepFormData = formData[activeStep];
        setSelectedValues(stepFormData.selectedValues || {});
        setImages(stepFormData.images || {});
        setRemarks(stepFormData.remarks || {});
    };
   

useEffect(() => {
    // Store form data in Recoil state immediately after capturing an image
    setFormData(prevFormData => ({
        ...prevFormData,
        [activeStep]: {
            selectedValues,
            images,
            remarks
        }
    }));
}, [selectedValues, images, remarks, setFormData]);

   
const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };




    const handleCapture = async (questionId) => {
        // Open the gallery for image selection
        if (fileInputRef.current) {
          fileInputRef.current.click(); // Trigger the input element to open the gallery
          fileInputRef.current.onchange = (e) => {
            const selectedFile = e.target.files[0];
         
            if (selectedFile) {
              // Process the selected image using the setUploadedFile function
              if (!selectedFile.type.startsWith("image/")) {
            
              }
              else{
              
                setUploadedFile({ target: { files: [selectedFile] } },questionId);
              }
             
            }
          };
       
        }
      };
      
      
      const setUploadedFile = async (event,questionId) => {
          const file = event.target.files[0];
          console.log("file",file)
         
          if (file) {
            const options = {
              maxSizeMB: 0.5,  // Reduce max size further to 0.5MB
              maxWidthOrHeight: 800,  // Reduce dimensions to 800px
              useWebWorker: true
            };
            try {
              const compressedFile = await imageCompression(file, options);
              if (compressedFile.size < 1000000) { // Ensure the file is less than 1MB
                await generateImagesArray(compressedFile, questionId);
              } else {
                console.error('Compressed image size is still too large.');
              }
            } catch (error) {
              console.error('Error compressing image:', error);
            }
          } else {
            // If no image is uploaded, reset the imagePath state
           // setImagePath('');
          }
        };
      
      
      const generateImagesArray = async (file,questionId) => {
        const imagesData = [];
        console.log("fileimage",file)
        console.log("fileimage",file.type)
        const reader = new FileReader();
        reader.onloadend = async () => {
          const imageType = file.type.split('/')[1];
          console.log("imageType",imageType)
          const imageBase64 = reader.result.split(',')[1];
          const imageName = `image_${Date.now()}.${imageType}`;
      
          console.log("imageBase6411",imageBase64)
          imagesData.push({
            imageName,
            imageBase64,
            imageType,
          });
          setImages(prevImages => ({ ...prevImages, [questionId]: `data:image/${imageType};base64,`+ imageBase64 }));
        
          // Call the upload function after processing the images
          //await uploadBlobImagesAPI(imagesData,file);
        };
       
      reader.readAsDataURL(file);
        console.log("imagesData",imagesData)
      }




    const generateUniqueImageName = (questionId) => {
        const timestamp = Date.now();
        return `image_${timestamp}_${questionId}.png`;
    };

    const handleRetake = (questionId) => {
        // Clear the previously captured image and allow the user to capture a new one
        setImages(prevImages => ({ ...prevImages, [questionId]: null }));
        handleCapture(questionId);
    };

    useEffect(() => {
        if (assessmenteditflg) {
            localStorage.setItem("assessmentIdcreate","");
            const assessmentId = localStorage.getItem("assessmentId");
            geteditdetails(assessmentId);
        }
    }, []);


    async function geteditdetails(assessmentId) {
        try {
            const response = await axios.get(`${ApiURL}/get_AssessmentByIds/${assessmentId}`,{headers: {
                Authorization: `Bearer ${access_token}`
              }});
            if (response.data) {
                const formData = JSON.parse(response.data.data.details);
                setSelectedValues(formData.selectedValues || {});
                setRemarks(formData.remarks || {});
    
                // Construct image paths for each image
                const updatedImages = {};
                for (const questionId in formData.images) {
                    if (formData.images.hasOwnProperty(questionId)) {
                        const imageName = formData.images[questionId];
                        const imagePath = `${ApiURL}/api/images/${imageName}`;// Replace this with your actual image directory path
                        updatedImages[questionId] = imagePath;
                    }
                }
                setImages(updatedImages);
    
                console.log("Updated Images", updatedImages);
            } else {
                console.log("No data found in the response");
            }
        } catch (error) {
            console.log("Error:", error);
            return null;
        }
    }
    


    const handleRemarksChange = (questionId, value) => {
        // Update selectedValues state
        setRemarks({ ...remarks, [questionId]: value });

        // Update formData state by merging selectedValues into the current step's data
        setFormData(prevFormData => ({
            ...prevFormData,
            [activeStep]: {
                ...prevFormData[activeStep],
                remarks: { ...prevFormData[activeStep].remarks, [questionId]: value }
            }
        }));
    };


   
    
    
   
   //old code
    const handleSubmitClick = async () => {
            const { isComplete, unselectedQuestions } = handleFinalSubmit();
           
    
        if (isComplete) {

            try {
                
                const assessmentData = {status_flg :"C" }
                let assessmentId = localStorage.getItem("assessmentIdcreate");
            
               console.log(assessmentId,"assessmentId");
           //     console.log("assessmentIdfinal",assessmentId,localStorage.getItem("assessmentId"));
                if(assessmentId == ""){
                     assessmentId=localStorage.getItem("assessmentId");
                    console.log("assessmentIdfinalsubmit",assessmentId);
                }
                    
                const response = await axios.post(`${ApiURL}/finalsubmit_update/${assessmentId}`, { assessmentData: assessmentData },{headers: {
                    Authorization: `Bearer ${access_token}`
                  }});
                  console.log("responsedata",response)
                  if (response && response.data) {

                    const fetchResponse = await axios.get(`${ApiURL}/get_AssessmentByIds/${assessmentId}`, {
                        headers: {
                            Authorization: `Bearer ${access_token}`
                        }
                    });
                    // Assuming response.data contains the assessment details like ass_no and created_by
                    if (fetchResponse && fetchResponse.data.success) {
                    const { ass_no, created_by,id, auditor_name, group_leader, wcm_cooridnator} = fetchResponse.data.data;
    console.log("fetchResponse.data.data",fetchResponse.data.data)
                    // Now store the assessment data dynamically in localStorage
                    const dynamicAssessmentData = {
                        ass_no: ass_no || 'N/A',
                        created_by: created_by || 'Unknown',
                        id:id || 'N/A'
                    };
                    localStorage.setItem('assessmentData', JSON.stringify(dynamicAssessmentData));
    
                    const emailPromises = [
                        axios.get(`${ApiURL}/getUserEmail/${auditor_name}`, { headers: { Authorization: `Bearer ${access_token}` } }),
                        axios.get(`${ApiURL}/getUserEmail/${group_leader}`, { headers: { Authorization: `Bearer ${access_token}` } }),
                        axios.get(`${ApiURL}/getUserEmail/${wcm_cooridnator}`, { headers: { Authorization: `Bearer ${access_token}` } })
                    ];
    
                    const emailResponses = await Promise.allSettled(emailPromises);
    console.log("emailResponsesemailResponses",emailResponses)
                    // Extract emails from the responses
                    const from_email = emailResponses[0].status === "fulfilled" ? emailResponses[0].value.data.email.email : null;
const groupLeaderEmail = emailResponses[1].status === "fulfilled" ? emailResponses[1].value.data.email.email : null;
const coordinatorEmail = emailResponses[2].status === "fulfilled" ? emailResponses[2].value.data.email.email : null;
                    // const from_email = emailResponses[0]?.data?.email?.email; // Auditor's email
                    // const groupLeaderEmail = emailResponses[1]?.data?.email?.email; // Group leader's email
                    // const coordinatorEmail = emailResponses[2]?.data?.email?.email; // Coordinator's email
    console.log("from_email",from_email)
                    // Prepare to send the email
                    const to_email = {
                        group_leader: groupLeaderEmail || null,
                        wcm_cooridnator: coordinatorEmail || null
                    };
                    const filteredToEmails = Object.values(to_email).filter(email => email !== null);
                    console.log("to_email",to_email)
                    console.log("filteredToEmails",filteredToEmails)
                    const auditorDetails = emailResponses[0].status === "fulfilled" ? emailResponses[0].value.data.email : null;
                    const groupLeaderDetails = emailResponses[1].status === "fulfilled" ? emailResponses[1].value.data.email : null;
                    const coordinatorDetails = emailResponses[2].status === "fulfilled" ? emailResponses[2].value.data.email : null;

                    const auditorFullName = auditorDetails ? `${auditorDetails.firstName} ${auditorDetails.lastName}` : 'N/A';
                    const groupLeaderFullName = groupLeaderDetails ? `${groupLeaderDetails.firstName} ${groupLeaderDetails.lastName}` : 'N/A';
                    const coordinatorFullName = coordinatorDetails ? `${coordinatorDetails.firstName} ${coordinatorDetails.lastName}` : 'N/A';
//                     const auditorDetails = emailResponses[0]?.data?.email;
// const groupLeaderDetails = emailResponses[1]?.data?.email;
// const coordinatorDetails = emailResponses[2]?.data?.email;

// const auditorFullName = `${auditorDetails?.firstName || ''} ${auditorDetails?.lastName || ''}`;
// const groupLeaderFullName = `${groupLeaderDetails?.firstName || ''} ${groupLeaderDetails?.lastName || ''}`;
// const coordinatorFullName = `${coordinatorDetails?.firstName || ''} ${coordinatorDetails?.lastName || ''}`;

localStorage.setItem('auditorFullName', auditorFullName);
localStorage.setItem('groupLeaderFullName', groupLeaderFullName);
localStorage.setItem('coordinatorFullName', coordinatorFullName);
                    // Trigger success alert dialog
                    AlertDialog({
                        type: 'success',
                        title: 'Success',
                        text: 'Assessment Completed successfully.'
                    });
    
                    console.log('Assessment created:', response.data);
                    navigate('/auditAssessment', { state: { message: "Audit is complete" } });
    
                    if (from_email) {
                        await handleSend(from_email, filteredToEmails); // Proceed with sending only if valid emails exist
                    }
                    // await handleSend(from_email, to_email);
                }
                }

                //    AlertDialog({
                //      type: 'success',
                //      title: 'success',
                //      text: 'Assessment Completed successfully.',
                   
           
                //    });

                

                // console.log('Assessment created:', response.data);
                // navigate('/auditAssessment', { state: { message: "Audit is complete" } });

                // const from_email = "sonapriya.s@saint-gobain.com"; // Replace with actual sender email
                // const to_email = "sonapriya.s@saint-gobain.com"; // Replace with actual recipient email
    
                // // Call handleSend function with defined from_email and to_email
                // await handleSend(from_email, to_email);
                // navigate('/audit', { state: { assessmentData: assessmentData } });
    
            } catch (error) {
                console.error('Error creating assessment:', error);
                // Handle error, update UI, etc.
            }


        } 
        
        else {
            // Form is incomplete, display error message or navigate with unselectedQuestions
            setSnackbarMessage("Audit is incomplete. Please complete all questions.");
            setSnackbarOpen(true);
            navigate('/auditAssessment', { state: { message: "Audit is incomplete. Please complete all questions.", unselectedQuestions } });
        }
    }
    
  
    

    const handleFinalSubmit = () => {
        let isComplete = true; // Assume the audit is complete initially
        let unselectedQuestions = {}; // Object to store unselected questions with step information
        
        // Define an array of objects containing step information
        const steps = [
            { stepNumber: 0, questions: ['question1', 'question2', 'question3'] },
            { stepNumber: 1, questions: [ 'question4', 'question5', 'question6', 'question7','question8','question9', 'question10',
            'question11', 'question12', 'question13', 'question14'] },
            { stepNumber: 2, questions: ['question15', 'question16','question17','question18', 'question19', 'question20'] },
            { stepNumber: 3, questions: ['question21', 'question22','question23', 'question24', 'question25'] },
            { stepNumber: 4, questions: ['question26', 'question27','question28', 'question29'] },
            { stepNumber: 5, questions: ['question30', 'question31', 'question32','question33', 'question34', 'question35', 'question36'] },
          
            // Add more steps as needed
        ];
    
        // Merge unselected questions from all steps into a single array
        const allSelectedValues = [];
        for (let step in formData) {
            if (formData.hasOwnProperty(step) && formData[step].selectedValues) {
                allSelectedValues.push(...Object.keys(formData[step].selectedValues));
            }
        }
    
        // Find unselected questions and their corresponding steps
        steps.forEach(step => {
            const unselectedInStep = step.questions.filter(question => !allSelectedValues.includes(question));
            console.log("unselectedInStep",unselectedInStep)

            const numbersOnly = unselectedInStep.map(item => item.replace(/\D/g, ''));

            if (numbersOnly.length > 0) {
                isComplete = false; // Set isComplete to false if any unselected questions are found
                unselectedQuestions[`Step ${step.stepNumber}`] =  numbersOnly;
                console.log(unselectedQuestions[`Step ${step.stepNumber}`] );
            }
        });
    
        // Log audit status
        if (isComplete) {
           
            console.log("Audit is complete");
        } else {
            console.log("Audit is incomplete. Please complete all questions.");
        }
    
        // Return the result
        return { isComplete, unselectedQuestions };
    };
    


// Assuming formData is your object containing form data entries
const handleSaveClick = () => {
    if (!loading) {
    const newErrors = {};
    let functionEntered = false; 
    if (selectedValues['question30'] === "0") {
        // If radio button value is "0", both image and remarks fields are mandatory
        if (!images['question30']) {
            newErrors["question30"] = "Please capture an image.";
        }
        if (!remarks['question30']) {
            newErrors["question30"] = "Please provide remarks.";
        }
        functionEntered = true; 
    }
      if (selectedValues['question30'] === "1") {
        // If radio button value is "1", only remarks field is mandatory
        if (!remarks['question30']) {
            newErrors["question30"] = "Please provide remarks.";
        }
        functionEntered = true; 
    }

    if (selectedValues['question31'] === "0") {
        // If radio button value is "0", both image and remarks fields are mandatory
        if (!images['question31']) {
            newErrors["question31"] = "Please capture an image.";
        }
        if (!remarks['question31']) {
            newErrors["question31"] = "Please provide remarks.";
        }
        functionEntered = true; 
    }
      if (selectedValues['question31'] === "1") {
        // If radio button value is "1", only remarks field is mandatory
        if (!remarks['question31']) {
            newErrors["question31"] = "Please provide remarks.";
        }
        functionEntered = true; 
    }

        if (selectedValues['question32'] === "0") {
            // If radio button value is "0", both image and remarks fields are mandatory
            if (!images['question32']) {
                newErrors["question32"] = "Please capture an image.";
            }
            if (!remarks['question32']) {
                newErrors["question32"] = "Please provide remarks.";
            }
            functionEntered = true; 
        }
          if (selectedValues['question32'] === "1") {
            // If radio button value is "1", only remarks field is mandatory
            if (!remarks['question32']) {
                newErrors["question32"] = "Please provide remarks.";
            }
            functionEntered = true; 
        }
            if (selectedValues['question33'] === "0") {
                // If radio button value is "0", both image and remarks fields are mandatory
                if (!images['question33']) {
                    newErrors["question33"] = "Please capture an image.";
                }
                if (!remarks['question33']) {
                    newErrors["question33"] = "Please provide remarks.";
                }
                functionEntered = true; 
            }
              if (selectedValues['question33'] === "1") {
                // If radio button value is "1", only remarks field is mandatory
                if (!remarks['question33']) {
                    newErrors["question33"] = "Please provide remarks.";
                }
                functionEntered = true; 
            }
            if (selectedValues['question34'] === "0") {
                // If radio button value is "0", both image and remarks fields are mandatory
                if (!images['question34']) {
                    newErrors["question34"] = "Please capture an image.";
                }
                if (!remarks['question34']) {
                    newErrors["question34"] = "Please provide remarks.";
                }
                functionEntered = true; 
            }
              if (selectedValues['question34'] === "1") {
                // If radio button value is "1", only remarks field is mandatory
                if (!remarks['question34']) {
                    newErrors["question34"] = "Please provide remarks.";
                }
                functionEntered = true; 
            }
            if (selectedValues['question35'] === "0") {
                // If radio button value is "0", both image and remarks fields are mandatory
                if (!images['question35']) {
                    newErrors["question35"] = "Please capture an image.";
                }
                if (!remarks['question35']) {
                    newErrors["question35"] = "Please provide remarks.";
                }
                functionEntered = true; 
            }
              if (selectedValues['question35'] === "1") {
                // If radio button value is "1", only remarks field is mandatory
                if (!remarks['question35']) {
                    newErrors["question35"] = "Please provide remarks.";
                }
                functionEntered = true; 
            }
            if (selectedValues['question36'] === "0") {
                // If radio button value is "0", both image and remarks fields are mandatory
                if (!images['question36']) {
                    newErrors["question36"] = "Please capture an image.";
                }
                if (!remarks['question36']) {
                    newErrors["question36"] = "Please provide remarks.";
                }
                functionEntered = true; 
            }
              if (selectedValues['question36'] === "1") {
                // If radio button value is "1", only remarks field is mandatory
                if (!remarks['question36']) {
                    newErrors["question36"] = "Please provide remarks.";
                }
                functionEntered = true; 
            }
               
    
    setErrors(newErrors);

    // If there are errors, display Snackbar and prevent moving to the next step
    if (Object.keys(newErrors).length > 0) {
        setSnackbarMessage("Please fill the questions.");
        setSnackbarOpen(true);
    } 

    if (!functionEntered) {
        setSnackbarMessage("Please fill at least one field.");              
        setSnackbarOpen(true);
       // newErrors["general"] = "Please fill at least one field.";
    }
 
    else {
        if (selectedValues['question30'] === "0") {
            // If radio button value is "0", both image and remarks fields are mandatory
            if (!images['question30']) {
                newErrors["question30"] = "Please capture an image.";
            }
            if (!remarks['question30']) {
                newErrors["question30"] = "Please provide remarks.";
            }
         
        }
          if (selectedValues['question30'] === "1") {
            // If radio button value is "1", only remarks field is mandatory
            if (!remarks['question30']) {
                newErrors["question30"] = "Please provide remarks.";
            }
         
        }
    
        if (selectedValues['question31'] === "0") {
            // If radio button value is "0", both image and remarks fields are mandatory
            if (!images['question31']) {
                newErrors["question31"] = "Please capture an image.";
            }
            if (!remarks['question31']) {
                newErrors["question31"] = "Please provide remarks.";
            }
          
        }
          if (selectedValues['question31'] === "1") {
            // If radio button value is "1", only remarks field is mandatory
            if (!remarks['question31']) {
                newErrors["question31"] = "Please provide remarks.";
            }
          
        }
    
            if (selectedValues['question32'] === "0") {
                // If radio button value is "0", both image and remarks fields are mandatory
                if (!images['question32']) {
                    newErrors["question32"] = "Please capture an image.";
                }
                if (!remarks['question32']) {
                    newErrors["question32"] = "Please provide remarks.";
                }
               
            }
              if (selectedValues['question32'] === "1") {
                // If radio button value is "1", only remarks field is mandatory
                if (!remarks['question32']) {
                    newErrors["question32"] = "Please provide remarks.";
                }
                
            }
                if (selectedValues['question33'] === "0") {
                    // If radio button value is "0", both image and remarks fields are mandatory
                    if (!images['question33']) {
                        newErrors["question33"] = "Please capture an image.";
                    }
                    if (!remarks['question33']) {
                        newErrors["question33"] = "Please provide remarks.";
                    }
                   
                }
                  if (selectedValues['question33'] === "1") {
                    // If radio button value is "1", only remarks field is mandatory
                    if (!remarks['question33']) {
                        newErrors["question33"] = "Please provide remarks.";
                    }
                  
                }
                if (selectedValues['question34'] === "0") {
                    // If radio button value is "0", both image and remarks fields are mandatory
                    if (!images['question34']) {
                        newErrors["question34"] = "Please capture an image.";
                    }
                    if (!remarks['question34']) {
                        newErrors["question34"] = "Please provide remarks.";
                    }
                
                }
                  if (selectedValues['question34'] === "1") {
                    // If radio button value is "1", only remarks field is mandatory
                    if (!remarks['question34']) {
                        newErrors["question34"] = "Please provide remarks.";
                    }
                
                }
                if (selectedValues['question35'] === "0") {
                    // If radio button value is "0", both image and remarks fields are mandatory
                    if (!images['question35']) {
                        newErrors["question35"] = "Please capture an image.";
                    }
                    if (!remarks['question35']) {
                        newErrors["question35"] = "Please provide remarks.";
                    }
                  
                }
                  if (selectedValues['question35'] === "1") {
                    // If radio button value is "1", only remarks field is mandatory
                    if (!remarks['question35']) {
                        newErrors["question35"] = "Please provide remarks.";
                    }
                  
                }
                if (selectedValues['question36'] === "0") {
                    // If radio button value is "0", both image and remarks fields are mandatory
                    if (!images['question36']) {
                        newErrors["question36"] = "Please capture an image.";
                    }
                    if (!remarks['question36']) {
                        newErrors["question36"] = "Please provide remarks.";
                    }
                  
                }
                  if (selectedValues['question36'] === "1") {
                    // If radio button value is "1", only remarks field is mandatory
                    if (!remarks['question36']) {
                        newErrors["question36"] = "Please provide remarks.";
                    }
                 
                }
                   
        
        setErrors(newErrors);
    
        // If there are errors, display Snackbar and prevent moving to the next step
        if (Object.keys(newErrors).length > 0) {
            setSnackbarMessage("Please fill the questions.");
            setSnackbarOpen(true);
        } 
    

else{
    onSave(selectedValues, images, remarks);
}
    }
   
    }
  };


    
    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };
    
    const handleRadioChange = (questionId, value) => {
        // Update selectedValues state
        setSelectedValues({ ...selectedValues, [questionId]: value });

        // Update formData state by merging selectedValues into the current step's data
        setFormData(prevFormData => ({
            ...prevFormData,
            [activeStep]: {
                ...prevFormData[activeStep],
                selectedValues: { ...prevFormData[activeStep].selectedValues, [questionId]: value }
            }
        }));
    };
   
 
    
    return (
        <Box>

      
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            {/* Question 1 */}
            <Card variant="outlined" style={{ marginBottom: '20px' }}>
                <CardContent>
                    {/* <Typography variant="h6" style={{ textTransform: 'uppercase',fontSize: "16px",  fontWeight: '600', color: '#17428B', marginBottom: '10px' }}>1S: Sort(seiri)</Typography>
            <p style={{ marginBottom: '10px',fontSize: "10px",fontWeight: '500' }}>Tidiness, Organization. Refers To The Practice Of Sorting Through All The Tools, Materials, Etc., In The Work Area And Keeping Only Essential Items.</p> */}
                    <Typography variant="h5" style={{ textTransform: 'uppercase', fontSize: "14px", fontWeight: '500', color: '#17428B', marginBottom: '10px' }}>Emergency exits</Typography>
                    <div>
                        <p style={{ marginBottom: '10px', fontSize: "14px", fontWeight: '500' }}>30. The emergency exits are not blocked.The fire-exit doors are not open.Roller Shutter doors which should be closed, are closed.</p>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                            <p style={{ marginRight: '10px', fontSize: "14px", fontWeight: '500' }}>SCORE:</p>
                            <label>
                                <input
                                    type="radio"
                                    name="radioButton30"
                                    value="0"
                                    checked={selectedValues['question30'] === "0"}
                                    // checked={formData.question1 === "0"}
                                    // onChange={(e) => setFormData({ ...formData, question1: e.target.value })}
                                    onChange={(e) => handleRadioChange('question30', e.target.value)}

                                /> 0
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="radioButton30"
                                    value="1"
                                    checked={selectedValues['question30'] === "1"}
                                    // checked={formData.question30 === "1"}
                                    // onChange={(e) => setFormData({ ...formData, question30: e.target.value })}
                                    onChange={(e) => handleRadioChange('question30', e.target.value)}

                                /> 1
                            </label>
                        </div>
                    </div>

                    {selectedValues.question30 === "0" && (
                        <div>

                            <div id="camera-container-question30" style={{ border: "1px dashed #e6e6e6", height: '160px',textAlign:'center', marginBottom: '20px', position: 'relative', borderRadius:'10px' }}>
                                {!images['question30'] ? (
                                    <>
                                        {/* Camera button */}
                                        <input
                                            id="upload-image_question30"
                                            multiple
                                            type="file"
                                            style={{ display: "none" }}
                                            // onChange={setUploadedFile}
                                            ref={fileInputRef}
                                        />
                                        <button onClick={() => handleCapture('question30')} onChange={(e) => setFormData({ ...selectedValues, image30: e.target.value })} style={{ position: 'absolute', cursor: 'pointer', background: 'none', border: 'none', width: '100%', height: '100%',left:'0',top:'0' }}>
                                        </button>
                                        <LogoutIcon sx={{
                                            transform: 'rotate(-90deg)',
                                            color: '#C9C9CC',
                                            margin:'30px 0 10px'
                                        }} />
                                        <Typography style={{ textAlign: 'center', color: 'black', fontWeight: 500, fontSize: '16px' }}>Drag & drop images or <span style={{ color: '#17428B' }}>browse images</span></Typography>
                                        {/* <Typography style={{ textAlign: 'center', color: '#4F4D55', fontSize: '12px', marginTop: '10px' }}>.xlsx - max file size 2MB</Typography> */}
                                    </>
                                ) : (
                                    <>
                                        {/* Display captured image */}
                                        <img src={images['question30']} onChange={(e) => setFormData({ ...selectedValues, image30: e.target.value })} alt="Captured" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                                        <div style={{ position: 'absolute', bottom: '10px', left: '50%', transform: 'translateX(-50%)', zIndex: 1 }}>
                                            {/* Retake button */}
                                            <Button variant="contained" onClick={() => handleRetake('question30')}>Retake</Button>
                                        </div>
                                    </>
                                )}</div>
                        </div>
                    )}



                    <div>
                        <p style={{ marginBottom: '10px', fontSize: "14px", fontWeight: '500' }}>Remarks for Improvement</p>
                        {/* <textarea value={remarks["question30"] || ''} onChange={(e) => handleChange('remarks', 'question30', e.target.value)} placeholder="Type here" style={{ width: '100%', padding: '8px', border: '1px solid #E6E6E6', borderRadius: '4px' }} /> */}
                        <textarea value={remarks["question30"] || ''} onChange={(e) => handleRemarksChange('question30', e.target.value)} placeholder="Type here" style={{ width: '100%', padding: '8px', outline: 'none', border: '1px solid #E6E6E6', borderRadius: '4px', resize: "none" }} />
                    </div>
                    {errors["question30"] && <Typography style={{ color: 'red' }}>{errors["question30"]}</Typography>}
                </CardContent>
            </Card>
            {/* Question 2 */}
            <Card variant="outlined" style={{ marginBottom: '20px' }}>
                <CardContent>
                    <Typography variant="h5" style={{ textTransform: 'uppercase', fontSize: "14px", fontWeight: '500', color: '#17428B', marginBottom: '10px' }}>Fire extinguishers</Typography>
                    <div>
                        <p style={{ marginBottom: '10px', fontSize: "14px", fontWeight: '500' }}>31. The Fire extinguishers and hoses are visible and easily accessible, in working order, and in test (functionnality reviewed regularly).</p>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                            <p style={{ marginRight: '10px', fontSize: "14px", fontWeight: '500' }}>SCORE:</p>
                            <label>
                                <input
                                    type="radio"
                                    name="radioButton31"
                                    value="0"
                                    checked={selectedValues['question31'] === "0"}
                                    // checked={formData.question2 === "0"}
                                    // onChange={(e) => setFormData({ ...formData, question2: e.target.value })}
                                    onChange={(e) => handleRadioChange('question31', e.target.value)}


                                /> 0
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="radioButton31"
                                    value="1"
                                    checked={selectedValues['question31'] === "1"}
                                    // checked={formData.question31 === "1"}
                                    // onChange={(e) => setFormData({ ...formData, question31: e.target.value })}
                                    onChange={(e) => handleRadioChange('question31', e.target.value)}


                                /> 1
                            </label>
                        </div>
                    </div>
                    {/* </div> */}

                    {selectedValues.question31 === "0" && (
                        <div>
                            <div id="camera-container-question31" style={{ border: "1px dashed #e6e6e6", height: '160px',textAlign:'center', marginBottom: '20px', position: 'relative', borderRadius:'10px' }}>
                                {!images['question31'] ? (
                                    <>
                                        {/* Camera button */}
                                        <input
                                            id="upload-image_question31"
                                            multiple
                                            type="file"
                                            style={{ display: "none" }}
                                            // onChange={setUploadedFile}
                                            ref={fileInputRef}
                                        />
                                        <button onClick={() => handleCapture('question31')} onChange={(e) => setFormData({ ...selectedValues, image31: e.target.value })} style={{ position: 'absolute', cursor: 'pointer', background: 'none',left:'0',top:'0', border: 'none', width: '100%', height: '100%' }}>

                                        </button>
                                        <LogoutIcon sx={{
                                            transform: 'rotate(-90deg)',
                                            color: '#C9C9CC',
                                            margin:'30px 0 10px'
                                        }} />
                                        <Typography style={{ textAlign: 'center', color: 'black', fontWeight: 500, fontSize: '16px' }}>Drag & drop images or <span style={{ color: '#17428B' }}>browse images</span></Typography>
                                        <Typography style={{ textAlign: 'center', color: '#4F4D55', fontSize: '12px', marginTop: '10px' }}>.xlsx - max file size 2MB</Typography>
                                    </>
                                ) : (
                                    <>
                                        {/* Display captured image */}
                                        <img src={images['question31']} alt="Captured" onChange={(e) => setFormData({ ...selectedValues, image31: e.target.value })} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                                        <div style={{ position: 'absolute', bottom: '10px', left: '50%', transform: 'translateX(-50%)', zIndex: 1 }}>
                                            {/* Retake button */}
                                            <Button variant="contained" onClick={() => handleRetake('question31')}>Retake</Button>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    )}


                    {/* Remarks */}

                    <div>
                        <p style={{ marginBottom: '10px', fontSize: "14px", fontWeight: '500' }}>Remarks for Improvement</p>
                        {/* <textarea value={remarks["question30"] || ''} onChange={(e) => handleChange('remarks', 'question30', e.target.value)} placeholder="Type here" style={{ width: '100%', padding: '8px', border: '1px solid #E6E6E6', borderRadius: '4px' }} /> */}
                        <textarea value={remarks["question31"] || ''} onChange={(e) => handleRemarksChange('question31', e.target.value)} placeholder="Type here" style={{ width: '100%', padding: '8px', outline: 'none', border: '1px solid #E6E6E6', borderRadius: '4px', resize: "none" }} />
                    </div>

                    {errors["question31"] && <Typography style={{ color: 'red' }}>{errors["question31"]}</Typography>}
                </CardContent>
            </Card>

            {/* Question 3 */}
            <Card variant="outlined" style={{ marginBottom: '20px' }}>
                <CardContent>
                    <Typography variant="h5" style={{ textTransform: 'uppercase', fontSize: "14px", fontWeight: '500', color: '#17428B', marginBottom: '10px' }}>
                    Safety behaviour
                    </Typography>
                    <div>
                        <p style={{ marginBottom: '10px', fontSize: "14px", fontWeight: '500' }}>32. No observed unsafe acts. Adherence to PPE requirements. Awareness of unsafe conditions.</p>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                            <p style={{ marginRight: '10px', fontSize: "14px", fontWeight: '500' }}>SCORE:</p>
                            <label>
                                <input
                                    type="radio"
                                    name="radioButton32"
                                    value="0"
                                    checked={selectedValues['question32'] === "0"}
                                    // checked={formData.question32 === "0"}
                                    // onChange={(e) => setFormData({ ...formData, question32: e.target.value })}
                                    onChange={(e) => handleRadioChange('question32', e.target.value)}

                                /> 0
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="radioButton32"
                                    value="1"
                                    checked={selectedValues['question32'] === "1"}
                                    // checked={formData.question32 === "1"}
                                    // onChange={(e) => setFormData({ ...formData, question32: e.target.value })}
                                    onChange={(e) => handleRadioChange('question32', e.target.value)}


                                /> 1
                            </label>
                        </div>
                    </div>

                    {console.log(images['question32'])}
                    {selectedValues.question32 === "0" && (
                        <div>

                            <div id="camera-container-question32" style={{ border: "1px dashed #e6e6e6", height: '160px',textAlign:'center', marginBottom: '20px', position: 'relative', borderRadius:'10px' }}>
                                {!images['question32'] ? (
                                    <>
                                        {/* Camera button */}
                                        <input
                                            id="upload-image_question32"
                                            multiple
                                            type="file"
                                            style={{ display: "none" }}
                                            // onChange={setUploadedFile}
                                            ref={fileInputRef}
                                        />
                                        <button onClick={() => handleCapture('question32')} onChange={(e) => setFormData({ ...selectedValues, image32: e.target.value })} style={{ position: 'absolute', cursor: 'pointer', background: 'none', border: 'none', width: '100%', height: '100%',left:'0',top:'0' }}>
                                        </button>
                                        <LogoutIcon sx={{
                                            transform: 'rotate(-90deg)',
                                            color: '#C9C9CC',
                                            margin:'30px 0 10px'
                                        }} />
                                        <Typography style={{ textAlign: 'center', color: 'black', fontWeight: 500, fontSize: '16px' }}>Drag & drop images or <span style={{ color: '#17428B' }}>browse images</span></Typography>
                                        <Typography style={{ textAlign: 'center', color: '#4F4D55', fontSize: '12px', marginTop: '10px' }}>.xlsx - max file size 2MB</Typography>
                                    </>
                                ) : (
                                    <>
                                        {/* Display captured image */}
                                        <img src={images['question32']} onChange={(e) => setFormData({ ...selectedValues, image32: e.target.value })} alt="Captured" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                                        <div style={{ position: 'absolute', bottom: '10px', left: '50%', transform: 'translateX(-50%)', zIndex: 1 }}>
                                            {/* Retake button */}
                                            <Button variant="contained" onClick={() => handleRetake('question32')}>Retake</Button>
                                        </div>
                                    </>
                                )}</div>
                        </div>
                    )}



                    <div>
                        <p style={{ marginBottom: '10px', fontSize: "14px", fontWeight: '500' }}>Remarks for Improvement</p>
                        {/* <textarea value={remarks["question320"] || ''} onChange={(e) => handleChange('remarks', 'question320', e.target.value)} placeholder="Type here" style={{ width: '100%', padding: '8px', border: '1px solid #E6E6E6', borderRadius: '4px' }} /> */}
                        <textarea value={remarks["question32"] || ''} onChange={(e) => handleRemarksChange('question32', e.target.value)} placeholder="Type here" style={{ width: '100%', padding: '8px', outline: 'none', border: '1px solid #E6E6E6', borderRadius: '4px', resize: "none" }} />
                    </div>
                    {errors["question32"] && <Typography style={{ color: 'red' }}>{errors["question32"]}</Typography>}
                </CardContent>
            </Card>

    {/* Question 33 */}
    <Card variant="outlined" style={{ marginBottom: '20px' }}>
                <CardContent>
                    <Typography variant="h5" style={{ textTransform: 'uppercase', fontSize: "14px", fontWeight: '500', color: '#17428B', marginBottom: '10px' }}>
                    Safety Barrier
                    </Typography>
                    <div>
                        <p style={{ marginBottom: '10px', fontSize: "14px", fontWeight: '500' }}>33. All safety barriers are in working order and are effective.</p>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                            <p style={{ marginRight: '10px', fontSize: "14px", fontWeight: '500' }}>SCORE:</p>
                            <label>
                                <input
                                    type="radio"
                                    name="radioButton33"
                                    value="0"
                                    checked={selectedValues['question33'] === "0"}
                                    // checked={formData.question32 === "0"}
                                    // onChange={(e) => setFormData({ ...formData, question32: e.target.value })}
                                    onChange={(e) => handleRadioChange('question33', e.target.value)}

                                /> 0
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="radioButton33"
                                    value="1"
                                    checked={selectedValues['question33'] === "1"}
                                    // checked={formData.question32 === "1"}
                                    // onChange={(e) => setFormData({ ...formData, question32: e.target.value })}
                                    onChange={(e) => handleRadioChange('question33', e.target.value)}


                                /> 1
                            </label>
                        </div>
                    </div>

                    {console.log(images['question33'])}
                    {selectedValues.question33 === "0" && (
                        <div>

                            <div id="camera-container-question33" style={{ border: "1px dashed #e6e6e6", height: '160px',textAlign:'center', marginBottom: '20px', position: 'relative', borderRadius:'10px' }}>
                                {!images['question33'] ? (
                                    <>
                                        {/* Camera button */}
                                        <input
                                            id="upload-image_question33"
                                            multiple
                                            type="file"
                                            style={{ display: "none" }}
                                            // onChange={setUploadedFile}
                                            ref={fileInputRef}
                                        />
                                        <button onClick={() => handleCapture('question33')} onChange={(e) => setFormData({ ...selectedValues, image33: e.target.value })} style={{ position: 'absolute', cursor: 'pointer', background: 'none', border: 'none', width: '100%', height: '100%',left:'0',top:'0' }}>
                                        </button>
                                        <LogoutIcon sx={{
                                            transform: 'rotate(-90deg)',
                                            color: '#C9C9CC',
                                            margin:'30px 0 10px'
                                        }} />
                                        <Typography style={{ textAlign: 'center', color: 'black', fontWeight: 500, fontSize: '16px' }}>Drag & drop images or <span style={{ color: '#17428B' }}>browse images</span></Typography>
                                        <Typography style={{ textAlign: 'center', color: '#4F4D55', fontSize: '12px', marginTop: '10px' }}>.xlsx - max file size 2MB</Typography>
                                    </>
                                ) : (
                                    <>
                                        {/* Display captured image */}
                                        <img src={images['question33']} onChange={(e) => setFormData({ ...selectedValues, image33: e.target.value })} alt="Captured" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                                        <div style={{ position: 'absolute', bottom: '10px', left: '50%', transform: 'translateX(-50%)', zIndex: 1 }}>
                                            {/* Retake button */}
                                            <Button variant="contained" onClick={() => handleRetake('question33')}>Retake</Button>
                                        </div>
                                    </>
                                )}</div>
                        </div>
                    )}



                    <div>
                        <p style={{ marginBottom: '10px', fontSize: "14px", fontWeight: '500' }}>Remarks for Improvement</p>
                        {/* <textarea value={remarks["question320"] || ''} onChange={(e) => handleChange('remarks', 'question320', e.target.value)} placeholder="Type here" style={{ width: '100%', padding: '8px', border: '1px solid #E6E6E6', borderRadius: '4px' }} /> */}
                        <textarea value={remarks["question33"] || ''} onChange={(e) => handleRemarksChange('question33', e.target.value)} placeholder="Type here" style={{ width: '100%', padding: '8px', outline: 'none', border: '1px solid #E6E6E6', borderRadius: '4px', resize: "none" }} />
                    </div>
                    {errors["question33"] && <Typography style={{ color: 'red' }}>{errors["question33"]}</Typography>}
                </CardContent>
            </Card>

               {/* Question 34 */}
    <Card variant="outlined" style={{ marginBottom: '20px' }}>
                <CardContent>
                    <Typography variant="h5" style={{ textTransform: 'uppercase', fontSize: "14px", fontWeight: '500', color: '#17428B', marginBottom: '10px' }}>
                    Safety deficiencies
                    </Typography>
                    <div>
                        <p style={{ marginBottom: '10px', fontSize: "14px", fontWeight: '500' }}>34. There are no noticeable safety deficiencies i.e poor lighting, lack of permit to work system as applicable etc.</p>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                            <p style={{ marginRight: '10px', fontSize: "14px", fontWeight: '500' }}>SCORE:</p>
                            <label>
                                <input
                                    type="radio"
                                    name="radioButton34"
                                    value="0"
                                    checked={selectedValues['question34'] === "0"}
                                    // checked={formData.question32 === "0"}
                                    // onChange={(e) => setFormData({ ...formData, question32: e.target.value })}
                                    onChange={(e) => handleRadioChange('question34', e.target.value)}

                                /> 0
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="radioButton34"
                                    value="1"
                                    checked={selectedValues['question34'] === "1"}
                                    // checked={formData.question32 === "1"}
                                    // onChange={(e) => setFormData({ ...formData, question32: e.target.value })}
                                    onChange={(e) => handleRadioChange('question34', e.target.value)}


                                /> 1
                            </label>
                        </div>
                    </div>

                    {console.log(images['question34'])}
                    {selectedValues.question34 === "0" && (
                        <div>

                            <div id="camera-container-question34" style={{ border: "1px dashed #e6e6e6", height: '160px',textAlign:'center', marginBottom: '20px', position: 'relative', borderRadius:'10px' }}>
                                {!images['question34'] ? (
                                    <>
                                        {/* Camera button */}
                                        <input
                                            id="upload-image_question34"
                                            multiple
                                            type="file"
                                            style={{ display: "none" }}
                                            // onChange={setUploadedFile}
                                            ref={fileInputRef}
                                        />
                                        <button onClick={() => handleCapture('question34')} onChange={(e) => setFormData({ ...selectedValues, image34: e.target.value })} style={{ position: 'absolute', cursor: 'pointer', background: 'none', border: 'none', width: '100%', height: '100%',left:'0',top:'0' }}>
                                        </button>
                                        <LogoutIcon sx={{
                                            transform: 'rotate(-90deg)',
                                            color: '#C9C9CC',
                                            margin:'30px 0 10px'
                                        }} />
                                        <Typography style={{ textAlign: 'center', color: 'black', fontWeight: 500, fontSize: '16px' }}>Drag & drop images or <span style={{ color: '#17428B' }}>browse images</span></Typography>
                                        <Typography style={{ textAlign: 'center', color: '#4F4D55', fontSize: '12px', marginTop: '10px' }}>.xlsx - max file size 2MB</Typography>
                                    </>
                                ) : (
                                    <>
                                        {/* Display captured image */}
                                        <img src={images['question34']} onChange={(e) => setFormData({ ...selectedValues, image34: e.target.value })} alt="Captured" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                                        <div style={{ position: 'absolute', bottom: '10px', left: '50%', transform: 'translateX(-50%)', zIndex: 1 }}>
                                            {/* Retake button */}
                                            <Button variant="contained" onClick={() => handleRetake('question34')}>Retake</Button>
                                        </div>
                                    </>
                                )}</div>
                        </div>
                    )}



                    <div>
                        <p style={{ marginBottom: '10px', fontSize: "14px", fontWeight: '500' }}>Remarks for Improvement</p>
                        {/* <textarea value={remarks["question320"] || ''} onChange={(e) => handleChange('remarks', 'question320', e.target.value)} placeholder="Type here" style={{ width: '100%', padding: '8px', border: '1px solid #E6E6E6', borderRadius: '4px' }} /> */}
                        <textarea value={remarks["question34"] || ''} onChange={(e) => handleRemarksChange('question34', e.target.value)} placeholder="Type here" style={{ width: '100%', padding: '8px', outline: 'none', border: '1px solid #E6E6E6', borderRadius: '4px', resize: "none" }} />
                    </div>
                    {errors["question34"] && <Typography style={{ color: 'red' }}>{errors["question34"]}</Typography>}
                </CardContent>
            </Card>


               {/* Question 35 */}
               <Card variant="outlined" style={{ marginBottom: '20px' }}>
                <CardContent>
                    <Typography variant="h5" style={{ textTransform: 'uppercase', fontSize: "14px", fontWeight: '500', color: '#17428B', marginBottom: '10px' }}>
                    Safety marking
                    </Typography>
                    <div>
                        <p style={{ marginBottom: '10px', fontSize: "14px", fontWeight: '500' }}>35. All dangerous / hazardous areas are completely marked and have been reviewed to see if this hazard can be removed.</p>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                            <p style={{ marginRight: '10px', fontSize: "14px", fontWeight: '500' }}>SCORE:</p>
                            <label>
                                <input
                                    type="radio"
                                    name="radioButton35"
                                    value="0"
                                    checked={selectedValues['question35'] === "0"}
                                    // checked={formData.question32 === "0"}
                                    // onChange={(e) => setFormData({ ...formData, question32: e.target.value })}
                                    onChange={(e) => handleRadioChange('question35', e.target.value)}

                                /> 0
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="radioButton35"
                                    value="1"
                                    checked={selectedValues['question35'] === "1"}
                                    // checked={formData.question32 === "1"}
                                    // onChange={(e) => setFormData({ ...formData, question32: e.target.value })}
                                    onChange={(e) => handleRadioChange('question35', e.target.value)}


                                /> 1
                            </label>
                        </div>
                    </div>

                    {console.log(images['question35'])}
                    {selectedValues.question35 === "0" && (
                        <div>

                            <div id="camera-container-question35" style={{ border: "1px dashed #e6e6e6", height: '160px',textAlign:'center', marginBottom: '20px', position: 'relative', borderRadius:'10px' }}>
                                {!images['question35'] ? (
                                    <>
                                        {/* Camera button */}
                                        <input
                                            id="upload-image_question35"
                                            multiple
                                            type="file"
                                            style={{ display: "none" }}
                                            // onChange={setUploadedFile}
                                            ref={fileInputRef}
                                        />
                                        <button onClick={() => handleCapture('question35')} onChange={(e) => setFormData({ ...selectedValues, image35: e.target.value })} style={{ position: 'absolute', cursor: 'pointer', background: 'none', border: 'none', width: '100%', height: '100%',left:'0',top:'0' }}>
                                        </button>
                                        <LogoutIcon sx={{
                                            transform: 'rotate(-90deg)',
                                            color: '#C9C9CC',
                                            margin:'30px 0 10px'
                                        }} />
                                        <Typography style={{ textAlign: 'center', color: 'black', fontWeight: 500, fontSize: '16px' }}>Drag & drop images or <span style={{ color: '#17428B' }}>browse images</span></Typography>
                                        <Typography style={{ textAlign: 'center', color: '#4F4D55', fontSize: '12px', marginTop: '10px' }}>.xlsx - max file size 2MB</Typography>
                                    </>
                                ) : (
                                    <>
                                        {/* Display captured image */}
                                        <img src={images['question35']} onChange={(e) => setFormData({ ...selectedValues, image35: e.target.value })} alt="Captured" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                                        <div style={{ position: 'absolute', bottom: '10px', left: '50%', transform: 'translateX(-50%)', zIndex: 1 }}>
                                            {/* Retake button */}
                                            <Button variant="contained" onClick={() => handleRetake('question35')}>Retake</Button>
                                        </div>
                                    </>
                                )}</div>
                        </div>
                    )}



                    <div>
                        <p style={{ marginBottom: '10px', fontSize: "14px", fontWeight: '500' }}>Remarks for Improvement</p>
                        {/* <textarea value={remarks["question320"] || ''} onChange={(e) => handleChange('remarks', 'question320', e.target.value)} placeholder="Type here" style={{ width: '100%', padding: '8px', border: '1px solid #E6E6E6', borderRadius: '4px' }} /> */}
                        <textarea value={remarks["question35"] || ''} onChange={(e) => handleRemarksChange('question35', e.target.value)} placeholder="Type here" style={{ width: '100%', padding: '8px', outline: 'none', border: '1px solid #E6E6E6', borderRadius: '4px', resize: "none" }} />
                    </div>
                    {errors["question35"] && <Typography style={{ color: 'red' }}>{errors["question35"]}</Typography>}
                </CardContent>
            </Card>


       {/* Question 36 */}
       <Card variant="outlined" style={{ marginBottom: '20px' }}>
                <CardContent>
                    <Typography variant="h5" style={{ textTransform: 'uppercase', fontSize: "14px", fontWeight: '500', color: '#17428B', marginBottom: '10px' }}>
                    PPE
                    </Typography>
                    <div>
                        <p style={{ marginBottom: '10px', fontSize: "14px", fontWeight: '500' }}>36. Required Personal Protective Equipment is identified, available and is being worn in the area.</p>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                            <p style={{ marginRight: '10px', fontSize: "14px", fontWeight: '500' }}>SCORE:</p>
                            <label>
                                <input
                                    type="radio"
                                    name="radioButton36"
                                    value="0"
                                    checked={selectedValues['question36'] === "0"}
                                    // checked={formData.question32 === "0"}
                                    // onChange={(e) => setFormData({ ...formData, question32: e.target.value })}
                                    onChange={(e) => handleRadioChange('question36', e.target.value)}

                                /> 0
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="radioButton36"
                                    value="1"
                                    checked={selectedValues['question36'] === "1"}
                                    // checked={formData.question32 === "1"}
                                    // onChange={(e) => setFormData({ ...formData, question32: e.target.value })}
                                    onChange={(e) => handleRadioChange('question36', e.target.value)}


                                /> 1
                            </label>
                        </div>
                    </div>

                    {console.log(images['question36'])}
                    {selectedValues.question36 === "0" && (
                        <div>

                            <div id="camera-container-question36" style={{ border: "1px dashed #e6e6e6", height: '160px',textAlign:'center', marginBottom: '20px', position: 'relative', borderRadius:'10px' }}>
                                {!images['question36'] ? (
                                    <>
                                        {/* Camera button */}
                                        <input
                                            id="upload-image_question36"
                                            multiple
                                            type="file"
                                            style={{ display: "none" }}
                                            // onChange={setUploadedFile}
                                            ref={fileInputRef}
                                        />
                                        <button onClick={() => handleCapture('question36')} onChange={(e) => setFormData({ ...selectedValues, image36: e.target.value })} style={{ position: 'absolute', cursor: 'pointer', background: 'none', border: 'none', width: '100%', height: '100%',left:'0',top:'0' }}>
                                        </button>
                                        <LogoutIcon sx={{
                                            transform: 'rotate(-90deg)',
                                            color: '#C9C9CC',
                                            margin:'30px 0 10px'
                                        }} />
                                        <Typography style={{ textAlign: 'center', color: 'black', fontWeight: 500, fontSize: '16px' }}>Drag & drop images or <span style={{ color: '#17428B' }}>browse images</span></Typography>
                                        {/* <Typography style={{ textAlign: 'center', color: '#4F4D55', fontSize: '12px', marginTop: '10px' }}>.xlsx - max file size 2MB</Typography> */}
                                    </>
                                ) : (
                                    <>
                                        {/* Display captured image */}
                                        <img src={images['question36']} onChange={(e) => setFormData({ ...selectedValues, image36: e.target.value })} alt="Captured" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                                        <div style={{ position: 'absolute', bottom: '10px', left: '50%', transform: 'translateX(-50%)', zIndex: 1 }}>
                                            {/* Retake button */}
                                            <Button variant="contained" onClick={() => handleRetake('question36')}>Retake</Button>
                                        </div>
                                    </>
                                )}</div>
                        </div>
                    )}



                    <div>
                        <p style={{ marginBottom: '10px', fontSize: "14px", fontWeight: '500' }}>Remarks for Improvement</p>
                        {/* <textarea value={remarks["question320"] || ''} onChange={(e) => handleChange('remarks', 'question320', e.target.value)} placeholder="Type here" style={{ width: '100%', padding: '8px', border: '1px solid #E6E6E6', borderRadius: '4px' }} /> */}
                        <textarea value={remarks["question36"] || ''} onChange={(e) => handleRemarksChange('question36', e.target.value)} placeholder="Type here" style={{ width: '100%', padding: '8px', outline: 'none', border: '1px solid #E6E6E6', borderRadius: '4px', resize: "none" }} />
                    </div>
                    {errors["question36"] && <Typography style={{ color: 'red' }}>{errors["question36"]}</Typography>}
                </CardContent>
            </Card>
      
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                message={snackbarMessage}
            />
            <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', marginTop: '20px' }}>
                <Button variant="contained" color="primary" onClick={handleSaveClick} style={{
                    width: '100%',
                    background: 'white', // Set background color to white
                    borderRadius: '5px', // Add border radius for rounded corners
                    border: '1px solid #17428B', // Set initial border to 1px solid
                    color: '#15408A', // Set font color to blue
                    boxShadow: 'none',
                    position: 'relative',
                    // Remove default box shadow
                }} disabled={loading}

                >Save

               
                </Button>
                <Button variant="contained" style={{
                    width: '100%',
                    background: 'linear-gradient(45deg, rgb(57 106 23) 30%, rgb(2 218 87) 90%)',
                    //background: '#17428B', // Adjust the gradient colors as needed
                    borderRadius: 3,
                    border: 0,
                    color: 'white',
                    height: 48,
                    padding: '0 30px',
                    boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
                }} color="primary" onClick={handleSubmitClick} disabled={loading} >
                    Submit
                </Button>
            </div>

        </div>
        {loading && (
            <div
                style={{
                    position: 'fixed',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 9999,
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    padding: '20px',
                    borderRadius: '8px',
                    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                }}
            >
                {/* Replace this with your preferred loading indicator */}
                <Typography variant="h6" style={{ textAlign: 'center', marginBottom: '10px' }}>
                    Saving Assessment...
                </Typography>
                {/* Example loading spinner */}
                <div style={{ textAlign: 'center' }}>
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
        )}
    </Box>
    );
}

export default Safety;
