import LoginLaunchPage from '../src/Login/LaunchScreen';
import Plant from './Pages/Plant';
import Dashboard from './Pages/Dashboard';
import Audit from './Pages/Audit/Audit';
import RequestForm from './Pages/RequestForm/RequestForm';
import Straighten from './Pages/RequestForm/Straighten';
import Shine from './Pages/RequestForm/Shine';
import Standardise from './Pages/RequestForm/Standardise';
import Sustain from './Pages/RequestForm/Sustain';
import Safety from './Pages/RequestForm/Safety';
import HorizontalCombinedStepper from './Pages/RequestForm/HorizontalCombinedStepper';
import HelpAndSupportButton from './Pages/HelpAndSupport/HelpAndSupport';
import AuditAssessment from './Pages/RequestForm/AuditAssessment';
import Settings from './Pages/settings/Settings';
import NewUser from './Pages/settings/Users';
// import RedTagging from './Pages/RedTagging/RedTag';
import RedTagActivities from './Pages/RedTagging/RedTagActivities';
// import NewRedTagDetails from './Pages/RedTagging/NewRedTagDetails';
import RedTagForm from './Pages/RedTagging/RedTagForm';
import RedTagRegister from './Pages/RedTagging/RedTagRegister';

import WasteToWealth from './Pages/RedTagging/WasteToWealth';

import RedTagDetails from './Pages/RedTagging/RedTagDetails';

import Achievement from './Pages/Achievements/Achievement';
import Mass from './Pages/MassFiveS/Mass';
import NewEntry from './Pages/MassFiveS/NewEntry';
import Reports from './Pages/Reports/Reports';
import WasteWealthDetails from './Pages/RedTagging/WasteWealthDetails';
export const mainRoutes = [

  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard
  },

  {
    path: '/audit',
    name: 'Audit',
    component: Audit
  },
  // {
  //   path: '/RedTagging',
  //   name: 'RedTagging',
  //   component: RedTagging
  // },
  {
    path: '/RedTagActivities',
    name: 'RedTagActivities',
    component: RedTagActivities
  },
  // {
  //   path: '/NewRedTagDetails',
  //   name: 'NewRedTagDetails',
  //   component: NewRedTagDetails
  // },
  // {
  //   path: '/RedTagForm',
  //   name: 'RedTagForm',
  //   component: RedTagForm
  // },
  {
    path: '/RedTagRegister',
    name: 'RedTagRegister',
    component: RedTagRegister
  },
  {
    path: '/RedTagDetails',
    name: 'RedTagDetails',
    component: RedTagDetails
  },

  
  {
    path: '/WasteToWealth',
    name: 'WasteToWealth',
    component: WasteToWealth
  },
  
  {
    path: '/WasteWealthDetails',
    name: 'WasteWealthDetails',
    component: WasteWealthDetails
  },
  
  {
    path: '/settings',
    name: 'Settings',
    component: Settings
  },
  {
    path: '/requestForm',
    name: 'RequestForm',
    component: RequestForm
  },

  {
    path: '/straighten',
    name: 'Straighten',
    component: Straighten
  },

  {
    path: '/shine',
    name: 'Shine',
    component: Shine
  },


  {
    path: '/helpandsupportButton',
    name: 'HelpAndSupportButton',
    component: HelpAndSupportButton
  },

  // {
  //   path: '/auditassessment',
  //   name: 'AuditAssessment',
  //   component: AuditAssessment
  // },
  {
    path: '/newuser',
    name: 'New User',
    component: NewUser
  },
  // {
  //   path: '/function',
  //   name: 'Function',
  //   component: Function
  // }
  {
    path: '/standardise',
    name: 'Standardise',
    component: Standardise
  },
  {
    path: '/sustain',
    name: 'Sustain',
    component: Sustain
  },
  {
    path: '/safety',
    name: 'Safety',
    component: Safety
  },
  {
    path: '/achievements',
    name: 'Achievement',
    component: Achievement
  },
  {
    path: '/massfives',
    name: 'Mass',
    component: Mass
  },
  {
    path: '/newEntry',
    name: 'NewEntry',
    component: NewEntry
  },
  {
    path: '/reports',
    name: 'Reports',
    component: Reports
  },


]

export default mainRoutes;