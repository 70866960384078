import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../../app/TopAppBar';
import configParam from "../../config";
import { useLocation } from 'react-router-dom';
// import { CardActions, CardMedia, Card, CardContent, Dialog, DialogTitle, DialogContent, DialogActions, Button, Select, MenuItem, FormControl, InputLabel, TextField, Autocomplete, Typography } from "@mui/material";
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Modal, Box, Typography, IconButton, Tooltip, CircularProgress, FormHelperText, FormControl, InputLabel, Dialog, DialogTitle, DialogContent, Select, MenuItem, DialogActions, TextField
} from '@mui/material';
// import './style.css';

const RedTagDetails = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const ApiURL = configParam.API_URL;
  const access_token = JSON.parse(localStorage.getItem("accesstoken"));
  const [selectedRedTag, setSelectedRedTag] = useState(null);
  const location = useLocation();
  const [zones, setZones] = useState([]);
  useEffect(() => {
   

   fetchData();
  }, [location.search]);
//my code
  const fetchData = async () => {
    const query = new URLSearchParams(location.search);
    const filters = Object.fromEntries(query.entries());

    try {
      const response = await axios.get(`${ApiURL}/get_redtag_details?${query.toString()}`, {
        headers: {
          Authorization: `Bearer ${access_token}`
        },
      });
      const responseData = response.data.data;
console.log("responseDataresponseData",responseData)
      let filteredData = responseData.filter(item => {
        return Object.entries(filters).every(([key, value]) => {
          if (key === 'filterType') return true; // Skip filterType
          if (value === '') return true;
          return item[key] && item[key].toString() === value.toString();
        });
      });

      setData(filteredData);
    } catch (error) {
      setError(error);
    }
  };

 
  const renderTable = () => {
    const query = new URLSearchParams(location.search);
    const filterType = query.get('filterType');
    const groupKey = filterType === 'zone' ? 'zone_no' : filterType === 'category' ? 'category_no' : filterType;
    
    if (!filterType) {
      return (
        <Table className="example" border="1" cellSpacing="0" cellPadding="0" width="780" style={{ borderCollapse: 'collapse' }}>
          <TableHead>
            <TableRow bgcolor="#abd6ff" height="22px">
              <TableCell align="center">S.No</TableCell>
              <TableCell align="center">Red Tag No</TableCell>
              <TableCell align="center">Item Name</TableCell>
              <TableCell align="center">Zone</TableCell>
              <TableCell align="center">Category</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Created Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item, index) => (
              <TableRow key={item.red_tagno} bgcolor={index % 2 === 0 ? '#ffffff' : '#f9f9f9'}>
                <TableCell align="right">{index + 1}</TableCell>
                <TableCell align="right" style={{ cursor: 'pointer' }} onClick={() => handleRowClick(item.red_tagno)}>
                  <u>{item.red_tagno}</u>
                </TableCell>
                <TableCell>{item.item_name}</TableCell>
                <TableCell align="center">{item.zone_name}</TableCell>
                <TableCell align="center">{item.category_no}</TableCell>
                <TableCell>{item.status}</TableCell>
                <TableCell align="left">{item.entry_date}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      );
    }
    const groupedData = data.reduce((acc, item) => {
      const key = item[groupKey];
      if (!acc[key]) acc[key] = [];
      acc[key].push(item);
      return acc;
    }, {});
    console.log("groupedData", groupedData);

    return Object.entries(groupedData).map(([group, items]) => (
      <div key={group}>
        <h3>
          {filterType === 'status' ? `Status: ${group}` :
           filterType === 'zone' ? `Zone: ${items[0].zone_name}` :
           filterType === 'category' ? `Category: ${group}` :
           ''}
        </h3>
        <Table className="example" border="1" cellSpacing="0" cellPadding="0" width="780" style={{ borderCollapse: 'collapse' }}>
          <TableHead>
            <TableRow bgcolor="#abd6ff" height="22px">
              <TableCell align="center">S.No</TableCell>
              <TableCell align="center">Red Tag No</TableCell>
              <TableCell align="center">Item Name</TableCell>
              {filterType !== 'status' && <TableCell align="center">Status</TableCell>}
              {filterType === 'zone' && <TableCell align="center">Category</TableCell>}
              {filterType === 'category' && <TableCell align="center">Zone</TableCell>}
              {filterType === 'status' && <>
                <TableCell align="center">Zone</TableCell>
                <TableCell align="center">Category</TableCell>
              </>}
              <TableCell align="center">Created Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item, index) => (
              <TableRow key={item.red_tagno} bgcolor={index % 2 === 0 ? '#ffffff' : '#f9f9f9'}>
                <TableCell align="right">{index + 1}</TableCell>
                <TableCell align="right" style={{ cursor: 'pointer' }} onClick={() => handleRowClick(item.red_tagno)}>
                  <u>{item.red_tagno}</u>
                </TableCell>
                <TableCell>{item.item_name}</TableCell>
                {filterType !== 'status' && <TableCell>{item.status}</TableCell>}
                {filterType === 'zone' && <TableCell align="center">{item.category_no}</TableCell>}
                {filterType === 'category' && <TableCell align="center">{item.zone_name}</TableCell>}
                {filterType === 'status' && <>
                  <TableCell align="center">{item.zone_name}</TableCell>
                  <TableCell align="center">{item.category_no}</TableCell>
                </>}
                <TableCell align="left">{item.entry_date}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    ));
  };



//monisha code
  // const fetchData = async () => {
  //   const query = new URLSearchParams(location.search);
  //   const filters = Object.fromEntries(query.entries());
    
  //   try {
  //     const response = await axios.get(`${ApiURL}/get_redtag_details`, {
  //       headers: {
  //         Authorization: `Bearer ${access_token}`
  //       },
  //       // params: filters, // Remove this line
  //     });
  
  //     const responseData = response.data.data;
  //     console.log("Filters:", filters);
  //     console.log("Response Data:", responseData);
  
  //     let filteredData = [...responseData];
  //     if (Object.keys(filters).length > 0) {
  //       filteredData = filteredData.filter(item => {
  //         // Check if each filter key exists in the item and matches the filter value
  //         return Object.entries(filters).every(([key, value]) => {
  //           // If the filter value is empty, skip the filter
  //           if (value === '') return true;
            
  //           // Convert both item value and filter value to string for strict comparison
  //           return item[key] && item[key].toString() === value.toString();
  //         });
  //       });
  //     }
      

  
  //     console.log("Filtered Data:", filteredData);
  //     setData(filteredData);
  //   } catch (error) {
  //     setError(error);
  //   }
  // };
  
 

  const handleRowClick = (redTagNo) => {
    const redTagWindow = window.open('', '_blank', 'width=800,height=600');
    redTagWindow.document.write(`
      <html>
      <head>
        <title>Red Tag Details</title>
        <style>
          body { font-family: Arial, sans-serif; margin: 20px; }
          h2 { color: #333; }
          table { width: 100%; border-collapse: collapse; margin-bottom: 20px; }
          th, td { border: 1px solid #ddd; padding: 8px; }
          th { background-color: #f2f2f2; text-align: left; }
          .section-title { background-color: #ff7f50; color: white; padding: 10px; }
        </style>
      </head>
      <body>
        <h2>Loading...</h2>
      </body>
      </html>
    `);

    axios.get(`${ApiURL}/get_redtag_number_details/${redTagNo}`, {
      headers: {
        Authorization: `Bearer ${access_token}`
      }
    })
    .then(response => {
      const redTagData = response.data.data;
      redTagWindow.document.body.innerHTML = `
        <h2>Red Tag Details</h2>
        <table>
          <tr class="section-title"><td colspan="2">Tag Details</td></tr>
          <tr><th>Red Tag No</th><td>${redTagData.red_tagno}</td></tr>
          <tr><th>Category</th><td>${redTagData.category_no}</td></tr>
          <tr><th>Item Name</th><td>${redTagData.item_name}</td></tr>
          <tr><th>Quantity</th><td>${redTagData.qty}</td></tr>
          <tr><th>Approximate value of Each(in Rs)</th><td>${redTagData.approximate}</td></tr>
          <tr><th>Reason For tagging</th><td>${redTagData.reason}</td></tr>
          <tr><th>Disposal Action Suggested</th><td>${redTagData.disposal_method}</td></tr>
        </table>
        <table>
          <tr class="section-title"><td colspan="2">Tag Review</td></tr>
          <tr><th>Disposal Action Recommended By Group Leader</th><td> </td></tr>
          <tr><th>Comment By Group Leader</th><td></td></tr>
          <tr><th>Reviewed</th><td></td></tr>
        </table>
        <table>
          <tr class="section-title"><td colspan="2">Common Red Tag Area Details</td></tr>
          <tr><th>Action By Common Redtag Area</th><td> </td></tr>
          <tr><th>Common Redtag Area Comment</th><td> </td></tr>
          <tr><th>Redeployed Zone</th><td> </td></tr>
          <tr><th>Scrap Value</th><td> </td></tr>
          <tr><th>Disposal Value</th><td> </td></tr>
        </table>
      `;
    })
    .catch(error => {
      redTagWindow.document.body.innerHTML = `<h2>Error loading details</h2>`;
    });
  };

  // const handleRowClick = (redTagNo) => {
  //   fetchRedTagDetails(redTagNo);
  // };

  // const handleRowClick = (prid) => {
  //   const screenWidth = window.screen.width;
  //   const screenHeight = window.screen.height;
  //   window.open(`redtag_details.asp?prid=${prid}`, '_blank', `height=${screenHeight - 10},width=${screenWidth - 10},menubar=0,resizable=yes,location=no,status=yes,scrollbars=yes`);
  // //  window.open(`redtag_details.asp?prid=${prid}`, '_blank', 'height=' + (screen.height - 10) + ',width=' + (screen.width - 10) + ',menubar=0,resizable=yes,location=no,status=yes,scrollbars=yes');
  // };

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (data.length === 0) {
    return <div>No list to populate for the given criteria</div>;
  }

  return (
    <div>
      <Header pageTitle="Red Tag Register" isRedTagPage={true} />
      <div style={{ margin: '0 auto', backgroundColor: '#f9f9f9', borderRadius: '8px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', marginTop: "65px" }}>
        <div className="spot-award">
          {renderTable()}
        </div>
      </div>
    </div>
  );
//monisha code
//   return (
//     <div>
//     <Header pageTitle= "Red Tag Register" isRedTagPage={true} />
//     <div style={{  margin: '0 auto', backgroundColor: '#f9f9f9', borderRadius: '8px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', marginTop: "65px" }}>
   
//     <div className="spot-award">
//       <Table className="example" border="1" cellSpacing="0" cellPadding="0" width="780" style={{ borderCollapse: 'collapse' }}>
//         <TableHead>
//           <TableRow bgcolor="#abd6ff" height="22px">
//             <TableCell align="center">S.No</TableCell>
//             <TableCell align="center">Red Tag No</TableCell>
//             <TableCell align="center">Item Name</TableCell>
//             <TableCell align="center">Status</TableCell>           
//             <TableCell align="center">Category</TableCell>
//             <TableCell align="center">Created Date</TableCell>
//           </TableRow>
//         </TableHead>
//         <TableBody>
//   {data && data.map((item, index) => (
//     <TableRow key={item.red_tagno} bgcolor={index % 2 === 0 ? '#ffffff' : '#ffffff'}>
//       <TableCell align="right">{index + 1}</TableCell>
//       <TableCell align="right" style={{ cursor: 'pointer' }} onClick={() => handleRowClick(item.red_tagno)}>
//         <u>{item.red_tagno}</u>
//       </TableCell>
//       <TableCell>{item.item_name}</TableCell>
//       <TableCell>{item.status}</TableCell>     
//       <TableCell align="center">{item.category_no}</TableCell>              
//       <TableCell align="left">{item.entry_date}</TableCell>
//     </TableRow>
//   ))}
// </TableBody>

//       </Table>
//     </div>
//     </div>  

//     {/* {selectedRedTag && (
//         <div style={{ marginTop: '20px', padding: '20px', backgroundColor: 'white', borderRadius: '8px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
//           <h2>Red Tag Details</h2>
//           <p><strong>Red Tag No:</strong> {selectedRedTag.red_tagno}</p>
//           <p><strong>Item Name:</strong> {selectedRedTag.item_name}</p>
//           <p><strong>Category:</strong> {selectedRedTag.category_no}</p>
//           <p><strong>Status:</strong> {selectedRedTag.status}</p>
//           <p><strong>Reason:</strong> {selectedRedTag.reason}</p>
//           <p><strong>Zone:</strong> {selectedRedTag.zone_no}</p>
//           <p><strong>Plant:</strong> {selectedRedTag.plant}</p>
//           <p><strong>Quantity:</strong> {selectedRedTag.qty}</p>
//           <p><strong>Unit of Measure:</strong> {selectedRedTag.unit_of_measure}</p>
//           <p><strong>Approximate Value:</strong> {selectedRedTag.approximate}</p>
//           <p><strong>Disposal Method:</strong> {selectedRedTag.disposal_method}</p>
//           <p><strong>Created Date:</strong> {selectedRedTag.entry_date}</p>
//         </div>
//       )} */}


//     </div>
//   );
};

export default RedTagDetails;




// my working code only with filetrby logic
//   const renderTable = () => {
//     const filterType = new URLSearchParams(location.search).get('filterType');
//     const groupKey = filterType === 'zone' ? 'zone_no' : filterType === 'category' ? 'category_no' : filterType;
//     const groupedData = data.reduce((acc, item) => {
//       const key = item[groupKey];
//       if (!acc[key]) acc[key] = [];
//       acc[key].push(item);
//       return acc;
//     }, {});
// console.log("groupeddata",groupedData)
//     return Object.entries(groupedData).map(([group, items]) => (
//       <div key={group}>
//         <h3>
//           {filterType === 'status' ? `Status: ${group}` :
//            filterType === 'zone' ? `Zone: ${items[0].zone_name}` :
//            filterType === 'category' ? `Category: ${group}` :
//            ''}
//         </h3>
//         <Table className="example" border="1" cellSpacing="0" cellPadding="0" width="780" style={{ borderCollapse: 'collapse' }}>
//           <TableHead>
//             <TableRow bgcolor="#abd6ff" height="22px">
//               <TableCell align="center">S.No</TableCell>
//               <TableCell align="center">Red Tag No</TableCell>
//               <TableCell align="center">Item Name</TableCell>
//               {filterType !== 'status' && <TableCell align="center">Status</TableCell>}
//               {filterType === 'zone' && <TableCell align="center">Category</TableCell>}
//               {filterType === 'category' && <TableCell align="center">Zone</TableCell>}
//               {filterType === 'status' && <>
//                 <TableCell align="center">Zone</TableCell>
//                 <TableCell align="center">Category</TableCell>
//               </>}
//               <TableCell align="center">Created Date</TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {items.map((item, index) => (
//               <TableRow key={item.red_tagno} bgcolor={index % 2 === 0 ? '#ffffff' : '#f9f9f9'}>
//                 <TableCell align="right">{index + 1}</TableCell>
//                 <TableCell align="right" style={{ cursor: 'pointer' }} onClick={() => handleRowClick(item.red_tagno)}>
//                   <u>{item.red_tagno}</u>
//                 </TableCell>
//                 <TableCell>{item.item_name}</TableCell>
//                 {filterType !== 'status' && <TableCell>{item.status}</TableCell>}
//                 {filterType === 'zone' && <TableCell align="center">{item.category_no}</TableCell>}
//                 {filterType === 'category' && <TableCell align="center">{item.zone_name}</TableCell>}
//                 {filterType === 'status' && <>
//                   <TableCell align="center">{item.zone_name}</TableCell>
//                   <TableCell align="center">{item.category_no}</TableCell>
//                 </>}
//                 <TableCell align="left">{item.entry_date}</TableCell>
//               </TableRow>
//             ))}
//           </TableBody>
//         </Table>
//       </div>
//     ));
//   };
