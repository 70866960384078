import React, { useState } from "react";
import LaunchLogo from "../Asset/Icons/fiveslogo.svg";
import sgLogo from "../Asset/Icons/SgLogo.png";
import { Button, Typography, Grid } from "@mui/material";
import { useEffect } from "react";
import configParam from "../config";
import axios from "axios";
import debug from 'debug';
const log = debug('login');
// import API from "../Services/API";
//import LeftDrawer from "../Pages/LeftDrawer";
// import Typography from '@mui/material';
const LoginLaunchPage = () => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const ApiURL = configParam.API_URL;
  // const classes = useStyles();

  const containerStyle = {
    position: 'fixed',
    right: '0',
    top: '0',
    height: "100%",
    width: '400px',
    padding: '20px',
    backgroundColor: "#fff", // Change this to your preferred background color
  };



  const onHandleSSO = () => {
    const url = configParam.SSO_URL;
    window.location.replace(url);
  };
 

  const userDetails_old = () => {
    const url = window.location.href;
    const Response = decodeURIComponent(url);
    const myArray = Response.split("?");
    log(Response)
    if (myArray.length > 1) {
      const queryParameters = new URLSearchParams(window.location.search)
      console.log("queryParameters", queryParameters);
      const UserDetails = queryParameters.get("UserData");
      const access_token = queryParameters.get("access_token");
      // const secret = queryParameters.get("secret_key");
      const err = queryParameters.get("err");
    
      const currentUserDetails = JSON.parse(UserDetails)

      console.log('currentUserDetails', currentUserDetails);
      // const currentUserDetails = response.data.userDetails;
      if (currentUserDetails && Object.keys(currentUserDetails).length > 0) {
        //   localStorage.setItem("userDatas", JSON.stringify(currentUserDetails));
        localStorage.setItem("userDatas", JSON.stringify(currentUserDetails));
        console.log("currentUserDetails.access_token", currentUserDetails.access_token, access_token);
        // console.log("currentUserDetails.access_token",currentUserDetails.secret_key,secret);
        localStorage.setItem("accesstoken", access_token);
        //  localStorage.setItem("secret", secret);
        window.location.href = "/Plant";
      } else {
        localStorage.setItem("userDetails", "");
        setError(err ?? "Access denied");
        setLoading(false);
      }
    }
  }

  const fetchUserDetails = async (code) => {
    try {
      console.log("code",code);
      const response = await axios.get(`${ApiURL}/login`, {
        params: {
          code: code
        }
      });
      // const response = await axios.get('/api/login', {
      //   params: {
      //     code: code
      //   }
      // });
      console.log("response", response);
      const { success, UserData, access_token, err } = response.data;
      if (success) {
        localStorage.setItem("userDatas", JSON.stringify(UserData));
        localStorage.setItem('accesstoken', JSON.stringify(access_token));

        //  localStorage.setItem("accesstoken", access_token);
        window.location.href = "/Plant";
      } else {
        setError(err ?? "Access denied");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error during login:", error);
      setError("An error occurred during login");
      setLoading(false);
    }
  };

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const code = queryParameters.get("code");

    if (code) {
      fetchUserDetails(code);
    } else {
      setLoading(false);
    }
  }, []);


  return (
    <Grid
      container
      style={{ ...containerStyle }}
      justifyContent="center"
      alignItems="center"
    >
      <Grid
        item
        container
        direction="column"
        justifyContent="center"
        alignItems="start"
      >
        <Grid item style={{alignSelf:"center"}}>
          <img src={LaunchLogo} alt="Logo" className="icon"  />
        </Grid>
        {/* <Typography variant="h2" sx={{ fontSize: '20px', fontWeight: '600', marginBottom: '16px', marginTop: '16px' }}>SSO Login</Typography> */}
        <Grid item container direction="column" alignItems="center">

          {error && (
            <Typography
              variant="subtitle1"
              style={{ color: "red", marginTop: "15px" }}
            >
              {error}&nbsp;
            </Typography>
          )}
          {!loading ? (
            <Button
              variant="contained"
              style={{
                backgroundImage: '#0086DC',
                width: '100%',
                border: 0,
                borderRadius: 3,
                color: 'white',
                height: 48,
                padding: '0 30px',
              }}
              onClick={onHandleSSO}
            >
              Single Sign-On
            </Button>
          ) : (
            // <CircularProgress />
            <></>
          )}
          <Typography variant="body1" sx={{ fontSize: '12px', width: '100%', color: '#6E7C87', textAlign: 'left', margin: '16px 0' }}>Note: Login using Single Sign-On if you are
            Saint-Gobain User.</Typography>
        </Grid>
        <Grid item sx={{position: 'absolute',left: 0,right:'0',bottom: '0',margin:'auto',textAlign:'center'}}>
          <img src={sgLogo} alt="Logo" className="icon" />

          <Typography
            variant="body1"
            style={{
              marginTop: 8,
              fontSize: 12,
              textAlign: "center",
              marginBottom: "15px",
            }}
          >
            © 2024 INDEC4.0 Saint-Gobain
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LoginLaunchPage;

