// import { jwtDecode } from "jwt-decode";


const _get_api_url = function () {
  switch (process.env.REACT_APP_STAGE) {
    case "dev":
      return "https://dev.sg5s.api.saint-gobain.com";
    case "local":
      return "http://localhost:3045";
    default:
      return "https://sg5s.saint-gobain.com/sg5s-api";
    
  }
};

const _get_sso_url = () => {
  switch (process.env.REACT_APP_STAGE) {
    case "dev":
      return "https://uat.cloudgateway.saint-gobain.com/openam/oauth2/realms/root/realms/AccessManagement/authorize?response_type=code&client_id=POC_FIVE_S&scope=stGoSGI&redirect_uri=https://dev.sg5s.saint-gobain.com/login";
    case "local":
      return "https://uat.cloudgateway.saint-gobain.com/openam/oauth2/realms/root/realms/AccessManagement/authorize?response_type=code&client_id=POC_FIVE_S&scope=stGoSGI&redirect_uri=http://localhost:3000/login";
    default:
      return "https://cloudsso.saint-gobain.com/openam/oauth2/realms/root/realms/AccessManagement/authorize?response_type=code&client_id=SG_FIVE_S&scope=stGoSGI&redirect_uri=https://sg5s.saint-gobain.com/login";
      ;
  }
};

const _get_app_url = function () {
  switch (process.env.REACT_APP_STAGE) {
    case "dev":
      return "https://dev.sg5s.saint-gobain.com";
    case "local":
      return "http://localhost:3000";
    default:
      return "https://sg5s.saint-gobain.com";
    
  }
};

// const _check_and_get_token = async (existingTokens) => {
//   try {
//     let decodedToken = jwtDecode(existingTokens);
//     let dateNow = new Date();
//     if (
//       decodedToken.exp < parseInt(dateNow.getTime() / 1000) ||
//       decodedToken.exp === parseInt(dateNow.getTime() / 1000)
//     ) {
//       (async () => {
//         let myHeaders = new Headers();
//         myHeaders.append("Content-Type", "application/json");
//         let url = configParam.AUTH_URL + "refresh";
//         await fetch(url, {
//           method: "POST",
//           headers: myHeaders,
//           body: JSON.stringify({ refresh_token: existingTokens }),
//         })
//           .then((response) => response.json())
//           .then(async (result) => {
//             if (Object.keys(result).length > 0) {
//               localStorage.setItem("SPGCAccessToken", result["access_token"]);
//               return result["access_token"];
//             } else {
//               console.log("no response from refresh token hit");
//             }
//           })
//           .catch((error) => console.log("error", error));
//       })();
//     } else {
//       return existingTokens;
//     }
//   } catch (e) {
//     return "";
//   }
// };

// const refreshAccessToken = async (query, variables, retries) => {
//   return _run_rest_api("refresh", { "refresh_token": localStorage.getItem("SPGCRefreshToken") }, true)
//     .then((data) => {
//       if (data && data.access_token) {
//         localStorage.setItem("", data.access_token);
//         return _run_gql_api(query, variables, retries - 1);
//       }
//     })
// }

// const _run_gql_api = async (query, variables, retries = 3) => {
//   let controller = new AbortController();
//   let signal = controller.signal;
//   var myHeaders = new Headers();
//   myHeaders.append("Content-Type", "application/json");
//   myHeaders.append(
//     "Authorization",
//     "Bearer "
//     + localStorage.getItem("SPGCAccessToken").replace(/['"]+/g, "")
//   );
//   setTimeout(() => controller.abort(), 20000);
//   return fetch(_get_graphql_url(), {
//     method: "POST",
//     headers: myHeaders,
//     body: JSON.stringify({ query: query, variables: variables }),
//     signal: signal,
//   })
//     .then(response => response.json())
//     .then(result => {
//       if (result.errors && result.errors.length && result.errors[0].extensions && result.errors[0].extensions.code === "invalid-jwt") {
//         redirectToLoginPage();
//         return refreshAccessToken(query, variables, retries);
//       } else {
//         return result["data"];
//       }
//     })
//     .catch((e) => {
//       if (retries > 0) return _run_gql_api(query, variables, retries - 1);
//       else {
//         promiseSetRecoil(apiTimeout, true);
//         return e;
//       }
//     });
// };

export const configParam = {
  primaryColor: "#FF6D04",
  lightBackground: "#FC8C200C",
  grey: "#D8D8D8",
  // CHECK_TOKEN: _check_and_get_token,
  API_URL: _get_api_url(),
  SSO_URL: _get_sso_url(),
  APP_URL: _get_app_url(),
};

export default configParam;