import React, { useState } from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import Header from '../../app/TopAppBar';
import ZoneDefinitions from './ZoneDefinitions';
import NewUser from './Users';

const Settings = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box sx={{padding:{
      md:"24px",
      xs: "16px"
    }}}>
     
      <div style={{ borderBottom: '1px solid #E5E9EB' }}>
        <Tabs value={selectedTab} onChange={handleTabChange} left
          indicatorColor="primary" // Change indicator color to primary
          TabIndicatorProps={{ // Customize the indicator style
            style: { width: '120px' } // Increase the width of the indicator line
          }} >
          <Tab label="Zone Definition"
            sx={{
              textTransform: "capitalize",
              fontSize: "16px"
            }} />
          <Tab label="Users Details"
            sx={{
              textTransform: "capitalize",
              fontSize: "16px"
            }} />
        </Tabs>
      </div>
      <div>
        <div>
          {selectedTab === 0 && <ZoneDefinitions />}
          {selectedTab === 1 && <NewUser />}
        </div>
      </div>
    </Box>
  );
};

export default Settings;