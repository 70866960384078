import React, { useState } from "react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import AddIcon from "@mui/icons-material/Add"; // Import the Add icon
import DeleteIcon from "@mui/icons-material/Delete";

const ImageSlider = ({ images, handlePopover, onDeleteIconClick, isEdit, disablecondition }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handleDeleteIconClick = () => {
    const clickedImageName = images[currentImageIndex];
    if (typeof onDeleteIconClick === "function") {
      onDeleteIconClick(clickedImageName, currentImageIndex);
      if (currentImageIndex === images.length - 1) {
        prevImage();
      }
    }
  };

  const styles = {
    imageSliderContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
      justifyContent: "center",
      textAlign: "center",
      position: "relative",
    },
    sliderImage: {
      maxWidth: "100%",
      height: "auto",
      borderRadius: "10px",
    },
    arrowButton: {
      backgroundColor: "transparent",
      border: "none",
      cursor: "pointer",
      padding: 0,
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
    },
    leftArrow: {
      left: "10px",
    },
    rightArrow: {
      right: "10px",
    },
    arrowIcon: {
      fontSize: "40px",
    },
    closeIcon: {
      position: "absolute",
      top: "10px",
      right: "10px",
      cursor: "pointer",
      fontSize: "30px",
    },
    numberContainer: {
      display: "flex",
      justifyContent: "center",
      marginTop: "10px",
    },
    number: {
      width: "20px",
      height: "20px",
      backgroundColor: "#ccc",
      borderRadius: "50%",
      margin: "5px",
      cursor: "pointer",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    activeNumber: {
      backgroundColor: "#B1174B",
      color: "white",
      fontSize: "12px",
    },
    iconContainer: {
      position: "absolute",
      bottom: "2px",
      right: "2px",
      display: "flex",
      alignItems: "center",
      marginTop: "10px !important",
    },
    addIcon: {
      fontSize: "30px",
      marginRight: "10px",
      cursor: "pointer",
      color: "#B1174B",
    },
    deleteIcon: {
      fontSize: "30px",
      cursor: "pointer",
      color: "red",
    },
  };

  return (
    <div>
      <div style={styles.imageSliderContainer}>
        <button
          style={{ ...styles.arrowButton, ...styles.leftArrow }}
          onClick={prevImage}
        >
          <KeyboardArrowLeftIcon style={styles.arrowIcon} />
        </button>
        <img
          style={styles.sliderImage}
          src={images[currentImageIndex]}
          alt={`Image ${currentImageIndex + 1}`}
        />
        <button
          style={{ ...styles.arrowButton, ...styles.rightArrow }}
          onClick={nextImage}
        >
          <KeyboardArrowRightIcon style={styles.arrowIcon} />
        </button>

        <div style={styles.numberContainer}>
          {images.map((_, index) => (
            <span
              key={index}
              style={{
                ...styles.number,
                ...(index === currentImageIndex ? styles.activeNumber : {}),
              }}
              onClick={() => setCurrentImageIndex(index)}
            >
              {index + 1}
            </span>
          ))}
        </div>

        {isEdit && !disablecondition && ( // Conditionally render Edit and Delete icons
          <div style={styles.iconContainer}>
            <AddIcon style={styles.addIcon} onClick={handlePopover} />
            <DeleteIcon
              style={styles.deleteIcon}
              onClick={handleDeleteIconClick}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageSlider;






// import React, { useState } from "react";
// import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
// import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
// import { makeStyles } from "@mui/styles";
// import AddIcon from "@mui/icons-material/Add"; // Import the Add icon
// import DeleteIcon from "@mui/icons-material/Delete";
// // import Swal from 'sweetalert2';

// const useStyles = makeStyles(() => ({
//   imageSliderContainer: {
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//     width: "100%",
//     justifyContent: "center",
//     textAlign: "center",
//     position: "relative",
//   },
//   sliderImage: {
//     maxWidth: "100%",
//     height: "auto",
//     borderRadius: "10px",
//   },
//   arrowButton: {
//     backgroundColor: "transparent",
//     border: "none",
//     cursor: "pointer",
//     padding: 0,
//     position: "absolute",
//     top: "50%",
//     transform: "translateY(-50%)",
//   },
//   leftArrow: {
//     left: "10px",
//   },
//   rightArrow: {
//     right: "10px",
//   },
//   arrowIcon: {
//     fontSize: "40px",
//   },
//   closeIcon: {
//     position: "absolute",
//     top: "10px",
//     right: "10px",
//     cursor: "pointer",
//     fontSize: "30px",
//   },
//   numberContainer: {
//     display: "flex",
//     justifyContent: "center",
//     marginTop: "10px",
//   },
//   number: {
//     width: "20px",
//     height: "20px",
//     backgroundColor: "#ccc",
//     borderRadius: "50%",
//     margin: "5px",
//     cursor: "pointer",
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//   },
//   activeNumber: {
//     backgroundColor: "#B1174B",
//     color: "white",
//     fontSize: "12px",
//   },
//   iconContainer: {
//     position: "absolute",
//     bottom: "2px",
//     right: "2px",
//     display: "flex",
//     alignItems: "center",
//     marginTop: "10px !important",
//   },
//   addIcon: {
//     fontSize: "30px",
//     marginRight: "10px",
//     cursor: "pointer",
//     color: "#B1174B",
//   },
//   deleteIcon: {
//     fontSize: "30px",
//     cursor: "pointer",
//     color: "red",
//   },
// }));

// const ImageSlider = ({ images, handlePopover, onDeleteIconClick, isEdit, disablecondition }) => {
//   const classes = useStyles();
//   const [currentImageIndex, setCurrentImageIndex] = useState(0);
//   const prevImage = () => {
//     setCurrentImageIndex((prevIndex) =>
//       prevIndex === 0 ? images.length - 1 : prevIndex - 1
//     );
//   };

//   const nextImage = () => {
//     setCurrentImageIndex((prevIndex) =>
//       prevIndex === images.length - 1 ? 0 : prevIndex + 1
//     );
//   };

//   const handleDeleteIconClick = () => {
//     const clickedImageName = images[currentImageIndex];
//     // Check if onDeleteIconClick is defined and is a function
//     if (typeof onDeleteIconClick === "function") {
//       // Call onDeleteIconClick function with the clicked image name and index
//       onDeleteIconClick(clickedImageName, currentImageIndex);
//       if (currentImageIndex === images.length - 1) {
//         prevImage();
//       }

//     }
//   };


//   return (
//     <div>
//       <div className={classes.imageSliderContainer}>
//         <button
//           className={`${classes.arrowButton} ${classes.leftArrow}`}
//           onClick={prevImage}
//         >
//           <KeyboardArrowLeftIcon className={classes.arrowIcon} />
//         </button>
//         <img
//           className={classes.sliderImage}
//           src={images[currentImageIndex]}
//           alt={`Image ${currentImageIndex + 1}`}
//         // onClick={handleImageClick}
//         />
//         <button
//           className={`${classes.arrowButton} ${classes.rightArrow}`}
//           onClick={nextImage}
//         >
//           <KeyboardArrowRightIcon className={classes.arrowIcon} />
//         </button>

//         <div className={classes.numberContainer}>
//           {images.map((_, index) => (
//             <span
//               key={index}
//               className={`${classes.number} ${index === currentImageIndex ? classes.activeNumber : ""
//                 }`}
//               onClick={() => setCurrentImageIndex(index)}
//             >
//               {index + 1}
//             </span>
//           ))}
//         </div>

//         {isEdit && !disablecondition && ( // Conditionally render Edit and Delete icons
//           <div className={classes.iconContainer}>
//             <AddIcon className={classes.addIcon} onClick={handlePopover} />
//             <DeleteIcon
//               className={classes.deleteIcon}
//               onClick={handleDeleteIconClick}
//             />
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };
// export default ImageSlider;